import React, { useRef, useState, useEffect } from "react";
import useOnScreen from "../../../hooks/useOnScreen";
import ImageShimmer from "../ImageShimmer/ImageShimmer";

export default function LazyImage({ src, width, height, className, alt }) {
  var [imageLoaded, setImageLoaded] = useState(false);
  var ref = useRef();
  var vis = useOnScreen(ref);

  useEffect(() => {
    if (vis) {
      ref.current
        .querySelector("img")
        .setAttribute(
          "src",
          ref.current.querySelector("img").getAttribute("data-src")
        );

      // ref.current.setAttribute("src", ref.get)
    }
  }, [vis]);

  return (
    <div ref={ref} className="w-100 h-100">
      {" "}
      <img
        alt={alt}
        data-src={src}
        className={className}
        style={imageLoaded ? {} : { display: "none" }}
        onLoad={() => setImageLoaded(true)}
      />
      {imageLoaded ? null : <ImageShimmer width={width} height={height} />}
    </div>
  );
}
