import React, { useState, useEffect } from "react";

function Tab({
  title,
  label,
  id,
  changeTab,
  active,
  itemClass,
  itemStyle = {},
  activeClass,
  activeStyle,
}) {
  return (
    <button
      className={
        (active == id ? " active" : "") +
        " " +
        (itemClass ? itemClass : "") +
        " " +
        (active == id ? activeClass : "")
      }
      style={active == id ? activeStyle : itemStyle}
      onClick={() => changeTab(id)}
    >
      {label ? label : title}
    </button>
  );
}
export default Tab;
