import { Marker, useLeaflet, Popup } from "react-leaflet";
import React, { useContext, useState } from "react";
import L from "leaflet";
import EditOptions from "../EditOptions/EditOptions";
import MapContext from "@app/context/MapContext";
import { Scrollbars } from "react-custom-scrollbars";
import ImageShimmer from "../../../ImageShimmer/ImageShimmer";
import LazyImage from "../../../LazyImage/LazyImage";

import PolylineDecorator from "./../PolylineDecorator/PolylineDecorator";
import StoryCard from "../../StoryCards/StoryCard/StoryCard";
function CustomMarker({
  node,
  preview,
  type,
  brandPosition,
  arrow,
  widgetName,
  nodeList,
}) {
  const [imageLoaded, setImageLoaded] = useState(false);
  var { brandId } = useContext(MapContext);

  return node?.type == "story" ? (
    <>
      <Marker
        onClick={(e) => {
          e.target.openPopup();
        }}
        key={node.id}
        position={[node.fields.location.lat, node.fields.location.lng]}
        icon={
          new L.Icon({
            iconUrl: node.fields.avatar
              ? node.fields.avatar
              : "https://rootip.io/sharedlayout/img/brand-img/1.png",
            iconRetinaUrl: node.fields.avatar,
            iconAnchor: new L.Point(20, 20),
            popupAnchor: new L.Point(0, -22),
            shadowUrl: null,
            shadowSize: null,
            shadowAnchor: null,
            iconSize: new L.Point(40, 40),
            className: `leaflet-div-icon  rounded-2 object-contain border-2 border-primary`,
          })
        }
      >
        <Popup className="story-card-popup ">
          <Scrollbars autoHide autoHeight autoHeightMax={360}>
            <StoryCard
              widgetName={widgetName}
              fromMap={true}
              story={node}
              setSwipe={() => {}}
              preview={preview}
            ></StoryCard>
          </Scrollbars>
        </Popup>
      </Marker>

      {/* <PolylineDecorator
        key={node.id + "xo"}
        color="#00a79e"
        patterns={arrow}
        positions={[
          [node.fields.location.lat, node.fields.location.lng],
          [brandPosition.lat, brandPosition.lng],
        ]}
      /> */}
    </>
  ) : (
    <Marker
      onClick={(e) => {
        e.target.openPopup();
      }}
      key={node.id}
      position={[node.fields.location.lat, node.fields.location.lng]}
      zIndexOffset={node.id == brandId ? 1000 : 0}
      icon={
        new L.Icon({
          iconUrl: node.fields.logo
            ? node.fields.logo
            : "https://rootip.io/sharedlayout/img/brand-img/1.png",
          iconRetinaUrl: node.fields.logo,
          iconAnchor:
            node.id == brandId ? new L.Point(30, 30) : new L.Point(20, 20),
          popupAnchor:
            node.id == brandId ? new L.Point(0, -32) : new L.Point(0, -22),
          shadowUrl: null,
          shadowSize: null,
          shadowAnchor: null,
          iconSize:
            node.id == brandId ? new L.Point(60, 60) : new L.Point(40, 40),
          className: `leaflet-div-icon circle object-contain ${
            node.id == brandId ? "border-2" : ""
          } border-primary`,
        })
      }
    >
      {preview ? (
        <EditOptions node={node} type={type} nodeList={nodeList} />
      ) : (
        <Popup>
          <Scrollbars autoHide autoHeight autoHeightMax={200}>
            {node.fields.coverImage && (
              <div className="w-300p h-100p">
                {/* <img
                  alt="cover"
                  src={node.fields.coverImage}
                  style={imageLoaded ? {} : { display: "none" }}
                  onLoad={() => setImageLoaded(true)}
                />
                {imageLoaded  ? null : <ImageShimmer height="100" width="300" />} */}
                <LazyImage
                  src={node.fields.coverImage}
                  alt="cover"
                  height="100"
                  width="300"
                  className="w-100 h-100 object-cover"
                />
              </div>
            )}
            <h1 className="mt-2 ">{node.fields.businessName}</h1>
            <p className="pre-line">{node.fields.description}</p>
          </Scrollbars>
        </Popup>
      )}
    </Marker>
  );
}

export default CustomMarker;
