// node modules
import React, { useEffect, useMemo, useState } from "react";

// components
import Topbar from "./Topbar";
import Sidebar from "./Sidebar";

import Footer from "./Footer";

// context
import Context from "@app/context/RootipAdminContext";

import { useLocation } from "react-router";

function RootipAdminLayout({
  children,
  pointList,
  pageTitle,
  isPrimary = false,
}) {
  // Metrics
  var [kpiTarget, setKpiTarget] = useState(null);
  var [brandsOnboarded, setBrandsOnboarded] = useState([]);
  var [suppliersSignedUp, setSuppliersSignedUp] = useState([]);
  var [b2cRegistrations, setB2cRegistrations] = useState([]);

  // KPIs
  var [report, setReport] = useState(null);

  // KPITabs
  var [websiteViews, setWebsiteViews] = useState({});
  var [tipsReport, setTipsReport] = useState({});
  var [scansReport, setScansReport] = useState({});
  var [tipsAmount, setTipsAmount] = useState({});
  var [productViews, setProductViews] = useState({});

  // context is the value provided for Context.Provider
  // first param is the assigned value
  // second param is the list of dependencies. here we prevent recalcuations on irrelevent rerenders
  var context = useMemo(
    () => ({
      kpiTarget,
      setKpiTarget,
      brandsOnboarded,
      setBrandsOnboarded,
      suppliersSignedUp,
      setSuppliersSignedUp,
      b2cRegistrations,
      setB2cRegistrations,
      report,
      setReport,
      websiteViews,
      setWebsiteViews,
      scansReport,
      setScansReport,
      tipsReport,
      setTipsReport,
      tipsAmount,
      setTipsAmount,
      productViews,
      setProductViews,
    }),
    [
      kpiTarget,
      setKpiTarget,
      brandsOnboarded,
      setBrandsOnboarded,
      suppliersSignedUp,
      setSuppliersSignedUp,
      b2cRegistrations,
      setB2cRegistrations,
      report,
      setReport,
      websiteViews,
      setWebsiteViews,
      scansReport,
      setScansReport,
      tipsReport,
      setTipsReport,
      tipsAmount,
      setTipsAmount,
      productViews,
      setProductViews,
    ]
  );
  var [open, setOpen] = useState(true);

  return (
    <Context.Provider value={context}>
      <div className="wrapper">
        <Topbar open={open} setOpen={setOpen} />
        <Sidebar open={open} />
        <div className="content">
          <main>
            <div className="content-box">{children}</div>
          </main>
          <Footer />
        </div>
      </div>
      <style jsx global>{`
        body {
          background-color: var(--color-background);
          overflow: hidden;
        }
      `}</style>
      <style jsx>{`
        .wrapper {
          display: grid;
          grid-template-columns: auto 1fr;
          grid-template-rows: 70px calc(100vh - 70px);
          grid-template-areas:
            "sidebar topbar"
            "sidebar content";
        }
        .content {
          grid-area: content;
          overflow: auto;
        }
        .content-box {
          padding: 2rem;
        }
      `}</style>
    </Context.Provider>
  );
}
export default RootipAdminLayout;
