import apiClient from "./apiClient";

function addNode(credentials) {
  return apiClient("base").post("/addNode", credentials);
}

function getNode(nodeId) {
  return apiClient("base").post("/getnode", { nodeId });
}

function updateNode(credentials) {
  return apiClient("base").post("/updateNode", credentials);
}

function removeNode(nodeId) {
  return apiClient("base").post("/removeNode", { nodeId });
}

function getNodeList(brandId) {
  return apiClient("base").post("/getNodeList", { brandId });
}

function getNodeChain(nodeId) {
  return apiClient("base").post("/getNodeChain", { nodeId });
}

function linkRequest(credentials) {
  return apiClient("base").post("/linkRequest", credentials);
}

function getBrandTypes() {
  return apiClient("base").post("/getBrandTypes");
}
export default {
  addNode,
  getNode,
  updateNode,
  removeNode,
  getNodeList,
  getNodeChain,
  linkRequest,
  getBrandTypes,
};
