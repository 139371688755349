import apiClient from "./apiClient";

// Metrics
function kpiTarget() {
  return apiClient("base").post("/kpiTarget");
}

function brandsOnboarded(points) {
  return apiClient("base").post("/brandsOnboarded", { points });
}

function suppliersSignedUp(points) {
  return apiClient("base").post("/suppliersSignedUp", { points });
}

function b2cRegistrations(points) {
  return apiClient("base").post("/b2cRegistrations", { points });
}

// KPIs
function kpiReport() {
  return apiClient("base").post("/kpiReport");
}
function reportStoryViewHistory() {
  return apiClient("base").post("/reportStoryVeiwHistory");
}
function engagementReports() {
  return apiClient("base").post("/engagementReports");
}
// KPITabs
function websiteViewsReport(points) {
  return apiClient("base").post("/websiteViewsReport", points);
}
function scansReport(points) {
  return apiClient("base").post("/scansReport", points);
}
function tipsReport(points) {
  return apiClient("base").post("/tipsReport", points);
}
function tipsAmountReport(points) {
  return apiClient("base").post("/tipsAmountReport", points);
}
function productViewsReport(points) {
  return apiClient("base").post("/productViewsReport", points);
}

function transparencyRanking(groupName) {
  return apiClient("base").post("/transparencyRanking", { groupName });
}

function transparencyTablesClass(groupName) {
  return apiClient("base").post("/transparencyTablesClassLength", {
    groupName,
  });
}

function getTransparencyPoints() {
  return apiClient("base").post("/getTransparencyPoints");
}
function setTransparencyPoints(points) {
  return apiClient("base").post("/setTransparencyPoints", { points });
}
function getUsageStories() {
  return apiClient("base").post("/storyUsageReport");
}
function getAiReport(params) {
  return apiClient("ai").get("/scrape?", params);
}
function aiUpdateConcepts(params) {
  return apiClient("base").post("/aiUpdateConcepts", params);
}
function storyUsageStateReport() {
  return apiClient("base").post("/storyUsageStateReport");
}
function setAiScanLog(scrapeUrls, scanList) {
  return apiClient("base").post("/aiScanLog", {
    scrapeUrls,
    scanList,
  });
}

function transparencyStatisticsTrigger() {
  return apiClient("base").post("/transparencyStatistics_trigger");
}

function storyUsageStateTrigger() {
  return apiClient("base").post("/storyUsageState_trigger");
}

function connectionTrigger() {
  return apiClient("base").post("/connection_trigger");
}

function triggerLastUpdate() {
  return apiClient("base").post("/triggerLastUpdate");
}

export default {
  kpiReport,
  reportStoryViewHistory,
  kpiTarget,
  brandsOnboarded,
  suppliersSignedUp,
  b2cRegistrations,
  websiteViewsReport,
  scansReport,
  tipsReport,
  tipsAmountReport,
  productViewsReport,
  engagementReports,
  transparencyRanking,
  transparencyTablesClass,
  getTransparencyPoints,
  setTransparencyPoints,
  getUsageStories,
  storyUsageStateReport,
  getAiReport,
  setAiScanLog,
  transparencyStatisticsTrigger,
  storyUsageStateTrigger,
  connectionTrigger,
  triggerLastUpdate,
  aiUpdateConcepts,
};
