import React, { useContext, useEffect } from "react";
import L from "leaflet";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { useLeaflet } from "react-leaflet";
import MapContext from "../../../../../context/MapContext";
import EditOptions from "../EditOptions/EditOptions";
import ReactDOMServer from "react-dom/server";

const mcg = L.markerClusterGroup({
  spiderLegPolylineOptions: { weight: 1.5, color: "#00a79e", opacity: 0.5 },
  maxClusterRadius: 40,
});

const MarkerCluster = ({ markers,nodeList}) => {
  const { map } = useLeaflet();
  var { brandId, preview } = useContext(MapContext);

  useEffect(() => {
    mcg.clearLayers();
    markers.forEach((marker) => {
      L.marker(
        new L.LatLng(marker.fields.location.lat, marker.fields.location.lng),
        {
          icon: new L.Icon({
            iconUrl: marker.fields.logo
              ? marker.fields.logo
              : "https://rootip.io/sharedlayout/img/brand-img/1.png",
            iconRetinaUrl: marker.fields.logo,
            iconAnchor:
              marker.id == brandId ? new L.Point(30, 30) : new L.Point(20, 20),
            popupAnchor:
              marker.id == brandId ? new L.Point(0, -32) : new L.Point(0, -22),
            shadowUrl: null,
            shadowSize: null,
            shadowAnchor: null,
            iconSize:
              marker.id == brandId ? new L.Point(60, 60) : new L.Point(40, 40),
            className: `leaflet-div-icon circle object-contain ${
              marker.id == brandId ? "border-2" : ""
            } border-primary`,
          }),
        }
      )
        .bindPopup(preview ? <EditOptions node={marker} nodeList={nodeList}/> : "no")
        .addTo(mcg);
    });

    // optionally center the map around the markers
    // map.fitBounds(mcg.getBounds());
    // // add the marker cluster group to the map
    map.addLayer(mcg);
  }, [markers, map]);

  return null;
};

export default MarkerCluster;
