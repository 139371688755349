/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from "react";
import useValidation from "../../../../hooks/useValidation";
import Layout from "../Layout/Layout";
import { firebase } from "@app/constants/constants";
import Swal from "sweetalert2";
import "sweetalert2";
import Context from "@app/context/AppContext";
import authServices from "../../../../services/authServices";
import Icon from "../../../common/Icon/Icon";
export const clearLocalStorage = () => {
  localStorage.removeItem("user", "");
  localStorage.removeItem("paymentMethods", "");
  localStorage.removeItem("userId", "");
  localStorage.removeItem("rootipper", "");
  localStorage.removeItem("rootipperId", "");
  localStorage.removeItem("grantedBrands", "");
};
const login = () => {
  const { setUser } = useContext(Context);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const formChange = (params) => {
    setData({ ...data, ...params });
  };
  const { ValidationHandler, errors } = useValidation();

  useEffect(() => {
    ValidationHandler({
      rule: "email",
      value: data.email,
      key: "email",
    });
    ValidationHandler({
      rule: "password",
      value: data.password,
      key: "password",
    });
  }, [data]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (userRecord) {
      setLoading(true);

      if (userRecord) {
        firebase
          .auth()
          .currentUser?.getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            //get user data by token
            loginHandler(idToken);
          });
      } else {
        setLoading(false);
      }
    });
  }, []);

  const loginValidation = ({
    user,
    userId,
    rootipper,
    paymentMethod,
    grantedBrands,
    rootipperId,
  }) => {
    if (user.state.isActive) {
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("paymentMethods", JSON.stringify(paymentMethod));
      localStorage.setItem("userId", JSON.stringify(userId));
      localStorage.setItem("rootipper", JSON.stringify(rootipper));
      localStorage.setItem("rootipperId", JSON.stringify(rootipperId));
      localStorage.setItem("grantedBrands", JSON.stringify(grantedBrands));
      setUser({
        user,
        userId,
        rootipper,
        paymentMethod,
        grantedBrands,
        rootipperId,
      });
      if (grantedBrands.length > 0) {
        localStorage.removeItem("rootipper");
        localStorage.removeItem("rootipperId");
        multiLoginModalHandler({
          user,
          rootipper,
          rootipperId,
          grantedBrands,
        });
      } else if (user.role.RootipAdmin === true) {
        window.location = window.location.toString().includes("localhost")
          ? "/rootipadmin/brands.html"
          : "/app/rootipadmin";
      } else if (user.role.B2BOwner === true || user.role.B2BAdmin) {
        if (rootipper) {
          if (rootipper.state.isActive === true) {
            window.location = "/admin/overview.html";
          } else {
            setUser({});
            Swal.fire({
              icon: "error",
              text:
                "Please wait for Rootip admin to approve your account. If you are experiencing any difficulties please contact us at info@rootip.io",
              button: "ok",
              confirmButtonColor: "var(--color-primary)",
            });
            clearLocalStorage();
            firebase.auth().signOut();
          }
        }
      } else if (user.role.B2BEmployee === true) {
        window.location = "/employee/dashboard.html";
      } else if (user.role.B2CUser === true) {
        window.location = "/";
      } else {
        clearLocalStorage();
        firebase.auth().signOut();
      }
    } else {
      setUser({});
      firebase.auth().signOut();
      Swal.fire({
        icon: "error",
        text:
          "Please wait for Rootip admin to approve your account. If you are experiencing any difficulties please contact us at info@rootip.io",
        button: "ok",
        confirmButtonColor: "var(--color-primary)",
      });
    }
  };
  const loginHandler = (idToken) => {
    authServices.getCurrentUser(idToken).then((user) => {
      setLoading(false);
      loginValidation(user);
      console.log("step3", user);
    });
  };

  const multiLoginModalHandler = ({
    rootipper,
    rootipperId,
    grantedBrands,
  }) => {
    let list = [
      ...grantedBrands.map((item) => ({ current: false, ...item })),
      { id: rootipperId, fields: rootipper, current: true },
    ];
    Swal.fire({
      title: "you can login with below brand List ",
      html: `

      ${list.map(
        (dom) => `
        <div class="w-full flex " id="login_modal">
        <input id="${dom?.id}" name="loginSwitch" type="radio" checked="${
          dom?.current ? "true" : ""
        }" value="${dom?.id}" > 
        <span class="mx-2">
          <img src="${
            dom?.fields?.logoLowRes
          }" width="30px" height="30px" class="rounded-lg border"/>
        </span>
        <label for="${dom?.id}" class="mx-2">
           ${dom?.fields?.title} ${dom?.current ? "(current Bussiness)" : ""}
        </label>
     
       </div>
        `
      )}
       
       
       `,
      confirmButtonColor: "var(--color-primary)",

      focusConfirm: false,
      preConfirm: () => {
        return [
          ...list
            .map((input) => {
              let target = document.getElementById(input.id);
              if (target.checked) {
                return target.value;
              }
            })
            .filter((item) => item != undefined),
        ];
      },
    }).then((res) => {
      let cur = list.find((curB) => curB?.id == res?.value?.[0]);

      localStorage.setItem("allowContinue", JSON.stringify(true));
      localStorage.setItem("rootipper", JSON.stringify(cur.fields));
      localStorage.setItem("rootipperId", JSON.stringify(cur.id));
      setTimeout(() => {
        window.location = "/admin/overview.html";
      }, 1000);
    });
  };
  const login = () => {
    setLoading(true);

    if (errors.length > 0) {
      Swal.fire({
        icon: "error",
        text: "Invalid email or password, please try again.",
        button: "ok",
        confirmButtonColor: "var(--color-primary)",
      });
    } else {
      firebase
        .auth()
        .signInWithEmailAndPassword(data.email, data.password)

        .catch((error) => {
          setLoading(false);
          var errorCode = error.code;
          var errorMessage = error.message;
          if (errorCode === "auth/wrong-password") {
            Swal.fire({
              icon: "error",
              title: "Oops",
              text: " Invalid email or password, please try again.",
              confirmButtonColor: "var(--color-primary)",
            });
          } else if (errorCode === "auth/invalid-email") {
            Swal.fire({
              icon: "error",
              title: "Oops",
              text: " Invalid email or password, please try again.",
              confirmButtonColor: "var(--color-primary)",
            });
          } else if (errorCode === "auth/user-not-found") {
            Swal.fire({
              icon: "error",
              title: "Oops",
              text:
                "There is no user record corresponding to this identifier. The user may have been deleted.",
              confirmButtonColor: "var(--color-primary)",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "oops",
              text: errorMessage,
              confirmButtonColor: "var(--color-primary)",
            });
          }
        });
    }
  };

  function LoginwithGoogle() {
    var provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)

      .catch(function (error) {});
  }

  return (
    <Layout>
      <div className="w-full flex justify-center">
        <div className="w-full max-w-[600px] sm:px-0 px-16 h-full flex flex-col items-center justify-center ">
          <div className="text-[65px] mt-10  ">
            <span style={{ color: "#00a79e", fontWeight: "bold" }}>S</span>
            <span style={{ color: "#18ac8e", fontWeight: "bold" }}>i</span>
            <span style={{ color: "#2fb17e", fontWeight: "bold" }}>g</span>
            <span style={{ color: "#47b66f", fontWeight: "bold" }}>n</span>
            <span style={{ color: "#5ebc5f", fontWeight: "bold" }}> </span>
            <span style={{ color: "#76c14f", fontWeight: "bold" }}>i</span>
            <span style={{ color: "#8dc63f", fontWeight: "bold" }}>n</span>
          </div>

          {/* <div className="w-[300px] h-16 flex items-stretch overflow-hidden border-primary border rounded-xl mt-5 ">
            <bottom className="w-1/2 h-full bg-primary text-white text-center flex items-center justify-center  ">
              Business
            </bottom>
            <bottom className="w-1/2 h-full bg-white text-black  text-center flex items-center justify-center  ">
              Customer
            </bottom>
          </div> */}

          <div className="w-full text-left mt-10">
            <input
              type="text"
              name="email"
              className={`rounded-lg border h-[40px] px-5 w-full outline-none  `}
              id="email"
              onChange={(e) => {
                formChange({
                  email: e.target.value,
                });
              }}
              placeholder=" Email"
            />

            <input
              type="password"
              name="password"
              className={`rounded-lg border h-[40px] px-5 w-full mt-5 outline-none  `}
              id="password"
              onChange={(e) => {
                formChange({
                  password: e.target.value,
                });
              }}
              placeholder=" Password"
            />

            <div>
              <div className="checkbox-group move-left mt-[20px] flex flex-wrap items-center justify-between">
                <div className="w-fit">
                  <input
                    type="checkbox"
                    id="checkBox12"
                    name="checkbox"
                    defaultChecked
                  />
                  <span className=" px-3">
                    <span className="check" />
                    <span className="box" />
                    Stay signed in
                  </span>
                </div>
                <div className="w-fit text-right">
                  Forgot your password?{" "}
                  <a
                    id="forgotPass"
                    className="link-text text-primary"
                    href="/pasrecover.html"
                  >
                    Click here
                  </a>
                </div>
              </div>
            </div>

            <div className="mt-10">
              <button
                disabled={
                  !(data?.password?.length > 1) ||
                  !(data?.email?.length > 1) ||
                  loading
                }
                onClick={() => {
                  if (data?.password?.length > 1 && data?.email?.length > 1) {
                    login();
                  }
                }}
                className={`rounded-lg   w-full h-[40px]   bg-primary hover:bg-PrimaryDark text-white flex justify-center items-center `}
              >
                {loading ? "loading..." : " Sign in"}
              </button>
            </div>
            <div className="text-center  mt-10">
              Don’t have an account?{" "}
              <a className="text-primary" id="getStart" href="/signup.html">
                Get started
              </a>
            </div>
            <div className="text-center py-9 ">
              <span className="or-text">Or</span>
            </div>
            <div
              disabled={loading}
              className="w-full border rounded-lg h-[40px] flex justify-center items-center cursor-pointer"
              onClick={() => {
                LoginwithGoogle();
              }}
            >
              <span className="mx-2">
                <Icon name="gg-icon" size="24" viewBox=" 0 0 500 500" />
              </span>
              {loading ? "loading..." : "     Continue with Google"}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default login;
