import React, { useContext } from "react";
import ReactPlayer from "react-player";
import Modal from "@common/Modal/Modal";
import StoriesContext from "@app/context/StoriesContext";

import Button from "@common/Button/Button";
import { FaTimes } from "@react-icons";

function VideoModal(props) {
  console.log(props);

  if (Object.keys(props).length == 0) {
    var { videoSrc, videoModalState, videoModalSend, widgetName } = useContext(
      StoriesContext
    );
  } else {
    var { videoSrc, videoModalState, videoModalSend, widgetName } = props;
  }
  return (
    <Modal
      visible={!videoModalState.matches("idle")}
      shadowDOM={widgetName}
      bg="bg-light"
    >
      <Button
        type="link"
        action={() => {
          videoModalSend("CLOSE");
        }}
        className="flex  ml-auto"
      >
        <FaTimes size="32" className="p-3" />
      </Button>
      <ReactPlayer
        onReady={() => videoModalSend("RESOLVE")}
        onError={() => videoModalSend("REJECT")}
        url={videoSrc}
        controls
        playing
        className="max-w-screen-90"
        config={{
          vimeo: {
            playerOptions: {
              playsinline: true,
            },
          },
        }}
      />
    </Modal>
  );
}

export default VideoModal;
