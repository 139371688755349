import React from "react";
import { Map } from "react-leaflet";
import greyMap from "@app/assets/images/GreyMap.png";
import { ROOT_ID } from "@app/constants/constants";
function MapShimmer() {
  return (
    <Map
      zoom={2}
      scrollWheelZoom={true}
      minZoom={1}
      maxBoundsViscosity={1.0}
      className="flex items-center justify-center z-1 relative"
    >
      <div className="shimmer-overlay"></div>
      <img
        style={{
          height: "inherit",
          objectFit: "cover",
        }}
        src={ROOT_ID + greyMap}
        alt="Map Shimmer"
      ></img>
    </Map>
  );
}

export default MapShimmer;
