import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { format } from "date-fns";

function LineChart(props) {
  var { data } = props;

  var ref = useRef();

  // 5. X scale will use the index of our data
  var xExtent = d3.extent(data, (d) => d.key);
  var xScale = d3
    .scaleTime()
    .domain(xExtent) // input
    .range([0, 100]); // output

  // 6. Y scale will use the randomly generate number
  var yExtent = d3.extent(data, (d) => d.value);
  var [min, max] = yExtent;
  var yScale = d3
    .scaleLinear()
    .domain([min > 10 ? min - 10 : 0, max + 10]) // input
    .range([100, 0]); // output

  // 7. d3's line generator
  var line = d3
    .line()
    .x((d) => xScale(d.key)) // set the x values for the line generator
    .y((d) => yScale(d.value)); // set the y values for the line generator
  // .curve(d3.curveMonotoneX); // apply smoothing to the line

  useEffect(() => {
    drawChart();
  }, [data]);

  function drawChart() {
    // 1. Add the SVG to the page and employ #2
    var svg = d3
      .select(ref.current)
      .attr("viewBox", "-30 -10 140 140")
      .append("g");

    svg
      .append("g")
      .attr("class", "grid")
      .call(d3.axisLeft(yScale).tickSize(-100).tickFormat(""));

    svg
      .append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + 100 + ")")
      .call(d3.axisBottom(xScale).ticks(data.length))
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("y", "0")
      .attr("dx", "0")
      .attr("dy", "0")
      .attr("transform", "rotate(-90)");

    // 4. Call the y axis in a group tag
    svg.append("g").attr("class", "y axis").call(d3.axisLeft(yScale).ticks(5)); // Create an axis component with d3.axisLeft

    // 9. Append the path, bind the data, and call the line generator
    svg
      .append("path")
      .datum(data) // 10. Binds data to the line
      .attr("class", "line") // Assign a class for styling
      .attr("d", line); // 11. Calls the line generator

    // Define the div for the tooltip
    var div = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    // 12. Appends a circle for each datapoint
    svg
      .selectAll(".dot")
      .data(data)
      .enter()
      .append("circle") // Uses the enter().append() method
      .attr("class", "dot") // Assign a class for styling
      .attr("cx", function (d) {
        return xScale(d.key);
      })
      .attr("cy", function (d) {
        return yScale(d.value);
      })
      .attr("r", 3)
      .on("mouseover", function (a, b) {
        div.transition().duration(200).style("opacity", 1);
        div
          .html(
            `<h3 class="tooltip-date">${format(b.key, "yyyy/M/dd")}<h4>
             <h2 class="tooltip-value">${b.value}<h2>
        `
          )
          .style("padding", "1rem")
          .style("background-color", "var(--color-light)")
          .style("border-radius", "3px")
          .style(
            "box-shadow",
            "rgba(0,0,0,0.02) 0px 1px 3px 0px, rgba(27,31,35,0.15) 0px 0px 0px 1px"
          )
          .style("position", "absolute")
          .style("z-index", 100)
          .style("left", a.pageX - 120 + "px")
          .style("top", a.pageY - 48 + "px");
      })
      .on("mouseout", function () {
        div
          .transition()
          .duration(200)
          .style("z-index", -100)
          .style("opacity", 0);
      });
  }

  return (
    <>
      <svg ref={ref}></svg>
      <style jsx global>{`
        .tooltip-date {
          color: var(--color-primary);
        }
      `}</style>
    </>
  );
}

export default LineChart;
