import React, { useMemo, useState, useEffect, useRef } from "react";
import service from "@app/services/rootipAdminServices";
import useFetch from "../../../../hooks/useFetch";
import LoaderIcon from "@common/LoaderIcon/LoaderIcon";
import RootipAdminLayout from "../../../layout/admin/RootipAdminLayout";
import TitleBox from "@common/TitleBox/TitleBox";
import CusDataTable from "../../Admin/common/CusDataTable";
import style from "!!raw-loader!../../../../vendors/styles/dataTable.css?id=15";
import Swal from "sweetalert2";

function AiReport() {
  var [aiReport, setAiReport] = useState();
  var urlRef = useRef();
  var [crawlDepth, setCrawlDepth] = useState(1);

  var [url, setUrl] = useState();

  var [, AiReportState, AiReportSend] = useFetch(
    service.getAiReport,
    [{ scrapeUrls: url, crawlDepth: crawlDepth }],
    (res) => {
      setAiReport(res);
      setUrl(null);
    }
  );

  var [, AiScanLogState, AiScanLogSend] = useFetch(
    service.setAiScanLog,
    [url, aiReport],
    (res) => {}
  );

  var [, conceptState, conceptSend] = useFetch(
    service.aiUpdateConcepts,
    [],
    (res) => {
      if (res.type === "success") {
        Swal.fire({
          icon: "success",
          type: "success",
          title: "success!",
          text: res.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          type: "error",
          title: "oops!",
          text:
            "Please contact with ai scan admin with this log:" +
            res.data.message,
        });
      }
    }
  );

  useEffect(() => {
    if (url) AiReportSend("FETCH");
  }, [url]);

  useEffect(() => {
    if (aiReport) AiScanLogSend("FETCH");
  }, [aiReport]);

  const submit = () => {
    setUrl(urlRef.current.value);
  };

  const updateConcept = () => {
    conceptSend("FETCH");
  };
  const columns = [
    {
      name: "Story Title",
      selector: (row) => row.itemKeyword,
      sortable: true,
    },

    {
      name: "Chapter Name",
      selector: (row) => row.storyChapter,
      sortable: true,
    },
    {
      name: "Text",
      selector: (row) => row.itemText,
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row.itemUrl,
      sortable: true,
    },
  ];

  const customStyles = {
    rows: {
      style: { cursor: "pointer" },
    },

    headCells: {
      style: {
        fontWeight: 800, // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
  };

  return (
    <>
      <style>{style}</style>
      <RootipAdminLayout
        subMenu={[]}
        pageTitle={"Ai Scan Report"}
        pointList={[
          {
            title: "",
            href: "",
          },
        ]}
      >
        <TitleBox title="AI Scan Report" />

        <>
          <div className="flex flex-col pb-2">
            <div className="w-100">
              <button
                className="border-primary float-right  p-2  -right-full  border rounded-1 bg-primary text-light "
                onClick={updateConcept}
              >
                Update Concepts
              </button>
            </div>
            <div className="w-100 ">
              <label className="form-check-label mr-4 " htmlFor="one">
                <input
                  className="form-check-input mr-1"
                  type="radio"
                  name="scan-option"
                  id="one"
                  defaultChecked={true}
                  onChange={() => setCrawlDepth(1)}
                />
                Only This Page
              </label>

              <label className="form-check-label " htmlFor="all">
                <input
                  className="form-check-input mr-1"
                  type="radio"
                  name="scan-option"
                  id="all"
                  onChange={() => setCrawlDepth(3)}
                />
                All Website
              </label>
            </div>
          </div>
          <div className="flex flex:row mb-2">
            <input
              type="text"
              name="url"
              className="w-100 p-2 border"
              ref={urlRef}
            />

            <button
              className="border-primary  p-2 ml-1 border rounded-1 bg-primary text-light tag_printQr"
              onClick={submit}
            >
              Scan
            </button>
          </div>
        </>
        {AiReportState.matches("pending") ? (
          <>
            <LoaderIcon width="80" height="80" />
          </>
        ) : (
          <>
            {aiReport && (
              <CusDataTable
                data={aiReport}
                columns={columns}
                emptyMessage="There are no result"
                customStyles={customStyles}
              />
            )}
          </>
        )}
      </RootipAdminLayout>
    </>
  );
}

export default AiReport;
