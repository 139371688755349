import React from "react";
import ReactDOM from "react-dom";

import App from "./app/App";
import "@app/styles/main.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
ReactDOM.render(<App />, document.getElementById("root"));
