import { Machine } from "xstate";

const fetchMachine = Machine({
  initial: "idle",
  states: {
    idle: {
      on: {
        FETCH: {
          target: "pending",
        },
        DENY: {
          target: "denied",
        },
      },
    },
    pending: {
      entry: "goFetch",
      on: {
        FETCH: {
          target: "pending",
        },
        RESOLVE: {
          target: "resolved",
        },
        REJECT: {
          target: "rejected",
        },
      },
    },
    resolved: {
      on: {
        FETCH: {
          target: "pending",
        },
      },
    },
    rejected: {
      on: {
        FETCH: {
          target: "pending",
        },
      },
    },
    denied: {},
  },
});

export default fetchMachine;
