/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
const SideBar = ({ OpenStatus, SetStatus, children }) => {
  const [open, setOpen] = useState();
  useEffect(() => {
    setOpen(OpenStatus);
  }, [OpenStatus]);

  return (
    <>
      <nav
        className={`
      w-[270px] fixed  h-full bg-white  top-0 transition ${
        !open ? " -translate-x-full" : "sm:shadow-lg"
      }
      sm:hidden block
      `}
        style={{ zIndex: 999999 }}
      >
        <div
          className="w-full h-20 border-b  flex items-center justify-start cursor-pointer pl-3"
          onClick={() => SetStatus(false)}
        >
          <span className="mx-2">
            <IoCloseSharp />
          </span>
          <span className=" mx-2">close</span>
        </div>

        {children}
      </nav>
      <div
        onClick={() => SetStatus(false)}
        className={`w-full h-full fixed opacity-50 bg-black       sm:hidden block ${
          open ? "block" : "hidden"
        }`}
        style={{ zIndex: 999998 }}
      ></div>
    </>
  );
};

export default SideBar;
