import React, { createContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
export const QrOptionsContext = createContext({})
const QrProvider = ({ children }) => {
    const location = useLocation()
    const [qrOptions, setQrOptions] = useState({qrOptionStatus:false})
    useEffect(() => {
        //check if qrcode flag is in url 
        const status = new URLSearchParams(location.search).get("qrcode");

        setQrOptions(prev => ({ ...prev, qrOptionStatus: status??false }))
 
 
    }, [location.search])
    return (
        <QrOptionsContext.Provider value={{ qrOptions: qrOptions, setOption: (params) => setQrOptions(prev => ({ ...prev, ...params })) }}>
            {children}
        </QrOptionsContext.Provider>


    )
}

export default QrProvider