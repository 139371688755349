import React from "react";

import WebsiteViews from "./WebsiteViews/WebsiteViews";
import Scans from "./Scans/Scans";
import Tips from "./Tips/Tips";
import TipsAmount from "./TipsAmount/TipsAmount";
import Tabs from "@common/Tabs/Tabs";
import ProductViews from "./ProductViews/ProductViews";

import service from "@app/services/rootipAdminServices";

function KPITabs() {
  return (
    <>
      <Tabs
        selector="overview-tabs"
        items={[
          <WebsiteViews
            key="0"
            title="Website Views"
            service={service.websiteViewsReport}
          />,
          <Scans key="1" title="Scans" service={service.scansReport} />,
          <Tips key="2" title="Tips" service={service.tipsReport} />,
          <TipsAmount
            key="3"
            title="Tips Amount"
            service={service.tipsAmountReport}
          />,
          <ProductViews
            key="4"
            title="Product Views"
            service={service.productViewsReport}
          />,
        ]}
      />

      <style jsx global>{`
        .overview-tabs {
          display: flex;
          flex-direction: column;
          min-width: 60%;
        }
        .overview-tabs_tabs {
          position: relative;
          z-index: 10;
          transform: translateY(-2px);
          border-radius: var(--border-radius-general)
            var(--border-radius-general) 0 0;
        }
        .overview-tabs_tabs ul {
          display: flex;
          transform: translateY(2px);
        }
        .overview-tabs_tabs li {
          display: flex;
        }
        .overview-tabs_tabs li:not(:last-child) {
          margin-right: 0.2rem;
        }
        .overview-tabs_tabs button {
          background-color: #ccc;
          text-decoration: none;
          border-radius: var(--border-radius-general)
            var(--border-radius-general) 0 0;
          padding: 0.5rem 1rem;
          color: #000;
          border: none;
          cursor: pointer;
        }
        .overview-tabs_tabs button.active {
          background-color: #fff;
          box-shadow: rgba(255, 255, 255, 1) 0px 2px 0px 0px,
            rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
            rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        }

        .overview-tabs_view {
          position: relative;
          box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
            rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
          border-radius: 0 var(--border-radius-general)
            var(--border-radius-general) var(--border-radius-general);
          padding: 1rem;
          background-color: var(--color-light);
          height: 100%;
        }
        .overview-tabs_view_item {
          display: none;
        }
        .overview-tabs_view_item.active {
          display: initial;
        }
      `}</style>
    </>
  );
}

export default KPITabs;
