import { Machine } from "xstate";

const engagementMachine = Machine({
  id: "engagement",
  initial: "idle",
  states: {
    idle: {
      on: {
        READ_MORE: "moreRead",
        SLIDE_STORIES: "storiesSlid",
        PLAY_VIDEO: "videoPlayed",
        SLIDE_MEDIA: "mediaSlid",
        SCROLL_CHAPTERS: "chaptersScrolled",
        SELECT_CHAPTERS: "chapterSelected",
        CLICK_POWERED_BY: "poweredByClicked",
        SELECT_MAP: "mapSelected",
        SELECT_TRACKER: "trackerSelected",
        SELECT_EVIDENCE: "evidenceSelected",
      },
    },
    moreRead: {
      on: {
        DISMISS: "idle",
      },
    },
    storiesSlid: {
      on: {
        DISMISS: "idle",
      },
    },
    videoPlayed: {
      on: {
        DISMISS: "idle",
      },
    },
    mediaSlid: {
      on: {
        DISMISS: "idle",
      },
    },
    chaptersScrolled: {
      on: {
        DISMISS: "idle",
      },
    },
    chapterSelected: {
      on: {
        DISMISS: "idle",
      },
    },
    poweredByClicked: {
      on: {
        DISMISS: "idle",
      },
    },
    mapSelected: {
      on: {
        DISMISS: "idle",
      },
    },
    trackerSelected: {
      on: {
        DISMISS: "idle",
      },
    },
    evidenceSelected: {
      on: {
        DISMISS: "idle",
      },
    },
  },
});

export default engagementMachine;
