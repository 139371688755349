/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-unresolved */
import React, { useContext, useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import { Link } from "react-router-dom";
import ImageSlider from "../../../../common/ImageSlider/ImageSlider";
import { createSellerWebsiteClickHistory, getCollectionDetails } from "../../../../../services/WebsiteRequeries";
import LazyLoadImageComponent from "../../Components/LazyLoadImageComponent";
import { IoLocationSharp } from "react-icons/io5";
import ProductDetailsShimmer from "../../Components/Shimmers/ProductDetailsShimmer";
import SliderShimmer from "../../Components/Shimmers/SliderShimmer";
import Stories from "@common/Stories/Stories";

import ProductCollectionContainer from "./ProductCollectionContainer";
import { QrOptionsContext } from "../../Provider/QrProvider";
import { extractTextFromHtml } from "../../../../../utils/helper";
const Collection = (props) => {
  const [productData, setProductData] = useState({});
  const [loading, setLoading] = useState(true);
  const [readMore, setReadMore] = useState(false);
  const { qrOptions, setOption } = useContext(QrOptionsContext);

  const qrOwnerId = new URLSearchParams(location.search).get("qrOwnerId");
  const sendCount = ({ collectionId, brandId }) => {
    let data = {
      collectionId: collectionId,
      brandId: brandId,
      source: "web",
      userId: "",
    };
    createSellerWebsiteClickHistory(data);
  };
  useEffect(() => {
    setLoading(true);
    getCollectionDetails({
      id: props.match.params.id,
      ...(qrOwnerId ? { qrOwnerId: qrOwnerId } : {}),
    })
      .then((res) => {
        setLoading(false);
        setProductData({
          product: res.collection,
          ...res,
        });
        setOption(res?.brand?.fields?.customizeLandingPageOptions);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [qrOwnerId]);

  let qrStatus = !qrOptions?.qrOptionStatus;
  return (
    <Layout subMenu={[]}>
      {qrOptions?.showHeaderFooter || qrStatus ? (
        <div className="w-full p-2 bg-secondaryLight mt-10">
          <div className="container">
            <ul className="ml-3 flex text-lg">
              <li className="mr-2">
                <Link className="text-primary" to="/explore">
                  Explore
                </Link>
              </li>
              /
              <li className="mx-2">
                <Link className="text-primary" to="/explore/products">
                  Collection
                </Link>
              </li>
              /
              <li className="ml-2">
                <span className="productTitle">
                  {productData?.product?.fields?.title}
                </span>
              </li>
            </ul>
          </div>
        </div>
      ) : null}

      <div className="w-full h-auto ">
        <div className="container ">
          {qrOptions?.showCollectionDetails || qrStatus ? (
            <div className="w-full flex flex-wrap mt-2 ">
              <div className="sm:w-1/2 w-full p-3">
                {loading ? (
                  <SliderShimmer />
                ) : (
                  <ImageSlider>
                    {productData?.product?.fields?.media
                      ?.filter((item) => item.type == "image")
                      ?.map((image) => (
                        <LazyLoadImageComponent
                          src={image.url}
                          key={image.filename}
                        />
                      ))}
                  </ImageSlider>
                )}
              </div>
              <div className="sm:w-1/2 w-full sm:pl-20 p-3 sm:mt-0 mt-10">
                {loading ? (
                  <ProductDetailsShimmer />
                ) : (
                  <>
                    <div className="w-full flex h-40 ">
                      <div className="w-fit">
                        <LazyLoadImageComponent
                          src={productData?.brand?.fields?.logo}
                          alt=""
                          className="w-32 h-32 rounded-full border border-secondary"
                        />
                      </div>
                      <div className="w-fit h-full flex flex-col  pl-3  ">
                        <span
                          className="text-[40px] font-bold"
                          style={{ lineHeight: "1" }}
                        >
                          {productData?.brand?.fields?.title}
                        </span>

                        <div className="w-full flex  items-center">
                          <span className="pr-2">
                            <IoLocationSharp size={14} />
                          </span>
                          <span className="text-[14px] ">
                            {productData?.brand?.fields?.location?.city +
                              " , " +
                              productData?.brand?.fields?.location?.country}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="w-full mt-5">
                      {qrOptions?.showCollectionTitle || qrStatus ? (
                        <h3 className="w-full text-5xl font-bold">
                          {productData?.product?.fields?.title}
                        </h3>
                      ) : null}
                      {readMore ? (
                        <p
                          className={`w-full  ${
                            readMore
                              ? "h-auto"
                              : "overflow-hidden h-40 limit-text-4"
                          }`}
                          id={"description"}
                          style={{ whiteSpace: "pre-wrap" }}
                          dangerouslySetInnerHTML={{
                            __html: productData?.product?.fields?.description,
                          }}
                        ></p>
                      ) : (
                        <p>
                          {extractTextFromHtml(
                            productData?.product?.fields?.description
                          ).slice(0, 151)}
                          {"..."}
                        </p>
                      )}
                      <span
                        className="w-full text-primary cursor-pointer  "
                        onClick={() => setReadMore(!readMore)}
                      >
                        {readMore ? " read less" : "read more"}
                      </span>
                    </div>
                    {qrOptions?.showPrice || qrStatus ? (
                      <div className="w-full mt-10">
                        <span className="text-5xl font-bold">
                          {productData?.product?.fields?.price > 0
                            ? productData?.product?.fields?.currency.symbol +
                              " " +
                              productData?.product?.fields?.price
                            : null}
                        </span>
                      </div>
                    ) : null}

                    {qrOptions?.showViewSallerWebsite || qrStatus ? (
                      <div className="w-full mt-12">
                        <a
                          href={productData?.product?.fields?.url}
                          target="_blank"
                          onClick={() =>
                            sendCount({
                              collectionId: props.match.params.id,
                              brandId: productData?.brand?.id,
                            })
                          }
                          className="w-full rounded-xl  hover: bg-primary text-white h-14 justify-center flex items-center"
                        >
                          {"View Seller Website"}
                        </a>
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            </div>
          ) : null}
          {!qrStatus && !qrOptions?.showCollectionDetails ? (
            <>
              {qrOptions?.showCollectionTitle ? (
                <h3 className="w-full text-5xl font-bold">
                  {productData?.product?.fields?.title}
                </h3>
              ) : null}
              {qrOptions?.showPrice ? (
                <div className="w-full mt-10">
                  <span className="text-5xl font-bold">
                    {productData?.product?.fields?.price > 0
                      ? productData?.product?.fields?.currency.symbol +
                        " " +
                        productData?.product?.fields?.price
                      : null}
                  </span>
                </div>
              ) : null}
              {qrOptions?.showViewSallerWebsite ? (
                <div className="w-full mt-12" style={{ maxWidth: "350px" }}>
                  <a
                    href={productData?.product?.fields?.url}
                    target="_blank"
                    onClick={() =>
                      sendCount({
                        collectionId: props.match.params.id,
                        brandId: productData?.brand?.id,
                      })
                    }
                    className="w-full rounded-xl  hover: bg-primary text-white h-14 justify-center flex items-center"
                  >
                    {"View Seller Website"}
                  </a>
                </div>
              ) : null}
            </>
          ) : null}
          <div
            className={`w-full container sm:px-0 px-5 font-bold ${
              qrStatus && !qrOptions?.showCollectionDetails ? "mt-32" : ""
            }   ml-auto`}
          >
            <rootip-component
              key={!qrOptions?.showHeaderFooter && !qrStatus}
              id={props.match.params.id}
              name="stories"
              type="collection"
              preview={true}
              poweredby={!qrOptions?.showHeaderFooter && !qrStatus}
            />
          </div>
          {qrOptions?.showProductsCollectionsList || qrStatus ? (
            <div className="mt-10">
              <ProductCollectionContainer brandId={productData?.product?.id} />
            </div>
          ) : null}
        </div>
      </div>
    </Layout>
  );
};

export default Collection;
