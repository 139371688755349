/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from "react";
import { useMachine } from "@xstate/react";
import { Machine, assign } from "xstate";
import Button from "@common/Button/Button";
import { FaCaretLeft, FaCaretRight } from "@react-icons";

function HrMenu({ children, setActiveCond, engagementSend }) {
  var containerEl = useRef(null);
  var wrapperEl = useRef(null);

  const HrMenuMachine = Machine({
    id: "scroll-drag",
    initial: "idle",
    context: {
      x1: 0,
      x2: 0,
      px: 0,
      dx: 0,
      pdx: 0,
      dif: 0,
      rem: -1,
    },
    states: {
      idle: {
        on: {
          INIT: {
            target: "fixed",
            actions: assign({
              x1: 0,
              x2: (ctx, e) => wrapperEl.current.getBoundingClientRect().right,
              dif: (ctx, e) =>
                wrapperEl.current.getBoundingClientRect().right -
                containerEl.current.getBoundingClientRect().right,
              rem: (ctx, e) =>
                -1 *
                (wrapperEl.current.getBoundingClientRect().right -
                  containerEl.current.getBoundingClientRect().right),
            }),
          },
        },
      },
      fixed: {
        on: {
          mousedown: {
            target: "dragging",
            actions: assign({
              px: (ctx, e) => e.clientX,
            }),
          },
          MOVE_R: {
            cond: (ctx, e) => ctx.x1 >= ctx.rem,
            actions: assign({
              x1: (ctx, e) =>
                ctx.x1 - 200 >= ctx.rem ? ctx.x1 - 200 : ctx.rem,
              dif: (ctx, e) =>
                wrapperEl.current.getBoundingClientRect().right -
                  containerEl.current.getBoundingClientRect().right >=
                0
                  ? wrapperEl.current.getBoundingClientRect().right -
                    containerEl.current.getBoundingClientRect().right
                  : 0,
            }),
          },
          MOVE_L: {
            cond: (ctx, e) => ctx.x1 <= 0,
            actions: assign({
              x1: (ctx, e) => (ctx.x1 + 200 < 0 ? ctx.x1 + 200 : 0),
              dif: (ctx, e) =>
                wrapperEl.current.getBoundingClientRect().right -
                  containerEl.current.getBoundingClientRect().right >=
                0
                  ? wrapperEl.current.getBoundingClientRect().right -
                    containerEl.current.getBoundingClientRect().right
                  : 0,
            }),
          },

          resize: {
            actions: assign({
              rem: (ctx, e) =>
                -1 *
                (wrapperEl.current.getBoundingClientRect().right -
                  containerEl.current.getBoundingClientRect().right),
            }),
          },
        },
      },
      dragging: {
        on: {
          mouseup: {
            target: "fixed",
            actions: assign({
              x1: (ctx, e) =>
                ctx.x1 + ctx.dx < 0
                  ? wrapperEl.current.getBoundingClientRect().right -
                      containerEl.current.getBoundingClientRect().right >=
                    0
                    ? ctx.x1 + ctx.dx
                    : ctx.rem
                  : 0,
              dx: 0,
              pdx: (ctx, e) => ctx.dx,
            }),
          },
          mousemove: {
            cond: (ctx, e) => ctx.x1 <= 0 && ctx.dif >= 0,
            actions: assign({
              dx: (ctx, e) => e.clientX - ctx.px,
              pdx: (ctx, e) => ctx.dx,
              dif: (ctx, e) =>
                wrapperEl.current.getBoundingClientRect().right -
                  containerEl.current.getBoundingClientRect().right >=
                0
                  ? wrapperEl.current.getBoundingClientRect().right -
                    containerEl.current.getBoundingClientRect().right
                  : 0,
            }),
          },
        },
      },
    },
  });

  var [state, send] = useMachine(HrMenuMachine);
  useEffect(() => {
    window.addEventListener("resize", (event) => send(event));
  }, []);

  return (
    <>
      <div
        ref={containerEl}
        className="bg-transparent overflow-hidden relative"
        onMouseEnter={() => {
          send("INIT");
        }}
      >
        {state.context.x1 != 0 && (
          <Button
            type="nav"
            action={() => {
              send("MOVE_L");
              engagementSend("SCROLL_CHAPTERS");
            }}
            styles={{
              background: "transparent",
            }}
          >
            <FaCaretLeft size="1.5em" />
          </Button>
        )}

        <ul
          className="flex relative overflow-hidden w-min duration-150"
          role="presentation"
          ref={wrapperEl}
          style={{
            transform: `translateX(${
              state.matches("dragging")
                ? state.context.x1 + state.context.dx
                : state.context.x1
            }px)`,
          }}
        >
          {children}
        </ul>
        {state.context.x1 != state.context.rem &&
          wrapperEl?.current?.getBoundingClientRect()?.width >
            containerEl?.current?.getBoundingClientRect()?.width && (
            <Button
              type="nav"
              styles={{
                right: "0",
                background: "transparent",
              }}
              action={() => {
                send("MOVE_R");
                engagementSend("SCROLL_CHAPTERS");
              }}
            >
              <FaCaretRight size="1.5em" />
            </Button>
          )}
      </div>
    </>
  );
}
export default HrMenu;
