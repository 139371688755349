import service from "@app/services/rootipAdminServices";
import { useMachine } from "@xstate/react";
import fetchMachine from "@app/machines/fetchMachine";
import LoaderIcon from "@common/LoaderIcon/LoaderIcon";
import React, { useContext, useEffect, useState } from "react";
import useFetch from "../../../../../hooks/useFetch";
function UsageStories() {
  var [usageStorie, setUsageStorie] = useState();
  useEffect(() => {
    usageStorieSend("FETCH");
  }, []);

  var [, usageStorieState, usageStorieSend] = useFetch(
    service.getUsageStories,
    [],
    (res) => {
      setUsageStorie(res);
    }
  );

  return (
    <>
      {usageStorieState.matches("pending") && (
        <LoaderIcon width="80" height="80" />
      )}
      {usageStorieState.matches("resolved") && (
        <>
          <div className=" flex flex-col info-card w-50">
            <p className="ml-2 mb-1 info-label ">
              Usage Of Each Predefined Story
            </p>
            <div
              className="overflow-y-scroll"
              style={{
                maxHeight: 305,
              }}
            >
              {usageStorie.map((element, i) => {
                return (
                  <>
                    <div className="info-card m-2" style={{ marginRight: 5 }}>
                      <div className=" flex flex-col w-100 mr-auto m-2 p-2">
                        <div className="text-2x">{element.chapter}</div>
                        <div className="flex flex-row">
                          <div className="mr-auto font-weight-bold">
                            {element.name}
                          </div>

                          <div className="pt-1 text-center w-16 rounded-2 bg-primary  border-slate-300 ">
                            <div className="text-2x font-weight-bold text-light">
                              {element.usage}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </>
      )}

      <style jsx>{`
        .info-card {
          display: flex;
          padding-right: 4px;
          padding-top: 4px;
          margin-left: 5px;
          box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
            rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
          border-radius: var(--border-radius-general);
          background-color: var(--color-light);
        }

        .kpis-container {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 2rem;
          margin-bottom: 2rem;
        }
      `}</style>
    </>
  );
}

export default UsageStories;
