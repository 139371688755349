import React, { useContext } from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import Icon from "@common/Icon/Icon";
import { ROOT_ID } from "@app/constants/constants";
import Context from "@app/context/AppContext";
import { IoCopyOutline } from "react-icons/io5";
import { useLocation, useHistory } from "react-router";
function Sidebar({ open }) {
  let { url } = useRouteMatch();
  const history = useHistory();
  const { user, currentUser } = useContext(Context);
  const rootipper =
    JSON.parse(localStorage.getItem("rootipper")) ?? currentUser?.rootipper;

  const sidebarItems = [
    {
      href: "/admin/overview.html",
      icon: <Icon name="md/dashboard" size={open ? 16 : 24} />,
      title: "Overview",
    },
    {
      href: "/admin/add-brand.html",
      icon: <Icon name="fa/tags" size={open ? 16 : 24} />,
      title: "Brand",
    },
    {
      href: "/admin/collection-list.html",
      icon: <Icon name="collection" size={open ? 16 : 24} />,
      title: " Collections",
    },
    {
      href: "/admin/product-list.html",
      icon: <Icon name="md/view_carousel" size={open ? 16 : 24} />,
      title: "  Products & Services",
    },
    {
      href: "/admin/product-stories.html",
      icon: <Icon name="md/folder_shared" size={open ? 16 : 24} />,
      title: " Story Cards",
    },
    {
      href: "/admin/user.html",
      icon: <Icon name="md/person_add" size={open ? 16 : 24} />,
      title: "  Users   ",
    },
    {
      href: "/admin/employees.html",
      icon: <Icon name="md/supervisor_account" size={open ? 16 : 24} />,
      title: "   Employee   ",
    },
    {
      href: "/admin/report-tip.html",
      icon: <Icon name="md/redeem" size={open ? 16 : 24} />,
      title: "    Tips   ",
    },
    {
      href: "/app/admin/circle/",
      active: true,
      icon: <Icon name="rootip_circle" size={open ? 16 : 24} />,
      title: "     Rootip Circle   ",
    },
  ];

  const Retailer = [
    {
      href: "/admin/businesses.html",
      icon: <Icon name="fa/tags" size={open ? 16 : 24} />,
      title: "Businesses ",
    },
  ];

  const Employee = [
    {
      href: "/employee/tips.html",
      icon: <Icon name="md/redeem" size={open ? 16 : 24} />,
      title: "    Tips   ",
    },
    {
      href: "/employee/wallet.htmll",
      icon: <Icon name="md/account_balance_wallet" size={open ? 16 : 24} />,
      title: "Wallet ",
    },
  ];

  const RootipAdmin = [
    {
      href: "/rootipadmin/brands.html",
      icon: <Icon name="fa/tags" size={open ? 16 : 24} />,
      title: "Businesses ",
    },
    {
      href: "/rootipadmin/stories.html",
      icon: <Icon name="md/folder_shared" size={open ? 16 : 24} />,
      title: " Predefined Stories  ",
    },
  ];

  return (
    <>
      <div
        className="sidebar fixed top-0"
        style={{ zIndex: 2, width: open ? "240px" : "80px" }}
      >
        <div className="logo" style={{ paddingLeft: open ? "56px" : "24px" }}>
          <a href={"/admin/add-brand.html"}>
            {open ? (
              <Icon name="logo" size={70} />
            ) : (
              <Icon name="mobile-logo" size={32} />
            )}
          </a>
        </div>

        <nav>
          <ul>
            <li className="text-[14px] font-extrabold py-4 text-primary pl-9">
              {open ? "Admin" : ""}
            </li>
            {sidebarItems.map((item) => (
              <li key={item.href}>
                <a
                  href={item.href}
                  className={`${item.active ? "active" : ""}`}
                >
                  {item.icon}
                  {open && <span> {item.title} </span>}
                </a>
              </li>
            ))}

            {rootipper?.brandType?.retailer ? (
              <>
                <li className="text-[14px] font-extrabold py-4 text-primary pl-9">
                  {open ? "Retailer" : ""}
                </li>
                {Retailer.map((item) => (
                  <li key={item.href}>
                    <a
                      href={item.href}
                      className={`${
                        history.location.pathname === item.href ? "active" : ""
                      }`}
                    >
                      {item.icon}
                      {open && <span> {item.title} </span>}
                    </a>
                  </li>
                ))}
              </>
            ) : null}
            {currentUser?.user?.role?.B2BEmployee ? (
              <>
                <li className="text-[14px] font-extrabold py-4 text-primary pl-9">
                  {open ? "Employee" : ""}
                </li>
                {Employee.map((item) => (
                  <li key={item.href}>
                    <a
                      href={item.href}
                      className={`${
                        history.location.pathname === item.href ? "active" : ""
                      }`}
                    >
                      {item.icon}
                      {open && <span> {item.title} </span>}
                    </a>
                  </li>
                ))}
              </>
            ) : (
              ""
            )}
            {currentUser?.user?.role?.RootipAdmin ? (
              <>
                <li className="text-[14px] font-extrabold py-4 text-primary pl-9">
                  {open ? "Super Admin" : ""}
                </li>
                {RootipAdmin?.map((item) => (
                  <li key={item.href}>
                    <a
                      href={item.href}
                      className={`${
                        history?.location?.pathname === item.href
                          ? "active"
                          : ""
                      }`}
                    >
                      {item.icon}
                      {open && <span> {item.title} </span>}
                    </a>
                  </li>
                ))}
              </>
            ) : (
              ""
            )}
          </ul>
        </nav>
      </div>
      <style jsx>{`
        .sidebar {
          grid-area: sidebar;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: ${open ? "240px" : "70px"};
          height: fit-content;
        }

        .logo {
          background-color: var(--color-primary-dark);
          height: 70px;
          width: 100%;
          margin: auto;
          display: flex;
          justify-content: left;

          align-items: center;
        }

        .logo a {
          height: fit-content;
        }

        nav {
          height: calc(100% - 70px);
          background-color: var(--color-light);
        }
        nav ul {
          display: flex;
          flex-direction: column;
        }
        nav :global(a) {
          text-decoration: none;
          color: var(--color-darker);
          display: flex;
          align-items: center;
          height: 3.83438rem;
          ${open && "padding: 1rem 2.2rem;"}
          transition: background-color 0.3s;
          font-size: 1.4rem;
          border-left: 3px solid transparent;
          justify-content: ${open ? "" : "center"};
          min-height: ${open ? "" : "52px"};
        }
        nav :global(a svg) {
          margin: ${open ? "0 2rem 0 0.3rem" : ""};
          fill: var(--color-darker);
        }
        nav :global(a:hover) {
          color: var(--color-primary);
          fill: var(--color-primary);
        }
        nav :global(a:hover svg) {
          fill: var(--color-primary);
        }
        nav :global(a:focus),
        nav :global(a.active) {
          background-color: #f5f5f5f5;
          border-left: 3px solid var(--color-primary);
        }
      `}</style>
    </>
  );
}

export default Sidebar;
