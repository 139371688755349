import React, { useEffect, useContext, useRef, useState } from "react";
import { FaRegPlayCircle } from "@react-icons";
import Button from "@common/Button/Button";
import StoriesContext from "@app/context/StoriesContext";

function VideoCard({
  item,
  index,
  videoModalState,
  videoModalSend,
  setVideoSrc,
  videoSrc,
  preview,
}) {
  const aspectRatio = 1.85;
  var imgEl = useRef(null);
  if (preview) {
    containerWidth = 500;
  } else {
    var {
      videoModalState,
      videoModalSend,
      setVideoSrc,
      videoSrc,
      containerWidth,
      engagementSend,
    } = useContext(StoriesContext);
  }

  var [imgHeight, setImgHeight] = useState(null);

  useEffect(() => {
    if (item && imgEl.current) {
      setImgHeight(imgEl.current.width / aspectRatio);
    }
  }, [containerWidth]);

  useEffect(() => {
    if (imgHeight && imgEl.current) {
      imgEl.current.style.height = imgHeight + "px";
    }
  }, [imgHeight]);
  return (
    <>
      <div className="relative">
        <div className="pos-abs w-100 h-100 flex-center bg-overlay">
          <Button
            type="link"
            action={() => {
              setVideoSrc(item.src);
              if (engagementSend) engagementSend("PLAY_VIDEO");
              videoModalSend("OPEN");
            }}
            className="cursor-pointer"
          >
            <FaRegPlayCircle size="64" color="var(--color-grey)" />
          </Button>
        </div>
        <img
          ref={imgEl}
          src={item.url}
          className="w-100 object-cover"
          alt={item.title}
          // style={item && imgHeight && `height: ${imgHeight}`}
        ></img>
      </div>
    </>
  );
}

export default VideoCard;
