import RatingSelectedSVG from "@app/assets/icons/rating-selected.svg";
import RatingUnSelectedSVG from "@app/assets/icons/rating-un-selected.svg";

function RankingCard({ businessName, logo, rank, score, nClass, scoreClass }) {
  return (
    <>
      <div className="info-card m-2">
        <div className="mx-2 items-center flex flex-col ">
          <div className="font-weight-bold">{rank}</div>
          <div className="text-2x">Rank</div>
        </div>
        <div className="flex flex-center mr-auto py-3">
          <img
            className="border-slate-300 circle border"
            width="60"
            height="60"
            src={logo}
          />
          <div className="flex flex-col mx-2">
            <div className="font-weight-bold ml-1">{businessName}</div>
            <div style={{ gap: "3px" }} className="flex  ml-1">
              {createElements(nClass, scoreClass + 1, score)}
            </div>
          </div>
        </div>
        <div className="px-4 py-1 mr-1 items-center flex flex-col w-16 rounded-2 bg-primary  border-slate-300 ">
          <div className="text-2x font-weight-bold text-light    items-center">
            {score}
          </div>
          <div className="text-2x text-light">RTS</div>
        </div>
      </div>

      <style jsx>{`
        .info-card {
          display: flex;
          align-items: center;
          box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
            rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
          border-radius: var(--border-radius-general);
          background-color: var(--color-light);
        }
      `}</style>
    </>
  );
}

function createElements(nClass, ScorClass, businessScore) {
  var elements = [];
  if (businessScore > 0) {
    elements.push(
      <>
        {/* <Icon name={"rating-selected"} size={14} color="var(--color-primary)" /> */}
        <RatingSelectedSVG />
      </>
    );
    for (var i = 0; i < ScorClass; i++) {
      elements.push(
        <>
          {/* <Icon
            name={"rating-selected"}
            size={14}
            color="var(--color-primary)"
          /> */}
          <RatingSelectedSVG />
        </>
      );
    }
    for (var p = 0; p < nClass - ScorClass; p++) {
      elements.push(
        <>
          {/* <Icon
          name={"rating-un-selected"}
          size={14}
          color="var(--color-primary)"
        /> */}
          <RatingUnSelectedSVG />
        </>
      );
    }
    return elements;
  } else {
    for (var p = 0; p <= nClass; p++) {
      elements.push(
        <>
          {/* <Icon
          name={"rating-un-selected"}
          size={14}
          color="var(--color-primary)"
        /> */}
          <RatingUnSelectedSVG />
        </>
      );
    }
    return elements;
  }
}
export default RankingCard;
