import React, { useEffect, useState } from "react";
import { IoSearchOutline, IoCloseSharp } from "react-icons/io5";
import { useHistory, useLocation } from "react-router";
import createBrowserHistory from "history/createBrowserHistory";
const SearchModal = ({ OpenStatus, SetStatus }) => {
  const [open, setOpen] = useState();
  const [search, setSearch] = useState("");
  const history = useHistory();
  const locate = useLocation();
  const searchParams = new URLSearchParams(locate.search).get("q");

  useEffect(() => {
    setSearch(searchParams);
  }, [locate.search]);

  useEffect(() => {
    setOpen(OpenStatus);
  }, [OpenStatus]);

  const doSearch = (key) => {
    SetStatus(false);
    history.push({
      pathname: location.pathname.replace("/app", ""),
      search: "q=" + key,
      state: {
        ["s"]: true,
      },
    });
  };

  return (
    <div
      className={`
  w-full absolute sm:h-56 h-20 bg-white  top-0 transition ${
    !open ? "-translate-y-full" : "sm:shadow-lg"
  }
  `}
      style={{ zIndex: 99999 }}
    >
      <div className="w-full h-full container flex  flex-col sm:justify-end justify-center  ">
        <div className="w-full  justify-between hidden sm:flex">
          <div className="mb-3"> What are you Looking for?</div>
          <button
            onClick={() => {
           
              SetStatus(false);
            }}
            className="mr-4"
          >
            <IoCloseSharp />
          </button>
        </div>
        <div className="w-full h-fit flex items-center sm:mb-10">
          <input
            type="text"
            id="txtQuery"
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.keyCode === 13) {
                doSearch(search);
              }
            }}
            className="w-full sm:border-b outline-none h-18 sm:text-4xl text-black sm:mt-5 text-bold px-3"
            placeholder="Search Products Or Brands..."
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          <div className="flex justify-between sm:flex-col items-center pl-3">
            <button
              onClick={() => doSearch(search)}
              type="button"
              className="hover:text-primary mx-2 hidden sm:block "
            >
              <IoSearchOutline size={30} />
            </button>
            <button
              type="button"
              className="hover:text-primary mx-2 block sm:hidden text-primary"
            >
              <IoSearchOutline size={20} color="var(--color-primary)" />
            </button>
            <button
              onClick={() => SetStatus(false)}
              className="mr-4 block sm:hidden"
            >
              <IoCloseSharp size={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchModal;
