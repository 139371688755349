// node modules
import React, { useEffect, useMemo, useState } from "react";

// components
import Topbar from "./components/Topbar";
import Sidebar from "./components/Sidebar";

import Footer from "./components/Footer";

// context
import Context from "@app/context/RootipAdminContext";
import BreadCrumb from "./components/BreadCrumb";
import { useLocation } from "react-router";

function AdminLayout({ children, pointList, pageTitle, isPrimary = false }) {
  // Metrics
  var [kpiTarget, setKpiTarget] = useState(null);
  var [brandsOnboarded, setBrandsOnboarded] = useState([]);
  var [suppliersSignedUp, setSuppliersSignedUp] = useState([]);
  var [b2cRegistrations, setB2cRegistrations] = useState([]);

  // KPIs
  var [report, setReport] = useState(null);

  // KPITabs
  var [websiteViews, setWebsiteViews] = useState({});
  var [tipsReport, setTipsReport] = useState({});
  var [scansReport, setScansReport] = useState({});
  var [tipsAmount, setTipsAmount] = useState({});
  var [productViews, setProductViews] = useState({});

  // context is the value provided for Context.Provider
  // first param is the assigned value
  // second param is the list of dependencies. here we prevent recalcuations on irrelevent rerenders
  var context = useMemo(
    () => ({
      kpiTarget,
      setKpiTarget,
      brandsOnboarded,
      setBrandsOnboarded,
      suppliersSignedUp,
      setSuppliersSignedUp,
      b2cRegistrations,
      setB2cRegistrations,
      report,
      setReport,
      websiteViews,
      setWebsiteViews,
      scansReport,
      setScansReport,
      tipsReport,
      setTipsReport,
      tipsAmount,
      setTipsAmount,
      productViews,
      setProductViews,
    }),
    [
      kpiTarget,
      setKpiTarget,
      brandsOnboarded,
      setBrandsOnboarded,
      suppliersSignedUp,
      setSuppliersSignedUp,
      b2cRegistrations,
      setB2cRegistrations,
      report,
      setReport,
      websiteViews,
      setWebsiteViews,
      scansReport,
      setScansReport,
      tipsReport,
      setTipsReport,
      tipsAmount,
      setTipsAmount,
      productViews,
      setProductViews,
    ]
  );
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  var [open, setOpen] = useState(true);

  return (
    <Context.Provider value={context}>
      <div className="w-full ">
        <Topbar open={open} setOpen={setOpen} />
        <div className=" w-full flex flex-col justify-between">
          <Sidebar open={open} />
          <div
            className="content flex-1 "
            id="content_box"
            style={{ marginLeft: "240px" }}
          >
            <main style={{ marginTop: "80px" }}>
              <BreadCrumb
                pointList={pointList}
                pageTitle={pageTitle}
                isPrimary={isPrimary}
              />
              <div className="content-box">{children}</div>
            </main>
          </div>
          <Footer />
        </div>
      </div>

      <style jsx global>{`
        body {
          background-color: var(--color-background);
        }
      `}</style>
      <style jsx>{`
        .wrapper {
        }
        .content {
          grid-area: content;
        }
        .content-box {
          padding: 2rem;
        }
      `}</style>
    </Context.Provider>
  );
}

export default AdminLayout;
