import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import "leaflet/dist/leaflet.css";
import "leaflet";
function Modal({
  visible,
  outerCb,
  children,
  shadowDOM = null,
  width,
  height,
  bg,
  host,
  className,
}) {
  function modalRoot() {
    //return document.querySelector("#modal");
    if (shadowDOM && shadowDOM == "stories") {
      let shadowElement = document
        ?.querySelector("rootip-component")
        ?.shadowRoot?.querySelector("#stories-modal");

      if (shadowElement) {
        return shadowElement;
      } else {
        return document.querySelector("#modal");
      }
    } else if (shadowDOM && shadowDOM == "storiesPreview") {
      return document
        .querySelector("dashboard-component")
        .shadowRoot.querySelector("#stories-modal");
    }
    // if (shadowDOM && shadowDOM == "storiesPreview") {
    //   var x = document.querySelector("admin-widget-tab");
    //   if (x !== null) return x.shadowRoot.querySelector("#stories-modal");
    //   return document.querySelector("#stories-modal");
    // }
    else if (shadowDOM && shadowDOM == "clients-suppliers") {
      return document
        .querySelector("rootip-clients-suppliers")
        .shadowRoot.querySelector("#clients-suppliers-modal");
    } else {
      return document.querySelector("#modal");
    }
  }

  // element to which the modal will be rendered
  const el = document.createElement("div");

  useEffect(() => {
    // append to root when the children of Modal are mounted
    modalRoot().appendChild(el);

    // do a cleanup
    return () => {
      modalRoot().removeChild(el);
    };
  }, [el]);

  var containerEl = useRef(null);

  return visible
    ? createPortal(
        // child element
        <>
          <div
            className={`fixed overflow-y-scroll -top-0 -right-0 flex h-100 w-100 bg-overlay justify-center z-20`}
            role="presentation"
            ref={containerEl}
            onClick={(e) => {
              if (e.target == containerEl.current) {
                e.preventDefault();
                e.stopPropagation();
                outerCb();
              }
            }}
          >
            <div
              className={`relative bg-light text-center my-auto h-min rounded-2 ${className} ${
                width ? width : ""
              } ${height ? height : ""} ${bg ? bg : ""}`}
            >
              {children}
            </div>
          </div>
        </>,
        // target container
        el
      )
    : null;
}

export default Modal;
