import React, { useState, useContext } from "react";
import Button from "@common/Button/Button";
import CurrencyInput from "react-currency-input-field";
import { FaTimes, FaMapMarkerAlt } from "@react-icons";
import StoriesContext from "@app/context/StoriesContext";
import logoPng from "@app/assets/images/logo.png";
import { ROOT_ID } from "@app/constants/constants";
function OptionsModal({ tipModalSend, story, setTip, setComplement, type }) {
  var { location } = story.fields;
  var { engagementSend, host, brandCurrency, productPrice } = useContext(
    StoriesContext
  );
  var [percent, setPercent] = useState(null);
  var [tempComp, setTempComp] = useState("");
  var [customValue, setCustomValue] = useState("");

  if (type == "brand" || type == "collection") {
    return (
      <>
        <div className="flex flex-col items-center">
          <Button
            type="link"
            action={() => {
              tipModalSend("CLOSE");
            }}
            styles={{
              marginLeft: "auto",
            }}
          >
            <FaTimes size="32" />
          </Button>
          <div className="flex flex-col items-center">
            <img src={story.fields.avatar} alt="" className="m-3 w-40 circle" />
            <h3 className="text-3 font-bold">{story.fields.name}</h3>
            <h4 className="pb-1 text-3 text-muted flex items-center">
              <FaMapMarkerAlt className="mr-1/4 mt-1/4" size="12" />
              {location?.city}
              {location?.city && ","} {location?.country}
            </h4>
            <span className="flex items-center bg-grey-light p-1 rounded-1 text-2x w-min no-wrap">
              <h4>{story.fields.employeeRole}</h4>
            </span>
          </div>
          <h2 className="mr-auto text-3 font-bold">Tip Options</h2>
          <div className="w-100 grid grid-cols-4 gap-4  ">
            <div className="flex flex-col">
              <Button
                type={customValue == 5 ? "secondary" : "light"}
                size="lg"
                shadow
                action={() => {
                  setCustomValue(5);
                }}
                styles={{
                  padding: "10px 5px",
                }}
              >
                {brandCurrency.symbol + 5}
              </Button>
            </div>
            <div className="flex flex-col">
              <Button
                type={customValue == 10 ? "secondary" : "light"}
                size="lg"
                shadow
                action={() => {
                  setCustomValue(10);
                }}
                styles={{
                  padding: "10px 5px",
                }}
              >
                {brandCurrency.symbol + 10}
              </Button>
            </div>
            <div className="flex flex-col">
              <Button
                type={customValue == 20 ? "secondary" : "light"}
                size="lg"
                shadow
                action={() => {
                  setCustomValue(20);
                }}
                styles={{
                  padding: "10px 5px",
                }}
              >
                {brandCurrency.symbol + 20}
              </Button>
            </div>
            <div className="flex flex-col">
              <Button
                type={customValue == 50 ? "secondary" : "light"}
                size="lg"
                shadow
                action={() => {
                  setCustomValue(50);
                }}
                styles={{
                  padding: "10px 5px",
                }}
              >
                {brandCurrency.symbol + 50}
              </Button>
            </div>
          </div>
          <span className="text-muted p-3 text-4">OR</span>
          <form>
            <CurrencyInput
              id="input-example"
              className="border w-100 rounded-1 mb-4 p-2 text-3"
              placeholder="Custom Value"
              value={customValue}
              prefix={brandCurrency.symbol}
              onValueChange={(value) => {
                setCustomValue(value);
              }}
            />
            <input
              className="border w-100 rounded-1 mb-4 p-2 text-3"
              type="text"
              value={tempComp}
              onChange={(e) => {
                setTempComp(e.target.value);
              }}
              placeholder="Give a compliment"
            />
            <Button
              type="primary"
              stretch={true}
              size="lg"
              disabled={!percent && !customValue}
              action={() => {
                setComplement(tempComp);
                setTip(customValue);
                customValue && tipModalSend("CONTINUE");
              }}
            >
              Continue
            </Button>
          </form>
        </div>
      </>
    );
  } else if (type == "product") {
    return (
      <>
        <div className="flex flex-col items-center">
          <Button
            type="link"
            action={() => {
              tipModalSend("CLOSE");
            }}
            styles={{
              marginLeft: "auto",
            }}
          >
            <FaTimes size="32" />
          </Button>
          <div className="flex flex-col items-center">
            <img src={story.fields.avatar} alt="" className="m-3 w-40 circle" />
            <h3 className="text-3">{story.fields.name}</h3>
            <h4 className="pb-1 text-3 text-muted">
              <FaMapMarkerAlt className="mr-1/4 mt-1/4" size="12" />
              {location?.city}
              {location?.city && ","} {location?.country}
            </h4>
            <span className="flex items-center bg-grey-light p-1 rounded-1 text-2x w-min no-wrap">
              <h4>{story.fields.employeeRole}</h4>
            </span>
          </div>
          <h2 className="mr-auto text-3">Tip Options</h2>
          <div className="w-100 grid grid-cols-4 gap-4 text-primary">
            <div className="flex flex-col">
              <span>5%</span>
              <Button
                type={percent == 5 ? "secondary" : "light"}
                size="lg"
                shadow
                action={() => {
                  setPercent(5);
                }}
                styles={{
                  padding: "10px 5px",
                }}
              >
                {brandCurrency.symbol +
                  Math.round(
                    ((productPrice * 5) / brandCurrency.decimalCurrency) *
                      brandCurrency.decimalCurrency
                  ) /
                    brandCurrency.decimalCurrency}
              </Button>
            </div>
            <div className="flex flex-col">
              <span>10%</span>
              <Button
                type={percent == 10 ? "secondary" : "light"}
                size="lg"
                shadow
                action={() => {
                  setPercent(10);
                }}
                styles={{
                  padding: "10px 5px",
                }}
              >
                {brandCurrency.symbol +
                  Math.round(
                    ((productPrice * 10) / brandCurrency.decimalCurrency) *
                      brandCurrency.decimalCurrency
                  ) /
                    brandCurrency.decimalCurrency}
              </Button>
            </div>
            <div className="flex flex-col">
              <span>15%</span>
              <Button
                type={percent == 15 ? "secondary" : "light"}
                size="lg"
                shadow
                action={() => {
                  setPercent(15);
                }}
                styles={{
                  padding: "10px 5px",
                }}
              >
                {brandCurrency.symbol +
                  Math.round(
                    ((productPrice * 15) / brandCurrency.decimalCurrency) *
                      brandCurrency.decimalCurrency
                  ) /
                    brandCurrency.decimalCurrency}
              </Button>
            </div>
            <div className="flex flex-col">
              <span>20%</span>
              <Button
                type={percent == 20 ? "secondary" : "light"}
                size="lg"
                shadow
                action={() => {
                  setPercent(20);
                }}
                styles={{
                  padding: "10px 5px",
                }}
              >
                {brandCurrency.symbol +
                  Math.round(
                    ((productPrice * 20) / brandCurrency.decimalCurrency) *
                      brandCurrency.decimalCurrency
                  ) /
                    brandCurrency.decimalCurrency}
              </Button>
            </div>
          </div>
          <span className="text-muted p-3 text-4">OR</span>
          <form>
            <CurrencyInput
              id="input-example"
              className="border w-100 rounded-1 mb-4 p-2 text-3"
              placeholder="Custom Value"
              prefix={brandCurrency.symbol}
              value={
                percent
                  ? Math.round(
                      ((productPrice * percent) /
                        brandCurrency.decimalCurrency) *
                        brandCurrency.decimalCurrency
                    ) / brandCurrency.decimalCurrency
                  : customValue
              }
              onValueChange={(value) => {
                setCustomValue(value);
                setPercent(null);
              }}
            />
            <input
              className="border w-100 rounded-1 mb-4 p-2 text-3"
              type="text"
              value={tempComp}
              onChange={(e) => {
                setTempComp(e.target.value);
              }}
              placeholder="Give a compliment"
            />
            <Button
              type="primary"
              stretch={true}
              size="lg"
              disabled={!percent && !customValue}
              action={() => {
                setComplement(tempComp);
                setTip(
                  percent
                    ? (productPrice * percent) / brandCurrency.decimalCurrency
                    : customValue
                );
                (percent || customValue) && tipModalSend("CONTINUE");
              }}
            >
              Continue
            </Button>
          </form>
          {(host == "customer" || host == "dashboard") && (
            <div className="flex items-center mt-4">
              <span className="text-3">Powered by&nbsp;</span>
              <a
                onClick={() => engagementSend("CLICK_POWERED_BY")}
                href="https://rootip.io"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  height="16px"
                  src={ROOT_ID + logoPng}
                  alt="Rootip Logo"
                ></img>
              </a>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default OptionsModal;
