import React, { useContext } from "react";
import Button from "../Button/Button";
import Context from "../../../context/AppContext";

function UserModal() {
  var { firebase, user } = useContext(Context);
  return (
    <>
      <div className="modal">
        <ul>
          <li>
            <Button
              type="link"
              action={() => {
                localStorage.clear();
                firebase.auth().signOut();
              }}
            >
              Logout
            </Button>
          </li>
        </ul>
      </div>
      <style jsx>{`
        .modal {
          background-color: var(--color-light);
          position: absolute;
          bottom: -30px;
          right: 15px;
          padding: 0.2rem 1rem;
          border-radius: 5px;
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
        }
      `}</style>
    </>
  );
}

export default UserModal;
