import React, { useEffect, useState } from "react";
import services from "@app/services/adminServices";
import fetchMachine from "@app/machines/fetchMachine";
import { useMachine } from "@xstate/react";
import LoaderIcon from "@common/LoaderIcon/LoaderIcon";
import WidgetTabs from "./common/widgetTabs";

function AdminWidgetTab({ type, ownerId, id }) {
  var [items, setItems] = useState(null);
  var [active, setActive] = useState(null);
  var [hiddenTab, setHiddenTab] = useState(true);
  var [itemState, ItemSend] = useMachine(fetchMachine, {
    actions: {
      goFetch: () => {
        services
          .getRetailersName_qr(id, ownerId, type)
          .then((res) => {
            setActive(res[0]);
            //ریتیلز ندارد و صاحب برند درخواست کرده است
            if (res.length === 1 && res[0].ownerId === ownerId)
              setHiddenTab(true);
            else setHiddenTab(false);

            setItems(res);
            ItemSend("RESOLVE");
          })
          .catch((error) => {
            console.log(error);
            ItemSend("REJECT");
          });
      },
    },
  });

  useEffect(() => {
    if (id) {
      ItemSend("FETCH");
    }
  }, [id]);

  return items ? (
    <>
      <WidgetTabs
        listClass="flex justify-center border-primary border rounded-3 w-fit mb-3 mx-auto overflow-hidden"
        itemClass="bg-light text-center p-2 px-3 w-fit li-border-r border-primary text-3 text-primary"
        activeClass="bg-primary text-light"
        items={items}
        active={active}
        id={id}
        ownerId={ownerId}
        type={type}
        selector={hiddenTab === true ? "hidden" : ""}
      ></WidgetTabs>
    </>
  ) : itemState.matches("pending") ? (
    <>
      <LoaderIcon width="80" height="80" />
    </>
  ) : (
    <></>
  );
}
export default AdminWidgetTab;
