import React, { useRef, useEffect, useState } from "react";
// import Mapping from "../../common/Stories/Mapping/Mapping";
// import Stories from "../../common/Stories/Stories";
import StoriesPreview from "../../common/StoriesPreview/StoriesPreview";
import ClientsSuppliers from "../Admin/ClientsSuppliers";
import ImportProducts from "../Admin/ImportProducts";
import AdminQrTab from "../Admin/AdminQrTab";
import adminPreview from "../../common/Stories/Stories";
import RetailerBusinesses from "../Admin/RetailerBusinesses";
import AdminWidgetTab from "../Admin/AdminWidgetTab";
import Stories from "../../common/Stories/Stories";
//import RootipAdmin from "../RootipAdmin/__RootipAdmin";

import Overview from "../RootipAdmin/Overview/Overview";
import StoriesWidget from "../../widgets/StoriesWidget";
import Transparency from "../RootipAdmin/Overview/Transparency/TransparencySetting";
import StoryChaptersTransparency from "../RootipAdmin/Overview/Transparency/StoryChaptersTransparency";
import AiReport from "../RootipAdmin/AiScan/AiReport";
import Triggers from "../RootipAdmin/Overview/Transparency/Triggers";

function Temp() {
  // return null;
  // return (
  //   <>
  //     <AdminWidgetTab
  //       id="tv2yurgSkVrU9kVYAiQz"
  //       type="rootippers"
  //       ownerId="tv2yurgSkVrU9kVYAiQz"
  //     ></AdminWidgetTab>
  //   </>
  // );
  // return (
  //   <>
  //     <AdminQrTab
  //       id="moVMEAdL1HtasaOMv1bI"
  //       type="rootippers"
  //       qrOwnerId="7iGKCx6SLPkxWKsG3l0U"
  //     ></AdminQrTab>
  //   </>
  // );

  // return (
  //   <>
  //     <RootipAdmin></RootipAdmin>
  //   </>
  // );

  // return (
  //   <>
  //     <Triggers></Triggers>
  //   </>
  // );
  // return (
  // return (
  //   <>
  //     <RootipAdmin></RootipAdmin>
  //   </>
  // );
  // return (
  //   <>
  //     <AiReport></AiReport>
  //   </>
  // );
  // return (
  //   <>
  //     <AiReport></AiReport>
  //   </>
  // );
  // return (
  //   <>
  //     <ImportProducts brandId="tv2yurgSkVrU9kVYAiQz"></ImportProducts>
  //   </>
  // );
  // return (
  // <>
  // {
  /* <ImportProducts brandId="tv2yurgSkVrU9kVYAiQz"></ImportProducts> */
  // }
  // {
  /* <ClientsSuppliers brandId="tv2yurgSkVrU9kVYAiQz"></ClientsSuppliers> */
  // }
  // </>
  // );
  // return (
  //
  // );
  // return <StoriesWidget></StoriesWidget>;
  return (
    <>
      {/* <>
        <ClientsSuppliers brandId="tv2yurgSkVrU9kVYAiQz"></ClientsSuppliers>
      </> */}
      <StoriesWidget
        widgetType="brand"
        widgetId="tv2yurgSkVrU9kVYAiQz"
        widgetName="stories"
        widgetPreview={true}
      ></StoriesWidget>
    </>
  );
  // return (
  //   <>
  //     <StoriesWidget
  //       widgetType="product"
  //       widgetId="zuJqPKSyaOoJtnX5xeny"
  //       widgetName="stories"
  //       widgetPreview={false}
  //     ></StoriesWidget>
  //   </>
  // );
  // return (
  //   <div className="px-4 mx-4">
  //     <StoriesWidget
  //       widgetType="brand"
  //       widgetId="tv2yurgSkVrU9kVYAiQz"
  //       widgetName="stories"
  //       widgetPreview={true}
  //     ></StoriesWidget>
  //   </div>
  // );
  // return (
  //   <>
  //     <StoriesPreview
  //       widgetName="storiesPreview"
  //       widgetId="7iGKCx6SLPkxWKsG3l0U"
  //       ownerId="7iGKCx6SLPkxWKsG3l0U"
  //       type="brand"
  //       preview={true}
  //     />
  //   </>
  // );
  // return (
  //   <>
  //     <RetailerBusinesses brandId="tv2yurgSkVrU9kVYAiQz"></RetailerBusinesses>
  //   </>
  // );
}

export default Temp;
