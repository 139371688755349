import { IoLocationSharp, IoReloadSharp, IoTimeOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import timeConver from "@app/utils/timeConver";
import { replySupplyChain } from "@app/services/adminServices";
import Swal from "sweetalert2";
import { useContext, useEffect, useState } from "react";
import Context from "@app/context/AppContext";
const NotifCard = ({ item, setNotifs }) => {
  const { currentUser } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const replySupplyChainHandler = (params) => {
    setLoading(true);
    replySupplyChain(params)
      .then((res) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "success",
          text: res?.message,
          confirmButtonText: "OK",
          confirmButtonColor: "var(--color-primary)",
        }).then(() => {
          setLoading(false);
          setRemove(true);
        });
        setNotifs((prev) => prev.filter((x) => x.id != params.notifId));
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const rootipperId = localStorage.getItem("rootipperId")
    ? JSON?.parse(localStorage.getItem("rootipperId"))
    : null;

  const [data, setData] = useState({
    confirm_seen: {},
    accept_SupplierLink: {},
    accept_ClientLink: {},
    reject_SupplierLink: {},
    reject_ClientLink: {},
  });
  useEffect(() => {
    console.log(rootipperId);
    if (rootipperId && item) {
      setData({
        confirm_seen: {
          brandId: rootipperId,
          client: "",
          eventType: "confirm_seen",
          notifId: item?.id,
          supplier: "",
        },
        accept_SupplierLink: {
          brandId: null,
          client: item?.fields?.brand?.id,
          eventType: "accept_SupplierLink",
          notifId: item?.id,
          supplier: rootipperId,
        },
        accept_ClientLink: {
          brandId: null,
          client: rootipperId,
          eventType: "accept_ClientLink",
          notifId: item?.id,
          supplier: item?.fields?.brand?.id,
        },
        reject_SupplierLink: {
          brandId: null,
          client: item?.fields?.brand?.id,
          eventType: "reject_SupplierLink",
          notifId: item?.id,
          supplier: rootipperId,
        },
        reject_ClientLink: {
          brandId: null,
          client: rootipperId,
          eventType: "reject_ClientLink",
          notifId: item?.id,
          supplier: item?.fields?.brand?.id,
        },
      });
    }
  }, [currentUser, item]);
  return (
    <>
      <div className="w-full my-4  flex">
        <div>
          <img
            src={item?.fields?.brand?.fields?.logo}
            alt=""
            className="w-40 h-40 rounded-full"
          />
        </div>
        <div className="pl-8">
          <Link to={"/brand/" + item?.fields?.brand?.id}>
            <h3 className="text-bold text-[18px] text-black">
              {item?.fields?.brand?.fields?.businessName}
            </h3>
          </Link>
          <div className="w-full  flex  text-black items-center">
            <span className="pr-2">
              <IoLocationSharp size={14} />
            </span>
            <span className="text-[14px] ">
              {item?.fields?.brand?.fields?.location?.city +
                " , " +
                item?.fields?.brand?.fields?.location?.country}
            </span>
          </div>
          <div className="w-full  flex  text-black items-center">
            <span className="pr-2">
              <IoTimeOutline size={14} />
            </span>
            <span className="text-[14px] ">
              {timeConver(item?.fields?.dateTime._seconds * 1000)}
            </span>
          </div>
          <div className="w-full  flex  text-primary items-center">
            {!item?.fields?.type?.includes("req_") ? (
              item?.fields?.action === "accept" ? (
                <span className="text-[14px] text-primary ">
                  You Approved Connection
                </span>
              ) : item?.fields?.action === "reject" ? (
                <span className="text-[14px] text-primary ">
                  Connection Approved
                </span>
              ) : item?.fields?.type?.includes("accept_") ? (
                <span className="text-[14px] text-primary ">
                  Connection Approved
                </span>
              ) : (
                <span className="text-[14px] text-black  ">
                  Connection Rejected
                </span>
              )
            ) : item?.fields?.type?.includes("req_SupplierLink") ? (
              <span className="text-[14px] text-black ">
                Claiming you are their supplier
              </span>
            ) : item?.fields?.type?.includes("req_ClientLink") ? (
              <span className="text-[14px] text-black ">
                Claiming you are their client
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="flex justify-between flex-col items-end flex-1">
          {!loading ? (
            item?.fields?.type?.includes("req_") ? (
              <div className="w-full h-full flex flex-col justify-around items-end">
                <button
                  className="rounded-3xl bg-primary text-white px-5 py-2 w-40"
                  onClick={() => {
                    replySupplyChainHandler(
                      item?.fields?.type?.includes("req_ClientLink")
                        ? data?.accept_ClientLink
                        : data?.accept_SupplierLink
                    );
                  }}
                >
                  Accept
                </button>
                <button
                  className="rounded-3xl bg-red-500 text-white px-5 py-2 w-40"
                  onClick={() =>
                    replySupplyChainHandler(
                      item?.fields?.type?.includes("req_ClientLink")
                        ? data?.reject_ClientLink
                        : data?.reject_ClientLink
                    )
                  }
                >
                  Reject
                </button>
              </div>
            ) : (
              <button
                className="rounded-3xl bg-primary text-white px-5 py-2"
                onClick={() => replySupplyChainHandler(data?.confirm_seen)}
              >
                OK
              </button>
            )
          ) : (
            <div className="w-full h-full flex justify-center items-center ">
              <span className="block   animate-spin">
                <IoReloadSharp size={24} color="black" />
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NotifCard;
