import React, { useState, useContext, useRef, useEffect } from "react";
import MapContext from "@app/context/MapContext";

function ConnectionInput({
  init,
  clientId,
  supplierId,
  pairId,
  cond,
  setCond,
}) {
  var {
    brandId,
    linkRequestSend,
    linkRequestState,
    nodeChainState,
  } = useContext(MapContext);
  var [isChecked, setIsChecked] = useState(init);
  const isInitialMount = useRef(true);

  function update() {
    linkRequestSend({
      type: "FETCH",
      payload: {
        clientId: clientId,
        supplierId: supplierId,
        action: isChecked ? "add" : "delete",
        brandId: brandId,
      },
    });
  }
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      // Your useEffect code here to be run on update
      update();
    }
  }, [isChecked]);
  return (
    <label className="switch m-2">
      <input
        type="checkbox"
        checked={init}
        onClick={() => {
          setCond(clientId + supplierId);
        }}
        onChange={(e) => {
          linkRequestSend({
            type: "FETCH",
            payload: {
              clientId: clientId,
              supplierId: supplierId,
              action: e.target.checked ? "add" : "delete",
              brandId: brandId,
            },
          });
        }}
      ></input>
      <span
        className={`slider round ${
          (linkRequestState.matches("pending") ||
            nodeChainState.matches("pending")) &&
          cond == clientId + supplierId
            ? " loading"
            : ""
        }`}
      ></span>
    </label>
  );
}

export default ConnectionInput;
