import Swal from "sweetalert2";
import { usePapaParse } from "react-papaparse";
const { readString } = usePapaParse();

async function readWordpressExcell(evt, callBack) {
  var myf = evt.target.files[0];
  var reader = new FileReader();
  reader.fileName = evt.target.files[0].name;
  reader.fileType = evt.target.files[0].type;
  reader.fileSize = evt.target.files[0].size;

  if (reader.fileType !== undefined) {
    if (
      reader.fileType.includes("excel") ||
      reader.fileType.includes("text/csv")
    ) {
      reader.onload = function (e) {
        var data = e.target.result;

        readString(data, {
          worker: true,
          complete: (results) => {
            wrapperWordPressProduct(results, (json, collectionJson) => {
              collectionJson = findProducts(collectionJson, json);

              return callBack(reader.fileName, json, collectionJson);
            });
          },
        });
      };
      reader.onerror = function (ex) {
        console.log(ex);
      };
      reader.readAsText(myf, "UTF-8");
    } else {
      Swal.fire({
        icon: "error",
        type: "error",
        title: "oops!",
        text: "Please select a CSV file.",
      });
      return;
    }
  } else {
    Swal.fire({
      icon: "error",
      type: "error",
      title: "oops!",
      text: "Please select a CSV file.",
    });
    return;
  }
}
async function wrapperWordPressProduct(results, callBack) {
  var allRows = results.data;

  var collectionJson = [];
  var json = [];
  for (var singleRow = 0; singleRow < allRows.length; singleRow++) {
    var item = { source: "wordpress", url: "" };
    var rowCells = allRows[singleRow];
    var type = "";
    for (var rowCell = 0; rowCell < rowCells.length; rowCell++) {
      if (singleRow === 0) {
        if (rowCell === 0 && rowCells[rowCell] !== "ID") {
          Swal.fire({
            icon: "error",
            type: "error",
            title: "Column A",
            text: "Column A should be 'ID'",
          });
          return;
        }
        if (rowCell === 1 && rowCells[rowCell] !== "Type") {
          Swal.fire({
            icon: "error",
            type: "error",
            title: "Column B",
            text: "Column B should be 'Type'",
          });
          return;
        }
        if (rowCell === 2 && rowCells[rowCell] !== "SKU") {
          Swal.fire({
            icon: "error",
            type: "error",
            title: "Column C",
            text: "Column C should be 'SKU'",
          });
          return;
        }
        if (rowCell === 3 && rowCells[rowCell] !== "Name") {
          Swal.fire({
            icon: "error",
            type: "error",
            title: "Column D",
            text: "Column D should be 'Name'",
          });
          return;
        }
        if (rowCell === 4 && rowCells[rowCell] !== "Published") {
          Swal.fire({
            icon: "error",
            type: "error",
            title: "Column E",
            text: "Column E should be 'Published'",
          });
          return;
        }
        if (rowCell === 6 && rowCells[rowCell] !== "Visibility in catalog") {
          Swal.fire({
            icon: "error",
            type: "error",
            title: "Column G",
            text: "Column G should be 'Visibility in catalog'",
          });
          return;
        }
        if (rowCell === 8 && rowCells[rowCell] !== "Description") {
          Swal.fire({
            icon: "error",
            type: "error",
            title: "Column I",
            text: "Column I should be 'Description'",
          });
          return;
        }
        if (rowCell === 24 && rowCells[rowCell] !== "Regular price") {
          Swal.fire({
            icon: "error",
            type: "error",
            title: "Column Y",
            text: "Column Y should be 'Regular price'",
          });
          return;
        }
        if (rowCell === 28 && rowCells[rowCell] !== "Images") {
          Swal.fire({
            icon: "error",
            type: "error",
            title: "Column AC",
            text: "Column AC should be 'Images'",
          });
          return;
        }
      } else {
        if (rowCell === 0) {
          item.source2ndKey = rowCells[rowCell];
          item.sourceId = rowCells[rowCell];
        }

        if (rowCell === 1) {
          type = rowCells[rowCell];
        }
        if (rowCell === 2) {
          item.sku = rowCells[rowCell];
        }
        if (rowCell === 3) {
          item.title = rowCells[rowCell];
        }
        if (rowCell === 4) {
          item.isPublish = rowCells[rowCell] === "1" ? true : false;
        }
        if (rowCell === 6) {
          item.isArchived = rowCells[rowCell] === "visible" ? false : true;
        }

        if (rowCell === 8) {
          var div = document.createElement("div");
          div.innerHTML = rowCells[rowCell];
          var scripts = div.getElementsByTagName("script");
          var rootips = div.getElementsByTagName("rootip-component");
          var i = scripts.length;
          var r = rootips.length;
          while (r--) {
            rootips[r].parentNode.removeChild(rootips[r]);
          }
          while (i--) {
            scripts[i].parentNode.removeChild(scripts[i]);
          }
          item.description = div.innerHTML;
        }
        if (rowCell === 24) {
          item.price = rowCells[rowCell] === "" ? 0 : rowCells[rowCell];
        }
        if (rowCell === 28) {
          var media = [];
          var cells = rowCells[rowCell].replace(/("|')/g, "").split(", ");

          cells.forEach((c, i) => {
            if (i === 0) {
              item.image = c;
            }
            media.push(c);
          });

          item.media = media;
        }

        if (rowCell === 32) {
          var products = [];
          var list = rowCells[rowCell].replace(/("|')/g, "").split(", ");
          list.forEach((c, i) => {
            products.push({ sku: c });
          });
          item.products_sku = products;
        }
      }
    }
    if (singleRow > 0 && singleRow < allRows.length) {
      if (
        !type.includes("variation") &&
        item.source2ndKey !== "" &&
        item.media?.length > 0
      ) {
        json.push(item);
      } else if (type.includes("grouped")) {
        collectionJson.push(item);
      }
    }
  }
  return callBack(json, collectionJson);
}
const findProducts = (collectionJson, json) => {
  collectionJson.forEach(async (col) => {
    var products = [];
    col.products_sku.forEach((p) => {
      var item = json.find((q) => q.sku === p.sku);
      if (item !== undefined) {
        products.push({ source2ndKey: item.sourceId });
      }
    });

    col.products = products;
  });

  return collectionJson;
};
async function readShopifyExcell(evt, allowDraft, callBack) {
  var myf = evt.target.files[0];
  var reader = new FileReader();
  reader.fileName = evt.target.files[0].name;
  reader.fileType = evt.target.files[0].type;
  reader.fileSize = evt.target.files[0].size;

  if (reader.fileType !== undefined) {
    if (
      reader.fileType.includes("excel") ||
      reader.fileType.includes("text/csv")
    ) {
      reader.onload = async function (e) {
        var data = e.target.result;

        readString(data, {
          worker: true,
          complete: async (results) => {
            var c = await wrapperShopifyProduct(results, allowDraft);

            return callBack(reader.fileName, c);
          },
        });
      };

      reader.onerror = function (ex) {
        console.log(ex);
      };

      reader.readAsText(myf, "UTF-8");
    } else {
      Swal.fire({
        icon: "error",
        type: "error",
        title: "oops!",
        text:
          "Please select a CSV file.your file type is " +
          evt.target.files[0].type,
      });
      return;
    }
  } else {
    Swal.fire({
      icon: "error",
      type: "error",
      title: "oops!",
      text:
        "Please select a CSV file.your file type is " +
        evt.target.files[0].type,
    });
    return;
  }
}

async function wrapperShopifyProduct(results, allowDraft) {
  var allRows = results.data;
  var json = [];
  var lastIndex = -1;
  var lastItem = {};
  for (var singleRow = 0; singleRow < allRows.length; singleRow++) {
    var item = { source: "shopify", media: [], url: "" };
    var rowCells = allRows[singleRow];

    for (var rowCell = 0; rowCell < rowCells.length; rowCell++) {
      if (singleRow === 0) {
        if (rowCell === 0 && rowCells[rowCell] !== "Handle") {
          Swal.fire({
            icon: "error",
            type: "error",
            title: "Column A",
            text: "Column A should be 'Handle'",
          });
          return;
        }
        if (rowCell === 1 && rowCells[rowCell] !== "Title") {
          Swal.fire({
            icon: "error",
            type: "error",
            title: "Column B",
            text: "Column B should be 'Title'",
          });
          return;
        }
        if (rowCell === 2 && rowCells[rowCell] !== "Body (HTML)") {
          Swal.fire({
            icon: "error",
            type: "error",
            title: "Column C",
            text: "Column C should be 'Body (HTML)'",
          });
          return;
        }
        if (rowCell === 14 && rowCells[rowCell] !== "Variant SKU") {
          Swal.fire({
            icon: "error",
            type: "error",
            title: "Column O",
            text: "Column O should be 'Variant SKU'",
          });
          return;
        }

        if (rowCell === 20 && rowCells[rowCell] !== "Variant Price") {
          Swal.fire({
            icon: "error",
            type: "error",
            title: "Column U",
            text: "Column U should be 'Variant Price'",
          });
          return;
        }
        if (rowCell === 25 && rowCells[rowCell] !== "Image Src") {
          Swal.fire({
            icon: "error",
            type: "error",
            title: "Column Z",
            text: "Column Z should be 'Image Src'",
          });
          return;
        }

        if (rowCell === 48 && rowCells[rowCell] !== "Status") {
          Swal.fire({
            icon: "error",
            type: "error",
            title: "Column AW",
            text: "Column AW should be 'Status'",
          });
          return;
        }
      } else {
        if (rowCell === 0) {
          item.source2ndKey = rowCells[rowCell];
          item.sourceId = "";
        }

        if (rowCell === 1) {
          item.title = rowCells[rowCell];
        }
        if (rowCell === 2) {
          var div = document.createElement("div");
          div.innerHTML = rowCells[rowCell];
          var scripts = div.getElementsByTagName("script");
          var rootips = div.getElementsByTagName("rootip-component");
          var i = scripts.length;
          var r = rootips.length;
          while (r--) {
            rootips[r].parentNode.removeChild(rootips[r]);
          }
          while (i--) {
            scripts[i].parentNode.removeChild(scripts[i]);
          }
          item.description = div.innerHTML;
        }
        if (rowCell === 7) {
          item.isPublish = rowCells[rowCell] === "FALSE" ? false : true;
        }
        if (rowCell === 14) {
          item.sku = rowCells[rowCell] === "" ? "" : rowCells[rowCell];
        }
        if (rowCell === 20) {
          item.price = rowCells[rowCell] === "" ? 0 : rowCells[rowCell];
        }
        if (rowCell === 25) {
          var url = rowCells[rowCell];
          item.image = url;
        }

        if (rowCell === 44) {
          var url = rowCells[rowCell];
          item.media_v = url;
        }
        if (rowCell === 48) {
          item.status = rowCells[rowCell];
        }
      }
    }
    if (singleRow > 0 && singleRow < allRows.length) {
      if (singleRow === 1) {
        lastItem = item;
        lastIndex += 1;
        var u_p = lastItem.media.find((q) => q === item.image);

        if (u_p === undefined && item.image !== "") {
          lastItem.media.push(item.image);
        }
        var u_v = lastItem.media.find((q) => q === item.media_v);

        if (u_v === undefined && item.media_v !== "") {
          lastItem.media.push(item.media_v);
        }
        if (
          (item.status === "active" ||
            item.status === "archived" ||
            (allowDraft && item.status === "draft")) &&
          item.title !== ""
        ) {
          item.isArchived = item.status === "archived" ? true : false;
          json.push(item);
        }
      } else {
        if (item.source2ndKey !== lastItem.source2ndKey) {
          var u_p = item.media.find((q) => q === item.image);

          if (u_p === undefined && item.image !== "") {
            item.media.push(item.image);
          }
          var u_v = item.media.find((q) => q === item.media_v);

          if (u_v === undefined && item.media_v !== "") {
            item.media.push(item.media_v);
          }
          if (
            (item.status === "active" ||
              item.status === "archived" ||
              (allowDraft && item.status === "draft")) &&
            item.title !== ""
          ) {
            item.isArchived = item.status === "archived" ? true : false;

            json.push(item);
            lastItem = item;
            lastIndex += 1;
          }
        } else {
          if (lastItem.media !== undefined) {
            var u_p = lastItem.media.find((q) => q === item.image);

            if (u_p === undefined && item.image !== "") {
              lastItem.media.push(item.image);
            }
            var u_v = lastItem.media.find((q) => q === item.media_v);

            if (u_v === undefined && item.media_v !== "") {
              lastItem.media.push(item.media_v);
            }
          }

          json[lastIndex] = lastItem;
        }
      }
    }
  }
  return json;
}

async function wrapperSquareSpaceProduct(results, callBack) {
  var allRows = results.data;

  var json = [];
  for (var singleRow = 0; singleRow < allRows.length; singleRow++) {
    var item = { source: "squarespace", url: "" };
    var rowCells = allRows[singleRow];
    var type = "";
    for (var rowCell = 0; rowCell < rowCells.length; rowCell++) {
      if (singleRow === 0) {
        if (
          rowCell === 0 &&
          rowCells[rowCell] !== "Product ID [Non Editable]"
        ) {
          Swal.fire({
            icon: "error",
            type: "error",
            title: "Column A",
            text: "Column A should be 'Product ID [Non Editable]'",
          });
          return;
        }
        if (rowCell === 5 && rowCells[rowCell] !== "Title") {
          Swal.fire({
            icon: "error",
            type: "error",
            title: "Column F",
            text: "Column F should be 'Title'",
          });
          return;
        }
        if (rowCell === 6 && rowCells[rowCell] !== "Description") {
          Swal.fire({
            icon: "error",
            type: "error",
            title: "Column G",
            text: "Column G should be 'Description'",
          });
          return;
        }
        if (rowCell === 7 && rowCells[rowCell] !== "SKU") {
          Swal.fire({
            icon: "error",
            type: "error",
            title: "Column H",
            text: "Column H should be 'SKU'",
          });
          return;
        }
        if (rowCell === 20 && rowCells[rowCell] !== "Price") {
          Swal.fire({
            icon: "error",
            type: "error",
            title: "Column U",
            text: "Column U should be 'Price'",
          });
          return;
        }

        if (rowCell === 30 && rowCells[rowCell] !== "Visible") {
          Swal.fire({
            icon: "error",
            type: "error",
            title: "Column AE",
            text: "Column AE should be 'Visible'",
          });
          return;
        }

        if (rowCell === 31 && rowCells[rowCell] !== "Hosted Image URLs") {
          Swal.fire({
            icon: "error",
            type: "error",
            title: "Column AF",
            text: "Column AF should be 'Hosted Image URLs'",
          });
          return;
        }
      } else {
        if (rowCell === 0) {
          item.source2ndKey = rowCells[rowCell];
          item.sourceId = rowCells[rowCell];
        }

        if (rowCell === 5) {
          item.title = rowCells[rowCell];
        }

        if (rowCell === 7) {
          item.sku = rowCells[rowCell];
        }

        if (rowCell === 30) {
          item.isArchived = rowCells[rowCell] === "Yes" ? false : true;
        }

        if (rowCell === 6) {
          var div = document.createElement("div");
          div.innerHTML = rowCells[rowCell];
          var scripts = div.getElementsByTagName("script");
          var rootips = div.getElementsByTagName("rootip-component");
          var i = scripts.length;
          var r = rootips.length;
          while (r--) {
            rootips[r].parentNode.removeChild(rootips[r]);
          }
          while (i--) {
            scripts[i].parentNode.removeChild(scripts[i]);
          }
          item.description = div.innerHTML;
        }
        if (rowCell === 20) {
          item.price = rowCells[rowCell] === "" ? 0 : rowCells[rowCell];
        }
        if (rowCell === 31) {
          var media = [];
          var cells = rowCells[rowCell].replace(/("|')/g, "").split(" ");

          cells.forEach((c, i) => {
            if (i === 0) {
              item.image = c;
            }
            media.push(c);
          });

          item.media = media;
        }
      }
    }
    if (singleRow > 0 && singleRow < allRows.length) {
      if (item.source2ndKey !== "" && item.media?.length > 0) {
        json.push(item);
      }
    }
  }
  return callBack(json);
}

async function readSquareSpaceExcell(evt, callBack) {
  var myf = evt.target.files[0];
  var reader = new FileReader();
  reader.fileName = evt.target.files[0].name;
  reader.fileType = evt.target.files[0].type;
  reader.fileSize = evt.target.files[0].size;

  if (reader.fileType !== undefined) {
    if (
      reader.fileType.includes("excel") ||
      reader.fileType.includes("text/csv")
    ) {
      reader.onload = function (e) {
        var data = e.target.result;
        debugger;
        readString(data, {
          worker: true,
          complete: (results) => {
            wrapperSquareSpaceProduct(results, (json) => {
              return callBack(reader.fileName, json);
            });
          },
        });
      };
      reader.onerror = function (ex) {
        console.log(ex);
      };
      reader.readAsText(myf, "UTF-8");
    } else {
      Swal.fire({
        icon: "error",
        type: "error",
        title: "oops!",
        text: "Please select a CSV file.",
      });
      return;
    }
  } else {
    Swal.fire({
      icon: "error",
      type: "error",
      title: "oops!",
      text: "Please select a CSV file.",
    });
    return;
  }
}
export default {
  readShopifyExcell,
  readWordpressExcell,
  readSquareSpaceExcell,
};
