import React, { useMemo, useState, useEffect, useContext, useRef } from "react";
import { useMachine } from "@xstate/react";
import { loadStripe } from "@stripe/stripe-js";

import service from "@app/services/storiesServices";
import fetchMachine from "@app/machines/fetchMachine";
import videoMachine from "@app/machines/videoMachine";
import StoryChapters from "./StoryChapters/StoryChapters";
import StoryCards from "./StoryCards/StoryCards";
import Tabs from "@common/Tabs/Tabs";
import Tracker from "./Tracker/Tracker";
// const Tracker = loadable(() => import("./Tracker/Tracker"));
import Context from "@app/context/StoriesContext";
import AppContext from "@app/context/AppContext";

import "@app/vendors/styles/radar-chart.css";
import "@app/vendors/styles/slick.css";
import "@app/vendors/styles/slick-theme.css";
import L from "leaflet";

import "leaflet/dist/leaflet.css";

// stupid hack so that leaflet's images work after going through webpack
import marker from "leaflet/dist/images/marker-icon.png";
import marker2x from "leaflet/dist/images/marker-icon-2x.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: marker2x,
  iconUrl: marker,
  shadowUrl: markerShadow,
});

import logoPng from "@app/assets/images/logo.png";
import { ROOT_ID } from "@app/constants/constants";

import VideoModal from "./StoryCards/StoryCard/VideoModal/VideoModal";
import engagementMachine from "@app/machines/engagementMachine";
import StoriesMapping from "./Mapping/StoriesMapping";
// const Mapping = loadable(() => import("./Mapping/Mapping"));

import StoriesWidgetContext from "../../../context/StoriesWidgetContext";
import useFetch from "../../../hooks/useFetch";

function Stories({
  ownerId,
  widgetId,
  type = "product",
  preview,
  widgetName,
  setPreviewCustomStyles,
  setPreviewBrandId,
  setPreviewWidgetId,
  previewCustomStyles,
  isWebComponent = false,
  source,
  shopId,
  poweredby = false,
}) {
  var { urlParams, currentUser, userState } = useContext(
    isWebComponent ? StoriesWidgetContext : AppContext
  );

  var [activeChapter, setActiveChapter] = useState(null);
  var [storyId, setStoryId] = useState(null);
  var [customStyles, setCustomStyles] = useState(null);
  var [videoSrc, setVideoSrc] = useState(null);
  var [videoModalState, videoModalSend] = useMachine(videoMachine);
  var containerEl = useRef(null);
  var [engaged, setEngaged] = useState(false);
  var [isShowStories, setIsShowStories] = useState(true);
  var [isShowTracker, setIsShowTracker] = useState(false);
  var [isShowMap, setIsShowMap] = useState(false);
  var [textColor, setTextColor] = useState("#333333");
  var [buttonColor, setButtonColor] = useState("#8DC53E");
  var [host, setHost] = useState(null);
  var [brandId, setBrandId] = useState(null);
  var [brandCurrency, setBrandCurrency] = useState(null);
  var [productPrice, setProductPrice] = useState(null);
  var [stripePubKey, setStripePubKey] = useState(null);
  var [stripePromise, setStripePromise] = useState(null);
  var [valid, setValid] = useState(false);
  var [widgetType, setWidgetType] = useState(type);

  useEffect(() => {
    if (brandId) {
      ownerId = ownerId === undefined || ownerId === null ? brandId : ownerId;
    }
  }, [brandId]);

  useEffect(() => {
    if (!preview && widgetName == "stories") {
      setHost("customer");
    } else if (preview && widgetName == "stories") {
      setHost("web");
    } else if (preview && widgetName == "storiesPreview") {
      setHost("dashboard");
    } else {
      console.error("widgetName or preview attributes are incorrect.");
    }
  }, [widgetName, preview]);

  useEffect(() => {
    if (host == "web") {
      setIsShowStories(true);
      setIsShowTracker(true);
      setIsShowMap(true);
      setTextColor("");
      setButtonColor("");
    } else if (customStyles) {
      if (previewCustomStyles && host == "dashboard") {
        setIsShowStories(previewCustomStyles.isShowStories);
        setIsShowMap(previewCustomStyles.isShowMap);
        setIsShowTracker(previewCustomStyles.isShowTracker);
        setTextColor(previewCustomStyles.textColour);
        setButtonColor(previewCustomStyles.buttonColour);
      } else {
        setIsShowStories(customStyles.isShowStories);
        setIsShowMap(customStyles.isShowMap);
        setIsShowTracker(customStyles.isShowTracker);
        setTextColor(customStyles.textColour);
        setButtonColor(customStyles.buttonColour);
      }
    }
  }, [previewCustomStyles, customStyles, host]);

  var [, initialDataState, initialDataSend] = useFetch(
    service.getWidgetInitialData,
    [
      {
        type,
        entityId: widgetId,
        host,
        ownerId,
        source,
        shopId,
      },
    ],
    (data) => {
      if (data.displayFlag == false) {
        setValid(false);
        return;
      }
      if (setPreviewWidgetId) {
        setPreviewWidgetId(data.id);
      }
      if (setPreviewBrandId) {
        setPreviewBrandId(data.brandId);
      }
      if (setPreviewCustomStyles) {
        setPreviewCustomStyles(data.customization);
      }
      if (type == "product") {
        setProductPrice(data.price);
      }
      if (data.resultType) {
        setWidgetType(data.resultType);
      }
      setValid(true);
      setStoryId(data.id);
      setBrandId(data.brandId);
      setBrandCurrency(data.currency);
      setCustomStyles(data.customization);
      setStripePubKey(data.PublishableStripeKey);
      setStripePromise(loadStripe(data.PublishableStripeKey));
    }
  );
  useEffect(() => {
    if (host) {
      initialDataSend("FETCH");
    }
  }, [host]);

  useEffect(() => {
    if (initialDataState.matches("rejected")) {
      setValid(false);
    }
  }, [initialDataState]);

  var [chapters, chaptersState, chaptersSend] = useFetch(
    service.getChapters,
    [storyId, brandId, widgetType],
    (chapters) => {
      setActiveChapter(chapters[0]);
    }
  );

  useEffect(() => {
    if (initialDataState.matches("resolved")) {
      chaptersSend("FETCH");
    }
  }, [initialDataState]);

  var [stories, storiesState, storiesSend] = useFetch(service.getStories, [
    storyId,
    brandId,
    widgetType,
    activeChapter?.chapterName,
  ]);

  useEffect(() => {
    if (activeChapter) {
      storiesSend("FETCH");
    }
  }, [activeChapter]);

  var [, , viewsSend] = useFetch(service.createEmbeddedStoryViewHistory, [
    brandId,
    ownerId,
    widgetType,
    storyId,
    currentUser?.userId,
    window.location.href,
    source,
  ]);

  var [engagementApiState, engagementApiSend] = useMachine(fetchMachine, {
    actions: {
      goFetch: (ctx, event) => {
        if (widgetType == "product") {
          service
            .widgetEngagement(
              ownerId,
              brandId,
              storyId,
              undefined,
              !engaged,
              event.payload,
              source
            )
            .then((message) => {
              engagementSend("RESOLVE");
            })
            .catch((error) => {
              console.error(error);
              engagementSend("REJECT");
            });
        } else if (widgetType == "brand") {
          service
            .widgetEngagement(
              ownerId,
              brandId,
              undefined,
              undefined,
              !engaged,
              event.payload,
              source
            )
            .then((message) => {
              engagementSend("RESOLVE");
            })
            .catch((error) => {
              console.error(error);
              engagementSend("REJECT");
            });
        } else if (widgetType == "collection") {
          service
            .widgetEngagement(
              ownerId,
              brandId,
              undefined,
              storyId,
              !engaged,
              event.payload,
              source
            )
            .then((message) => {
              engagementSend("RESOLVE");
            })
            .catch((error) => {
              console.error(error);
              engagementSend("REJECT");
            });
        }
      },
    },
  });

  var [rootipStoryTelling, , rootipStoryTellingSend] = useFetch(
    service.rootipStoryTelling,
    [brandId],
    null,
    (data) => {
      data.forEach(function (part, index) {
        this[index] = {
          subject: Object.keys(part)[0],
          score: Object.values(part)[0],
        };
      }, data);
      return data;
    }
  );

  var [rootipScore, , rootipScoreSend] = useFetch(service.rootipScore, [
    brandId,
  ]);

  var [engagementState, engagementSend] = useMachine(engagementMachine);
  useEffect(() => {
    if (!preview) {
      if (engagementState.matches("chapterSelected")) {
        engagementApiSend({ type: "FETCH", payload: "chapterSelected" });
        engagementSend("DISMISS");
      } else if (engagementState.matches("moreRead")) {
        engagementApiSend({ type: "FETCH", payload: "moreRead" });
        engagementSend("DISMISS");
      } else if (engagementState.matches("storiesSlid")) {
        engagementApiSend({ type: "FETCH", payload: "storiesSlid" });
        engagementSend("DISMISS");
      } else if (engagementState.matches("videoPlayed")) {
        engagementApiSend({ type: "FETCH", payload: "videoPlayed" });
        engagementSend("DISMISS");
      } else if (engagementState.matches("mediaSlid")) {
        engagementApiSend({ type: "FETCH", payload: "mediaSlid" });
        engagementSend("DISMISS");
      } else if (engagementState.matches("poweredByClicked")) {
        engagementApiSend({ type: "FETCH", payload: "poweredByClicked" });
        engagementSend("DISMISS");
      } else if (engagementState.matches("chaptersScrolled")) {
        engagementApiSend({ type: "FETCH", payload: "chaptersScrolled" });
        engagementSend("DISMISS");
      } else if (engagementState.matches("mapSelected")) {
        engagementApiSend({ type: "FETCH", payload: "mapSelected" });
        engagementSend("DISMISS");
      } else if (engagementState.matches("trackerSelected")) {
        engagementApiSend({ type: "FETCH", payload: "trackerSelected" });
        engagementSend("DISMISS");
      } else if (engagementState.matches("evidenceSelected")) {
        engagementApiSend({ type: "FETCH", payload: "evidenceSelected" });
        engagementSend("DISMISS");
      }
      return;
    }
  }, [engagementState]);

  useEffect(() => {
    if (engagementApiState.matches("pending")) {
      setEngaged(true);
    }
  }, [engagementApiState]);

  var [containerWidth, setContainerWidth] = useState(null);
  var observer = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      setContainerWidth(Math.floor(entry.contentRect.width));
    });
  });

  useEffect(() => {
    observer.observe(containerEl.current);
  }, []);

  useEffect(() => {
    (userState.matches("resolved") || userState.matches("denied")) &&
      initialDataState.matches("resolved") &&
      !preview &&
      valid &&
      viewsSend("FETCH");
  }, [userState, initialDataState]);

  var context = useMemo(
    () => ({
      chapters,
      stories,
      activeChapter,
      setActiveChapter,
      storiesState,
      storyId,
      chaptersState,
      containerWidth,
      customStyles,
      widgetType,
      videoSrc,
      setVideoSrc,
      videoModalState,
      videoModalSend,
      engagementSend,
      preview,
      rootipStoryTelling,
      rootipScore,
      rootipStoryTellingSend,
      rootipScoreSend,
      previewCustomStyles,
      isShowStories,
      isShowTracker,
      textColor,
      buttonColor,
      host,
      brandCurrency,
      brandId,
      ownerId,
      widgetName,
      isWebComponent,
      initialDataState,
      productPrice,
      stripePubKey,
      stripePromise,
      valid,
    }),
    [
      chapters,
      stories,
      activeChapter,
      setActiveChapter,
      storiesState,
      storyId,
      chaptersState,
      containerWidth,
      customStyles,
      widgetType,
      videoSrc,
      setVideoSrc,
      videoModalState,
      videoModalSend,
      engagementSend,
      preview,
      rootipStoryTelling,
      rootipScore,
      rootipStoryTellingSend,
      rootipScoreSend,
      previewCustomStyles,
      isShowStories,
      isShowTracker,
      textColor,
      buttonColor,
      host,
      brandCurrency,
      brandId,
      ownerId,
      widgetName,
      isWebComponent,
      initialDataState,
      productPrice,
      stripePubKey,
      stripePromise,
      valid,
    ]
  );

  return (
    <>
      <Context.Provider value={context}>
        <div ref={containerEl}>
          <div
            className={`  my-0 bg-transparent leading-normal ${
              containerWidth <= 480 &&
              "ontouchstart" in document.documentElement
                ? ""
                : ""
            }`}
            style={{ display: valid ? "initial" : "none", margin: "0px" }}
          >
            {(!initialDataState.matches("resolved") || preview) && (
              <h1 className="mb-3 text-4 text-transparent">Transparency</h1>
            )}
            {(initialDataState.matches("resolved") || preview) && (
              <h1 className="mb-3 text-4" style={{ color: textColor }}>
                Transparency
              </h1>
            )}

            {((isShowStories &&
              isShowTracker &&
              isShowMap &&
              (host == "dashboard" || host == "customer")) ||
              host == "web") && (
              <>
                <Tabs
                  listClass="flex justify-center border border-primary rounded-3 w-min mb-3 overflow-hidden"
                  itemClass="bg-light text-center w-100p li-border-r  text-3 text-primary"
                  activeClass="bg-primary text-light"
                  contentClass=""
                  items={[
                    <div label="Stories" key={0}>
                      <StoryChapters />
                      <StoryCards />
                    </div>,
                    <div label="Map" key={2}>
                      <StoriesMapping
                        entityId={storyId}
                        type={widgetType}
                        brandId={brandId}
                      />
                    </div>,
                    <div label="Tracker" key={1}>
                      <Tracker />
                    </div>,
                  ]}
                  itemStyle={
                    host == "dashboard" || host == "customer"
                      ? {
                          color: buttonColor,
                          borderColor: buttonColor,
                        }
                      : {}
                  }
                  listStyle={
                    host == "dashboard" || host == "customer"
                      ? {
                          borderColor: buttonColor,
                        }
                      : {}
                  }
                  activeStyle={
                    host == "dashboard" || host == "customer"
                      ? {
                          backgroundColor: buttonColor,
                          borderColor: buttonColor,
                        }
                      : {}
                  }
                ></Tabs>
              </>
            )}

            {isShowStories &&
              isShowTracker &&
              !isShowMap &&
              (host == "dashboard" || host == "customer") && (
                <>
                  <Tabs
                    listClass="flex justify-center border-primary border rounded-3 w-min mb-3 overflow-hidden"
                    itemClass="bg-light text-center w-100p li-border-r border-primary text-3 text-primary"
                    activeClass="bg-primary text-light"
                    contentClass=""
                    items={[
                      <div label="Stories" key={0}>
                        <StoryChapters />
                        <StoryCards />
                      </div>,
                      <div label="Tracker" key={1}>
                        <Tracker />
                      </div>,
                    ]}
                    itemStyle={
                      host == "dashboard" || host == "customer"
                        ? {
                            color: buttonColor,
                            borderColor: buttonColor,
                          }
                        : {}
                    }
                    listStyle={
                      host == "dashboard" || host == "customer"
                        ? {
                            borderColor: buttonColor,
                          }
                        : {}
                    }
                    activeStyle={
                      host == "dashboard" || host == "customer"
                        ? {
                            backgroundColor: buttonColor,
                            borderColor: buttonColor,
                          }
                        : {}
                    }
                  ></Tabs>
                </>
              )}

            {isShowStories &&
              !isShowTracker &&
              isShowMap &&
              (host == "dashboard" || host == "customer") && (
                <>
                  <Tabs
                    listClass="flex justify-center border-primary border rounded-3 w-min mb-3 overflow-hidden"
                    itemClass="bg-light text-center w-100p li-border-r border-primary text-3 text-primary"
                    activeClass="bg-primary text-light"
                    contentClass=""
                    items={[
                      <div label="Stories" key={0}>
                        <StoryChapters />
                        <StoryCards />
                      </div>,
                      <div label="Map" key={1}>
                        <StoriesMapping
                          entityId={storyId}
                          type={widgetType}
                          brandId={brandId}
                        />
                      </div>,
                    ]}
                    itemStyle={
                      host == "dashboard" || host == "customer"
                        ? {
                            color: buttonColor,
                            borderColor: buttonColor,
                          }
                        : {}
                    }
                    listStyle={
                      host == "dashboard" || host == "customer"
                        ? {
                            borderColor: buttonColor,
                          }
                        : {}
                    }
                    activeStyle={
                      host == "dashboard" || host == "customer"
                        ? {
                            backgroundColor: buttonColor,
                            borderColor: buttonColor,
                          }
                        : {}
                    }
                  ></Tabs>
                </>
              )}

            {!isShowStories &&
              isShowTracker &&
              isShowMap &&
              (host == "dashboard" || host == "customer") && (
                <>
                  <Tabs
                    listClass="flex justify-center border-primary border rounded-3 w-min mb-3 overflow-hidden"
                    itemClass="bg-light text-center w-100p li-border-r border-primary text-3 text-primary"
                    activeClass="bg-primary text-light"
                    contentClass=""
                    items={[
                      <div label="Map" key={0}>
                        <StoriesMapping
                          entityId={storyId}
                          type={widgetType}
                          brandId={brandId}
                        />
                      </div>,
                      <div label="Tracker" key={1}>
                        <Tracker />
                      </div>,
                    ]}
                    itemStyle={
                      host == "dashboard" || host == "customer"
                        ? {
                            color: buttonColor,
                            borderColor: buttonColor,
                          }
                        : {}
                    }
                    listStyle={
                      host == "dashboard" || host == "customer"
                        ? {
                            borderColor: buttonColor,
                          }
                        : {}
                    }
                    activeStyle={
                      host == "dashboard" || host == "customer"
                        ? {
                            backgroundColor: buttonColor,
                            borderColor: buttonColor,
                          }
                        : {}
                    }
                  ></Tabs>
                </>
              )}

            {isShowStories &&
              !isShowTracker &&
              !isShowMap &&
              (host == "dashboard" || host == "customer") && (
                <>
                  {" "}
                  <StoryChapters />
                  <StoryCards />
                </>
              )}
            {!isShowStories &&
              !isShowTracker &&
              isShowMap &&
              (host == "dashboard" || host == "customer") && (
                <>
                  {" "}
                  <StoriesMapping
                    entityId={storyId}
                    type={widgetType}
                    brandId={brandId}
                  />
                </>
              )}
            {!isShowStories &&
              isShowTracker &&
              !isShowMap &&
              (host == "dashboard" || host == "customer") && (
                <>
                  {" "}
                  <Tracker />
                </>
              )}
            <VideoModal />

            {((widgetId && initialDataState.matches("resolved") && !preview) ||
              poweredby) && (
              <div
                className="flex items-center"
                key={
                  (widgetId &&
                    initialDataState.matches("resolved") &&
                    !preview) ||
                  poweredby
                }
              >
                <span className="text-3" style={{ color: textColor }}>
                  Powered by&nbsp;
                </span>
                <a
                  onClick={() => engagementSend("CLICK_POWERED_BY")}
                  href="https://rootip.io"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    height="16px"
                    src={ROOT_ID + logoPng}
                    alt="Rootip Logo"
                  ></img>
                </a>
              </div>
            )}
          </div>
        </div>
      </Context.Provider>
      <style>
        {`
      .slick-track{
        margin-left:0px
      }

      .recharts-tooltip-wrapper{
font-size:11px
      }


      `}
      </style>
    </>
  );
}

export default Stories;
