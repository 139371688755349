import React, { useContext, useState } from "react";
import MapContext from "../../../../../../../context/MapContext";

function TypeInput({ init, name, keys, keysState, readOnly }) {
  var [types, setTypes] = useState(init);

  if (keysState.matches("resolved"))
    return (
      <>
        <div className="w-100 rounded-1 mb-4 py-2 text-muted text-3 text-left">
          {Object.keys(keys).map((type) => (
            <label key={type} className="mr-1 no-wrap">
              <input
                type="checkbox"
                checked={types?.[keys[type]]}
                onChange={(e) => {
                  if (e.target.checked) {
                    var selected = keys[type];
                    setTypes({ ...types, [selected]: true });
                  }
                  if (!e.target.checked) {
                    var unSelected = keys[type];
                    setTypes({ ...types, [unSelected]: undefined });
                  }
                }}
                disabled={readOnly ? true : false}
              />
              {type}
            </label>
          ))}
        </div>
        <input
          value={JSON.stringify(types)}
          name={name}
          readOnly
          className="hidden"
        />
      </>
    );
}

export default TypeInput;
