import React from "react";
import ContetnLoader from "react-content-loader";

export default function ImageShimmer({ width, height }) {
  return (
    <ContetnLoader viewBox={`0 0 ${width} ${height}`}>
      <rect x="0" y="0" rx="2" ry="2" width={width} height={height} />
    </ContetnLoader>
  );
}
