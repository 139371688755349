import React, { useContext, useEffect, useState } from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Tooltip,
} from "recharts";

import Context from "@app/context/StoriesContext";
import Icon from "@common/Icon/Icon";
import RatingSelectedSVG from "@app/assets/icons/rating-selected.svg";
import RatingUnSelectedSVG from "@app/assets/icons/rating-un-selected.svg";

function Tracker() {
  const shimmerData = [
    {
      subject: "Math",
      score: 150,
    },
    {
      subject: "Chinese",
      score: 150,
    },
    {
      subject: "English",
      score: 150,
    },
    {
      subject: "Geography",
      score: 150,
    },
    {
      subject: "Physics",
      score: 150,
    },
    {
      subject: "History",
      score: 150,
    },
  ];
  var {
    rootipStoryTelling,
    rootipScore,
    rootipStoryTellingSend,
    rootipScoreSend,
    engagementSend,
  } = useContext(Context);

  useEffect(() => {
    rootipStoryTellingSend("FETCH");
    rootipScoreSend("FETCH");
    engagementSend("SELECT_TRACKER");
  }, []);

  var [suggestedMax, setSuggestedMax] = useState(0);

  useEffect(() => {
    var tempSuggestedMax = 0;
    if (rootipStoryTelling) {
      rootipStoryTelling.map((item) => {
        if (tempSuggestedMax < item.score) {
          tempSuggestedMax = item.score;
        }
      });
    }
    setSuggestedMax(tempSuggestedMax);
  }, [rootipStoryTelling]);

  return rootipStoryTelling && rootipScore ? (
    <div className="flex flex-col items-start justify-center mb-3">
      <div>
        <RadarChart
          outerRadius={"70%"}
          width={375}
          height={300}
          data={rootipStoryTelling}
        >
          <Tooltip contentStyle={{ fontSize: "13px" }} />
          <PolarGrid />
          <PolarAngleAxis dataKey="subject" radius={10} />
          <PolarRadiusAxis
            type="number"
            angle={90}
            domain={[0, "dataMax"]}
            tickCount={
              suggestedMax < 10
                ? suggestedMax + 1
                : suggestedMax < 20
                ? Math.floor(suggestedMax / 2) + 1
                : suggestedMax < 50
                ? Math.floor(suggestedMax / 5) + 1
                : suggestedMax % 10
            }
            tickFormatter={(e, i) => {
              if (suggestedMax >= 20) {
                if (e - i == 0) {
                  return;
                }
                return e - i;
              }
              if (e == 0) {
                return;
              }
              return e;
            }}
          />
          <Radar
            dataKey="score"
            stroke="#8dc53e"
            fill="#8dc53e"
            fillOpacity={0.4}
            animationDuration={800}
          />
        </RadarChart>

        <div className="mb-2">
          <div style={{ display: "inline-block" }}>
            <div className="flex flex-col items-center">
              <span className="text-4 font-weight-bold  h-12">
                {rootipScore.businessRate}
              </span>
              <span className="text-2x mb-2">Rootip Transparency Rating</span>

              <div style={{ gap: "3px" }} className="flex items-center">
                {createElements(
                  rootipScore.nClass,
                  rootipScore.scoreClass + 1,
                  rootipScore.businessScore
                )}
              </div>
            </div>
          </div>
          <div style={{ display: "inline-block", float: "right" }}>
            <div className="flex flex-col items-center">
              <span className="text-4 font-weight-bold h-12">
                {rootipScore.businessScore}
              </span>

              <span className="text-2x">Rootip Transparency Score</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <>
      {" "}
      <div className="relative">
        <div className="shimmer-overlay"></div>
        <div className="flex flex-col items-start justify-center">
          <RadarChart
            outerRadius={"70%"}
            width={300}
            height={300}
            data={shimmerData}
          >
            <PolarGrid />

            <Radar
              dataKey="score"
              stroke="transparent"
              fill="#8dc53e"
              fillOpacity={0}
            />
          </RadarChart>
        </div>
      </div>
    </>
  );
}

function createElements(nClass, ScorClass, businessScore) {
  var elements = [];
  if (businessScore > 0) {
    elements.push(
      <>
        {/* <Icon name={"rating-selected"} size={14} color="var(--color-primary)" /> */}
        <RatingSelectedSVG />
      </>
    );
    for (var i = 0; i < ScorClass; i++) {
      elements.push(
        <>
          {/* <Icon
            name={"rating-selected"}
            size={14}
            color="var(--color-primary)"
          /> */}
          <RatingSelectedSVG />
        </>
      );
    }
    for (var p = 0; p < nClass - ScorClass; p++) {
      elements.push(
        <>
          {/* <Icon
          name={"rating-un-selected"}
          size={14}
          color="var(--color-primary)"
        /> */}
          <RatingUnSelectedSVG />
        </>
      );
    }
    return elements;
  } else {
    for (var p = 0; p <= nClass; p++) {
      elements.push(
        <>
          {/* <Icon
          name={"rating-un-selected"}
          size={14}
          color="var(--color-primary)"
        /> */}
          <RatingUnSelectedSVG />
        </>
      );
    }
    return elements;
  }
}
export default Tracker;
