import React, { useEffect, useMemo, useState, useContext } from "react";
import storiesServices from "@app/services/storiesServices";
import fetchMachine from "@app/machines/fetchMachine";
import "leaflet-arrowheads";
import {
  Map,
  Popup,
  TileLayer,
  Marker,
  Circle,
  useLeaflet,
} from "react-leaflet";

import L, { latLngBounds, latLng } from "leaflet";
import { useMachine } from "@xstate/react";

// import Polyline from "react-leaflet-arrowheads";
import MarkerClusterGroup from "./MarkerClusters/MarkerClusters";

import mapServices from "../../../../services/mapServices";
import MapShimmer from "./MapShimmer/MapShimmer";

import MapContext from "@app/context/MapContext";
import Swal from "sweetalert2";
import MarkerClusters from "./MarkerClusters/MarkerClusters";
import MarkerCluster from "./MarkerCluster/MarkerCluster";

function Markers({ isVisible }) {
  const { map } = useLeaflet();
  var { markers } = useContext(MapContext);
  useEffect(() => {
    if (isVisible && markers.length && markers.length > 1) {
      // var bounds = new L.latLngBounds(markers);
      // map.fitBounds(bounds, { padding: [50, 50] });
    } else {
      return;
    }
  }, [markers, isVisible]);

  return <></>;
}

function Mapping({
  entityId,
  type,
  brandId,
  preview,
  widgetName = null,
  isVisible,
}) {
  const [map, setMap] = useState(null);
  var [brandTypes, setBrandTypes] = useState(null);
  var [brandPosition, setBrandPosition] = useState(null);
  var [nodeChain, setNodeChain] = useState(null);
  var [nodes, setNodes] = useState(null);
  var [allNodes, setAllNodes] = useState(null);
  var [parentIds, setParentIds] = useState([]);
  var [nodeDetails, setNodeDetails] = useState(null);
  var [selectedNode, setSelectedNode] = useState(null);
  var [markers, setMarkers] = useState([]);

  var [mapviewState, mapviewSend] = useMachine(fetchMachine, {
    actions: {
      goFetch: () => {
        storiesServices.mapView(brandId, type, entityId).then((data) => {
          data.forEach((node) => {
            if (node.node.id == brandId) {
              setBrandPosition([
                node.node.fields.location.lat,
                node.node.fields.location.lng,
              ]);
            }
          });
          setNodes(data);

          var temp = [];
          var temp2 = [];

          data.forEach((node) => {
            temp.push([
              node.node.fields.location.lat,
              node.node.fields.location.lng,
            ]);
            temp2.push(node.node);
            node.clients.forEach((node) => {
              temp.push([node.fields.location.lat, node.fields.location.lng]);
              temp2.push(node);
            });
            node.suppliers.forEach((node) => {
              temp.push([node.fields.location.lat, node.fields.location.lng]);
              temp2.push(node);
            });
          });

          setMarkers(temp);

          temp2.forEach((n, i) => {
            temp2.forEach((m, ii) => {
              if (n.id == m.id && i != ii) {
                temp2.splice(ii, 1);
              }
            });
          });

          setAllNodes(temp2);
          mapviewSend("RESOLVE");
        });
      },
    },
  });

  var [brandTypesState, brandTypesSend] = useMachine(fetchMachine, {
    actions: {
      goFetch: () => {
        mapServices.getBrandTypes().then((data) => {
          setBrandTypes(data);
          brandTypesSend("RESOLVE");
        });
      },
    },
  });
  var [addNodeState, addNodeSend] = useMachine(fetchMachine, {
    actions: {
      goFetch: (ctx, event) => {
        mapServices.addNode(event.payload).then((data) => {
          addNodeSend("RESOLVE");
          Swal.fire({
            icon: "success",
            title: "Node succesfully created.",
            confirmButtonText: `OK`,
            confirmButtonColor: "#00a79e",
          });
          mapviewSend("FETCH");
        });
      },
    },
  });
  var [getNodeState, getNodeSend] = useMachine(fetchMachine, {
    actions: {
      goFetch: (ctx, event) => {
        mapServices.getNode(event.payload).then((data) => {
          setNodeDetails(data);
          getNodeSend("RESOLVE");
        });
      },
    },
  });
  var [updateNodeState, updateNodeSend] = useMachine(fetchMachine, {
    actions: {
      goFetch: (ctx, event) => {
        mapServices.updateNode(event.payload).then((data) => {
          updateNodeSend("RESOLVE");
          Swal.fire({
            title: "Node details changed succesfully.",
            icon: "success",
            confirmButtonText: `OK`,
            confirmButtonColor: "#00a79e",
          });
          mapviewSend("FETCH");
        });
      },
    },
  });
  var [removeNodeState, removeNodeSend] = useMachine(fetchMachine, {
    actions: {
      goFetch: (ctx, event) => {
        mapServices.removeNode(event.payload).then((data) => {
          removeNodeSend("RESOLVE");
          Swal.fire({
            title: "Node succesfully removed.",
            icon: "success",
            confirmButtonText: `OK`,
            confirmButtonColor: "#00a79e",
          });
          mapviewSend("FETCH");
        });
      },
    },
  });

  var [nodeChainState, nodeChainSend] = useMachine(fetchMachine, {
    actions: {
      goFetch: (ctx, event) => {
        mapServices.getNodeChain(event.payload).then((data) => {
          setNodeChain(data);
          nodeChainSend("RESOLVE");
        });
      },
    },
  });

  var [linkRequestState, linkRequestSend] = useMachine(fetchMachine, {
    actions: {
      goFetch: (ctx, event) => {
        mapServices
          .linkRequest({
            clientId: event.payload.clientId,
            supplierId: event.payload.supplierId,
            action: event.payload.action,
            brandId: event.payload.brandId,
          })
          .then((data) => {
            linkRequestSend("RESOLVE");
            if (event.payload.host == "AddNode") {
              Swal.fire({
                title: "Node succesfully added.",
                icon: "success",
                confirmButtonText: `OK`,
                confirmButtonColor: "#00a79e",
              });
              mapviewSend("FETCH");
            }
            if (event.payload.host != "AddNode") {
              nodeChainSend({ type: "FETCH", payload: selectedNode });
            }
          });
      },
    },
  });

  var [nodeList, setNodeList] = useState(null);
  var [nodeListState, nodeListSend] = useMachine(fetchMachine, {
    actions: {
      goFetch: () => {
        mapServices.getNodeList(brandId).then((data) => {
          setNodeList(data);
          nodeListSend("RESOLVE");
        });
      },
    },
  });
  useEffect(() => {
    mapviewSend("FETCH");
    brandTypesSend("FETCH");
    nodeListSend("FETCH");
  }, []);

  useEffect(() => {
    if (nodes) {
      var tempNodes = [];
      nodes.forEach((node) => {
        tempNodes = [...tempNodes, node.node.id];
      });
      setParentIds(tempNodes);
    }
  }, [nodes]);

  const URL = `https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png`;
  const ATTRIBUTION =
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>';

  var context = useMemo(
    () => ({
      widgetName,
      preview,
      brandTypes,
      brandTypesState,
      brandId,
      mapviewSend,
      addNodeSend,
      addNodeState,
      removeNodeSend,
      getNodeSend,
      getNodeState,
      nodeDetails,
      updateNodeSend,
      updateNodeState,

      nodeChain,
      nodeChainState,
      nodeChainSend,
      linkRequestState,
      linkRequestSend,
      selectedNode,
      setSelectedNode,
      markers,
    }),
    [
      widgetName,
      preview,
      brandTypes,
      brandTypesState,
      brandId,
      mapviewSend,
      addNodeSend,
      addNodeState,
      removeNodeSend,
      getNodeSend,
      getNodeState,
      nodeDetails,
      updateNodeSend,
      updateNodeState,

      nodeChain,
      nodeChainState,
      nodeChainSend,
      linkRequestState,
      linkRequestSend,
      selectedNode,
      setSelectedNode,
      markers,
    ]
  );

  return (
    <MapContext.Provider value={context}>
      <div className="relative h-60vh">
        {mapviewState.matches("pending") ||
          mapviewState.matches("idle") ||
          ((nodeListState.matches("pending") ||
            nodeListState.matches("idle")) && <MapShimmer />)}
        {mapviewState.matches("resolved") && nodeListState.matches("resolved") && (
          <Map
            center={brandPosition}
            zoom={4}
            scrollWheelZoom={true}
            whenCreated={setMap}
            minZoom={1}
            className="z-1 relative"
          >
            <TileLayer url={URL} attribution={false} />
            <Markers isVisible={isVisible} />
            <MarkerClusters
              mapviewState={mapviewState}
              nodeListState={nodeListState}
              nodes={nodes}
              preview={preview}
              parentIds={parentIds}
              brandId={brandId}
              widgetName={widgetName}
              nodeList={nodeList}
              brandPosition={brandPosition}
            />
            {/* <MarkerCluster markers={allNodes} /> */}
          </Map>
        )}
      </div>
    </MapContext.Provider>
  );
}

export default Mapping;
