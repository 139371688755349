import React from "react";
import StoryCard from "../StoryCard";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";

function Stamps({ evidence }) {
  const position = evidence?.locationStamp;
  if (
    (evidence.locationStamp?.length > 0 && evidence.locationStamp) ||
    evidence.timeStamp
  )
    return (
      <>
        {evidence.showTimeStamp ? (
          <div>
            <p className="mb-2">Time stamp:</p>
            <p className="mb-2">{evidence.timeStamp}</p>
          </div>
        ) : (
          ""
        )}
        {evidence.showLocation &&
        evidence.locationStamp?.length > 0 &&
        evidence.locationStamp ? (
          <div>
            <p className="mb-2">Location stamp:</p>
            <div className="h-200p">
              <Map center={position} zoom={4} scrollWheelZoom={false}>
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={position}>
                  {/* <Popup>
                    A pretty CSS3 popup. <br /> Easily customizable.
                  </Popup> */}
                </Marker>
              </Map>
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    );
  else
    return (
      <>
        <p>No Evidence Stamps</p>
      </>
    );
}

export default Stamps;
