/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import AdminLayout from "../../../Layout/AdminLayout";
import { Link } from "react-router-dom";
import ImageSlider from "@app/components/common/ImageSlider/ImageSlider";
import { getProductDetails } from "@app/services/WebsiteRequeries";
import LazyLoadImageComponent from "@app/components/views/Website/Components/LazyLoadImageComponent";
import { IoLocationSharp } from "react-icons/io5";
import ProductDetailsShimmer from "@app/components/views/Website/Components/Shimmers/ProductDetailsShimmer";
import SliderShimmer from "@app/components/views/Website/Components/Shimmers/SliderShimmer";
import Stories from "@common/Stories/Stories";
import ProductCollectionContainer from "../../../../Website/Containers/Collection/ProductCollectionContainer";
import {
  createSellerWebsiteClickHistory,
  getCollectionDetails,
} from "../../../../../../services/WebsiteRequeries";

const Collection = (props) => {
  const [productData, setProductData] = useState({});
  const [loading, setLoading] = useState(true);
  const [readMore, setReadMore] = useState(false);

  useEffect(() => {
    setLoading(true);
    getCollectionDetails({ id: props.match.params.id })
      .then((res) => {
        setLoading(false);
        setProductData({
          product: res.collection,
          ...res,
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);
  const sendCount = ({ collectionId, brandId }) => {
    let data = {
      collectionId: collectionId,
      brandId: brandId,
      source: "web",
      userId: "",
    };
    createSellerWebsiteClickHistory(data);
  };

  return (
    <AdminLayout
      subMenu={[]}
      pageTitle={"Collection Details"}
      pointList={[
        {
          title: productData?.brand?.fields?.title,
          href: "/admin/circle/brand/" + productData?.brand?.id,
        },
        {
          title: productData?.product?.fields?.title,
        },
      ]}
    >
      <div className="w-full h-auto ">
        <div className="container ">
          <div className="w-full flex flex-wrap mt-2 ">
            <div className="sm:w-1/2 w-full p-3">
              {loading ? (
                <SliderShimmer />
              ) : (
                <ImageSlider>
                  {productData?.product?.fields?.media
                    ?.filter((item) => item.type == "image")
                    ?.map((image) => (
                      <LazyLoadImageComponent
                        src={image.url}
                        key={image.filename}
                      />
                    ))}
                </ImageSlider>
              )}
            </div>
            <div className="sm:w-1/2 w-full sm:pl-20 p-3 sm:mt-0 mt-10">
              {loading ? (
                <ProductDetailsShimmer />
              ) : (
                <>
                  <div className="w-full flex h-40 ">
                    <div className="w-fit">
                      <LazyLoadImageComponent
                        src={productData?.brand?.fields?.logo}
                        alt=""
                        className="w-32 h-32 rounded-full border border-secondary"
                      />
                    </div>
                    <div className="w-fit h-full flex flex-col  pl-3  ">
                      <span
                        className="text-[40px] font-bold"
                        style={{ lineHeight: "1" }}
                      >
                        {productData?.brand?.fields?.title}
                      </span>

                      <div className="w-full  flex  items-center">
                        <span className="pr-2 ">
                          <IoLocationSharp size={14} />
                        </span>
                        <span className="text-[14px] ">
                          {productData?.brand?.fields?.location?.city +
                            " , " +
                            productData?.brand?.fields?.location?.country}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="w-full mt-5">
                    <h3 className="w-full text-5xl font-bold">
                      {productData?.product?.fields?.title}
                    </h3>
                    <p
                      className={`w-full  ${
                        readMore
                          ? "h-auto"
                          : "overflow-hidden h-40 limit-text-4"
                      }`}
                      style={{ whiteSpace: "pre-wrap" }}
                      dangerouslySetInnerHTML={{
                        __html: productData?.product?.fields?.description,
                      }}
                    ></p>
                    <span
                      className="w-full text-primary cursor-pointer  "
                      onClick={() => setReadMore(!readMore)}
                    >
                      {readMore ? " read less" : "read more"}
                    </span>
                  </div>

                  <div className="w-full mt-10">
                    <span className="text-5xl font-bold">
                      {productData?.product?.fields?.price > 0
                        ? productData?.product?.fields?.currency.symbol +
                          " " +
                          productData?.product?.fields?.price
                        : null}
                    </span>
                  </div>
                  {productData?.product?.fields?.url ? (
                    <div className="w-full mt-12">
                      <a
                        href={productData?.product?.fields?.url}
                        target="_blank"
                        onClick={() =>
                          sendCount({
                            brandId: productData?.brand?.id,
                            collectionId: productData?.product?.id,
                          })
                        }
                        className="w-full rounded-xl  hover: bg-primary text-white h-14 justify-center flex items-center"
                      >
                        {"View Seller Website"}
                      </a>
                    </div>
                  ) : null}
                </>
              )}
            </div>
          </div>

          <div className="w-full container sm:px-0 px-5 font-bold mt-32 ml-auto ">
          <rootip-component
            id={props.match.params.id}
            name="stories"
            type="collection"
            preview={true}
          />
          </div>

          <div className="w-full container mt-10">
            <ProductCollectionContainer
              circle={true}
              brandId={props.match.params.id}
            />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Collection;
