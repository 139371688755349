import React, { useContext, useEffect, useState } from "react";
import { useMachine } from "@xstate/react";

import KPI from "./KPI/KPI";

import service from "@app/services/rootipAdminServices";

import Context from "@app/context/RootipAdminContext";

import fetchMachine from "@app/machines/fetchMachine";

function Reports() {
  var { report, setReport } = useContext(Context);
  var [widgetImpressions, setWidgetImpressions] = useState(null);
  var [widgetEngagements, setwidgetEngagements] = useState(null);
  // fetchMachine is used to hold the state of fetching data (e.g, idle, pending, resolved, rejected)
  // second param is used as options for the machine. The machine is agnostic about the second param.
  var [state, send] = useMachine(fetchMachine, {
    actions: {
      goFetch: () => {
        service
          .kpiReport()
          .then((report) => {
            setReport(report);
            send("RESOLVE");
          })
          .catch((error) => {
            setReport(error);
            send("REJECT");
          });
      },
    },
  });
  var [widgetImpressionsState, widgetImpressionsSend] = useMachine(
    fetchMachine,
    {
      actions: {
        goFetch: () => {
          service
            .reportStoryViewHistory()
            .then((report) => {
              setWidgetImpressions(report);
              widgetImpressionsSend("RESOLVE");
            })
            .catch((error) => {
              setWidgetImpressions(error);
              widgetImpressionsSend("REJECT");
            });
        },
      },
    }
  );
  var [widgetEngagementsState, widgetEngagementsSend] = useMachine(
    fetchMachine,
    {
      actions: {
        goFetch: () => {
          service
            .engagementReports()
            .then((report) => {
              setwidgetEngagements(report);
              widgetEngagementsSend("RESOLVE");
            })
            .catch((error) => {
              setwidgetEngagements(error);
              widgetEngagementsSend("REJECT");
            });
        },
      },
    }
  );
  // when component (re)renders
  // first param is the action to call
  // second param is the list of dependencies. no dependencies means it only runs on the first render
  useEffect(() => {
    send("FETCH");
    widgetImpressionsSend("FETCH");
    widgetEngagementsSend("FETCH");
  }, []);

  return (
    <>
      <div className="kpis-container">
        <KPI
          iconName="products"
          label="Products"
          value={
            state.matches("resolved")
              ? report.productCount
              : state.matches("rejected")
              ? report.message
              : "NA"
          }
          loading={state.matches("pending")}
        />
        <KPI
          iconName="average-stories-per-product"
          label="Average Stories Per Product"
          value={
            state.matches("resolved")
              ? Math.round((report.avgStoryinProduct + Number.EPSILON) * 100) /
                100
              : state.matches("rejected")
              ? report.message
              : "NA"
          }
          loading={state.matches("pending")}
        />
        <KPI
          iconName="custom-stories"
          label="Custom Stories"
          value={
            state.matches("resolved")
              ? report.customStories
              : state.matches("rejected")
              ? report.message
              : "NA"
          }
          loading={state.matches("pending")}
        />
        <KPI
          iconName="people-stories"
          label="People Stories"
          value={
            state.matches("resolved")
              ? report.peopleStories
              : state.matches("rejected")
              ? report.message
              : "NA"
          }
          loading={state.matches("pending")}
        />
        <KPI
          iconName="total-tips"
          label="Total Tips"
          value={
            state.matches("resolved")
              ? "€" + report.totalTips
              : state.matches("rejected")
              ? report.message
              : "NA"
          }
          loading={state.matches("pending")}
        />
        <KPI
          iconName="number-of-tips"
          label="Number of Tips"
          value={
            state.matches("resolved")
              ? report.numberOfTips
              : state.matches("rejected")
              ? report.message
              : "NA"
          }
          loading={state.matches("pending")}
        />
        <KPI
          iconName="supply-chain-connections"
          label="Supply Chain Connections"
          value={
            state.matches("resolved")
              ? report.realConnectionsCount
              : state.matches("rejected")
              ? report.message
              : "NA"
          }
          loading={state.matches("pending")}
        />
        <KPI
          iconName="supply-chain-connections"
          label="Unclaimed Orgs Connections"
          value={
            state.matches("resolved")
              ? report.virtualConnectionsCount
              : state.matches("rejected")
              ? report.message
              : "NA"
          }
          loading={state.matches("pending")}
        />
        <KPI
          iconName="supply-chain-connections"
          label="Unclaimed Orgs Numbers"
          value={
            state.matches("resolved")
              ? report.virtualNodesCount
              : state.matches("rejected")
              ? report.message
              : "NA"
          }
          loading={state.matches("pending")}
        />
        <KPI
          iconName="widget-impressions"
          label="Total Widget Impressions "
          value={
            widgetImpressionsState.matches("resolved")
              ? widgetImpressions.viewCount
              : state.matches("rejected")
              ? widgetImpressions.message
              : "NA"
          }
          loading={widgetImpressionsState.matches("pending")}
        />

        <KPI
          iconName="widget-engagements"
          label="Total Widget Engagements"
          value={
            widgetEngagementsState.matches("resolved")
              ? widgetEngagements.sumEngagementEvents
              : state.matches("rejected")
              ? widgetEngagements.message
              : "NA"
          }
          loading={widgetEngagementsState.matches("pending")}
        />
        <KPI
          iconName="unique-widget-engagements"
          label="Unique Widget Engagements"
          value={
            widgetEngagementsState.matches("resolved")
              ? widgetEngagements.sumUniqueEngagements
              : state.matches("rejected")
              ? widgetEngagements.message
              : "NA"
          }
          loading={widgetEngagementsState.matches("pending")}
        />
      </div>
      <style jsx>{`
        .kpis-container {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 2rem;
          margin-bottom: 2rem;
        }
      `}</style>
    </>
  );
}

export default Reports;
