import React, { useContext, useEffect, useState } from "react";
import Modal from "@common/Modal/Modal";
// import { Elements } from "@stripe/react-stripe-js";
import StripeForm from "@common/StripeForm/StripeForm";

import StoriesContext from "@app/context/StoriesContext";
import AuthenticationModal from "./AuthenticationModal/AuthenticationModal";
import OptionsModal from "./OptionsModal/OptionsModal";
import StoriesWidgetContext from "../../../../../../context/StoriesWidgetContext";
import AppContext from "@app/context/AppContext";

function TipModal({ tipModalState, tipModalSend, story }) {
  var { product, widgetType, widgetName, isWebComponent } = useContext(
    StoriesContext
  );
  var { user, stripePromise } = useContext(
    isWebComponent ? StoriesWidgetContext : AppContext
  );
  var [tip, setTip] = useState(null);
  var [complement, setComplement] = useState("");

  useEffect(() => {
    if (tipModalState.matches("idle")) {
      document.body.style.overflow = "initial";
    } else if (!tipModalState.matches("idle")) {
      document.body.style.overflow = "hidden";
    }
    if (
      tipModalState.matches("auth_check") ||
      tipModalState.matches("auth_check_back")
    ) {
      tipModalSend(user ? "PASS" : "FAIL");
    }
  }, [tipModalState]);

  return (
    <>
      <div
        style={{
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Modal
          visible={tipModalState.matches("options")}
          outerCb={() => tipModalSend("CLOSE")}
          width="w-350"
          shadowDOM={widgetName}
          className="p-4 my-100p"
        >
          <OptionsModal
            tipModalSend={tipModalSend}
            story={story}
            setTip={setTip}
            setComplement={setComplement}
            type={widgetType}
          />
        </Modal>
        <Modal
          visible={tipModalState.matches("authentication")}
          outerCb={() => tipModalSend("CLOSE")}
          width="w-350"
          shadowDOM={widgetName}
          className="p-4 my-100p"
        >
          <AuthenticationModal
            tipModalState={tipModalState}
            tipModalSend={tipModalSend}
          />
        </Modal>
        <Modal
          visible={tipModalState.matches("details")}
          outerCb={() => tipModalSend("CLOSE")}
          shadowDOM={widgetName}
          width="w-350"
          className="p-4 my-100p"
        >
          {/* <Elements stripe={stripePromise}> */}
          <StripeForm
            tip={tip}
            complement={complement}
            product={product}
            story={story}
            tipModalSend={tipModalSend}
          />
          {/* </Elements> */}
        </Modal>
      </div>
    </>
  );
}

export default TipModal;
