import React from "react";
import ContentLoader from "react-content-loader";

const BrandCardShimmer = (props) => (
    <ContentLoader 
    speed={2}
    width={'100%'}
    
    viewBox="0 0 393 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#e6e3e3"
    {...props}
  >
    <rect x="4" y="54" rx="8" ry="8" width="380" height="128" /> 
    <circle cx="77" cy="56" r="55" /> 
    <rect x="3" y="209" rx="3" ry="3" width="248" height="26" /> 
    <rect x="4" y="243" rx="3" ry="3" width="220" height="11" /> 
    <rect x="6" y="275" rx="4" ry="4" width="69" height="22" /> 
    <rect x="157" y="315" rx="4" ry="4" width="223" height="23" /> 
    <rect x="90" y="274" rx="4" ry="4" width="69" height="22" /> 
    <rect x="6" y="315" rx="4" ry="4" width="130" height="23" /> 
    <rect x="179" y="274" rx="4" ry="4" width="69" height="22" /> 
    <rect x="264" y="274" rx="4" ry="4" width="117" height="23" /> 
    <rect x="7" y="360" rx="4" ry="4" width="69" height="22" /> 
    <rect x="105" y="358" rx="4" ry="4" width="130" height="23" />
  </ContentLoader>
);

export default BrandCardShimmer;
