import React, { useState, useContext, useEffect } from "react";
import Slider from "react-slick";
import Button from "@common/Button/Button";
import TipModal from "./TipModal/TipModal";
import tipMachine from "@app/machines/tipMachine";
import videoMachine from "@app/machines/videoMachine";

import { useMachine } from "@xstate/react";
import Context from "@app/context/StoriesContext";

import { FaMapMarkerAlt, FaRegPlayCircle, FaRegFileAlt } from "@react-icons";
import VideoCard from "./VideoCard/VideoCard";
import Evidence from "./Evidence/Evidence";
import ReactCardFlip from "react-card-flip";
import LazyImage from "../../../LazyImage/LazyImage";
import VideoModal from "./VideoModal/VideoModal";

function StoryCard({ story, setSwipe, preview, widgetName, fromMap }) {
  if (preview) {
    var customStyles = {};
    var activeChapter = Object.keys(story.fields.chapters)[0];
    var [videoModalState, videoModalSend] = useMachine(videoMachine);
    var [videoSrc, setVideoSrc] = useState(null);
  } else {
    var {
      customStyles,
      engagementSend,
      activeChapter,
      buttonColor,
    } = useContext(Context);
  }

  var {
    avatar,
    description,
    location,
    media,
    name,
    employeeRole,
    partOfProduct,
    storyType,
    evidence,
    showTipBtn,
    materialPercentage,
  } = story?.fields;

  var { isSupplier } = story;

  var [isDescExtended, setIsDescExtended] = useState(false);

  var slickSettings = {
    swipe: true,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (index) => {
      if (engagementSend) engagementSend("SLIDE_MEDIA");
    },
  };

  const [isTipModalOpen, setTipModalOpen] = useState(false);
  var [tipModalState, tipModalSend] = useMachine(tipMachine);
  var [videoModalState, videoModalSend] = useMachine(videoMachine);
  var [videoSrc, setVideoSrc] = useState(null);
  var [view, setView] = useState(null);

  useEffect(() => {
    setView("main");
  }, []);

  return (
    <>
      <ReactCardFlip
        isFlipped={view == "evidence" ? true : false}
        flipDirection="horizontal"
      >
        <div
          className={`relative  rounded-2 overflow-hidden flex flex-col m-auto bg-light text-dark ${
            !fromMap
              ? activeChapter.chapterName == "People"
                ? "border na-story-card-employee"
                : "border na-story-card"
              : ""
          }`}
        >
          <div className="flex px-2 items-center">
            <img src={avatar} className="box-60 circle" alt="" />
            <div
              className={`flex flex-col ml-3 ${
                activeChapter.chapterName == "People" ? "min-h-100" : "min-h-75"
              } w-100 justify-center`}
            >
              {Object.keys(evidence)?.length > 0 && (
                <button
                  aria-label="Evidence"
                  className="pos-abs -top-0 -right-0 bg-primary text-3 text-light p-2 flex items-center rounded-l-5 cursor-pointer "
                  onClick={() => {
                    setView("evidence");
                    if (engagementSend) engagementSend("SELECT_EVIDENCE");
                  }}
                  style={{
                    backgroundColor: buttonColor,
                  }}
                >
                  <FaRegFileAlt size="18" className="pr-2" />
                  <span>Evidence</span>
                </button>
              )}
              <h2
                className="text-3"
                style={{
                  fontWeight: customStyles?.fontWeight
                    ? customStyles.fontWeight
                    : 400,
                }}
              >
                {name}
              </h2>
              {(location?.city != "" || location?.country != "") && (
                <span
                  className="flex pb-1 text-3 text-muted"
                  style={{
                    fontWeight: customStyles?.fontWeight
                      ? customStyles.fontWeight
                      : 400,
                  }}
                >
                  <FaMapMarkerAlt size="12" className="float-left mr-1/4" />

                  <span
                    className="text-14px"
                    style={{
                      fontWeight: customStyles?.fontWeight
                        ? customStyles.fontWeight
                        : 400,
                    }}
                  >
                    {location?.city}
                    {location?.city && ","} {location?.country}
                  </span>
                </span>
              )}
              <div className="flex">
                {employeeRole && (
                  <span
                    className="bg-grey-light p-1 rounded-1 text-2x w-min no-wrap"
                    style={{
                      fontWeight: customStyles?.fontWeight
                        ? customStyles.fontWeight
                        : 400,
                      marginRight: 2,
                    }}
                  >
                    {employeeRole}
                  </span>
                )}
                {partOfProduct &&
                  partOfProduct !== null &&
                  partOfProduct !== "" &&
                  activeChapter.chapterName == "Materials" && (
                    <span
                      className="bg-grey-light p-1 rounded-1 text-2x w-min no-wrap"
                      style={{
                        fontWeight: customStyles?.fontWeight
                          ? customStyles.fontWeight
                          : 400,
                        marginRight: 2,
                      }}
                    >
                      {partOfProduct}
                    </span>
                  )}
                {materialPercentage &&
                  materialPercentage !== null &&
                  materialPercentage !== "" &&
                  activeChapter.chapterName == "Materials" && (
                    <span
                      className="bg-grey-light p-1 rounded-1 text-2x w-min no-wrap"
                      style={{
                        fontWeight: customStyles?.fontWeight
                          ? customStyles.fontWeight
                          : 400,
                        marginRight: 2,
                      }}
                    >
                      {materialPercentage + "%"}
                    </span>
                  )}
                {isSupplier && (
                  <span
                    className="bg-grey-light p-1 rounded-1 text-2x w-min no-wrap"
                    style={{
                      fontWeight: customStyles?.fontWeight
                        ? customStyles.fontWeight
                        : 400,
                    }}
                  >
                    Supplier's story
                  </span>
                )}
              </div>
            </div>
          </div>
          {media?.length == 1 ? (
            <div className="mb-3">
              {media?.map((item, index) => {
                if (item.type == "image") {
                  return (
                    <LazyImage
                      key={index}
                      src={item.url}
                      className="w-100 object-cover"
                      alt={item.title}
                      width={340}
                      height={183.78}
                    ></LazyImage>
                  );
                }
                if (item.type == "video") {
                  return (
                    <div key={index}>
                      <VideoCard
                        item={item}
                        index={index}
                        videoModalState={videoModalState}
                        videoModalSend={videoModalSend}
                        setVideoSrc={setVideoSrc}
                        videoSrc={videoSrc}
                        preview={preview}
                      />
                    </div>
                  );
                }
              })}
            </div>
          ) : (
            <div
              role="presentation"
              className="mb-3"
              onMouseDown={(e) => {
                e.stopPropagation();
                setSwipe(false);
              }}
              onTouchStart={(e) => {
                e.stopPropagation();
                setSwipe(false);
              }}
              onMouseLeave={() => {
                setSwipe(true);
              }}
              onTouchEnd={() => {
                setSwipe(true);
              }}
            >
              <Slider {...slickSettings}>
                {media?.map((item, index) => {
                  if (item.type == "image") {
                    return (
                      <LazyImage
                        key={index}
                        src={item.url}
                        className="w-100 object-cover na-story-card-image"
                        alt={item.title}
                        width={340}
                        height={183.78}
                      ></LazyImage>
                    );
                  }
                  if (item.type == "video") {
                    return (
                      <div key={index}>
                        <VideoCard
                          item={item}
                          index={index}
                          videoModalState={videoModalState}
                          videoModalSend={videoModalSend}
                          setVideoSrc={setVideoSrc}
                          videoSrc={videoSrc}
                          preview={preview}
                        />
                      </div>
                    );
                  }
                })}
              </Slider>
            </div>
          )}
          <div className="pt-2">
            {isDescExtended ? (
              <p
                className="p-2 pre-wrap na-story-card-description text-3"
                style={{
                  fontWeight: customStyles?.fontWeight
                    ? customStyles.fontWeight
                    : 400,
                }}
              >
                {description}
                <Button
                  type="link"
                  action={() => {
                    setIsDescExtended(false);
                  }}
                  styles={{
                    color: buttonColor ? buttonColor : "#00867f",
                  }}
                >
                  {" show less"}
                </Button>
              </p>
            ) : (
              <p
                className="p-2 pre-wrap na-story-card-description text-3"
                style={{
                  fontWeight: customStyles?.fontWeight
                    ? customStyles.fontWeight
                    : 400,
                }}
              >
                {description?.length > 70 && description?.length <= 110
                  ? description
                  : description?.substring(0, 70)}
                <Button
                  type="link"
                  action={() => {
                    setIsDescExtended(true);
                    if (engagementSend) engagementSend("READ_MORE");
                  }}
                  styles={{
                    color: buttonColor ? buttonColor : "#00867f",
                  }}
                >
                  {description?.length <= 110 ? null : " ...show more"}
                </Button>
              </p>
            )}
          </div>
          {(storyType == "Employee" || storyType == "Groups") && showTipBtn && (
            <div className="my-auto">
              <Button
                type="tip"
                effect={
                  customStyles?.buttonColour ? "" : "shutter-out-horizontal"
                }
                action={() => {
                  setTipModalOpen(!isTipModalOpen);
                  tipModalSend("OPEN");
                }}
                styles={{
                  width: "73%",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "14px",
                  backgroundColor: buttonColor,
                }}
              >
                Tip This Person
              </Button>
              <TipModal
                tipModalState={tipModalState}
                tipModalSend={tipModalSend}
                story={story}
              />
            </div>
          )}
        </div>

        <Evidence story={story} setView={setView} />
      </ReactCardFlip>

      {preview ? (
        <VideoModal
          videoSrc={videoSrc}
          videoModalState={videoModalState}
          videoModalSend={videoModalSend}
          widgetName={widgetName}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default StoryCard;
