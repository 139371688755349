import React, { useState, useContext } from "react";
import Button from "@common/Button/Button";
import fetchMachine from "@app/machines/fetchMachine";
import { useMachine } from "@xstate/react";
import LoaderIcon from "@common/LoaderIcon/LoaderIcon";
import { FaTimes } from "@react-icons";
import styles from "./AuthenticationModal.module.css";
import { FcGoogle } from "@react-icons";
import StoriesContext from "@app/context/StoriesContext";
import logoPng from "@app/assets/images/logo.png";
import { ROOT_ID } from "@app/constants/constants";
import StoriesWidgetContext from "../../../../../../../context/StoriesWidgetContext";
import AppContext from "../../../../../../../context/AppContext";

function AuthenticationModal({ tipModalState, tipModalSend }) {
  var { engagementSend, host, isWebComponent } = useContext(StoriesContext);
  var { setUser, firebase } = useContext(
    isWebComponent ? StoriesWidgetContext : AppContext
  );

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  var [authState, authSend] = useMachine(fetchMachine, {
    actions: {
      goFetch: () => {
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then((user) => {
            setUser(user);
            tipModalSend("CONTINUE");
          })
          .catch((error) => {
            console.error(error);
          });
      },
    },
  });
  function LoginwithGoogle() {
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((user) => {
        setUser(user);
        tipModalSend("CONTINUE");
      })
      .catch((error) => error);
  }

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    }
  };
  return (
    <>
      <div className="flex flex-col items-center">
        <Button
          type="link"
          action={() => {
            tipModalSend("CLOSE");
          }}
          styles={{
            marginLeft: "auto",
          }}
        >
          <FaTimes size="32" />
        </Button>
        <h3>Account</h3>
        <p>If you already have an account please sign in.</p>
        <form className="mt-4" onSubmit={(e) => e.preventDefault()}>
          <input
            className="border w-100 rounded-1 mb-4 p-2"
            type="email"
            name="userEmail"
            value={email}
            placeholder="Enter email address"
            onChange={(event) => onChangeHandler(event)}
          ></input>
          <input
            className="border w-100 rounded-1 mb-4 p-2"
            type="password"
            name="userPassword"
            value={password}
            placeholder="Enter password"
            onChange={(event) => onChangeHandler(event)}
          ></input>

          {authState.matches("pending") ? (
            <LoaderIcon width={64} height={64} />
          ) : (
            <Button
              type="primary"
              size="lg"
              stretch
              action={() => {
                authSend("FETCH");
              }}
            >
              Sign In
            </Button>
          )}
        </form>
        <span>Or</span>
        <Button
          type="light"
          size="lg"
          stretch
          action={() => LoginwithGoogle()}
          className="flex-center"
        >
          <FcGoogle className="mr-1" />
          Continue with Google
        </Button>
        <span className="p-3">Otherwise please continue</span>
        <Button
          type="primary"
          size="lg"
          stretch
          action={() => {
            tipModalSend("CONTINUE");
          }}
          styles={{
            marginBottom: "16px",
          }}
        >
          Continue
        </Button>
        <Button
          type="primary"
          size="lg"
          stretch
          action={() => {
            tipModalSend("BACK");
          }}
        >
          Back
        </Button>
        {(host == "customer" || host == "dashboard") && (
          <div className="flex items-center mt-4">
            <span className="text-3">Powered by&nbsp;</span>
            <a
              onClick={() => engagementSend("CLICK_POWERED_BY")}
              href="https://rootip.io"
              target="_blank"
              rel="noreferrer"
            >
              <img
                height="16px"
                src={ROOT_ID + logoPng}
                alt="Rootip Logo"
              ></img>
            </a>
          </div>
        )}
      </div>
    </>
  );
}

export default AuthenticationModal;
