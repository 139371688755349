/* eslint-disable import/no-unresolved */
/* eslint-disable import/namespace */
/* eslint-disable import/named */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo, lazy, Suspense } from "react";
import { useMachine } from "@xstate/react";
import { BrowserRouter, Switch, Route, useLocation } from "react-router-dom";
import { firebase, ROOT_ID } from "./constants/constants";
import Context from "./context/AppContext";
import { loadStripe } from "@stripe/stripe-js";
import fetchMachine from "./machines/fetchMachine";
import stripeServices from "@app/services/stripeServices";
import authServices from "@app/services/authServices";
import CircleExplore from "./components/views/Admin/containers/Circle/CircleExplore/CircleExplore";
import BrandDetails from "./components/views/Admin/containers/Circle/BrandDetails/BrandDetails";
import ProductDetails from "./components/views/Admin/containers/Circle/ProductDetails/ProductDetails";
import CollectionDetails from "./components/views/Admin/containers/Circle/CollectionDetails/CollectionDetails";
import AiReport from "./components/views/RootipAdmin/AiScan/AiReport";

import "@app/styles/main.css";

import RootipAdmin from "./components/views/RootipAdmin/__RootipAdmin";
// const RootipAdmin = loadable(() =>
//   import("./components/views/RootipAdmin/RootipAdmin")
// );

import Temp from "./components/views/Temp/Temp";
// const Temp = loadable(() => import("./components/views/Temp/Temp"));

// import Stories from "@common/Stories/Stories";
// const Stories = loadable(() => import("@common/Stories/Stories"));

// import StoriesPreview from "@common/StoriesPreview/StoriesPreview";
// const StoriesPreview = loadable(() =>
//   import("@common/StoriesPreview/StoriesPreview")
// );

import LoaderIcon from "@common/LoaderIcon/LoaderIcon";
// const LoaderIcon = loadable(() => import("@common/LoaderIcon/LoaderIcon"));

import AdminWidgetTab from "../app/components/views/Admin/AdminWidgetTab";
// const AdminWidgetTab = loadable(() =>
//   import("../app/components/views/Admin/AdminWidgetTab")
// );

import ImportProducts from "../app/components/views/Admin/ImportProducts";
// const ImportProducts = loadable(() =>
//   import("../app/components/views/Admin/ImportProducts")
// );
import Explore from "./components/views/Website/Containers/Explore/Explore";
import Product from "./components/views/Website/Containers/Product/Product";
import Brand from "./components/views/Website/Containers/Brand/Brand";
import login from "./components/views/Website/Containers/Login";

import Collection from "./components/views/Website/Containers/Collection/Collection";
import QrProvider from "./components/views/Website/Provider/QrProvider";
import Overview from "./components/views/RootipAdmin/Overview/Overview";
function App({ widgetName = null, widgetType }) {
  var [user, setUser] = useState(null);
  var [currentUser, setCurrentUser] = useState(null);

  var [urlParams, setUrlParams] = useState(null);

  var [authState, authSend] = useMachine(fetchMachine, {
    actions: {
      goFetch: () => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            setUser(user);
            authSend("RESOLVE");
            idTokenSend("FETCH");
          } else {
            setUser(null);
            authSend("REJECT");
            idTokenSend("DENY");
            userSend("DENY");
          }
        });
      },
    },
  });

  var [idToken, setIdtoken] = useState(null);
  var [idTokenState, idTokenSend] = useMachine(fetchMachine, {
    actions: {
      goFetch: () => {
        firebase
          .auth()
          .currentUser?.getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            setIdtoken(idToken);
            idTokenSend("RESOLVE");
            userSend("FETCH");
          });
      },
    },
  });

  var [userState, userSend] = useMachine(fetchMachine, {
    actions: {
      goFetch: () => {
        authServices
          .getCurrentUser(idToken)
          .then((currentUser) => {
            setCurrentUser(currentUser);
            userSend("RESOLVE");
          })
          .catch((error) => {
            console.log(error);
            userSend("REJECT");
          });
      },
    },
  });

  useEffect(() => {
    setUrlParams(new URLSearchParams(window.location.search));
  }, []);
  useEffect(() => {
    let url = window.location.toString();
    if (
      (url.includes("rootipdev") || url.includes("rootipuat")) &&
      !url.includes("pasrecover")
    ) {
      let o_Date = localStorage.getItem("b2cUser");
      if (o_Date !== null && o_Date !== "" && o_Date !== undefined) {
        let currentDate = new Date();
        let c_date = currentDate.getTime();
        let d_In_Time = c_date - o_Date;
        let d_In_Days = d_In_Time / (1000 * 3600 * 24);
        if (d_In_Days > 6) {
          localStorage.removeItem("b2cUser", "");
          window.location.href = "/innerlogin.html";
        }
      } else {
        window.location.href = "/innerlogin.html";
      }
    }
  }, []);

  useEffect(() => {
    authSend("FETCH");
  }, []);

  var [id, setId] = useState(null);
  var [ownerid, setOwnerId] = useState(null);
  useEffect(() => {
    var local = JSON.parse(localStorage.getItem("user"));
    if (local) setOwnerId(local.brandId);

    const query = new URLSearchParams(window.location.search);
    if (widgetType === "products") {
      setId(query.get("product"));
    }
    if (widgetType === "collections") {
      setId(query.get("collection"));
    }
    if (widgetType === "rootippers" || widgetName == "importProducts") {
      setId(JSON.parse(localStorage.getItem("rootipperId")));
    }
  }, [widgetType, widgetName, id]);

  var context = useMemo(
    () => ({
      firebase,
      user,
      setUser,
      currentUser,
      setCurrentUser,
      urlParams,
      widgetName,
      userState,
      idToken,
    }),
    [
      firebase,
      user,
      setUser,
      currentUser,
      urlParams,
      widgetName,
      userState,
      idToken,
    ]
  );

  return (
    <Context.Provider value={context}>
      {widgetName ? (
        <>
          {widgetName == "adminTabStoriesPreview" && (
            <AdminWidgetTab ownerId={ownerid} type={widgetType} id={id} />
          )}
          {widgetName == "importProducts" && <ImportProducts brandId={id} />}
        </>
      ) : (
        <BrowserRouter basename="app">
          <Switch>
            {authState.matches("pending") ? (
              <Route
                exact
                path={`/rootipadmin`}
                component={() => <LoaderIcon width={514} height={514} />}
              />
            ) : authState.matches("resolved") ? (
              user ? (
                <Route exact path={`/rootipadmin`} component={Overview} />
              ) : (
                <Route
                  exact
                  path={`/rootipadmin`}
                  render={() => {
                    window.location.href = ROOT_ID + "/app/login";
                    return null;
                  }}
                />
              )
            ) : null}

            {/* <Route exact path={`/rootipadmin/overview`} component={Overview} />
            <Route exact path={`/rootipadmin`} component={Overview} />
            <Route exact path={`/rootipadmin/ai`} component={AiReport} /> */}
            <Route exact path={`/rootipadmin/overview`} component={Overview} />
            <Route exact path={`/rootipadmin/ai`} component={AiReport} />

            <Route exact path={`/admin/circle`} component={CircleExplore} />
            <Route
              exact
              path={`/admin/circle/brand/:id`}
              component={BrandDetails}
            />
            <Route
              exact
              path={`/admin/circle/collection/:id`}
              component={CollectionDetails}
            />
            <Route
              exact
              path={`/admin/circle/product/:id`}
              component={ProductDetails}
            />

            <Route path={`/explore/:mode`} component={Explore} />
            <Route path={`/explore`} component={Explore} />
            <Route exact path="/temp" component={Temp} />
            <Route exact path="/login" component={login} />

            <QrProvider>
              <Route path={`/product/:id`} component={Product} />
              <Route path={`/brand/:id`} component={Brand} />
              <Route path={`/collection/:id`} component={Collection} />
            </QrProvider>

            <Route render={() => <div>404</div>} />
          </Switch>
        </BrowserRouter>
      )}

      <style>
        {`


      .swal2-popup{
        font-size:16px
      }
      .swal2-container{
        z-index:9999
      }
      .swal2-confirm{
        background-color:var(--color-primary)
      }


      `}
      </style>
    </Context.Provider>
  );
}

export default App;
