import React, { useState } from "react";
import Button from "../../common/Button/Button";
import UserModal from "../../common/UserModal/UserModal";
import Icon from "@common/Icon/Icon";

function Topbar({ setOpen, open }) {
  var [showModal, setShowModal] = useState(false);

  return (
    <>
      <header>
        <Button type="link" action={() => setOpen(!open)}>
          <Icon
            name="md/menu"
            size={24}
            color="var(--color-light)"
            hover="var(--color-light)"
            className="icon-menu"
          />
        </Button>
        <ul>
          <li className="shop">
            <span>My Shop</span>
            <Icon
              name="md/store_mall_directory"
              size={18}
              color="var(--color-light)"
              hover="var(--color-light)"
            />
          </li>
          <li className="notification">
            <Button type="link" action={() => setShowModal(!showModal)}>
              <Icon
                name="md/account_circle"
                size={28}
                color="var(--color-light)"
                hover="var(--color-light)"
              />
            </Button>
          </li>
        </ul>
        {showModal ? <UserModal /> : null}
      </header>
      <style jsx>{`
        header {
          grid-area: topbar;
          position: sticky;
          top: 0;
          right: 0;
          left: 0;
          display: flex;
          background-color: var(--color-primary);
          align-items: center;
        }
        :global(.icon-menu) {
          margin-left: 2rem;
        }
        ul {
          margin: 0 3rem 0 auto;
          display: flex;
          color: #fff;
          align-items: center;
        }
        ul > li {
          padding: 2rem;
          display: flex;
        }
        .shop {
          flex-direction: row-reverse;
          align-items: center;
        }
        .shop > span {
          margin-left: 0.5rem;
          font-size: 1.4rem;
        }
        .notification {
        }
        .notification :global(button) {
          display: flex;
        }
      `}</style>
    </>
  );
}

export default Topbar;
