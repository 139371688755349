import React from "react";

import TitleBox from "@common/TitleBox/TitleBox";
import Tabs from "@common/Tabs/Tabs";
import OverviewTab from "./OverviewTab";
import TransparencyTab from "./TransparencyTab";
import ChapterTab from "./ChapterTab";
import RootipAdminLayout from "../../../layout/admin/RootipAdminLayout";
function Overview() {
  return (
    <RootipAdminLayout
      subMenu={[]}
      pageTitle={"Overview"}
      pointList={[
        {
          title: "",
          href: "",
        },
      ]}
    >
      <>
        <TitleBox title="Overview" />
        <Tabs
          selector="overview-tabs"
          items={[
            <OverviewTab key="0" title="Overview" />,
            <TransparencyTab key="1" title="Transparency" />,
            <ChapterTab key="2" title="Stories & Chapters" />,
          ]}
        />

        <style jsx global>{`
          .overview-tabs {
            display: flex;
            flex-direction: column;
            min-width: 60%;
          }
          .overview-tabs_tabs {
            position: relative;
            z-index: 10;
            transform: translateY(-2px);
            border-radius: var(--border-radius-general)
              var(--border-radius-general) 0 0;
          }
          .overview-tabs_tabs ul {
            display: flex;
            transform: translateY(2px);
          }
          .overview-tabs_tabs li {
            display: flex;
          }
          .overview-tabs_tabs li:not(:last-child) {
            margin-right: 0.2rem;
          }
          .overview-tabs_tabs button {
            background-color: #ccc;
            text-decoration: none;
            border-radius: var(--border-radius-general)
              var(--border-radius-general) 0 0;
            padding: 0.5rem 1rem;
            color: #000;
            border: none;
            cursor: pointer;
          }
          .overview-tabs_tabs button.active {
            background-color: #fff;
            box-shadow: rgba(255, 255, 255, 1) 0px 2px 0px 0px,
              rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
              rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
          }

          .overview-tabs_view {
            position: relative;
            box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
              rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
            border-radius: 0 var(--border-radius-general)
              var(--border-radius-general) var(--border-radius-general);
            padding: 1rem;
            background-color: var(--color-light);
            height: 100%;
          }
          .overview-tabs_view_item {
            display: none;
          }
          .overview-tabs_view_item.active {
            display: initial;
          }
        `}</style>
      </>
    </RootipAdminLayout>
  );
}

export default Overview;
