import React from "react";
import PieChart from "@common/PieChart/PieChart";

function ChannelView({ data }) {
  return (
    <>
      <div className="container">
        <PieChart
          data={[data?.mobile, data?.web]}
          outerRadius="100"
          innerRadius="80"
        />
        <div>
          <h2>Mobile Apps</h2>
          <h2>{data?.mobile}</h2>
          <div className="mobile percent">
            {data?.mobile == 0
              ? "0%"
              : Math.round((data?.mobile / (data?.mobile + data?.web)) * 100) +
                "%"}
          </div>
        </div>
        <div>
          <h2>Website</h2>
          <h2>{data?.web}</h2>
          <div className="web percent">
            {data?.web == 0
              ? "0%"
              : Math.round((data?.web / (data?.mobile + data?.web)) * 100) +
                "%"}
          </div>
        </div>
      </div>
      <style jsx>{`
        .container {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          text-align: center;
          align-items: center;
          padding: 3rem 0;
        }
        .mobile.percent {
          background-color: var(--color-primary);
        }
        .web.percent {
          background-color: var(--color-secondary);
        }
        .percent {
          max-width: max-content;
          padding: 0.2rem 0.5rem;
          border-radius: var(--border-radius-general);
          color: var(--color-light);
          margin: auto;
        }
      `}</style>
    </>
  );
}

export default ChannelView;
