import React from "react";
import StoryCard from "../StoryCard";

function Comment({ comment, qna }) {
  if (comment)
    return (
      <>
        <p>{comment}</p>
        {qna.map((qa, index) => (
          <div key={index} className="mt-4">
            <p className="font-weight-bold pre-wrap">
              {Object.keys(qa)[0]?.replace(/['"]+/g, "")}
            </p>
            <p>{qa[Object.keys(qa)[0]]}</p>
          </div>
        ))}
      </>
    );
  else
    return (
      <>
        <p>No Comments</p>
      </>
    );
}

export default Comment;
