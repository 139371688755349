import React, { useMemo, useState, useEffect, useRef } from "react";
import service from "@app/services/rootipAdminServices";
import Swal from "sweetalert2";
import LoaderIcon from "@common/LoaderIcon/LoaderIcon";
import useFetch from "../../../../../hooks/useFetch";

function Triggers() {
  var [firetime, setFireTime] = useState();

  useEffect(() => {
    loadSend("FETCH");
  }, []);

  var [, loadState, loadSend] = useFetch(
    service.triggerLastUpdate,
    [],
    (res) => {
      setFireTime(res);
    }
  );

  var [
    ,
    transparencyStatistic,
    transparencyStatisticsSend,
  ] = useFetch(service.transparencyStatisticsTrigger, [], (res) => {});

  var [
    ,
    storyUsageStateState,
    storyUsageStateSend,
  ] = useFetch(service.storyUsageStateTrigger, [], (res) => {});

  var [, connectionsState, connectionSend] = useFetch(
    service.connectionTrigger,
    [],
    (res) => {}
  );

  return loadState.matches("pending") ? (
    <>
      <LoaderIcon width="80" height="80" />
    </>
  ) : (
    <>
      <div>
        <div className="flex flex-col info-card mt-2">
          <div className="font-weight-bold ml-2">Last Calculated</div>
          {transparencyStatistic.matches("pending") ? (
            <>
              <LoaderIcon width="80" height="80" />
            </>
          ) : (
            <>
              <div className="flex flex-row ">
                <div className="p-2  m-1 w-30">
                  {firetime && (
                    <>
                      Date:
                      {firetime.transparencyStatistics_lastUpdate.split("T")[0]}
                      &nbsp; &nbsp; Time:
                      {firetime.transparencyStatistics_lastUpdate.split("T")[1]}
                    </>
                  )}
                </div>
                <div>
                  <button
                    className="border-primary  p-2 m-1 border rounded-1 bg-primary text-light "
                    id="save"
                    type="submit"
                    onClick={() => {
                      transparencyStatisticsSend("FETCH");
                    }}
                  >
                    Recalculate Transparency Score
                  </button>
                </div>
              </div>
            </>
          )}
          {storyUsageStateState.matches("pending") ? (
            <>
              <LoaderIcon width="80" height="80" />
            </>
          ) : (
            <>
              <div className="flex flex-row">
                <div className="p-2  m-1 w-30">
                  {firetime && (
                    <>
                      Date:{firetime.storyUsageStats_lastUpdate.split("T")[0]}
                      &nbsp; &nbsp; Time:
                      {firetime.storyUsageStats_lastUpdate.split("T")[1]}
                    </>
                  )}
                </div>
                <div>
                  <button
                    className="border-primary  p-2 m-1 border rounded-1 bg-primary text-light "
                    id="save"
                    type="submit"
                    onClick={() => {
                      storyUsageStateSend("FETCH");
                    }}
                  >
                    Recalculate Story Usages
                  </button>
                </div>
              </div>
            </>
          )}
          {connectionsState.matches("pending") ? (
            <>
              <LoaderIcon width="80" height="80" />
            </>
          ) : (
            <div className="flex flex-row">
              <div className="p-2  m-1 w-30">
                {firetime && (
                  <>
                    Date:
                    {firetime.supplyChainConnections_lastUpdate.split("T")[0]}
                    &nbsp; &nbsp; Time:
                    {firetime.supplyChainConnections_lastUpdate.split("T")[1]}
                  </>
                )}
              </div>
              <div>
                <button
                  className="border-primary  p-2 m-1 border rounded-1 bg-primary text-light "
                  id="save"
                  type="submit"
                  onClick={() => {
                    connectionSend("FETCH");
                  }}
                >
                  Recalculate Connections
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <style jsx>{`
      .info-card {
        display: flex;
        padding-right: 4px;
        padding-top: 4px;
        margin-left: 5px;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
          rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        border-radius: var(--border-radius-general);
        background-color: var(--color-light);

      `}</style>
    </>
  );
}

export default Triggers;
