import React from "react";

import Metrics from "./Metrics/Metrics";
import KPIs from "./KPIs/KPIs";
import KPITabs from "./KPITabs/KPITabs";
function OverviewTab() {
  return (
    <>
      <Metrics />
      <KPIs />
      <div className="bottom-row">
        <KPITabs />
      </div>

      <style jsx>{`
        .bottom-row {
          display: flex;
        }
      `}</style>
    </>
  );
}

export default OverviewTab;
