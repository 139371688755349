import React from "react";

import UsageStories from "./Chapters/UsageStories";
import UsageCharts from "./Chapters/UsageCharts";
function ChapterTab() {
  return (
    <>
      <div className="mb-2">
        <UsageCharts />
        <UsageStories />
      </div>
    </>
  );
}

export default ChapterTab;
