import React, { useCallback, useState } from "react";
import { getCroppedImg } from "../../../../../../../utils/canvasUtils";
import ProgressBar from "@ramonak/react-progress-bar";
import Cropper from "react-easy-crop";
import { storage } from "../../../../../../../constants/constants";

function FileInput({ init, edit, name, readOnly }) {
  var [isShowCrop, setIsShowCrop] = useState(!edit);
  var [image, setImage] = useState(null);
  var [imageSrc, setImageSrc] = useState(init);
  var [imageUrl, setImageUrl] = useState(init);
  var [progress, setProgress] = useState(0);
  var [isShowProgressBar, setIsShowProgressBar] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const uploadImage = useCallback(async () => {
    try {
      const croppedImageString = await getCroppedImg(
        imageSrc,
        croppedAreaPixels
      );

      var uploadTask = storage
        .ref()
        .child(`images/nodes/${name}s/${image.name}`)
        .putString(croppedImageString, "data_url");

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          setIsShowProgressBar(true);
          setProgress(
            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
          );
        },
        (error) => {
          console.error(error);
        },
        () => {
          storage
            .ref(`images/nodes/${name}s/`)
            .child(image.name)
            .getDownloadURL()
            .then((url) => {
              setImageUrl(url);
            });
        }
      );
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  if (readOnly) {
    return (
      <img
        src={imageUrl}
        alt={name}
        className={`${
          name == "logo" ? "w-100p h-100p circle object-cover" : "w-300p"
        } m-2`}
      />
    );
  } else
    return (
      <>
        <div
          className={`border w-100 rounded-1 ${
            imageSrc ? "" : "mb-4"
          } flex justify-between items-center`}
        >
          <div className=" w-100 text-left flex">
            <label
              htmlFor={name}
              className="text-muted text-3 cursor-pointer p-2 text-left w-100 block"
            >
              Select {name}
            </label>
            <input
              type="file"
              id={name}
              name={name}
              className="hidden"
              onChange={async (e) => {
                if (e.target.files && e.target.files.length > 0) {
                  var name =
                    e.target.files[0].name.substring(
                      0,
                      e.target.files[0].name.lastIndexOf(".")
                    ) +
                    Date.now() +
                    e.target.files[0].name.substring(
                      e.target.files[0].name.lastIndexOf(".")
                    );

                  var file = new File([e.target.files[0]], name, {
                    type: e.target.files[0].type,
                  });

                  let imageDataUrl = await readFile(file);

                  setImageSrc(imageDataUrl);
                  setImage(file);
                  setIsShowCrop(true);
                }
              }}
            ></input>
          </div>
          {edit && (
            <img
              src={imageUrl}
              alt={name}
              className={`${
                name == "logo" ? "w-100p h-100p circle object-cover" : "w-300p"
              } m-2`}
            />
          )}
        </div>
        {imageSrc && isShowCrop && (
          <>
            <div className="border w-100 rounded-1 mb-4 flex justify-between items-center">
              <div className=" w-100 text-left flex">
                <div>
                  <div className="flex h-200p m-2 text-3 cursor-pointer relative">
                    <div className={` p-0 h-200p w-350 transition-all`}>
                      <Cropper
                        image={imageSrc}
                        crop={crop}
                        zoom={zoom}
                        aspect={name == "logo" ? 1 : 720 / 240}
                        cropShape={name == "logo" ? "round" : "rect"}
                        showGrid={false}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                      />
                    </div>
                  </div>
                  <div className="flex justify-center m-2">
                    <input
                      type="range"
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.1}
                      aria-labelledby="Zoom"
                      onChange={(e) => {
                        setZoom(e.target.value);
                      }}
                      className="zoom-range"
                    />
                  </div>
                </div>
              </div>

              {imageSrc && (
                <div className="flex flex-col m-2 w-40">
                  <button
                    className="bg-primary rounded-1 p-2 text-light hover:bg-primary-dark active:bg-primary-darker"
                    onClick={(e) => {
                      e.preventDefault();
                      uploadImage();
                    }}
                  >
                    Upload
                  </button>
                  {isShowProgressBar && (
                    <ProgressBar
                      className="mt-2"
                      completed={progress}
                      maxCompleted={100}
                      bgColor="var(--color-success)"
                      labelSize="12px"
                      labelAlignment="center"
                    />
                  )}
                </div>
              )}
            </div>
          </>
        )}
        <input value={imageUrl} name={name} readOnly className="hidden" />
      </>
    );
}

export default FileInput;
