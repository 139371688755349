import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from "react-router";
import { getBrands } from "../../../../../services/WebsiteRequeries";
import BrandCard from "../../Components/BrandCard";
import BrandCardShimmer from "../../Components/Shimmers/BrandCardShimmer";

const BrandsContainer = () => {
  const [params, setParams] = useState({
    size: 6,
    startSeq: -1,
    startTitle: "0",
  });
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search).get("q");
  const [count, setCount] = useState(24);
  const changeParams = (newParams) => {
    setParams({ ...params, ...newParams });
  };

  const getBrandsHandler = (newParam, clear = false) => {
    setLoading(true);
    getBrands(newParam)
      .then((res) => {
        setLoading(false);
        if (clear) {
          setBrands(res.brandList);
        } else {
          setBrands([...brands, ...res.brandList]);
        }

        setParams({
          size: 6,
          startSeq: res.brandList?.[res.brandList.length - 1].fields.createDate,
          startTitle:
            res.brandList?.[res.brandList.length - 1].fields.searchTitle,
        });
        setCount(res.count);
        //get latest product createdDate for  fetch next products
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    setBrands([]);
    if (searchParams != "" && searchParams != null) {
      changeParams({
        query: searchParams ?? "",
        startTitle: searchParams ?? "",
      });
      getBrandsHandler(
        {
          ...params,
          query: searchParams ?? "",
          startTitle: searchParams ?? "",
        },
        true
      );
    } else {
      changeParams({
        size: 6,
        startSeq: -1,
        startTitle: "0",
      });
      getBrandsHandler(
        {
          size: 6,
          startSeq: -1,
          startTitle: "0",
        },
        true
      );
    }
  }, [search]);

  return (
    <div className="w-full h-auto">
      <InfiniteScroll
        dataLength={brands.length}
        next={() => (loading ? null : getBrandsHandler({ ...params }))}
        //To put endMessage and loader to the top.

        hasMore={count > brands.length}
      >
        <div className="my-10 flex  justify-between flex-wrap  ">
          {brands.length > 0 ? (
            brands.map((item) => (
              <div className="w-full sm:w-4/12 p-4 " key={item.id}>
                <BrandCard key={"brand_" + item.id} item={item} />
              </div>
            ))
          ) : loading ? null : (
            <div className="w-full mt-10 text-center text-[18px] text-caption">
              No match found
            </div>
          )}

          {loading ? (
            <>
              <div className="w-full sm:w-4/12 p-4 ">
                <BrandCardShimmer />
              </div>
              <div className="w-full sm:w-4/12 p-4 ">
                <BrandCardShimmer />
              </div>
              <div className="w-full sm:w-4/12 p-4 ">
                <BrandCardShimmer />
              </div>
            </>
          ) : null}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default BrandsContainer;
