import React, { useState } from "react";
import ComboBox from "./ComboBox";
import FileInput from "./FileInput";
import MapInput from "./MapInput";
import TypeInput from "./TypeInput";

function FormInput({
  type,
  label,
  name,
  init,
  required,
  visible,
  edit,
  keys,
  keysState,
  setSuggestedNode,
  readOnly,
  setSuggestionQuery,
  brandId,
}) {
  var [value, setValue] = useState(init);
  return (
    <>
      {" "}
      <label htmlFor={name}>
        {label}
        {required && <span className="text-error">*</span>}
      </label>
      {type == "combo" && (
        <ComboBox
          init={init}
          edit={edit}
          name={name}
          setSuggestedNode={setSuggestedNode}
          setSuggestionQuery={setSuggestionQuery}
          brandId={brandId}
        />
      )}
      {type == "text" && (
        <input
          required={required}
          type={type}
          className="border w-100 rounded-1 mb-4 p-2 text-muted text-3"
          value={value}
          name={name}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          readOnly={readOnly}
        ></input>
      )}
      {type == "textarea" && (
        <textarea
          required
          className="border w-100 rounded-1 mb-4 p-2 text-muted text-3 resize-y"
          value={value}
          name={name}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          readOnly={readOnly}
        ></textarea>
      )}
      {type == "map" && (
        <MapInput visible={visible} init={init} readOnly={readOnly} />
      )}
      {type == "file" && (
        <FileInput init={init} edit={edit} name={name} readOnly={readOnly} />
      )}
      {type == "checkbox" && (
        <TypeInput
          init={init}
          edit={edit}
          name={name}
          keys={keys}
          keysState={keysState}
          readOnly={readOnly}
        />
      )}
    </>
  );
}

export default FormInput;
