import React from "react";

function Footer() {
  return (
    <>
      <footer>
        <p>2020 © Rootip - rootip.io</p>
      </footer>
      <style jsx>{`
        footer {
          padding: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #98a6ad;
        }
      `}</style>
    </>
  );
}

export default Footer;
