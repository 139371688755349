import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
const ImageSlider = ({
  setting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  },
  children,
}) => {
  return <Slider {...setting}>{children}</Slider>;
};

export default ImageSlider;
