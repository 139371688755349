import React, { useEffect, useState } from "react";

function Icon({ path, name, size, color, hover, ...otherProps }) {
  /* Use state hook to store icon module value */
  const [iconModule, setIconModule] = useState(null);
  const [externalSvg, setExternalSvg] = useState(null);
  const [fill, setFill] = useState(color);

  useEffect(() => {
    let isSubscribed = true;
    if (path) {
      fetch(path)
        .then((res) => res.text())
        .then((data) => {
          setExternalSvg(data);
        });
    }

    /* Use dynamic import to get corresponding icon as a module */
    !path &&
      import(
        /* webpackChunkName: "icons/icon-[request]" */
        /* webpackMode: "lazy" */
        `@app/assets/icons/${name}.svg`
      )
        .then((res) => {
          isSubscribed ? setIconModule(res.default) : null;
        })
        .catch((error) => (isSubscribed ? console.error(error.message) : null));

    return () => {
      isSubscribed = false;
    };
  }, [
    name,
    /* update on name change */
  ]);

  function Component() {
    return React.cloneElement(iconModule, {
      ...iconModule.props,
    
      viewBox: `0 0 ${iconModule.props.width} ${iconModule.props.height}`,
      width: size,
      height: size,
      fill: fill,
      ...otherProps,
      onMouseEnter: () => {
        setFill(hover);
      },
      onMouseLeave: () => setFill(color),
    });
  }

  if (externalSvg) {
    return (
      <span
        className="mr-1 flex"
        dangerouslySetInnerHTML={{ __html: externalSvg }}
      ></span>
    );
  }

  return iconModule ? <Component /> : null;
}

export default Icon;
