import React, { useContext } from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import Icon from "@common/Icon/Icon";
import { ROOT_ID } from "@app/constants/constants";
import Context from "@app/context/AppContext";
import { useLocation, useHistory } from "react-router";
function Sidebar({ open }) {
  let { url } = useRouteMatch();
  const history = useHistory();
  const { user, currentUser } = useContext(Context);
  console.log(window.location.pathname);
  return (
    <>
      <div className="sidebar">
        <div className="logo">
          <a href={ROOT_ID}>
            {open ? (
              <Icon name="logo" size={70} />
            ) : (
              <Icon name="mobile-logo" size={32} />
            )}
          </a>
        </div>

        <nav>
          <ul>
            {/* <li>
              <NavLink to={`${url}`} activeClassName="active">
                <Icon name="md/dashboard" size={open ? 16 : 24} />
                {open && <span>Overview</span>}
              </NavLink>
            </li> */}
            <li>
              <a
                href="/app/rootipadmin/overview"
                className={
                  window.location.pathname === "/app/rootipadmin/overview" ||
                  window.location.pathname === "/app/rootipadmin"
                    ? "active"
                    : ""
                }
              >
                <Icon name="md/dashboard" size={open ? 16 : 24} />
                {open && <span>Overview</span>}
              </a>
            </li>
            <li>
              <a href="/rootipadmin/users.html">
                <Icon name="md/supervisor_account" size={open ? 16 : 24} />
                {open && <span>Users</span>}
              </a>
            </li>
            <li>
              <a href="/rootipadmin/brands.html">
                <Icon name="fa/tags" size={open ? 16 : 24} />
                {open && <span>Businesses</span>}
              </a>
            </li>
            <li>
              <a href="/rootipadmin/blog-list.html">
                <Icon name="fa/list" size={open ? 16 : 24} />
                {open && <span>Blog</span>}
              </a>
            </li>
            <li>
              <a href="/rootipadmin/stories.html">
                <Icon name="md/folder_shared" size={open ? 16 : 24} />
                {open && <span>Predefined Stories</span>}
              </a>
            </li>
            <li>
              <a href="/rootipadmin/payout-list.html">
                <Icon name="md/account_balance_wallet" size={open ? 16 : 24} />
                {open && <span>Payouts</span>}
              </a>
            </li>
            <li>
              <a href="/rootipadmin/tip-list.html">
                <Icon name="md/redeem" size={open ? 16 : 24} />
                {open && <span>Tips</span>}
              </a>
            </li>

            <li>
              <a
                href="/app/rootipadmin/ai"
                className={
                  window.location.pathname === "/app/rootipadmin/ai"
                    ? "active"
                    : ""
                }
              >
                <Icon name="md/search" size={open ? 16 : 24} />
                {open && <span>AI </span>}
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <style jsx>{`
        .sidebar {
          grid-area: sidebar;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: ${open ? "240px" : "70px"};
          height: 100vh;
        }

        .logo {
          background-color: var(--color-primary-dark);
          height: 70px;
          width: 100%;
          margin: auto;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .logo a {
          height: fit-content;
        }

        nav {
          height: calc(100% - 70px);
          background-color: var(--color-light);
        }
        nav ul {
          display: flex;
          flex-direction: column;
          margin-top: 2.2rem;
        }
        nav :global(a) {
          text-decoration: none;
          color: var(--color-darker);
          display: flex;
          align-items: center;
          height: 3.83438rem;
          ${open && "padding: 1rem 2.2rem;"}
          transition: background-color 0.3s;
          font-size: 1.4rem;
          border-left: 3px solid transparent;
          justify-content: ${open ? "" : "center"};
          min-height: ${open ? "" : "52px"};
        }
        nav :global(a svg) {
          margin: ${open ? "0 2rem 0 0.3rem" : ""};
          fill: var(--color-darker);
        }
        nav :global(a:hover) {
          color: var(--color-primary);
          fill: var(--color-primary);
        }
        nav :global(a:hover svg) {
          fill: var(--color-primary);
        }
        nav :global(a:focus),
        nav :global(a.active) {
          background-color: #f5f5f5f5;
          border-left: 3px solid var(--color-primary);
        }
      `}</style>
    </>
  );
}

export default Sidebar;
