import React from "react";

import { HiOutlinePaperClip } from "@react-icons";
function Attachments({ evidence }) {
  if (evidence.evidenceAttachments?.length > 0 && evidence.evidenceAttachments)
    return (
      <>
        <ul>
          {evidence.evidenceAttachments.map((item, index) => {
            return (
              <li key={index} className="flex items-center border">
                <HiOutlinePaperClip className="p-2" size="45" />
                <a href={item.url} target="_blank" className="text-primary">
                  {item.name}
                </a>
              </li>
            );
          })}
        </ul>
      </>
    );
  else
    return (
      <>
        <p>No Evidence Attachments</p>
      </>
    );
}

export default Attachments;
