import apiClient from "./apiClient";

function kpiReport(brandId) {
  return apiClient("base").post("/kpiReport", { brandId });
}

function rootipScore(brandId) {
  return apiClient("widgets").post("/getTransparencyScore", { brandId });
}

function adminWidgetReport(brandId, ownerId) {
  return apiClient("base").post("/adminWidgetReport", { brandId, ownerId });
}

function adminQrReport(brandId, qrOwnerId) {
  return apiClient("base").post("/adminQrReport", { brandId, qrOwnerId });
}

function retailerBusinesses(brandId) {
  return apiClient("base").post("/getRetailerBusinesses", { brandId });
}

function retailerBusinesses_pager(brandId, startAt, endAt, pageSize) {
  return apiClient("base").post("/getBusinessesList_pager", {
    brandId,
    startAt,
    endAt,
    pageSize,
  });
}

function getBrand(id) {
  return apiClient("base").post("/showBrand", { id });
}

function getRetailersName_qr(id, qrOwnerId, type) {
  return apiClient("base").post("/getRetailersName_qr", {
    id,
    qrOwnerId,
    type,
  });
}

function hiddenWidgets(retailerId, businessId, showWidget) {
  return apiClient("base").post("/hiddenWidgets", {
    retailerId,
    businessId,
    showWidget,
  });
}
function getRetailersName_overview(id, ownerId) {
  return apiClient("base").post("/getRetailersName_overview", { id, ownerId });
}

function qrGenerator(retailerId, brandId) {
  return apiClient("base").post("/setQrsFromRetailer", { retailerId, brandId });
}

function getScans_pager(type, id, qrOwnerId, startAt, endAt, pageSize) {
  return apiClient("base").post("/getScans_pager", {
    type,
    id,
    qrOwnerId,
    startAt,
    endAt,
    pageSize,
  });
}
function updateCustomPageUrl(id, qrOwnerId, type, customPageUrl) {
  return apiClient("base").post("/updateCustomPageUrl", {
    id,
    qrOwnerId,
    type,
    customPageUrl,
  });
}

function reportStoryViewHistory(id, type, ownerId) {
  return apiClient("base").post("/reportStoryVeiwHistory", {
    id,
    type,
    ownerId,
  });
}

function bulkImportProducts(list, brandId, isOfflineSource, authkey) {
  return apiClient("external").post(
    "/importProducts",
    {
      list,
      brandId,
      isOfflineSource,
    },
    authkey
  );
}
function importCollections(list, brandId, authkey) {
  return apiClient("external").post(
    "/importCollections",
    {
      list,
      brandId,
    },
    authkey
  );
}
function updateLandingOptions(brandId, options) {
  return apiClient("base").post("/updateCustomiseLandingPageOptions", {
    options,
    brandId,
  });
}
function checkRelation(params) {
  return apiClient("base").post("/checkRelation", params);
}
function requestSupplyChain(params) {
  return apiClient("base").post("/requestSupplyChain", params);
}
export const getNotifications = async (params) => {
  return await apiClient("base").post("/getNotifications", params);
};
export const replySupplyChain = async (params) => {
  return await apiClient("base").post("/replySupplyChain", params);
};
function updateQrLabelOptions(options, brandId, qrOwnerId) {
  return apiClient("base").post("/updateQrLabelOptions", {
    options,
    brandId,
    qrOwnerId,
  });
}
function getQrLabelOptions(brandId, qrOwnerId) {
  return apiClient("base").post("/getQrLabelOptions", {
    brandId,
    qrOwnerId,
  });
}

function findNodeByName(search, brandId) {
  return apiClient("base").post("/findNodeByName", {
    search,
    brandId,
  });
}

export default {
  requestSupplyChain,
  checkRelation,
  kpiReport,
  rootipScore,
  adminWidgetReport,
  adminQrReport,
  retailerBusinesses,
  getBrand,
  retailerBusinesses_pager,
  getRetailersName_qr,
  getRetailersName_overview,
  getScans_pager,
  updateCustomPageUrl,
  qrGenerator,
  reportStoryViewHistory,
  bulkImportProducts,
  importCollections,
  updateLandingOptions,
  findNodeByName,
  updateQrLabelOptions,
  getQrLabelOptions,
  hiddenWidgets,
};
