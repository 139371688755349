/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "@app/components/common/Button/Button";
import UserModal from "@app/components/common/UserModal/UserModal";
import Icon from "@common/Icon/Icon";
import Context from "@app/context/AppContext";
import { getNotifications } from "../../../../../services/adminServices";
import { firebase } from "@app/constants/constants";
import NotifCard from "./NotifCard";
import { Link } from "react-router-dom";
import { FaRegIdCard } from "react-icons/fa";
import {
  IoExitOutline,
  IoInformationCircle,
  IoPersonCircle,
  IoPersonOutline,
  IoWarning,
} from "react-icons/io5";
import Swal from "sweetalert2";
import { clearLocalStorage } from "../../../Website/Containers/Login";
import useOutsideClick from "../../../../../utils/useOutsideClick";

function Topbar({ setOpen, open }) {
  var [notifsData, setNotifsData] = useState([]);
  const { currentUser } = useContext(Context);
  const notificationDropdown = useOutsideClick();
  const profileDropDown = useOutsideClick();
  const rootipperId = localStorage.getItem("rootipperId")
    ? JSON?.parse(localStorage.getItem("rootipperId"))
    : null;
  useEffect(() => {
    if (currentUser?.rootipperId || rootipperId != "") {
      getNotifications({
        startSeq: -1,
        size: 3,
        status: "notSeen",
        brandId: rootipperId != "" ? rootipperId : currentUser?.rootipperId,
      }).then((response) => {
        setNotifsData(response?.notifications);
      });
    } else {
      location.replace("/");
    }
  }, [currentUser, rootipperId]);
  const singOut = () => {
    Swal.fire({
      icon: "warning",
      text: "Are you sure you would like to logout?",
      confirmButtonText: "Yes",
      confirmButtonColor: "var(--color-primary)",
      cancelButtonText: "No",
      showCancelButton: true,
      cancelButtonColor: "#aaaaaa",
    }).then((res) => {
      console.log("sss");
      if (res?.isConfirmed) {
        firebase.auth().signOut();
        clearLocalStorage();
        window.location.href = "/app/login";
      }
    });
  };
  const rootipper =
    JSON.parse(localStorage.getItem("rootipper")) ?? currentUser?.rootipper;
  return (
    <>
      <style>
        {`

body{
  font-family: "samsung" !important;
}import timeConvert from './../../../../../utils/timeConvert';


`}
      </style>

      <header className="fixed top-0 topBarHeader" style={{ zIndex: 9999 }}>
        <Button type="link" action={() => setOpen(!open)}>
          <Icon
            name="md/menu"
            size={24}
            color="var(--color-light)"
            hover="var(--color-light)"
            className="icon-menu"
          />
        </Button>

        <ul>
          {rootipper?.brandType?.brandDesigner == true ? (
            <a target={"_blank"} href={"/app/brand/" + rootipperId}>
              <li className="shop flex">
                <span>My Shop</span>
                <Icon
                  name="md/store_mall_directory"
                  size={18}
                  color="var(--color-light)"
                  hover="var(--color-light)"
                />
              </li>
            </a>
          ) : (
            <a
              target={"_blank"}
              href={"/app/admin/circle/brand/" + rootipperId}
            >
              <li className="shop flex">
                <span>My Shop</span>
                <Icon
                  name="md/store_mall_directory"
                  size={18}
                  color="var(--color-light)"
                  hover="var(--color-light)"
                />
              </li>
            </a>
          )}

          <li className="shop relative">
            <span
              className="cursor-pointer relative w-full h-full"
              onClick={() => notificationDropdown.setOpen(true)}
            >
              <Icon
                name="fa/bell"
                size={28}
                color="var(--color-light)"
                hover="var(--color-light)"
              />
              {notifsData?.length > 0 ? (
                <span className="w-3 h-3 bg-red-500 absolute rounded-full left-10  -top-3"></span>
              ) : null}
            </span>
            <div
              ref={notificationDropdown.reference}
              className={`border rounded shadow-sm w-[480px] top-full bg-light p-4 absolute  ${
                notificationDropdown.open ? "block" : "hidden"
              }`}
              style={{ zIndex: 99999 }}
            >
              <div className=" overflow-auto max-h-[350px]">
                {notifsData.map((item) => (
                  <NotifCard item={item} setNotifs={setNotifsData} />
                ))}
              </div>

              <a href="/admin/notification-list.html">
                <div className="w-full h-16  flex items-center justify-center border-t mt-4 text-primary cursor-pointer">
                  View All Notifications
                </div>
              </a>
            </div>
          </li>
          <li className="  relative">
            <Button
              type="link"
              action={() => profileDropDown.setOpen(true)}
              className="flex items-center"
            >
              {!currentUser?.user?.role?.RootipAdmin ? (
                <span>
                  <Icon
                    name="md/account_circle"
                    size={28}
                    color="#fff"
                    hover={"#fff"}
                  />
                </span>
              ) : (
                <img
                  src={rootipper?.logo}
                  alt=""
                  className="rounded-full border-white w-[50px] h-[50px]"
                />
              )}

              <span className="ml-5">
                {currentUser?.user?.firstName} {currentUser?.user?.lastName}
              </span>
            </Button>
            {profileDropDown.open ? (
              <ul
                ref={profileDropDown.reference}
                className={` w-[190px]  pb-[10px] pt-[5px] rounded-lg shadow bg-white absolute top-full left-0 flex flex-col ${
                  profileDropDown.open ? "block" : "hidden"
                }`}
                style={{ zIndex: 99999 }}
              >
                <a href="#" className="w-full hover:bg-gray-100">
                  <li className=" w-full flex items-start px-[10px] cursor-pointer text-black  first: h-11  ">
                    <span className="px-3  text-[14px]">
                      {" "}
                      Welcome {currentUser?.user?.firstName} !
                    </span>
                  </li>
                </a>

                <a
                  href="/admin/pricing/account.html"
                  className="w-full hover:bg-gray-100 px-[10px]"
                >
                  <li className=" w-full flex items-center  cursor-pointer text-black  first: h-11">
                    <span className="pr-2">
                      <Icon name="md/account_box" size={18} />
                    </span>
                    <span className="px-3  text-[14px]">Account</span>
                  </li>
                </a>

                <a
                  href="/admin/about.html"
                  className="w-full hover:bg-gray-100 px-[10px]"
                >
                  <li className=" w-full flex items-center  cursor-pointer text-black  first: h-11">
                    <span className="pr-2">
                      <Icon name="md/info" size={18} />
                    </span>
                    <span className="px-3  text-[14px]">About</span>
                  </li>
                </a>
                <a
                  href="/admin/profile.html"
                  className="w-full hover:bg-gray-100 px-[10px]"
                >
                  <li className=" w-full flex items-center  cursor-pointer text-black  first: h-11">
                    <span className="pr-2">
                      <Icon name="md/account" viewBox="0 0 24 24" size={18} />
                    </span>
                    <span className="px-3  text-[14px]">Profile</span>
                  </li>
                </a>
                <a
                  href="/admin/support.html"
                  className="w-full hover:bg-gray-100 px-[10px]"
                >
                  <li className=" w-full flex items-center  cursor-pointer text-black  first: h-11">
                    <span className="pr-2">
                      <Icon name="md/headset" size={18} />
                    </span>
                    <span className="px-3  text-[14px]">Support</span>
                  </li>
                </a>
                <a href="#" className="w-full  hover:bg-gray-100  px-[10px]">
                  <li
                    className=" w-full p-0 flex items-center cursor-pointer text-black h-11 "
                    onClick={singOut}
                    style={{ padding: "0px !important" }}
                  >
                    <span className="pr-2">
                      <Icon name="md/logout" size={18} />
                    </span>
                    <span className="px-3  text-[14px] ">Sign Out</span>
                  </li>
                </a>
              </ul>
            ) : null}
          </li>
        </ul>
      </header>
      <style jsx>{`
        header {
          grid-area: topbar;
          height: 70px;
          top: 0;
          right: 0;
          left: 0;
          display: flex;
          background-color: var(--color-primary);
          align-items: center;
        }
        :global(.icon-menu) {
          margin-left: 2rem;
        }
        ul {
          margin: 0 3rem 0 auto;
          display: flex;
          color: #fff;
          align-items: center;
        }
        ul > li {
          padding: 2rem;
          display: flex;
        }
        .shop {
          flex-direction: row-reverse;
          align-items: center;
        }
        .shop > span {
          margin-left: 0.5rem;
          font-size: 1.4rem;
        }
        .notification {
        }
        .notification :global(button) {
          display: flex;
        }
        .topBarHeader {
          ${open
            ? `
            width:calc(100% - 240px);
            left:240px;
            
            `
            : `
            width:calc(100% - 70px);
            left:70px;
            `}
        }
      `}</style>
    </>
  );
}

export default Topbar;
