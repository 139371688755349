import React, { useContext } from "react";
import Button from "@common/Button/Button";
import Context from "@app/context/StoriesContext";

function ChapterPills({ customStyles }) {
  var {
    chapters,
    activeChapter,
    setActiveChapter,
    engagementSend,
    buttonColor,
  } = useContext(Context);

  return (
    <>
      <div className="bg-transparent overflow-hidden relative">
        <ul
          className="flex overflow-x-scroll"
          onTouchStart={() => engagementSend("SCROLL_CHAPTERS")}
        >
          {chapters?.map((chapter, index) => (
            <li
              key={chapter.chapterName}
              className={`w-max list-space no-wrap`}
            >
              <Button
                type={
                  chapter.chapterName == activeChapter.chapterName
                    ? "chapter active"
                    : "chapter"
                }
                action={() => {
                  setActiveChapter(chapter);
                  engagementSend("SELECT_CHAPTERS");
                }}
                styles={
                  chapter.chapterName == activeChapter.chapterName
                    ? {
                        backgroundColor: buttonColor,
                        borderColor: buttonColor,
                      }
                    : {}
                }
                className="w-max"
              >
                <img
                  src={chapter.icon}
                  alt={chapter.chapterName + "_icon"}
                  className="mr-1"
                  style={
                    chapter.chapterName == activeChapter.chapterName
                      ? {
                          filter: "brightness(0) invert(1)",
                        }
                      : {}
                  }
                ></img>
                <span>
                  {chapter.chapterName +
                    String.fromCharCode(160) +
                    String.fromCharCode(40) +
                    chapter.count +
                    String.fromCharCode(41)}
                </span>
              </Button>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default ChapterPills;
