import service from "@app/services/rootipAdminServices";
import { useMachine } from "@xstate/react";
import fetchMachine from "@app/machines/fetchMachine";
import LoaderIcon from "@common/LoaderIcon/LoaderIcon";
import React, { useContext, useEffect, useState } from "react";
import RatingSelectedSVG from "@app/assets/icons/rating-selected.svg";
import RatingUnSelectedSVG from "@app/assets/icons/rating-un-selected.svg";
function Thresholds({ groupName }) {
  var [thresholdsList, setThresholdsList] = useState(null);
  var [thresholdsState, thresholdsSend] = useMachine(fetchMachine, {
    actions: {
      goFetch: () => {
        service
          .transparencyTablesClass(groupName)
          .then((list) => {
            setThresholdsList(list);
            thresholdsSend("RESOLVE");
          })
          .catch((error) => {
            setThresholdsList(error);
            thresholdsSend("REJECT");
          });
      },
    },
  });

  useEffect(() => {
    thresholdsSend("FETCH");
  }, []);

  return (
    <>
      {thresholdsState.matches("pending") && (
        <LoaderIcon width="80" height="80" />
      )}
      {thresholdsState.matches("resolved") && (
        <>
          <div className=" flex flex-col info-card w-30">
            <p className="ml-2 mb-1 info-label ">Rating Thresholds</p>
            <div>
              {thresholdsList.map((element, i) => {
                return (
                  <>
                    <div className="info-card m-2" style={{ marginRight: 5 }}>
                      <div className=" flex flex-col w-80 mr-auto m-2 p-2">
                        <div className="font-weight-bold">{element.rate}</div>
                        <div style={{ gap: "3px" }} className="flex ">
                          {createElements(4, element.classIndex + 1)}
                        </div>
                      </div>

                      <div className="px-4 p-3 m-2 mr-1 items-center flex flex-col w-16 rounded-2 bg-primary  border-slate-300 ">
                        <div className="text-2x font-weight-bold text-light  items-center">
                          {parseFloat(element.lowLimit).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </>
      )}

      <style jsx>{`
        .info-card {
          display: flex;
          padding-right: 4px;
          padding-top: 4px;
          margin-left: 5px;
          box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
            rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
          border-radius: var(--border-radius-general);
          background-color: var(--color-light);
        }

        .kpis-container {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 2rem;
          margin-bottom: 2rem;
        }
      `}</style>
    </>
  );
}
function createElements(nClass, classIndex) {
  var elements = [];

  elements.push(
    <>
      <RatingSelectedSVG />
    </>
  );
  for (var i = 0; i < classIndex; i++) {
    elements.push(
      <>
        <RatingSelectedSVG />
      </>
    );
  }
  for (var p = 0; p < nClass - classIndex; p++) {
    elements.push(
      <>
        {/* <Icon
          name={"rating-un-selected"}
          size={14}
          color="var(--color-primary)"
        /> */}
        <RatingUnSelectedSVG />
      </>
    );
  }
  return elements;
}
export default Thresholds;
