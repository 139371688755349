/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { IoLocationSharp } from "react-icons/io5";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

const BrandCard = ({ item, href }) => {
  const [imageLoad, setImageLoad] = useState(false);
  return (
    <div className="w-full relative mt-20 ">
      <Link to={href ?? "/brand/" + item?.id}>
        <div className="w-full rounded-2xl">
          <LazyLoadImage
            src={item?.fields?.coverImage}
            className="rounded-2xl min-h-[140px]  h-auto    "
            width={"100%"}
            onLoad={() => setImageLoad(true)}
            wrapperClassName={`w-full    min-h-[140px]  h-auto rounded-2xl ${
              imageLoad ? "" : "animate-pulse bg-gray-200"
            } `}
            effect="blur"
            alt=""
          />
        </div>
      </Link>
      <div className=" rounded-full  absolute top-[-50px] left-5 w-[100px] h-[100px] overflow-hidden">
        <LazyLoadImage
          src={item?.fields?.logo}
          className="rounded-full w-[100px] h-[100px]   border border-gray-200   "
          width={"100%"}
          onLoad={() => setImageLoad(true)}
          wrapperClassName={`w-full    w-[100px] h-[100px]  border border-gray-300 rounded-full  ${
            imageLoad ? "" : "animate-pulse bg-gray-200"
          } `}
          effect="blur"
          alt=""
        />
      </div>

      <div className="mt-5">
        <Link to={"/brand/" + item?.id}>
          {href ? (
            <h3 className={`font-extrabold  text-[20px] mb-4 `}>
              {item?.fields?.businessName}
            </h3>
          ) : (
            <h3 className={`font-bold  text-[30px] `}>
              {item?.fields?.businessName}
            </h3>
          )}
        </Link>
        <div className="w-full  flex mt-[-8px] items-center">
          <span className="pr-2">
            <IoLocationSharp size={14} />
          </span>
          <span className="text-[14px] ">
            {item?.fields?.location?.city +
              " , " +
              item?.fields?.location?.country}
          </span>
        </div>

        <div className="w-full mt-5 flex flex-wrap">
          {item.fields.tags
            ? Object?.keys(item.fields.tags).length > 0
              ? Object?.keys(item.fields.tags)?.map((item) => (
                  <span
                    className="py-[3px] px-[10px] rounded-lg border text-[14px] my-[5px] mr-[10px] "
                    key={item}
                  >
                    {item}
                  </span>
                ))
              : null
            : null}
        </div>
      </div>
    </div>
  );
};

export default BrandCard;
