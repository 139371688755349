import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const LazyLoadImageComponent = ({
  src,
  className = "w-full sm:min-h-[385px] sm:h-auto min-h-[188px] rounded-2xl",
}) => {
  const [imageLoad, setImageLoad] = useState(false);
  return (
    <LazyLoadImage
      src={src}
      className={className}
      width={"100%"}
      onLoad={() => setImageLoad(true)}
      wrapperClassName={` ${
        imageLoad ? "" : "animate-pulse bg-gray-200"
      } ${className}`}
      effect="blur" // use normal <img> attributes as props
    />
  );
};

export default LazyLoadImageComponent;
