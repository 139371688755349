/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext } from "react";
import Context from "@app/context/AppContext";
import Icon from "@common/Icon/Icon";

import { Link, useLocation } from "react-router-dom";

const BreadCrumb = ({ pointList, pageTitle, isPrimary }) => {
  const { currentUser } = useContext(Context);
  const pageParent = [
    {
      id: "circle",
      title: "Rootip Circle",
      href: "/app/admin/circle",
    },
  ];
  const location = useLocation();

  return (
    <>
      <div className="container flex justify-between items-center h-24 pb-8 pt-3 border-b">
        <div className="w-fit flex items-center pl-[27px]  ">
          {!isPrimary ? (
            <span className="cursor-pointer" onClick={() => history.back()}>
              <Icon
                hover="var(--color-primary)"
                name="fa/arrow-left"
                size={20}
                color="var(--color-primary)"
              />
            </span>
          ) : null}

          <span className="font-medium  text-[18px]  ">{pageTitle}</span>
        </div>

        <ul className="  flex text-2xl w-fit pr-[20px]">
          {!currentUser?.user?.role.RootipAdmin && (
            <li className="mr-2">
              {currentUser?.user
                ? currentUser?.user?.firstName +
                  " " +
                  currentUser?.user?.lastName +' /'
                : null}
            </li>
          )}
          
          <li className="mx-2">
            <Link className="text-primary" to="/admin/circle">
              Rootip Circle
            </Link>
          </li>
          {pointList?.length > 0
            ? pointList.map((item, index) => {
                return (
                  <>
                    /
                    <li className="mx-2">
                      {index + 1 == pointList.length ? (
                        <span className="productTitle">{item?.title}</span>
                      ) : (
                        <Link className="text-primary" to={item?.href}>
                          <span className="productTitle">{item?.title}</span>
                        </Link>
                      )}
                    </li>
                  </>
                );
              })
            : null}
        </ul>
      </div>
    </>
  );
};

export default BreadCrumb;
