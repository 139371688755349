import React, { useEffect, useState } from "react";
import services from "@app/services/adminServices";
import fetchMachine from "@app/machines/fetchMachine";
import { useMachine } from "@xstate/react";
import style from "!!raw-loader!../../../../vendors/styles/dataTable.css?id=15";
import StoriesPreview from "../../../common/StoriesPreview/StoriesPreview";

// import stylesUtils from "!!raw-loader!../app/styles/abstracts/utils.css";
import leafletmcStyles from "!!raw-loader!leaflet.markercluster/dist/MarkerCluster.css"; // inside .js file

import Tab from "./Tab";
import CusDataTable from "./CusDataTable";

function widgetTabs(props) {
  var {
    selector,
    items,
    listClass,
    itemClass,
    itemStyle,
    activeClass,
    activeStyle,
    listStyle,
    active,
    id,
    ownerId,
    type,
  } = props;

  var [tab, setTab] = useState(null);
  var [List, setList] = useState([]);
  var [totalRows, setTotalRows] = useState(0);
  var [totalEngagements, setTotalEngagements] = useState(0);
  var [disabled, setDisabled] = useState(false);
  var owner_id = ownerId;
  var [ownerId, setOwnerId] = useState(ownerId);
  function changeTab(key) {
    items.forEach((element, i) => {
      element.active = "";

      if (element.brandId === key) {
        if (i === 0) setDisabled(false);
        else setDisabled(true);
        element.active = key;
        setOwnerId(element.brandId);
        owner_id = element.brandId;
        setTab(element);
        ListSend("FETCH");
      }
    });
  }

  var [ListState, ListSend] = useMachine(fetchMachine, {
    actions: {
      goFetch: () => {
        services
          .reportStoryViewHistory(id, type, owner_id)
          .then((res) => {
            var list = [];
            list = res.urls;
            setList(list);
            setTotalRows(res.impressions);
            setTotalEngagements(res.engagements);
            ListSend("RESOLVE");
          })
          .catch((error) => {
            console.log(error);
            ListSend("REJECT");
          });
      },
    },
  });

  useEffect(() => {
    if (props.active) {
      setOwnerId(active.brandId);
      owner_id = active.brandId;
      setTab(active);

      ListSend("FETCH");
    }
  }, [props.active]);

  const columns = [
    {
      name: "URL",
      selector: (row) => row.url,
      sortable: true,
    },
    {
      name: "Count",
      selector: (row) => row.count,
      sortable: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontWeight: 800, // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
  };

  return tab ? (
    <>
      <div className={selector ? selector : ""}>
        <nav className={selector ? selector + "_tabs" : ""}>
          <ul className={listClass ? listClass : ""} style={listStyle}>
            {items.map((item) => (
              <Tab
                title={item.businessName}
                label={item.businessName}
                key={item.brandId}
                id={item.brandId}
                changeTab={() => changeTab(item.brandId)}
                active={item.active}
                itemClass={itemClass}
                itemStyle={itemStyle}
                activeClass={activeClass}
                activeStyle={activeStyle}
              />
            ))}
          </ul>
        </nav>
      </div>
      <style>
        {style}
        {leafletmcStyles}
      </style>
      <StoriesPreview
        widgetName="storiesPreview"
        widgetId={id}
        type={
          type === "rootippers"
            ? "brand"
            : type === "products"
            ? "product"
            : "collection"
        }
        preview={true}
        ownerId={ownerId}
        isRetailer={tab?.brandType?.retailer && tab.ownerId === tab.brandId}
        widgetCustomizationsApllyToAll={tab?.isApplyToAll}
      />
      <div id="stories-modal"></div>
      <hr className="border-t border-slate-300 border-solid " />
      Total Widget Impressions :{totalRows}
      <br />
      Total Widget Engagements :{totalEngagements.totalEngagements}
      <br />
      Unique Widget Engagements :{totalEngagements.uniqueEngagements}
      <hr className="border-t border-slate-300 border-solid " />
      <div className="container">
        <CusDataTable
          data={List}
          columns={columns}
          emptyMessage="There are no suppliers"
          customStyles={customStyles}
          progressPending={ListState.matches("pending")}
        />
      </div>
    </>
  ) : (
    <></>
  );
}

export default widgetTabs;
