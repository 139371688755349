/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState, useRef } from "react";
import "@app/styles/main2.css";
import Layout from "../../Layout/Layout";
import ProductCard from "../../Components/ProductCard";
import ProductContainer from "./ProductContainer";
import BrandsContainer from "./BrandsContainer";
import { useLocation, useHistory } from "react-router";
import { IoChevronUpOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

const Explore = (props) => {
  const [mode, setMode] = useState("products");
  const [scrollPos, setScrollPos] = useState(0);

  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    setMode(props?.match?.params?.mode ?? "products");
  }, [location]);
 

 
  useEffect(() => {
    document.addEventListener("scroll", () => {
      setScrollPos(window.scrollY);
    });
  
  }, []);

 
  return (
    <Layout
      subMenu={[
        {
          id: 1,
          title: "Products",
          active: mode == "products",
          onClick: () => history.push("/explore/products"),
        },
        {
          id: 2,
          title: "Brands",
          active: mode == "brands",
          onClick: () => history.push("/explore/brands"),
        },
      ]}
    >
      <div className="  h-full">
        <div className="container">
          {mode == "products" ? (
            <ProductContainer {...props} />
          ) : (
            <BrandsContainer {...props} />
          )}
        </div>
      </div>
      <div className="w-full fixed top-[50px] h-24 bg-[#ffffffef]  sm:hidden block">
        <div className="w-full h-full flex justify-center items-center">
          <Link
             to={'/explore/products'}
            className={`
            ${
              mode == "products"
                ? "bg-primary text-white"
                : "text-caption bg-white"
            }
          w-56 h-16 font-bold flex justify-center items-center  rounded-l-xl border-2 border-primary
          `}
          >
            <span>Products</span>
          </Link>
          <Link
            to={'/explore/brands'}
            className={`
          ${
            mode == "brands" ? "bg-primary text-white" : "text-caption bg-white"
          }
          w-56 h-16 font-bold flex justify-center items-center  border-2 border-primary   rounded-r-xl
          `}
          >
            <span>Brands</span>
          </Link>
        </div>
      </div>

      <div
        className={`w-[40px] h-[40px] fixed
       right-5 bottom-40 rounded-full bg-gradient-to-r
        from-primary to-primaryLight 
         flex justify-center items-center 
         overflow-hidden
         transition-all
         ${scrollPos > 700 ? "scale-1" : "scale-0"}
         `}
        onClick={() => window.scrollTo(0, 0)}
      >
        <IoChevronUpOutline color="white" size={18} />
      </div>
    </Layout>
  );
};

export default Explore;
