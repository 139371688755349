import React from "react"
import ContentLoader from "react-content-loader"

const BrandDetailsResponsiveShimmer = (props) => (
  <ContentLoader 
    speed={2}
    width={'100%'}
    height={800}
    viewBox="0 0 400 800"
    backgroundColor="#e9e9e9"
    foregroundColor="#e4e4e4"
    {...props}
  >
    <rect x="4" y="67" rx="0" ry="0" width="393" height="221" /> 
    <circle cx="92" cy="72" r="57" /> 
    <rect x="3" y="334" rx="5" ry="5" width="100%" height="17" /> 
    <rect x="3" y="364" rx="5" ry="5" width="100%" height="17" /> 
    <rect x="2" y="396" rx="5" ry="5" width="100%" height="17" /> 
    <rect x="2" y="425" rx="5" ry="5" width="147" height="17" /> 
    <rect x="2" y="455" rx="5" ry="5" width="100%" height="17" /> 
    <rect x="2" y="487" rx="5" ry="5" width="100%" height="17" /> 
    <rect x="2" y="518" rx="5" ry="5" width="100%" height="17" /> 
    <rect x="2" y="548" rx="5" ry="5" width="262" height="17" /> 
    <rect x="2" y="580" rx="5" ry="5" width="100%" height="17" /> 
    <rect x="2" y="639" rx="5" ry="5" width="183" height="17" /> 
    <rect x="2" y="609" rx="5" ry="5" width="100%" height="17" /> 
    <rect x="2" y="698" rx="8" ry="8" width="87" height="23" /> 
    <rect x="97" y="699" rx="5" ry="5" width="170" height="23" /> 
    <rect x="277" y="698" rx="8" ry="8" width="111" height="23" /> 
    <rect x="302" y="769" rx="8" ry="8" width="87" height="23" /> 
    <rect x="4" y="770" rx="5" ry="5" width="170" height="23" /> 
    <rect x="184" y="770" rx="8" ry="8" width="111" height="23" /> 
    <rect x="100" y="734" rx="5" ry="5" width="175" height="23" /> 
    <rect x="291" y="733" rx="8" ry="8" width="97" height="22" /> 
    <rect x="1" y="735" rx="8" ry="8" width="87" height="23" />
  </ContentLoader>
)

export default BrandDetailsResponsiveShimmer

