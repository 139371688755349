import React, { useEffect, useState } from "react";
import { getProducts } from "../../../../../services/WebsiteRequeries";
import ProductCard from "../../Components/ProductCard";
import ProductCardShimmer from "../../Components/Shimmers/ProductCardShimmer";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useHistory } from "react-router-dom";
import { IoWarning } from "react-icons/io5";
const ProductContainer = ({ brandId, circle = false }) => {
  const [params, setParams] = useState({
    size: 6,
    startSeq: -1,
    startWeight: 100,
  });
  const { search } = useLocation();
  ("?q=google");
  const searchParams = new URLSearchParams(search).get("q");
  const [count, setCount] = useState(24);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState("all");
  const [hasMore, setHasMore] = useState(true);
  const history = useHistory();

  const getProductFunction = (newParam, changeCategory = false) => {
    setLoading(true);
    getProducts(newParam)
      .then((res) => {
        setLoading(false);
        if (changeCategory) {
          setProducts(res.productList);
        } else {
          setProducts([...products, ...res.productList]);
        }

        if (res.productList.length < 6) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
        setParams({
          size: 6,
          startSeq:
            res.productList?.[res.productList.length - 1].product.fields
              .createDate,
          startWeight: res.productList?.[res.productList.length - 1].storyCount,
        });

        //get latest product createdDate for  fetch next products
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    if (
      searchParams != "" &&
      searchParams != null &&
      searchParams?.length > 0
    ) {
      setCategory("all");
    } else {
      setProducts([]);
    }
  }, [searchParams]);

  useEffect(() => {
    if (category != "all") {
      setProducts([]);

      setLoading(true);
      history.push({
        pathname: location.pathname.replace("/app", ""),
        search: "q=",
      });
    }
  }, [category]);

  useEffect(() => {
    setProducts([]);
    if (brandId) {
      setParams({
        size: 6,
        startSeq: -1,
        startWeight: 100,
        brandId: brandId,
        circle: circle,
      });

      getProductFunction({
        size: 6,
        startSeq: -1,
        startWeight: 100,
        brandId: brandId,
        circle: circle,
      });
    } else {
      if (
        searchParams != "" &&
        searchParams != null &&
        searchParams?.length > 0
      ) {
        setParams({
          size: 6,
          startSeq: -1,
          startWeight: 100,
          query: searchParams ?? "",
          startTitle: searchParams ?? "",
        });

        getProductFunction(
          {
            category: category,
            size: 6,
            startSeq: -1,
            startWeight: 100,
            query: searchParams ?? "",
            startTitle: searchParams ?? "",
          },
          true
        );
      } else {
        setProducts([]);
        setLoading(true);
        setParams({
          size: 6,
          startSeq: -1,
          startWeight: 100,
        });

        getProductFunction(
          {
            category: category,
            size: 6,
            startSeq: -1,
            startWeight: 100,
          },
          true
        );
      }
    }
  }, [search, searchParams, category, brandId]);

  const [items] = useState([
    {
      title: "All",
      id: "all",
    },
    {
      title: "Fashion",
      id: "fashion",
    },
    {
      title: "Food",
      id: "food",
    },
    {
      title: "Drink",
      id: "drink",
    },
    {
      title: "Accessories",
      id: "accessories",
    },
    {
      title: "Other",
      id: "other",
    },
  ]);

  return (
    <div className="w-full h-auto"  >
      <div
        className={`w-full px-3 mt-14 sticky sm:top-40 top-[100px] pt-5 bg-[#ffffffef] ${
          brandId ? "hidden" : ""
        } `}
        style={{ zIndex: 9999 }}
      >
        <div className="relative w-full flex gap-6 snap-x snap-mandatory overflow-x-auto  pb-4 ">
          {items.map((item) => (
            <button
              key={item.title}
              type=""
              onClick={() => (loading ? null : setCategory(item.id))}
              className={`
                border snap-left shrink-0 
                rounded-full
              transition-all
                  px-7 py-2 text-2xl 
                  ${loading ? "opacity-60" : ""}
                  ${
                    category == item.id
                      ? " border-primaryLight  bg-primaryLight text-white"
                      : "text-caption hover:text-black bg-white"
                  }
                `}
            >
              {item.title}
            </button>
          ))}
        </div>
      </div>
      {brandId ? <h3 className="text-4  font-bold">Products</h3> : null}
      <InfiniteScroll
      
        key={category}
        dataLength={products.length}
        pullDownToRefreshThreshold={10}
       
        next={() => {
          console.log("next");
          loading
            ? null
            : brandId
            ? getProductFunction({ ...params, brandId: brandId })
            : getProductFunction({ ...params, category: category });
        }}
        //To put endMessage and loader to the top.

        hasMore={hasMore}
       
        scrollableTarget={'sc'}

      >
        <div className="my-10 flex  justify-between flex-wrap  ">
          {products.length > 0 ? (
            products.map((product, index) => (
              <div
                className={`w-1/2 ${circle ? "sm:w-3/12" : "sm:w-4/12"}  p-3 ${
                  products[products.length - 1].product.id == product.product.id
                    ? "mr-auto"
                    : ""
                }  `}
                key={product.product.id}
              >
                <ProductCard
                  key={"pro_" + product.product.id}
                  item={product}
                  circle={!!circle}
                  href={
                    circle
                      ? `/admin/circle/product/${product?.product?.id}`
                      : false
                  }
                />
              </div>
            ))
          ) : loading ? null : (
            <>
              {brandId ? (
                <div className="p-8 bg-[#d1ecf1] text-[#0c5460] w-full flex rounded-xl items-center">
                  <IoWarning color={"0c5460"} /> Warning There are no products
                  available in this brand.
                </div>
              ) : (
                <div className="w-full mt-10 text-center text-[18px] text-caption">
                  No match found
                </div>
              )}
            </>
          )}

          {loading ? (
            <>
              {new Array(6).fill(0).map((x, index) => (
                <div
                  className={`w-1/2 ${circle ? "sm:w-3/12" : "sm:w-4/12"} p-3 ${index==5?'mr-auto':''} `}
                  key={index}
                >
                  <ProductCardShimmer />
                </div>
              ))}
            </>
          ) : null}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default ProductContainer;
