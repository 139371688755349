import React, { useEffect, useContext } from "react";
import Mapping from "./Mapping";
import Context from "@app/context/StoriesContext";
function StoriesMapping({ entityId, type, brandId }) {
  var { engagementSend } = useContext(Context);
  useEffect(() => {
    engagementSend("SELECT_MAP");
  }, []);
  return <Mapping entityId={entityId} type={type} brandId={brandId} />;
}

export default StoriesMapping;
