import React, { useEffect, useState, useContext } from "react";
import CustomDatePicker from "@common/CustomDatePicker/CustomDatePicker";
import ChannelView from "../common/ChannelView/ChannelView";
import { useMachine } from "@xstate/react";
import fetchMachine from "@app/machines/fetchMachine";
import LoaderIcon from "@common/LoaderIcon/LoaderIcon";
import Context from "@app/context/RootipAdminContext";
import { format } from "date-fns";
function WebsiteViews({ service }) {
  var [points, setPoints] = useState({});
  var { websiteViews, setWebsiteViews } = useContext(Context);
  function formatedPoints() {
    if (Object.keys(points).length == 0) {
      return {};
    } else {
      return {
        start: format(points.startDate, "yyyyMMdd"),
        end: format(points.endDate, "yyyyMMdd"),
      };
    }
  }
  var [state, send] = useMachine(fetchMachine, {
    actions: {
      goFetch: () => {
        service(formatedPoints())
          .then((data) => {
            setWebsiteViews(data);
            send("RESOLVE");
          })
          .catch((error) => {
            setWebsiteViews(error);
            send("REJECT");
          });
      },
    },
  });
  useEffect(() => {
    send("FETCH");
  }, [points]);

  function handleChange(points) {
    setPoints(points);
  }
  return (
    <>
      {state.matches("pending") ? (
        <LoaderIcon width="160" height="160" />
      ) : (
        <>
          <CustomDatePicker onChange={handleChange} points={points} />
          <ChannelView data={websiteViews} />
        </>
      )}

      <style jsx global>{`
        .product-impressions_tabs ul {
          display: flex;
          border: 1px solid var(--color-primary);
          border-radius: 1px;
          width: max-content;
        }
        .product-impressions_tabs li {
          display: flex;
        }
        .product-impressions_tabs button {
          background-color: #fff;
          text-decoration: none;
          padding: 0.2rem 1rem;
          color: #333;
          border: none;
          cursor: pointer;
        }
        .product-impressions_tabs button.active {
          background-color: var(--color-primary);
          color: #fff;
        }
      `}</style>
    </>
  );
}

export default WebsiteViews;
