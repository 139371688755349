import React, { useEffect, useState } from "react";
import { Map, TileLayer, Marker, useLeaflet } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import { useMachine } from "@xstate/react";
import fetchMachine from "@app/machines/fetchMachine";
import thirdPartyServices from "@app/services/thirdPartyServices";

// const ACCESS_TOK<></>EN =
//   "pk.eyJ1IjoiZGF2b29kZm94IiwiYSI6ImNrdDE1aGp3cjA2dGUyd254Y3QwOXEyNzAifQ.u29wpfDht7aa5dkt5EoUJg";
// const URL = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${ACCESS_TOKEN}`;
// const ATTRIBUTION =
//   'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>';

const URL = `https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png`;

function MapInput({ visible, init, readOnly }) {
  const { map } = useLeaflet();
  var [position, setPosition] = useState([init.lat, init.lng]);
  var [zoom, setZoom] = useState(2);
  var [countryObj, setCountryObj] = useState(null);
  var [country, setCountry] = useState(init.country);
  var [cityObj, setCityObj] = useState(null);
  var [city, setCity] = useState(init.city);

  useEffect(() => {
    if (map) {
      window.dispatchEvent(new Event("resize"));
    }
  }, []);

  var [geoState, geoSend] = useMachine(fetchMachine, {
    actions: {
      goFetch: () => {
        thirdPartyServices.reverseGeo(position).then((data) => {
          setCountryObj(
            data.results.find(
              (item) =>
                item.types.includes("country") &&
                item.types.includes("political")
            )
          );
          setCityObj(
            data.results.find((item) => {
              if (
                item.types.includes("locality") &&
                item.types.includes("political")
              ) {
                return (
                  item.types.includes("locality") &&
                  item.types.includes("political")
                );
              } else if (
                item.types.includes("administrative_area_level_1") &&
                item.types.includes("political")
              ) {
                return (
                  item.types.includes("administrative_area_level_1") &&
                  item.types.includes("political")
                );
              }
            })
          );
        });
      },
    },
  });

  useEffect(() => {
    if (visible) {
      geoSend("FETCH");
    }
  }, [position]);

  useEffect(() => {
    if (countryObj?.long_name) {
      setCountry(countryObj.long_name);
    } else if (countryObj?.address_components) {
      setCountryObj(
        countryObj.address_components.find(
          (item) =>
            item.types.includes("country") && item.types.includes("political")
        )
      );
    }
  }, [countryObj]);

  useEffect(() => {
    if (cityObj?.long_name) {
      setCity(cityObj.long_name);
    } else if (cityObj?.address_components) {
      setCityObj(
        cityObj.address_components.find((item) => {
          if (
            item.types.includes("locality") &&
            item.types.includes("political")
          ) {
            return (
              item.types.includes("locality") &&
              item.types.includes("political")
            );
          } else if (
            item.types.includes("administrative_area_level_1") &&
            item.types.includes("political")
          ) {
            return (
              item.types.includes("administrative_area_level_1") &&
              item.types.includes("political")
            );
          }
        })
      );
    }
  }, [cityObj]);

  return (
    <>
      <div className="mb-4 w-100 text-left">
        <input value={city} name="city" className="hidden" readOnly />
        <input value={country} name="country" className="hidden" readOnly />
        <input value={position[0]} name="lat" className="hidden" readOnly />
        <input value={position[1]} name="lng" className="hidden" readOnly />
        <div className="relative h-60vh">
          <Map
            zoom={zoom}
            scrollWheelZoom={true}
            center={position}
            minZoom={1}
            className="z-1 relative"
          >
            <TileLayer url={URL} attribution={false} />
            <Marker
              draggable={readOnly ? false : true}
              ondragstart={() => {
                // window.dispatchEvent(new Event("resize"));
              }}
              onDragend={(e) => {
                setZoom(e.target.options.leaflet.map._zoom);
                setPosition([e.target._latlng.lat, e.target._latlng.lng]);
                // window.dispatchEvent(new Event("resize"));
              }}
              autoPan
              position={position}
            ></Marker>
          </Map>
        </div>
        <span className="text-2x text-muted">
          {country}, {city}
        </span>
      </div>
    </>
  );
}

export default MapInput;
