import React, { useEffect, useRef, useState } from "react";
import searchMachine from "@app/machines/searchMachine";
import { useMachine } from "@xstate/react";
import service from "@app/services/adminServices";
import LoaderIcon from "@common/LoaderIcon/LoaderIcon";
import { MdSearch, FaMapMarkerAlt } from "@react-icons";

function ComboBox({
  name,
  init,
  setSuggestedNode,
  suggestedNode,
  setSuggestionQuery,
  brandId,
}) {
  var [val, setVal] = useState("");
  var [results, setResults] = useState(null);
  var inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.value = searchState.context.query;
  }, []);

  useEffect(() => {
    if (init) {
      inputRef.current.value = init;
    }
  }, [init]);

  var [searchState, searchSend] = useMachine(searchMachine, {
    actions: {
      setTimer: (context, event) => {
        context.timer = setTimeout(() => {
          searchSend("FETCH");
        }, 1000);
      },
      clearTimer: (context, event) => {
        clearTimeout(context.timer);
      },
      goFetch: (context, event) => {
        service
          .findNodeByName(context.query.toLowerCase(), brandId)
          .then((response) => {
            setResults(response);
            context.results = response;
            searchSend("RESOLVE");
          })
          .catch((error) => {
            console.error(error);
            context.error = error.message;
            searchSend("REJECT");
          });
      },
      updateQuery: (context, event) => {
        context.query = event.target.value;
      },
    },
  });

  useEffect(() => {
    if (val) {
      setSuggestedNode(val);
      searchSend("blur");
    }
  }, [val]);

  useEffect(() => {
    if (searchState.matches("focused")) {
      setSuggestedNode(null);
    }
  }, [searchState]);

  return (
    <div className="relative w-100">
      <div className="flex items-center border p-2">
        {!searchState.matches("pending") && (
          <MdSearch size={36} color="var(--color-border)" />
        )}
        {searchState.matches("pending") && (
          <LoaderIcon width={36} height={36} />
        )}
        <input
          ref={inputRef}
          name={name}
          style={{
            outline: "none",
          }}
          type="text"
          className=" w-100 rounded-1 text-muted text-3"
          onFocus={(e) => {
            searchSend(e);
          }}
          onBlur={(e) => searchSend(e)}
          onKeyUp={(e) => {
            searchSend(e);
            setResults(null);
          }}
        ></input>
      </div>
      {results && results.length != 0 && (
        <div className=" pos-abs z-20 bg-light w-100 border">
          {results.map((item, index) => (
            <div
              key={item.id}
              role="menuitem"
              tabIndex={index}
              className="flex items-center h-80p p-2  cursor-pointer bg-grey-light  hover:bg-light"
              onClick={() => {
                setVal(item);

                // setSuggestedNode(item);
                // searchSend("blur");
              }}
              onKeyPress={() => {
                setVal(item);
                // setSuggestedNode(item);
                // searchSend("blur");
              }}
            >
              <img
                src={item.fields.logo}
                alt={item.fields.businessName}
                width={50}
                height={50}
                className="w-50p h-50p circle m-3"
              />
              <div className="flex flex-col items-start">
                <span className="text-3 font-weight-bold">
                  {item.fields.businessName}
                </span>
                <span className="flex items-center text-muted">
                  <FaMapMarkerAlt size="12" className="float-left mr-1/4" />
                  <span className="text-14px text-muted">
                    {item.fields.location.city}
                  </span>
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ComboBox;
