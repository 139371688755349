import React, { useMemo, useState, useEffect, useRef } from "react";
import service from "@app/services/rootipAdminServices";
import useFetch from "../../../../../hooks/useFetch";
import Swal from "sweetalert2";
import LoaderIcon from "@common/LoaderIcon/LoaderIcon";
import StoryChaptersTransparency from "./StoryChaptersTransparency";
function TransparencySetting() {
  var [transparencyPoints, setTransparencyPoints] = useState();

  var [points, setPoints] = useState();
  const embededBadgesPt = useRef();
  const embededWidgetsPt = useRef();
  const productsPt = useRef();
  const realConnectionsPt = useRef();
  const virtualConnectionsPt = useRef();
  const evidencesPt = useRef();
  const connectionsMaxScore = useRef();
  const productsMaxScore = useRef();
  const [storyChaptersPt, setStoryChaptersPt] = useState();

  useEffect(() => {
    transparencyPointsSend("FETCH");
  }, []);

  var [, transparencyPointsState, transparencyPointsSend] = useFetch(
    service.getTransparencyPoints,
    [],
    (res) => {
      setPoints(res.points);
    }
  );

  useEffect(() => {
    if (transparencyPointsState.matches("resolved") && points) {
      embededBadgesPt.current.value = points.embededBadgesPt;
      embededWidgetsPt.current.value = points.embededWidgetsPt;
      productsPt.current.value = points.productsPt;
      realConnectionsPt.current.value = points.realConnectionsPt;
      virtualConnectionsPt.current.value = points.virtualConnectionsPt;
      connectionsMaxScore.current.value = points.connectionsMaxScore;
      evidencesPt.current.value = points.evidencesPt;
      productsMaxScore.current.value = points.productsMaxScore;
      setStoryChaptersPt(points.storyChaptersPt);
    }
  }, [transparencyPointsState, points]);

  useEffect(() => {
    if (transparencyPoints) pointsSend("FETCH");
  }, [transparencyPoints]);

  var [, pointsState, pointsSend] = useFetch(
    service.setTransparencyPoints,
    [transparencyPoints],
    (res) => {
      if (res.errorType === "error")
        return Swal.fire({
          icon: "error",
          text: res.message,
          title: "Oops",
          confirmButtonColor: "#00a79e",
        });
      else {
        Swal.fire({
          icon: "success",
          text: res.message,
          title: "Success!",
          confirmButtonColor: "#00a79e",
        }).then((result) => {});
      }
    }
  );

  const add = () => {
    setTransparencyPoints({
      embededBadgesPt: Number(embededBadgesPt.current.value),
      embededWidgetsPt: Number(embededWidgetsPt.current.value),
      productsPt: Number(productsPt.current.value),
      realConnectionsPt: Number(realConnectionsPt.current.value),
      virtualConnectionsPt: Number(virtualConnectionsPt.current.value),
      connectionsMaxScore: Number(connectionsMaxScore.current.value),
      evidencesPt: Number(evidencesPt.current.value),
      storyChaptersPt: storyChaptersPt,
      productsMaxScore: Number(productsMaxScore.current.value),
    });
  };

  return transparencyPointsState.matches("pending") ? (
    <>
      <LoaderIcon width="80" height="80" />
    </>
  ) : (
    <>
      <div className="flex flex-col    info-card mt-2">
        <p className="ml-2 mb-1 font-weight-bold">Transparency Setting</p>

        <div className="flex md:flex-col ml-2">
          <label className="text-14px ml-2">Story Chapters Point</label>
          <div className=" info-card ">
            <div
              className=" w-100  grid grid-cols-2 gap-2"
              // className="overflow-y-scroll w-100  grid grid-cols-2 gap-2"
              // style={{
              //   maxHeight: 375,
              // }}
            >
              <StoryChaptersTransparency
                storyChaptersPt={storyChaptersPt}
                setStoryChaptersPt={setStoryChaptersPt}
              />
            </div>
          </div>

          <div className="w-100  grid grid-cols-6 gap-1">
            <div className="mt-3 flex md:flex-col  pr-1">
              <label className="text-14px" htmlFor="txtEmbededBadgesPt">
                Embeded Badges Point
              </label>

              <input
                ref={embededBadgesPt}
                type="number"
                name="txtEmbededBadgesPt"
                className="w-60 p-2 border"
                id="txtEmbededBadgesPt"
              />
            </div>
            <div className="mt-3 flex md:flex-col pr-1">
              <label className="text-14px" htmlFor="txtEmbededWidgetsPt">
                Embeded Widgets Point
              </label>

              <input
                ref={embededWidgetsPt}
                type="number"
                name="txtEmbededWidgetsPt"
                className="w-60 p-2 border"
                id="txtEmbededWidgetsPt"
              />
            </div>
            <div className="mt-3 flex md:flex-col pr-1">
              <label className="text-14px" htmlFor="txtProductsPt">
                Products Point
              </label>

              <input
                ref={productsPt}
                type="number"
                name="txtProductsPt"
                className="w-60 p-2 border"
                id="txtProductsPt"
              />
            </div>
            <div className="mt-3 flex md:flex-col pr-1">
              <label className="text-14px" htmlFor="txtProductsMaxScore">
                Products Max Score
              </label>

              <input
                ref={productsMaxScore}
                type="number"
                name="txtProductsMaxScore"
                className="w-60 p-2 border"
                id="txtProductsMaxScore"
              />
            </div>
            <div className="mt-3 flex md:flex-col pr-1">
              <label className="text-14px" htmlFor="txtRealConnectionsPt">
                Real Connections Point
              </label>

              <input
                ref={realConnectionsPt}
                type="number"
                name="txtRealConnectionsPt"
                className="w-60 p-2 border"
                id="txtRealConnectionsPt"
              />
            </div>
            <div className="mt-3 flex md:flex-col pr-1">
              <label className="text-14px" htmlFor="txtVirtualConnectionsPt">
                Virtual Connections Point
              </label>

              <input
                ref={virtualConnectionsPt}
                type="number"
                name="txtVirtualConnectionsPt"
                className="w-60 p-2 border"
                id="txtVirtualConnectionsPt"
              />
            </div>
            <div className="mt-3 flex md:flex-col pr-1">
              <label className="text-14px" htmlFor="txtConnectionsMaxPt">
                Connections Max Score
              </label>

              <input
                ref={connectionsMaxScore}
                type="number"
                name="txtConnectionsPt"
                className="w-60 p-2 border"
                id="txtConnectionsMaxPt"
              />
            </div>
            <div className="mt-3 flex md:flex-col pr-1">
              <label className="text-14px" htmlFor="txtEvidencesPt">
                Evidences Point
              </label>

              <input
                ref={evidencesPt}
                type="number"
                name="txtEvidencesPt"
                className="w-60 p-2 border"
                id="txtEvidencesPt"
              />
            </div>
          </div>
        </div>
        <div className="mt-2 text-right">
          <button
            className="border-primary  p-2 m-1 border rounded-1 bg-primary text-light tag_printQr"
            id="save"
            type="submit"
            onClick={add}
          >
            Save
          </button>
        </div>
      </div>
      <style jsx>{`
      .info-card {
        display: flex;
        padding-right: 4px;
        padding-top: 4px;
        margin-left: 5px;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
          rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        border-radius: var(--border-radius-general);
        background-color: var(--color-light);

      `}</style>
    </>
  );
}
export default TransparencySetting;
