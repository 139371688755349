import thirdPartyClient from "./thirdPartyClient";

function reverseGeo(position) {
  return thirdPartyClient.post(
    "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
      position[0] +
      "," +
      position[1] +
      "&sensor=false" +
      "&key=AIzaSyCTJteDLnljzUW7pRfEYtqOg8doaVfPL3k"
  );
}

export default {
  reverseGeo,
};
