import React from "react";
import "./Button.css";

function Button(props) {
  var {
    children,
    styles,
    title,
    className = "",
    type = "",
    size = "",
    effect = "",
    action,
    onClick,
    stretch = false,
    disabled = false,
    shadow = false,
    refrence,
    label,
  } = props;

  return (
    <>
      <button
        aria-label={label}
        type="button"
        onClick={action ? action : onClick}
        onTouchStart={action ? action : onClick}
        disabled={disabled}
        className={`${className} ${type} ${size} ${effect} ${
          stretch ? "stretch" : ""
        } ${shadow ? "shadow" : ""}`}
        style={styles}
        ref={refrence}
      >
        {title}
        {children}
      </button>
    </>
  );
}

export default Button;
