/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useContext, useEffect, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import "@app/styles/main2.css";

import Context from "@app/context/AppContext";
import { FaFacebookF, FaInstagram, FaRegIdCard } from "react-icons/fa";
import {
  IoExitOutline,
  IoPersonOutline,
  IoSearchOutline,
  IoMenuSharp,
} from "react-icons/io5";
import SearchModal from "./components/SearchModal";
import SideBar from "../Components/SideBar";
import CollapseMenu from "../Components/CollapseItem";
import { Link } from "react-router-dom";
import { ROOT_ID } from "../../../../constants/constants";
import default_avatar from "@app/assets/images/default_avatar.png";
import logoPng from "@app/assets/images/logo.png";
import { firebase } from "@app/constants/constants";
import Swal from "sweetalert2";
import authServices from "../../../../services/authServices";
const Header = (props) => {
  const { currentUser, setCurrentUser } = useContext(Context);
  const [dropdown, setDropdown] = useState(false);
  const [search, setSearch] = useState(false);
  const [menu, setMenu] = useState(false);
  const [loading, setLoading] = useState(true);
  const ref = useRef();
  const singOut = () => {
    Swal.fire({
      icon: "warning",
      text: "Are you sure you would like to logout?",
      confirmButtonText: "Yes",
      confirmButtonColor: "var(--color-primary)",
      cancelButtonText: "No",
      showCancelButton: true,
      cancelButtonColor: "#aaaaaa",
    }).then((res) => {
      if (res?.isConfirmed) {
        firebase.auth().signOut();
        window.location.reload();
      }
    });
  };
  useEffect(() => {
    if (window.innerWidth > 500) {
      const checkIfClickedOutside = (e) => {
        // If the menu is open and the clicked target is not within the menu,
        // then close the menu

        if (dropdown && ref.current && !ref.current.contains(e.target)) {
          setDropdown(false);
        }
      };
      document.addEventListener("mousedown", checkIfClickedOutside);
      return () => {
        // Cleanup the event listener
        document.removeEventListener("mousedown", checkIfClickedOutside);
      };
    }
  }, [dropdown]);

  useEffect(() => {
    setMenu(false);
  }, [props]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (userRecord) {
      setLoading(true);
      let sessionTimeout = null;
      if (userRecord) {
        firebase
          .auth()
          .currentUser?.getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            authServices.getCurrentUser(idToken).then((user) => {
              setCurrentUser(user);
              setLoading(false);
            });
          });
        userRecord.getIdTokenResult().then((idTokenResult) => {
          const authTime = idTokenResult.claims.auth_time * 1000;
          const whileOfLogin = (Date.now() - authTime) / 1000 / 60;
          console.log(whileOfLogin);
          if (whileOfLogin > 60 * 24 * 7) {
            firebase.auth().signOut();
            clearLocalStorage();
            window.location.reload();
          }
        });
      } else {
        setLoading(false);
        sessionTimeout && clearTimeout(sessionTimeout);
        sessionTimeout = null;
        var noUserPath = ["/app/login", "/pasrecover.html", "/signup.html"];
        if (!noUserPath.includes(window.location.pathname)) {
          clearLocalStorage();
          window.location = "/app/login";
        }
      }
    });
  }, []);

  return (
    <>
      <ReactTooltip place="bottom" type="light" effect="solid" />
      <SearchModal OpenStatus={search} SetStatus={setSearch} />
      <SideBar OpenStatus={dropdown} SetStatus={setDropdown}>
        <ul className="w-full h-full pl-3">
          <li>
            <li className="p-3 w-full flex items-center cursor-pointer hover:text-primary ">
              <span className="pr-2">
                <FaRegIdCard />
              </span>
              <span className="px-3">Dashboard</span>
            </li>

            {currentUser?.user.role.RootipAdmin ? (
              <Link to="/rootipadmin">
                <li className="p-3 w-full flex items-center cursor-pointer hover:text-primary ">
                  <span className="pr-2">
                    <FaRegIdCard />
                  </span>
                  <span className="px-3 ">Dashboard</span>
                </li>
              </Link>
            ) : currentUser?.user.role.B2BAdmin ||
              currentUser?.user.role.B2BOwner ? (
              <a href="/admin/overview.html">
                <li className="p-3 w-full flex items-center cursor-pointer hover:text-primary ">
                  <span className="pr-2">
                    <FaRegIdCard />
                  </span>
                  <span className="px-3">Dashboard</span>
                </li>
              </a>
            ) : currentUser?.user.role.B2BEmployee ? (
              <a href="/employee/dashboard.html">
                <li className="p-3 w-full flex items-center cursor-pointer hover:text-primary ">
                  <span className="pr-2">
                    <FaRegIdCard />
                  </span>
                  <span className="px-3  ">Dashboard</span>
                </li>
              </a>
            ) : currentUser?.user.role.B2CUser ? (
              <a href="/registerb2b.html">
                <li className="p-3 w-full flex items-center cursor-pointer hover:text-primary ">
                  <span className="pr-2">
                    <FaRegIdCard />
                  </span>
                  <span className="px-3 ">Business Register</span>
                </li>
              </a>
            ) : (
              ""
            )}
            <a href="/user/account.html">
              <li className="p-3 w-full flex items-center  cursor-pointer hover:text-primary ">
                <span className="pr-2">
                  <IoPersonOutline size={18} />
                </span>
                <span className="px-3"> Account</span>
              </li>
            </a>
            <li className="p-3 w-full flex items-center cursor-pointer hover:text-primary ">
              <span className="pr-2">
                <IoExitOutline size={18} />
              </span>
              <span className="px-3">Sign Out</span>
            </li>
          </li>
        </ul>
      </SideBar>

      <SideBar OpenStatus={menu} SetStatus={setMenu}>
        <ul className="w-full pl-8">
          <li className=" py-3 flex items-center">
            <CollapseMenu title={"For Businesses"}>
              <ul className="w-fit ml-8">
                <li>
                  <a href="index.html" className="mm-original-link">
                    For Businesses
                  </a>
                </li>
                <li className="py-3">
                  <a
                    className="nav-link scroll text-black  "
                    href="#resolveIssue "
                  >
                    Issues We Solve
                  </a>
                </li>
                <li className="py-3">
                  <a
                    className="nav-link scroll text-black "
                    href="#howtotransform "
                  >
                    How To Transform
                  </a>
                </li>
                <li>
                  <a
                    className="nav-link scroll text-black "
                    href="#valuerootip "
                  >
                    Values We Deliver
                  </a>
                </li>
                <li className="py-3">
                  <a
                    className="nav-link scroll text-black "
                    href="#Storytelling "
                  >
                    Why Stories Matter
                  </a>
                </li>
                <li className="py-3">
                  <a className="nav-link scroll text-black " href="#contact">
                    Request Demo
                  </a>
                </li>
              </ul>
            </CollapseMenu>
          </li>
          <li className=" py-3">
            <a href="for-customer.html">For Customers</a>
          </li>
          <li className=" py-3">
            <CollapseMenu title={"Explore"}>
              <ul className="w-fit ml-8">
                <li className="py-3">
                  <Link to={"/explore/products"}> Products </Link>
                </li>
                <li className="py-3">
                  <Link to={"/explore/brands"}> Brands </Link>
                </li>
              </ul>
            </CollapseMenu>
          </li>
          <li className=" py-3">
            <a href="share-vision.html">Shared Vision</a>
          </li>
          <li className=" py-3">
            <a href="rootip-blog.html"> Blog</a>
          </li>
        </ul>
      </SideBar>

      <header
        className="w-full sm:bg-secondaryLight bg-[#ffffffef] border-b sm:border-0 sticky sm:static top-0"
        style={{ zIndex: 9999 }}
      >
        <div className="container sm:p-0 px-4 flex flex-row-reverse items-center sm:h-[43px] h-[50px] justify-between">
          {/* social  */}
          <div className="w-26 h-full flex items-center justify-between ">
            <div className="px-3">
              <a href="" data-tip="facebook">
                <FaFacebookF size={14} color="var(--color-body)" />
              </a>
            </div>

            <div className="pl-4">
              <a href="" data-tip="instagram ">
                <FaInstagram />
              </a>
            </div>
          </div>

          <div className="flex  w-full   flex-row-reverse  sm:justify-[end]  items-center">
            {!loading ? (
              currentUser ? (
                <div className="relative  transition  sm:w-fit w-full h-full flex px-3 items-center">
                  <div
                    className="w-full flex   items-center cursor-pointer "
                    onClick={() => setDropdown(true)}
                  >
                    <span className="block h-12 w-12  overflow-hidden mx-2">
                      <img
                        src={
                          currentUser?.user?.picture ?? ROOT_ID + default_avatar
                        }
                        className="w-full h-full "
                        alt=""
                      />
                    </span>
                    <span className="limit-text-1 w-20 hover:text-primary ">
                      {currentUser?.user?.firstName +
                        " " +
                        currentUser?.user?.lastName}
                    </span>
                  </div>

                  <ul
                    ref={ref}
                    className={` w-[190px] p-[10px] rounded-lg shadow bg-white absolute top-full left-0 ${
                      dropdown ? "sm:block hidden" : "hidden"
                    }`}
                    style={{ zIndex: 99999 }}
                  >
                    {currentUser?.user.role.RootipAdmin ? (
                      <Link to="/rootipadmin">
                        <li className=" w-full flex items-center cursor-pointer hover:text-primary h-11">
                          <span className="pr-2">
                            <FaRegIdCard />
                          </span>
                          <span className="px-3 text-[14px] ">Dashboard</span>
                        </li>
                      </Link>
                    ) : currentUser?.user.role.B2BAdmin ||
                      currentUser?.user.role.B2BOwner ? (
                      <a href="/admin/overview.html">
                        <li className=" w-full flex items-center cursor-pointer hover:text-primary h-11">
                          <span className="pr-2">
                            <FaRegIdCard />
                          </span>
                          <span className="px-3 text-[14px] ">Dashboard</span>
                        </li>
                      </a>
                    ) : currentUser?.user.role.B2BEmployee ? (
                      <a href="/employee/dashboard.html">
                        <li className=" w-full flex items-center cursor-pointer hover:text-primary h-11">
                          <span className="pr-2">
                            <FaRegIdCard />
                          </span>
                          <span className="px-3 text-[14px] ">Dashboard</span>
                        </li>
                      </a>
                    ) : currentUser?.user.role.B2CUser ? (
                      <a href="/registerb2b.html">
                        <li className=" w-full flex items-center cursor-pointer hover:text-primary h-11">
                          <span className="pr-2">
                            <FaRegIdCard />
                          </span>
                          <span className="px-3 text-[14px] ">
                            Business Register
                          </span>
                        </li>
                      </a>
                    ) : (
                      ""
                    )}

                    <a href="/user/account.html">
                      <li className=" w-full flex items-center  cursor-pointer hover:text-primary h-11">
                        <span className="pr-2">
                          <IoPersonOutline size={18} />
                        </span>
                        <span className="px-3  text-[14px]">Account</span>
                      </li>
                    </a>

                    <li
                      className=" w-full flex items-center cursor-pointer hover:text-primary h-11 "
                      onClick={singOut}
                    >
                      <span className="pr-2">
                        <IoExitOutline size={18} />
                      </span>
                      <span className="px-3  text-[14px] ">Sign Out</span>
                    </li>
                  </ul>
                </div>
              ) : (
                <ul
                  className="flex px-3 sm:w-fit  w-full justify-end text-[14px] font-bold"
                  style={{ fontFamily: "sf-compact" }}
                >
                  <li className="px-2  hover:text-primary">
                    <a href="/signup.html" id="header_signIn">
                      Register
                    </a>
                  </li>
                  <li className="px-2 hover:text-primary">
                    <Link to="/login" id="header_signIn">
                      Sign in
                    </Link>
                  </li>
                </ul>
              )
            ) : null}

            {/* search */}
            {location.href.includes("explore") ? (
              <button type="" onClick={() => setSearch(true)} className="mx-3">
                <IoSearchOutline size={18} />
              </button>
            ) : null}

            {/* side bar btn  */}
            <button
              type=""
              className="w-16 h-16 sm:hidden block"
              onClick={() => setMenu(true)}
            >
              <IoMenuSharp size={22} />
            </button>
          </div>
        </div>
      </header>

      <div className="w-full flex justify-center items-center sm:hidden block">
        <img src={ROOT_ID + logoPng} className="w-32 h-8 mt-6" alt="" />
      </div>
    </>
  );
};

export default Header;
