import React, { useContext, useState, useEffect } from "react";
import Modal from "@common/Modal/Modal";
import MapContext from "@app/context/MapContext";
import ConnectionInput from "./ConnectionInput/ConnectionInput";
import Button from "@common/Button/Button";
import { FaTimes } from "@react-icons";
import mapServices from "../../../../../../services/mapServices";
import { useMachine } from "@xstate/react";
import fetchMachine from "../../../../../../machines/fetchMachine";

function Connections({ visible, outerCb, node, nodeList }) {
  var {
    nodeChain,
    linkRequestState,
    mapviewSend,
    widgetName,
    preview,
    brandId,
  } = useContext(MapContext);
  var [nodeChainClientIds, setNodeChainClientIds] = useState([]);
  var [nodeChainSupplierIds, setNodeChainSupplierIds] = useState([]);
  var [cond, setCond] = useState(null);

  useEffect(() => {
    if (nodeChain) {
      setNodeChainClientIds([
        ...nodeChain.clients.map((client) => {
          return client.id;
        }),
      ]);
      setNodeChainSupplierIds([
        ...nodeChain.suppliers.map((supplier) => {
          return supplier.id;
        }),
      ]);
    }
  }, [nodeChain]);

  return (
    <Modal
      visible={visible}
      outerCb={() => {
        // outerCb();
        // if (linkRequestState.matches("resolved")) {
        //   mapviewSend("FETCH");
        // }
      }}
      width="w-600p"
      shadowDOM={widgetName}
      className="p-4 my-100p"
    >
      <div>
        <div className="w-100 flex items-start mb-4">
          <h2>Edit Connections</h2>
          <Button
            type="link"
            action={() => {
              outerCb();
              if (linkRequestState.matches("resolved")) {
                mapviewSend("FETCH");
              }
            }}
            styles={{
              marginLeft: "auto",
            }}
          >
            <FaTimes size="32" />
          </Button>
        </div>
        <ul>
          <li className="flex">
            <span className="m-2 text-3 w-100p border-bottom font-weight-bold">
              Clients
            </span>
            <span className="m-2 text-3 w-100p border-bottom font-weight-bold">
              Suppliers
            </span>
            <span className="m-2 text-3  border-bottom font-weight-bold m-auto">
              Organization
            </span>
          </li>
          {nodeList?.map((nodeItem) => (
            <React.Fragment key={nodeItem.id + "connection"}>
              {nodeItem.id == node.id ? (
                <></>
              ) : (
                <li className="flex items-center">
                  <div className="w-100p m-2">
                    <ConnectionInput
                      init={nodeChainClientIds.includes(nodeItem.id)}
                      clientId={nodeItem.id}
                      supplierId={node.id}
                      pairId={nodeItem.id + node.id}
                      cond={cond}
                      setCond={setCond}
                    />
                  </div>
                  <div className="w-100p m-2">
                    <ConnectionInput
                      init={nodeChainSupplierIds.includes(nodeItem.id)}
                      clientId={node.id}
                      supplierId={nodeItem.id}
                      pairId={nodeItem.id + node.id}
                      cond={cond}
                      setCond={setCond}
                    />
                  </div>
                  <div className="flex items-center m-auto">
                    <img
                      className="m-2 w-50p h-50p circle object-cover"
                      src={nodeItem.fields.logo}
                      alt={nodeItem.fields.businessName}
                    ></img>
                    <h2>{nodeItem.fields.businessName}</h2>
                  </div>
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>
      </div>
    </Modal>
  );
}

export default Connections;
