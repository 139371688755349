import React, { useState } from "react";
import { IoChevronForward } from "react-icons/io5";

const CollapseMenu = ({ children, title, open = false }) => {
  const [collapse, setCollapse] = useState(open);

  return (
    <div className="w-full p-0 m-0">
      <button
        type="button"
        className="w-full p-0 text-right"
        onClick={(e) => setCollapse(!collapse)}
      >
        <div className=" w-fit flex items-center justify-between">
          <span>{title}</span>
          <span
            className={` ml-3 ${collapse ? "rotate-[90deg]" : " "}`}
            style={{ transition: "0.3s all" }}
          >
            <IoChevronForward />
          </span>
        </div>
      </button>
      <div
        className={`relative overflow-hidden   ${
          collapse ? `max-h-[100vh]` : "max-h-[0px] "
        }  `}
        style={{ transition: "0.3s all" }}
      >
        {children}
      </div>
    </div>
  );
};

export default CollapseMenu;
