import { useMachine } from "@xstate/react";
import { useState } from "react";
import fetchMachine from "../machines/fetchMachine";

export default function useFetch(
  service,
  credentials,
  callback = () => {},
  manipulate = null
) {
  var [data, setData] = useState(null);
  var [state, send] = useMachine(fetchMachine, {
    actions: {
      goFetch: () => {
        service(...credentials)
          .then((res) => {
            if (res.error) {
              send("REJECT");
              return;
            }
            if (manipulate) {
              setData(manipulate(res));
              send("RESOLVE");
              return;
            }
            setData(res);
            callback(res);
            send("RESOLVE");
          })
          .catch((error) => {
            send("REJECT");
          });
      },
    },
  });
  return [data, state, send, setData];
}
