import React, { useEffect, useContext } from "react";
import Tabs from "@common/Tabs/Tabs";
import Comment from "./Comment";
import Stamps from "./Stamps";
import Attachments from "./Attachments";
import { MdClose } from "@react-icons";
import Button from "@common/Button/Button";

function Evidence({ story, setView }) {
  return (
    <>
      <div className="border rounded-2 overflow-hidden flex flex-col m-auto bg-light na-story-card relative pre-wrap">
        <div className="flex justify-between p-2 py-4">
          <h2 className="text-3">Evidence</h2>
          <Button
            label="close button"
            type="link"
            action={() => setView("main")}
          >
            <MdClose />
          </Button>
        </div>
        <Tabs
          listClass="grid grid-cols-3 border-bottom"
          itemClass="bg-light text-center p-2 text-2x"
          activeClass="evidence-tab-border-bottom text-primary"
          contentClass="p-2"
          items={[
            <Comment
              title="Comments"
              key={0}
              comment={story.fields.evidence?.comment}
              qna={story.fields.evidence?.qna}
            />,
            <Stamps title="Stamps" key={1} evidence={story.fields.evidence} />,
            <Attachments
              title="Attachments"
              key={2}
              evidence={story.fields.evidence}
            />,
          ]}
        ></Tabs>
      </div>
    </>
  );
}

export default Evidence;
