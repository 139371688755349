import { Machine } from "xstate";

const tipMachine = Machine({
  context: {
    amount: null,
  },
  id: "tip",
  initial: "idle",
  states: {
    idle: {
      on: {
        OPEN: {
          target: "options",
        },
      },
    },
    options: {
      on: {
        CONTINUE: {
          target: "auth_check",
        },
        CLOSE: {
          target: "idle",
        },
      },
    },
    auth_check: {
      on: {
        PASS: {
          target: "details",
        },
        FAIL: {
          target: "authentication",
        },
      },
    },
    auth_check_back: {
      on: {
        PASS: {
          target: "options",
        },
        FAIL: {
          target: "authentication",
        },
      },
    },
    authentication: {
      on: {
        CONTINUE: {
          target: "details",
        },
        CLOSE: {
          target: "idle",
        },
        BACK: {
          target: "options",
        },
      },
    },
    details: {
      on: {
        CONTINUE: {
          target: "final",
        },
        BACK: {
          target: "auth_check_back",
        },
        CLOSE: {
          target: "idle",
        },
      },
    },
    final: {},
  },
});

export default tipMachine;
