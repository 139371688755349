import React from "react";
import Icon from "@common/Icon/Icon";
import LoaderIcon from "@common/LoaderIcon/LoaderIcon";

function InfoCard({
  iconName,
  value = "NA" || 0,
  label = "NA",
  loading = false,
  data = null,
}) {
  return (
    <>
      <div className="info-card">
        {iconName ? (
          <div className="icon-container">
            {iconName ? (
              <Icon name={iconName} size={32} color="var(--color-primary)" />
            ) : null}
          </div>
        ) : null}
        {loading ? (
          <LoaderIcon width="80" height="80" />
        ) : (
          <>
            {" "}
            <div className="info-main">
              <p className={data ? "info-data" : "info-value"}>{value}</p>
              {data ? (
                <div
                  style={{ display: "inline-block" }}
                  className="items-center"
                >
                  {createElements(
                    data.nClass,
                    data.scoreClass + 1,
                    data.businessScore
                  )}
                </div>
              ) : null}
              <p className="info-label">{label}</p>
            </div>
          </>
        )}
      </div>
      <style jsx>{`
        .info-card {
          display: flex;
          align-items: center;
          box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
            rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
          border-radius: var(--border-radius-general);
          background-color: var(--color-light);
        }
        .icon-container {
          display: flex;
          background-color: #eee;
          padding: 0.8rem;
          margin: 0.8rem;
          border-radius: 50px;
        }

        .info-main {
          line-height: 1;
          padding: 1rem;
        }
        .info-value {
          font-size: 32px;
        }
        .info-data {
          font-size: 22px;
          margin: 0;
        }
      `}</style>
    </>
  );
}
function createElements(nClass, ScorClass, businessScore) {
  var elements = [];
  if (businessScore > 0) {
    elements.push(
      <Icon name={"rating-selected"} size={14} color="var(--color-primary)" />
    );
    for (var i = 0; i < ScorClass; i++) {
      elements.push(
        <Icon name={"rating-selected"} size={14} color="var(--color-primary)" />
      );
    }
    for (var p = 0; p < nClass - ScorClass; p++) {
      elements.push(
        <Icon
          name={"rating-un-selected"}
          size={14}
          color="var(--color-primary)"
        />
      );
    }
    return elements;
  } else {
    for (var p = 0; p <= nClass; p++) {
      elements.push(
        <Icon
          name={"rating-un-selected"}
          size={14}
          color="var(--color-primary)"
        />
      );
    }
    return elements;
  }
}
export default InfoCard;
