import React from "react";
import { Link } from "react-router-dom";

function TitleBox({ title = "Default" }) {
  return (
    <>
      <div className="title-box">
        <h4>{title}</h4>
        <h4 className="route">
          <Link to="/rootipadmin">Rootip</Link> / {title}
        </h4>
      </div>
      <style jsx>{`
        h4 {
          font-size: 1.76rem;
        }
        .title-box {
          background-color: var(--color-light);
          box-shadow: 0 2px 2px rgba(0, 0, 0, 0.03), 0 1px 0 rgba(0, 0, 0, 0.03);
          padding: 16px;
          margin: -16px -8px 16px -8px;
          display: flex;
          justify-content: space-between;
        }
        .route {
          font-size: 1.4rem;
          color: var(--color-text-muted);
        }
        .route :global(a) {
          color: var(--color-primary);
          text-decoration: none;
        }
      `}</style>
    </>
  );
}

export default TitleBox;
