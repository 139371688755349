import React from "react";
import LoaderIcon from "@common/LoaderIcon/LoaderIcon";
import DataTable from "react-data-table-component";

function CusDataTable({
  data,
  columns,
  emptyMessage,
  onRowClicked,
  customStyles,
  paginationTotalRows,
  onChangeRowsPerPage,
  onChangePage,
  progressPending,
}) {
  return data && onChangeRowsPerPage ? (
    <>
      <DataTable
        columns={columns}
        data={data}
        pagination
        onRowClicked={onRowClicked}
        customStyles={customStyles}
        paginationServer
        paginationTotalRows={paginationTotalRows}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        progressPending={progressPending}
        progressComponent={<LoaderIcon width="80" height="80" />}
      />
    </>
  ) : data && !onChangeRowsPerPage ? (
    <>
      <DataTable
        columns={columns}
        data={data}
        pagination
        onRowClicked={onRowClicked}
        customStyles={customStyles}
        progressPending={progressPending}
        progressComponent={<LoaderIcon width="80" height="80" />}
      />
    </>
  ) : (
    <LoaderIcon width="80" height="80" />
  );
}

export default CusDataTable;
