import React from "react"
import ContentLoader from "react-content-loader"

const SliderShimmer = (props) => (
  <ContentLoader 
    speed={2}
    width={'100%'}
    height={'100%'}
    viewBox="0 0 600 416"
    backgroundColor="#f3f3f3"
    foregroundColor="#e6e3e3"
    {...props}
  >
    <rect x="1" y="2" rx="10" ry="10" width="100%" height="100%" /> 
    <circle cx="221" cy="372" r="8" /> 
    <circle cx="240" cy="372" r="8" /> 
    <circle cx="260" cy="372" r="8" />
  </ContentLoader>
)

export default SliderShimmer

