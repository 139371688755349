/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";

import { getBrandDetails } from "@app/services/WebsiteRequeries";
import services from "@app/services/adminServices";
import LazyLoadImageComponent from "../../../../Website/Components/LazyLoadImageComponent";
import { IoLocationSharp } from "react-icons/io5";
import { MdFolder, MdPublic } from "react-icons/md";
import BrandDetailsShimmer from "../../../../Website/Components/Shimmers/BrandDetailsShimmer";
import Stories from "@app/components/common/Stories/Stories";
import BrandDetailsResponsiveShimmer from "../../../../Website/Components/Shimmers/BrandDetailsResponsiveShimmer";
import BrandCollections from "@app/components/views/Website/Containers/Brand/BrandCollections";
import ProductContainer from "../../../../Website/Containers/Explore/ProductContainer";
import AdminLayout from "../../../Layout/AdminLayout";
import Icon from "@common/Icon/Icon";
import Context from "@app/context/AppContext";
import Swal from "sweetalert2";
const Brand = (props) => {
  const [brandData, setBrandData] = useState({});
  const [loading, setLoading] = useState(true);
  const { currentUser } = useContext(Context);
  const [relationLoading, setRelationLoading] = useState(true);
  const [relationActionLoading, setRelationActionLoading] = useState(false);
  const [AddActions, setAddActions] = useState([]);
  const location = useLocation();
  const rootipperId = localStorage.getItem("rootipperId")
    ? JSON?.parse(localStorage.getItem("rootipperId"))
    : null;

  const CheckRelationHandler = (
    {
      currentUser = {
        rootipperId: rootipperId,
      },
      brandData,
    },
    setLoadingHandler
  ) => {
    if (currentUser?.rootipperId && brandData?.id) {
      //check if brand is not sef page by equation of the ids
      if (brandData?.id != currentUser?.rootipperId) {
        setLoadingHandler ? setLoadingHandler(true) : setRelationLoading(true);
        //check current user business relation with target business
        services
          ?.checkRelation({
            brandId: currentUser?.rootipperId,
            otherBrandId: brandData.id,
          })
          .then((relationRes) => {
            setLoadingHandler
              ? setLoadingHandler(false)
              : setRelationLoading(false);
            setAddActions([
              {
                title: "Add As Client",
                pendingTitle: "Pending Client Request",
                acceptedTitle: "Already Your Client",
                linkType: "client",
                linkStatus:
                  relationRes?.find((item) => item.linkType == "client")
                    ?.linkStatus ?? "none",
                data: {
                  client: brandData?.id,
                  supplier: currentUser?.rootipperId,
                  eventType: "req_ClientLink",
                },
              },
              {
                title: "Add As Supplier",
                pendingTitle: "Pending Supplier Request",
                acceptedTitle: "Already Your Supplier",
                linkType: "supplier",
                linkStatus:
                  relationRes?.find((item) => item.linkType == "supplier")
                    ?.linkStatus ?? "none",
                data: {
                  supplier: brandData?.id,
                  client: currentUser?.rootipperId,
                  eventType: "req_SupplierLink",
                },
              },
            ]);
          })
          .catch((error) => {
            //fire alert if process will fail
            Swal.fire({
              icon: "error",
              title: "Error",
              text: error.message,
              button: "OK",
              confirmButtonColor: "var(--color-primary)",
            });
            setLoadingHandler
              ? setLoadingHandler(false)
              : setRelationLoading(false);
          });
      }
    }
  };

  const sendRelationRequest = ({ linkType, data }) => {
    Swal.fire({
      showCancelButton: true,
      icon: "info",
      text: `Are you sure you would like to add this business as a ${linkType}?`,
      cancelButtonColor: "var(--color-error)",
      confirmButtonColor: "var(--color-primary)",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((response) => {
      if (response.isConfirmed) {
        setRelationActionLoading(true);
        services
          .requestSupplyChain(data)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: response.status,
              text: response.message,
              confirmButtonText: "OK",
              confirmButtonColor: "var(--color-primary)",
            });
            CheckRelationHandler(
              {
                currentUser:
                  currentUser?.rootipperId.length > 5
                    ? currentUser
                    : { rootipperId: rootipperId },
                brandData,
              },
              setRelationActionLoading
            );
            setRelationActionLoading(false);
          })
          .catch((err) => {
            setRelationActionLoading(false);
            Swal.fire({
              icon: "info",
              title: response.status,
              text: response.message,
              confirmButtonText: "OK",
              confirmButtonColor: "var(--color-primary)",
            });
          });
      }
    });
  };

  useEffect(() => {
 
    setLoading(true);
    getBrandDetails({ id: props.match.params.id })
      .then((res) => {
        setBrandData(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
 
    CheckRelationHandler({
      currentUser:
        currentUser?.rootipperId.length > 5
          ? currentUser
          : { rootipperId: rootipperId },
      brandData,
    });
  }, [brandData, currentUser]);

  return (
    <AdminLayout
      subMenu={[]}
      pointList={[
        {
          title: brandData?.fields?.businessName,
        },
      ]}
      pageTitle={" Bussiness Details"}
    >
      <div className="container mb-20">
        {/*  breadCrumb  */}

        {/* end */}
        {loading ? (
          <div className="w-full  flex justify-center items-center  ">
            <div className="w-full sm:hidden block">
              <BrandDetailsResponsiveShimmer />
            </div>
            <div className="w-full sm:block hidden">
              <BrandDetailsShimmer />
            </div>
          </div>
        ) : (
          <div className="w-full   p-10 sm:p-0">
            <div className="relative w-full mt-24 ">
              <div className="w-48 h-48  border border-secondary rounded-full top-[-50px] left-20 overflow-hidden absolute">
                <LazyLoadImageComponent
                  src={brandData?.fields?.logo}
                  className="w-full h-full rounded-full"
                  alt=""
                />
              </div>
              <LazyLoadImageComponent
                src={brandData?.fields?.coverImage}
                alt=""
                className="w-full min-h-[200px] sm:min-h-[400px]"
              />
            </div>
            <div className="mt-5">
              <div className="w-full flex justify-between ">
                <Link to={"/brand/" + brandData?.id}>
                  <h3
                    className="text-bold text-[36px]"
                    style={{ fontWeight: 500 }}
                  >
                    {brandData?.fields?.businessName}
                  </h3>
                </Link>

                {brandData?.id != rootipperId ? (
                  <div className="flex">
                    {relationLoading ? (
                      <>
                        <div className="w-56 mx-2 h-[35px] rounded-xl bg-gray-200 animate-pulse"></div>
                        <div className="w-56 mx-2 h-[35px] rounded-xl bg-gray-200 animate-pulse"></div>
                      </>
                    ) : (
                      <>
                        {AddActions?.map((item) => (
                          <button
                            key={item?.linkType}
                            onClick={() =>
                              sendRelationRequest({
                                linkType: item.linkType,
                                data: item.data,
                              })
                            }
                            disabled={
                              item?.linkStatus == "pending" ||
                              item?.linkStatus == "accept" ||
                              relationActionLoading
                            }
                            className={`p-3 h-[35px] mx-2 rounded-lg
                                ${
                                  item?.linkStatus == "pending" ||
                                  item?.linkStatus == "accept"
                                    ? "bg-gray-900"
                                    : "bg-primary"
                                }
                                 text-white `}
                          >
                            {item?.linkStatus == "pending"
                              ? item?.pendingTitle
                              : item?.linkStatus == "accept"
                              ? item?.acceptedTitle
                              : item.title}
                          </button>
                        ))}
                      </>
                    )}
                  </div>
                ) : null}
              </div>

              <div className="w-full  flex mt-2 text-caption items-center">
                <span className="pr-2">
                  <MdFolder size={14} />
                </span>
                <span className="text-[14px] ">
                  {Object.keys(brandData?.fields?.brandType ?? {}).map(
                    (key, index) => (
                      <>
                        <span> {key}</span>
                        <span className="last:hidden">,</span>
                      </>
                    )
                  )}
                </span>
              </div>

              <div className="w-full  flex mt-2 text-caption items-center">
                <span className="pr-2">
                  <IoLocationSharp size={14} />
                </span>
                <span className="text-[14px] ">
                  {brandData?.fields?.location?.city +
                    " , " +
                    brandData?.fields?.location?.country}
                </span>
              </div>

              <div className="w-full  flex mt-2 text-caption items-center">
                <a
                target={'_blank'}
                  href={brandData?.fields?.website}
                  className="hover:text-primary  flex"
                >
                  <span className="pr-2 flex justify-center items-center">
                    <MdPublic size={14} />
                  </span>
                  <span className="text-[14px] text-primary ">
                    {brandData?.fields?.website}
                  </span>
                </a>
              </div>

              <div className="w-full my-4">
                <h4 className="text-[24px] text-black font-medium mt-16">
                  Description
                </h4>
                <p className="w-full h-auto whitespace-pre-wrap text-[14px]">
                  {brandData?.fields?.description}
                </p>
              </div>
              {brandData?.fields?.tags ? (
                <div className="w-full mt-5 flex flex-wrap">
                  {Object?.keys(brandData?.fields?.tags)?.map((item) => (
                    <span
                      className="py-[3px] px-[10px] rounded-lg border text-[14px] my-[5px] mr-[10px] "
                      key={item}
                    >
                      {item}
                    </span>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        )}

        <div className="w-full  ">
          <rootip-component
            id={props.match.params.id}
            name="stories"
            type="brand"
            preview={true}
          />
        </div>

        <div className="w-full  ">
          <BrandCollections {...props} circle={true} />
        </div>

        <div className="w-full   mt-32" id="content_box_2">
          <ProductContainer brandId={props.match.params.id} circle={true} />
        </div>

        <style>
          {`
      .na-full-width{
        position: static !important;
        padding:5px
      }
      `}
        </style>
      </div>
    </AdminLayout>
  );
};

export default Brand;
