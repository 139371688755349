import React from "react";
import ContentLoader from "react-content-loader";

const BrandDetailsShimmer = (props) => (
  <ContentLoader
    speed={2}
    width="100%"
    viewBox="0 0 1200 800"
    backgroundColor="#e9e9e9"
    foregroundColor="#e4e4e4"
    {...props}
  >
    <circle cx="105" cy="62" r="61" />
    <rect x="2" y="44" rx="0" ry="0" width="100%" height="420" />

    <rect x="2" y="480" rx="6" ry="6" width="230" height="23" />
    <rect x="2" y="512" rx="6" ry="6" width="145" height="12" />

    <rect x="2" y="536" rx="6" ry="6" width="100%" height="15" />
    <rect x="2" y="561" rx="6" ry="6" width="100%" height="15" />
    <rect x="2" y="586" rx="6" ry="6" width="50%" height="14" />
    <rect x="2" y="623" rx="6" ry="6" width="100%" height="15" />
    <rect x="2" y="648" rx="6" ry="6" width="100%" height="15" />
    <rect x="2" y="673" rx="6" ry="6" width="50%" height="14" />

    <rect x="1" y="715" rx="6" ry="6" width="129" height="21" />
    <rect x="155" y="751" rx="6" ry="6" width="170" height="21" />
    <rect x="334" y="716" rx="6" ry="6" width="61" height="21" />
    <rect x="152" y="716" rx="6" ry="6" width="66" height="21" />
    <rect x="412" y="716" rx="6" ry="6" width="181" height="21" />
    <rect x="1" y="752" rx="6" ry="6" width="70" height="21" />
    <rect x="86" y="751" rx="6" ry="6" width="57" height="21" />
    <rect x="232" y="717" rx="6" ry="6" width="89" height="21" />
    <rect x="339" y="749" rx="6" ry="6" width="61" height="21" />
  </ContentLoader>
);

export default BrandDetailsShimmer;
