import React from "react";
import ContentLoader from "react-content-loader";

const ProductDetails = (props) => (
  <ContentLoader 
    speed={2}
    width={600}
    height={416}
    viewBox="0 0 600 416"
    backgroundColor="#f3f3f3"
    foregroundColor="#e6e3e3"
    {...props}
  >
    <circle cx="45" cy="47" r="44" /> 
    <rect x="98" y="29" rx="12" ry="12" width="276" height="30" /> 
    <rect x="96" y="68" rx="10" ry="10" width="192" height="18" /> 
    <rect x="5" y="124" rx="8" ry="8" width="337" height="28" /> 
    <rect x="4" y="197" rx="10" ry="10" width="192" height="18" /> 
    <rect x="5" y="165" rx="10" ry="10" width="192" height="18" /> 
    <rect x="5" y="250" rx="10" ry="10" width="359" height="17" /> 
    <rect x="4" y="280" rx="10" ry="10" width="54" height="18" /> 
    <rect x="5" y="320" rx="10" ry="10" width="115" height="29" /> 
    <rect x="5" y="368" rx="10" ry="10" width="506" height="41" />
  </ContentLoader>
);

export default ProductDetails;
