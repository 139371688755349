import apiClient from "./apiClient";

function getProducts(params) {
  return apiClient("base").post("/getProductsExploreDataV4", params);
}

function getProductDetails(params) {
  return apiClient("base").post("/getProductV3", {
    source: "web",
    ...params,
  });
}
function getCollectionDetails(params) {
  return apiClient("base").post("/showCollection", {
    source: "web",
    ...params,
  });
}
function getProductsCollectionExploreData(params) {
  return apiClient("base").post("/getProductsCollectionExploreData", params);
}
function getBrands(params) {
  return apiClient("base").post("/getBrandsExploreData", params);
}
function getBrandDetails(params) {
  return apiClient("base").post("/showBrandDetails", params);
}
function getBrandCollection(params) {
  return apiClient("base").post("/getCollectionsExploreData", params);
}
function createSellerWebsiteClickHistory(params) {
  return apiClient("base").post("/createSellerWebsiteClickHistory", params);
}

export {
  getProducts,
  getBrands,
  getProductDetails,
  getBrandDetails,
  getBrandCollection,
  getCollectionDetails,
  getProductsCollectionExploreData,
  createSellerWebsiteClickHistory,
};
