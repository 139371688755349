/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
const ProductCard = ({ item, href = false, circle }) => {
  const [imageLoad, setImageLoad] = useState(false);
  return (
    <div className="w-full mb-5">
      <Link to={href ? href : `/product/${item.product.id}`}>
        <div className="w-full h-full ">
          <LazyLoadImage
            src={item?.product?.fields?.image?.url}
            className={`rounded-2xl  sm:h-auto ${
              circle ? "min-h-[188px]" : "min-h-[188px]"
            }`}
            width={"100%"}
            onLoad={() => setImageLoad(true)}
            wrapperClassName={`w-full      sm:h-auto ${
              circle ? "min-h-[188px]" : "min-h-[188px]"
            } rounded-2xl ${
              imageLoad ? "" : "animate-pulse bg-gray-200  sm:min-h-[385px]"
            } `}
            effect="blur" // use normal <img> attributes as props
          />
        </div>
      </Link>

      <Link to={href ? href : `/product/${item.product.id}`}>
        <h3 className="font-bold mt-3 hover:text-primary">
          {item?.product?.fields?.title}
        </h3>
      </Link>
      <Link to={href ? href : `/product/${item.product.id}`}>
        <div className="w-full flex justify-between items-center   ">
          <span className="text-primary text-xl  ">
            {item?.storyCount} Stories
          </span>
          <div className="w-fit user-counts">
            <ul className="list-none">
              {item?.stories?.map((story, index) => (
                <li
                  className=" inline-block ml-[-55px] "
                  key={"story_" + index}
                >
                  <a
                    className="tag_productDetails"
                    href="product-details.html?id=kZD6Ico30l1g7MM9r8Jn&sec=story"
                  >
                    <LazyLoadImage
                      className={`w-[45px] h-[45px] rounded-full border ${
                        imageLoad ? "border-white border-2 bg-white" : ""
                      } `}
                      wrapperClassName={`w-full   h-full rounded-full ${
                        imageLoad ? "" : "animate-pulse bg-gray-200"
                      } `}
                      src={story?.imageUrl}
                    />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Link>
      <style jsx>
        {`
          ul.user-counts {
            /* list-style-type: none;
        margin: 0;
        padding: 0;
        direction: rtl;
        padding-right: 40px; */

            list-style-type: none;
            padding-left: 0;
            /*direction: ltr !important;*/
            /*margin: 0;*/
            margin: 0 3rem 0 0;
          }
          ul.user-counts > li {
            position: relative;
            /* display: inline-block;

        border: 1.5px solid #fff;
        border-radius: 100px; */

            text-transform: uppercase;
            transition: background 0.5s ease;
            display: inline-block;
            margin-left: -55px;
          }

          ul.user-counts > li img {
            width: 45px;
            height: 45px;
            border-radius: 100px;
          }

          ul.user-counts > li img {
            width: 45px;
          }
        `}
      </style>
    </div>
  );
};

export default ProductCard;
