import React, { useEffect, useState } from "react";
import { ROOT_ID } from "@app/constants/constants";
function FileUpload({
  onChanged,
  filename,
  UploadFile,
  disabled,
  id,
  source,
  refer,
}) {
  return (
    <>
      <div className="flex">
        <div className="flex w-100">
          <label className="flex" style={{ width: "-webkit-fill-available" }}>
            <input
              ref={refer}
              onChange={onChanged}
              type="file"
              id={id}
              style={{ display: "none" }}
              className="block w-full text-slate-500
      mr-4 py-2 px-4
      rounded-1  border-1
      bg-violet-50 text-slate-500"
            />
            <label
              htmlFor={id}
              id="mylbl"
              className="block w-full text-slate-500
      mr-4 py-1 px-2
      rounded-tbl-1  border-1
      font-semibold
       text-slate-500 border
       border-slate-300"
            >
              {filename}
            </label>
            <label
              htmlFor={id}
              className="border-slate-300 p-2 border rounded-tbr-1 bg-grey-light text-dark "
            >
              Browse
            </label>
          </label>
          <input
            type="button"
            disabled={disabled}
            value="Import"
            className={
              disabled === true
                ? "border-slate-300 p-2  border rounded-1 bg-overlay  text-light "
                : "border-primary p-2  border rounded-1 bg-primary text-light "
            }
            onClick={UploadFile}
          />
        </div>
      </div>
      <div className=" w-100">
        <a
          className="text-primary"
          style={{ width: "200px" }}
          target="_blank"
          href={ROOT_ID + "/sharedcontent/importTemplate/" + source + ".xls"}
        >
          {source === "shopify"
            ? "Shopify "
            : source === "wordpress"
            ? "Wordpress "
            : "Squarespace "}
          Format Template
        </a>
      </div>
    </>
  );
}
export default FileUpload;
