function thirdPartyClient() {
  const headers = { "Content-Type": "application/json" };
  return {
    post: async function post(endpoint, credentials, authKey) {
      if (authKey) {
        headers["Authorization"] = authKey;
      }
      var response = await fetch(endpoint, {
        method: "POST",
        body: credentials ? JSON.stringify(credentials) : null,
      });
      var data = await response.json();
      return data;
    },
  };
}

export default thirdPartyClient();
