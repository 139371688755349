import RankingCard from "./RankingCard";
import service from "@app/services/rootipAdminServices";
import { useMachine } from "@xstate/react";
import fetchMachine from "@app/machines/fetchMachine";
import LoaderIcon from "@common/LoaderIcon/LoaderIcon";
import React, { useContext, useEffect, useState } from "react";
function Ranking({ groupName }) {
  var [rankingList, setRankingList] = useState(null);
  var [rankingState, rankingSend] = useMachine(fetchMachine, {
    actions: {
      goFetch: () => {
        service
          .transparencyRanking(groupName)
          .then((list) => {
            setRankingList(list);
            rankingSend("RESOLVE");
          })
          .catch((error) => {
            setRankingList(error);
            rankingSend("REJECT");
          });
      },
    },
  });

  useEffect(() => {
    rankingSend("FETCH");
  }, []);

  return (
    <>
      {rankingState.matches("pending") && <LoaderIcon width="80" height="80" />}
      {rankingState.matches("resolved") && (
        <>
          <div className="flex flex-col info-card w-100">
            <p className="ml-2 mb-1 info-label">
              Transparency Ranking in {groupName} section
            </p>
            <div
              className="overflow-y-scroll"
              style={{
                maxHeight: 305,
              }}
            >
              {rankingList.map((element, i) => {
                return (
                  <>
                    <RankingCard
                      key={i + 2}
                      businessName={element.businessName}
                      score={element.scoreValue}
                      logo={element.logo}
                      rank={i + 1}
                      scoreClass={element.scoreClass}
                      nClass={element.nClass}
                    ></RankingCard>
                  </>
                );
              })}
            </div>
          </div>
        </>
      )}

      <style jsx>{`
        .info-card {
          display: flex;
          padding-right: 4px;
          padding-top: 4px;
          margin-left: 5px;
          box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
            rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
          border-radius: var(--border-radius-general);
          background-color: var(--color-light);
        }
      `}</style>
    </>
  );
}

export default Ranking;
