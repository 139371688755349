import { Popup } from "react-leaflet";
import React, { useRef, useState, useContext } from "react";
import AddNode from "./AddNode/AddNode";
import Swal from "sweetalert2";
import MapContext from "@app/context/MapContext";
import Icon from "@common/Icon/Icon";
import Connections from "./Connections/Connections";
import { MdInfoOutline } from "@react-icons";
function EditOptions({ node, type, nodeList }) {
  var {
    removeNodeSend,
    getNodeSend,
    nodeChainSend,
    setSelectedNode,
    brandId,
  } = useContext(MapContext);
  var [addNodeModal, setAddNodeModal] = useState(null);
  var [edit, setEdit] = useState(false);
  var [connectionsModal, setConnectionsModal] = useState(null);
  var popUpRef = useRef();
  function closePopup() {
    popUpRef.current.leafletElement.options.leaflet.map.closePopup();
  }
  var [linkType, setLinkType] = useState(null);

  return (
    <>
      <Popup ref={popUpRef} className="edit-options" maxWidth="auto">
        <ul className="flex p-2">
          {(((type == "supplier" || type == "brand") &&
            node.fields.isVirtualNode) ||
            node.id == brandId) && (
            <li className="">
              <button
                className="bg-transparent no-wrap w-100 text-left p-2 flex flex-col items-center"
                onClick={() => {
                  setEdit(false);
                  setAddNodeModal(true);
                  closePopup();
                  setLinkType("req_SupplierLink");
                }}
              >
                <Icon name="maps/add-supplier" size="32" />
                <span className="text-primary pt-2">Add Supplier</span>
              </button>
            </li>
          )}
          {(((type == "client" || type == "brand") &&
            node.fields.isVirtualNode) ||
            node.id == brandId) && (
            <li className="">
              <button
                className="bg-transparent no-wrap w-100 text-left p-2 flex flex-col items-center"
                onClick={() => {
                  setEdit(false);
                  setAddNodeModal(true);
                  closePopup();
                  setLinkType("req_ClientLink");
                }}
              >
                <Icon name="maps/add-client" size="32" />
                <span className="text-primary pt-2">Add Client</span>
              </button>
            </li>
          )}
          {(node.fields.isVirtualNode || node.id == brandId) && (
            <li className="">
              <button
                className="bg-transparent no-wrap w-100 text-left p-2 flex flex-col items-center"
                onClick={() => {
                  setAddNodeModal(true);
                  closePopup();
                  setEdit(true);
                  getNodeSend({ type: "FETCH", payload: node.id });
                }}
              >
                <Icon name="maps/edit-details" size="32" />
                <span className="text-primary pt-2">Edit Details</span>
              </button>
            </li>
          )}
          {(node.fields.isVirtualNode || node.id == brandId) && (
            <li className="">
              <button
                className="bg-transparent no-wrap w-100 text-left p-2 flex flex-col items-center"
                onClick={() => {
                  setConnectionsModal(true);
                  closePopup();
                  nodeChainSend({ type: "FETCH", payload: node.id });
                  setSelectedNode(node.id);
                }}
              >
                <Icon name="maps/edit-connections" size="32" />
                <span className="text-primary pt-2">Edit Connections</span>
              </button>
            </li>
          )}
          {node.fields.isVirtualNode && (
            <li className="">
              <button
                className="bg-transparent no-wrap w-100 text-left p-2 flex flex-col items-center"
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure you want to delete this node?",
                    icon: "info",
                    showDenyButton: true,
                    confirmButtonText: `Yes`,
                    confirmButtonColor: "#00a79e",
                    denyButtonColor: "#ff5353",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      removeNodeSend({ type: "FETCH", payload: node.id });
                    }
                  });
                }}
              >
                <Icon name="maps/delete" size="32" />
                <span className="text-primary pt-2">Delete</span>
              </button>
            </li>
          )}
          {!node.fields.isVirtualNode && node.id != brandId && (
            <li className="flex items-center">
              <div className="mr-1">
                <MdInfoOutline size={16} />
              </div>
              <div className="w-200p">
                Only the owner of this organisation can add clients and
                suppliers for this organisation.
              </div>
            </li>
          )}
        </ul>
      </Popup>
      <AddNode
        visible={addNodeModal}
        outerCb={setAddNodeModal}
        linkType={linkType}
        node={node}
        edit={edit}
      />
      <Connections
        visible={connectionsModal}
        outerCb={setConnectionsModal}
        node={node}
        nodeList={nodeList}
      />
    </>
  );
}

export default EditOptions;
