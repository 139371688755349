import React from "react";

import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Tooltip,
} from "recharts";

function SpiderChart({ suggestedMax, spiderData }) {
  const shimmerData = [
    {
      subject: "Math",
      score: 150,
    },
    {
      subject: "Chinese",
      score: 150,
    },
    {
      subject: "English",
      score: 150,
    },
    {
      subject: "Geography",
      score: 150,
    },
    {
      subject: "Physics",
      score: 150,
    },
    {
      subject: "History",
      score: 150,
    },
  ];

  return spiderData && suggestedMax > 0 ? (
    <div className="flex flex-col items-center justify-center mb-3">
      <RadarChart
        outerRadius={"70%"}
        width={375}
        height={300}
        data={spiderData}
      >
        <Tooltip contentStyle={{ fontSize: "13px" }} />
        <PolarGrid />
        <PolarAngleAxis dataKey="subject" radius={10} />
        <PolarRadiusAxis
          type="number"
          angle={90}
          domain={[0, "dataMax"]}
          tickCount={
            suggestedMax < 10
              ? suggestedMax + 1
              : suggestedMax < 20
              ? Math.floor(suggestedMax / 2) + 1
              : suggestedMax < 50
              ? Math.floor(suggestedMax / 5) + 1
              : suggestedMax < 1000
              ? Math.floor(suggestedMax / 100) + 1
              : suggestedMax % 10
          }
          tickFormatter={(e, i) => {
            if (suggestedMax >= 20) {
              if (e - i == 0) {
                return;
              }
              return e - i;
            }
            if (e == 0) {
              return;
            }
            return e;
          }}
        />
        <Radar
          dataKey="score"
          stroke="#8dc53e"
          fill="#8dc53e"
          fillOpacity={0.4}
          animationDuration={800}
        />
      </RadarChart>
    </div>
  ) : (
    <>
      <div className="relative">
        <div className="shimmer-overlay"></div>
        <div className="flex flex-col items-start justify-center">
          <RadarChart
            outerRadius={"70%"}
            width={300}
            height={300}
            data={shimmerData}
          >
            <PolarGrid />

            <Radar
              dataKey="score"
              stroke="transparent"
              fill="#8dc53e"
              fillOpacity={0}
            />
          </RadarChart>
        </div>
      </div>
    </>
  );
}
export default SpiderChart;
