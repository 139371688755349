import React, { useEffect, useLayoutEffect, useState } from "react";
import MarkerClusterGroup from "react-leaflet-markercluster";
import CustomMarker from "../CustomMarker/CustomMarker";
import PolylineDecorator from "../PolylineDecorator/PolylineDecorator";
import { useLeaflet } from "react-leaflet";
import fetchMachine from "../../../../../machines/fetchMachine";
import { useMachine } from "@xstate/react";
import mapServices from "../../../../../services/mapServices";
import MapShimmer from "../MapShimmer/MapShimmer";

function MarkerClusters({
  mapviewState,
  nodeListState,
  nodes,
  preview,
  parentIds,
  brandId,
  widgetName,
  nodeList,
  brandPosition,
}) {
  const { map } = useLeaflet();

  useEffect(() => {
    if (map) {
      var brandLat = brandPosition[0];
      var brandLng = brandPosition[1];
      map.eachLayer(function (layer) {
        //iterate over map rather than clusters
        if (layer.getChildCount) {
          // if layer is markerCluster
          layer.getAllChildMarkers().map((marker) => {
            if (
              brandLat == marker._latlng.lat &&
              brandLng == marker._latlng.lng
            ) {
              layer.spiderfy();
            }
          });
        }
      });
    }
  }, [map]);

  const arrow = [
    {
      offset: "50%",
      repeat: "100%",
      symbol: L.Symbol.arrowHead({
        pixelSize: 10,
        polygon: true,
        pathOptions: {
          stroke: true,
          fillOpacity: 1,
          weight: 0,
          color: "#00a79e",
        },
      }),
    },
  ];
  return (
    <MarkerClusterGroup
      spiderLegPolylineOptions={{
        weight: 1.5,
        color: "#00a79e",
        opacity: 0.5,
      }}
      maxClusterRadius={40}
      showCoverageOnHover={false}
      zoomToBoundsOnClick={false}
      spiderfyOnMaxZoom={true}
      onClusterClick={(e) => {
        e.layer.spiderfy();
      }}
      onEachLayer={(layer) => {
        console.log(layer);
      }}
    >
      {mapviewState.matches("pending") ||
        mapviewState.matches("idle") ||
        ((nodeListState.matches("pending") ||
          nodeListState.matches("idle")) && <MapShimmer />)}

      {mapviewState.matches("resolved") &&
        nodeListState.matches("resolved") &&
        brandPosition &&
        nodes.map((node) => (
          <React.Fragment key={node.id}>
            <CustomMarker
              widgetName={widgetName}
              type="brand"
              key={node.node.id}
              node={node.node}
              preview={preview}
              nodeList={nodeList}
            ></CustomMarker>
            {node.node?.type === "story" && (
              <PolylineDecorator
                key={node.node.id + "line"}
                color="#00a79e"
                patterns={arrow}
                positions={[
                  [
                    node.node.fields.location.lat,
                    node.node.fields.location.lng,
                  ],
                  [brandPosition[0], brandPosition[1]],
                ]}
              ></PolylineDecorator>
            )}
            {node.clients.map((client) => {
              if (!parentIds.includes(client.id)) {
                return (
                  <CustomMarker
                    type="client"
                    key={client.id}
                    node={client}
                    preview={preview}
                    nodeList={nodeList}
                  ></CustomMarker>
                );
              } else return null;
            })}
            {node.clients.map((client) => (
              <PolylineDecorator
                key={client.id + "line"}
                color="#00a79e"
                patterns={arrow}
                positions={[
                  [
                    node.node.fields.location.lat,
                    node.node.fields.location.lng,
                  ],
                  [client.fields.location.lat, client.fields.location.lng],
                ]}
              />
            ))}
            {node.suppliers.map((supplier) => {
              if (!parentIds.includes(supplier.id)) {
                return (
                  <CustomMarker
                    nodeList={nodeList}
                    type="supplier"
                    key={supplier.id}
                    node={supplier}
                    preview={preview}
                  ></CustomMarker>
                );
              } else return null;
            })}
            {node.suppliers.map((supplier) => (
              <PolylineDecorator
                key={supplier.id + "line"}
                color="#00a79e"
                patterns={arrow}
                positions={[
                  [supplier.fields.location.lat, supplier.fields.location.lng],
                  [
                    node.node.fields.location.lat,
                    node.node.fields.location.lng,
                  ],
                ]}
              />
            ))}
          </React.Fragment>
        ))}
    </MarkerClusterGroup>
  );
}

export default MarkerClusters;
