import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { getBrandCollection } from "../../../../../services/WebsiteRequeries";
import ProductCard from "../../Components/ProductCard";
import ProductCardShimmer from "../../Components/Shimmers/ProductCardShimmer";

const BrandCollections = (props) => {
  const [collectionData, setCollection] = useState([]);
  const [width, setWidth] = useState(1280);
  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);
  const [loading, setLoading] = useState(true);
  const { circle = false } = props
  const [params, setParams] = useState({
    brandId: props.match.params.id,
    isPublish: true,
    size: 4,
    startSeq: -1,
    startWeight: 100,
  });

  const [count, setCount] = useState(24);

  const getBrandsHandler = (newParam, clear = false) => {
    setLoading(true);

    getBrandCollection(newParam)
      .then((res) => {
        setLoading(false);
        if (clear) {
          setCollection(res.collectionList);
        } else {
          setCollection([...collectionData, ...res?.collectionList]);
        }

        setParams({
          brandId: props.match.params.id,
          size: width > 420 ? 3 : 4,
          startSeq:
            res.collectionList?.[res.collectionList.length - 1].collection
              .fields.createDate,

          isPublish: true,
          startWeight:
            res.collectionList?.[res.collectionList.length - 1].storyCount,
        });
        setCount(res.count);
        //get latest product createdDate for  fetch next products
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    getBrandsHandler({ ...params, size: width > 420 ? 3 : 4 }, true);
  }, [props.match.params.id, width]);
  return (
    <div className={`${collectionData?.length > 0 ? "" : "hidden"} w-full`}>
      <h3 className="text-4 font-bold ">Collections</h3>
      <div className="w-full flex justify-start items-center">
        <div className="my-10 flex  justify-between flex-wrap w-full ">
          {collectionData?.length > 0
            ? collectionData?.map((item) => (
              <div
                className={`  sm:w-4/12  w-1/2 p-3 ${collectionData[collectionData.length - 1].collection.id ==
                  item.collection.id
                  ? "mr-auto"
                  : ""
                  }  `}
                key={item.collection.id}
              >
                <ProductCard
                  href={circle ? "/admin/circle/collection/" + item.collection.id : "/collection/" + item.collection.id}
                  item={{
                    product: item.collection,
                    stories: item.stories,
                    storyCount: item.storyCount,
                  }}
                />
              </div>
            ))
            : null}

          {loading ? (
            <>
              <div className=" sm:w-4/12 w-1/2 p-4 ">
                <ProductCardShimmer />
              </div>
              <div className=" sm:w-4/12 w-1/2 p-4 ">
                <ProductCardShimmer />
              </div>
              <div className=" sm:w-4/12 w-1/2 p-4 ">
                <ProductCardShimmer />
              </div>
              {width < 420 ? (
                <div className=" sm:w-4/12 w-1/2 p-4 ">
                  <ProductCardShimmer />
                </div>
              ) : null}
            </>
          ) : null}

          {count > 3 && count > collectionData.length ? (
            <div className="w-full flex justify-center items-center mt-10">
              <button
                onClick={() => {
                  getBrandsHandler({
                    ...params,
                    size: width > 420 ? 3 : 4,
                    brandId: props.match.params.id,
                  });
                }}
                className="bg-primary px-[31px] py-3 rounded-lg text-white"
                disabled={!(count > collectionData.length) || loading}
              >
                Load more
              </button>
            </div>
          ) : null}
        </div>
      </div>
      <style>
        {`
      .infinite-scroll-component__outerdiv{
        width:100% !important;
      }
      `}
      </style>
    </div>
  );
};

export default BrandCollections;
