import React, { useState } from "react";
import Button from "@common/Button/Button";
import { DateRangePicker, createStaticRanges } from "react-date-range";

import { subDays, format } from "date-fns";

function CustomDatePicker({ onChange, points }) {
  var [showDatePicker, setShowDatePicker] = useState(false);
  var [range, setRange] = useState([
    {
      endDate: new Date(),
      startDate: subDays(new Date(), 7),
      key: "selection",
      color: "#00a79e",
      showDateDisplay: false,
    },
  ]);
  var staticRanges = createStaticRanges([
    {
      label: "30 Days",
      range: () => ({
        startDate: subDays(new Date(), 30),
        endDate: new Date(),
      }),
    },
    {
      label: "60 Days",
      range: () => ({
        startDate: subDays(new Date(), 60),
        endDate: new Date(),
      }),
    },
    {
      label: "90 Days",
      range: () => ({
        startDate: subDays(new Date(), 90),
        endDate: new Date(),
      }),
    },
    {
      label: "1 Year",
      range: () => ({
        startDate: subDays(new Date(), 365),
        endDate: new Date(),
      }),
    },
  ]);

  return (
    <>
      <button
        onFocus={() => setShowDatePicker(true)}
        className="date-picker-btn"
      >
        {Object.keys(points).length == 0 ? (
          "All Time"
        ) : (
          <>
            {" "}
            {format(points.startDate, "d MMMM, yyyy")} -{" "}
            {format(points.endDate, "d MMMM, yyyy")}{" "}
          </>
        )}
      </button>
      {showDatePicker ? (
        <div className="date-picker">
          <DateRangePicker
            onChange={(item) => setRange([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={range}
            direction="horizontal"
            showMonthAndYearPickers={true}
            maxDate={new Date()}
            dateDisplayFormat="d MMMM, yyyy"
            inputRanges={[]}
            staticRanges={staticRanges}
          />
          <div className="date-picker_bottom-row">
            <div>
              {format(range[0].startDate, "dd/MM/yyyy")}-{" "}
              {format(range[0].endDate, "dd/MM/yyyy")}
            </div>
            <Button
              action={() => setShowDatePicker(false)}
              title="Cancel"
              type="cancel"
            />
            <Button
              action={() => {
                setShowDatePicker(false);
                onChange({
                  startDate: range[0].startDate,
                  endDate: range[0].endDate,
                });
              }}
              title="Apply"
              type="primary"
            />
          </div>
        </div>
      ) : null}

      <style jsx global>{`
        .date-picker {
          position: absolute;
          right: calc(50% - 5rem);
          top: calc(42px + 1rem + 0.5rem);
          z-index: 100;
          border: 1px solid var(--color-border);
          border-radius: var(--border-radius-general);
          max-width: 95%;
          background-color: var(--color-light);
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }
        .rdrMonth {
          width: 22em;
        }
        .rdrDefinedRangesWrapper {
          width: 9em;
        }
        .date-picker_bottom-row {
          display: flex;
          justify-content: flex-end;
          border-top: 1px solid var(--color-border);
          padding: 0.5rem 1rem;
          align-items: center;
        }
        .date-picker_bottom-row *:not(:last-child) {
          margin-right: 1rem;
        }

        .date-picker-btn {
          width: 50%;
          position: absolute;
          right: 1rem;
          padding: 0.5rem 1rem;
          border: 1px solid var(--color-border);
          border-radius: var(--border-radius-general);
          background-color: var(--color-light);
        }
      `}</style>
    </>
  );
}

export default CustomDatePicker;
