import firebase from "firebase/app";
import "firebase/auth";
import "firebase/performance";
import "firebase/storage";

const firebaseConfig = {
  // apiKey: "AIzaSyCTJteDLnljzUW7pRfEYtqOg8doaVfPL3k",
  // authDomain: "rootipuat.firebaseapp.com",
  // databaseURL: "https://rootipuat.firebaseio.com",
  // projectId: "rootipuat",
  // storageBucket: "rootipuat.appspot.com",
  // messagingSenderId: "810332169",
  // appId: "1:810332169:web:94461d81126b69bfcbc8d8",
  // measurementId: "G-ZLYQ8EPLVH",
  // apiKey: "AIzaSyBXnti7sDW5XOvVv7Ubdu8uAgi8vzyJh98",
  // authDomain: "rootip-development.firebaseapp.com",
  // projectId: "rootip-development",
  // storageBucket: "rootip-development.appspot.com",
  // messagingSenderId: "830886759321",
  // appId: "1:830886759321:web:c5ded625e2f001691ca108",
  // measurementId: "G-2LQNN70MXN",
  apiKey: "AIzaSyCk57nMR_ocJGxp-41cPm_qgpr-b4cVrEo",
  authDomain: "rootipapp.firebaseapp.com",
  databaseURL: "https://rootipapp.firebaseio.com",
  projectId: "rootipapp",
  storageBucket: "rootipapp.appspot.com",
  messagingSenderId: "619241555462",
  appId: "1:619241555462:web:c01554a98cea11e824a587",
  measurementId: "G-3CMPMY6F22",
};

firebase.initializeApp(firebaseConfig);
const perf = firebase.performance();
const storage = firebase.storage();

//const ROOT_ID = "http://localhost:8080";
//const ROOT_ID = "http://localhost:5000";
//const ROOT_ID = "https://rootip-development.web.app";
//const ROOT_ID = "https://rootipuat.web.app";
const ROOT_ID = "https://rootip.io";
//const ROOT_ID = "https://rootiprefactor.web.app";

//const BASE_URL = "http://localhost:5010/api/v3";

//const BASE_URL = "https://rootip-development.web.app/api/v3";
//const BASE_URL = "https://rootipuat.web.app/api/v3";
const BASE_URL = "https://rootipapp.web.app/api/v3";

//const EXTERNAL_APP_URL = "http://localhost:5010/api/externalApps/v1";
//const EXTERNAL_APP_URL =
//"https://rootip-development.web.app/api/externalApps/v2";
//const EXTERNAL_APP_URL = "https://rootipuat.web.app/api/externalApps/v2";
const EXTERNAL_APP_URL = "https://rootipapp.web.app/api/externalApps/v2";

//const WIDGETS_BASE_URL = "http://localhost:5010/api/widgets/v1";
//const WIDGETS_BASE_URL = "https://rootip-development.web.app/api/widgets/v1";
//const WIDGETS_BASE_URL = "https://rootipuat.web.app/api/widgets/v1";
const WIDGETS_BASE_URL = "https://rootipapp.web.app/api/widgets/v1";

//prod
const AI_SCAN_URL = "https://rootipaiscan-kbmcecusja-lm.a.run.app";
//uat
//const AI_SCAN_URL = "https://rootipaiscanuat-kbmcecusja-lm.a.run.app";

const version = "1.21.3";

export {
  firebase,
  ROOT_ID,
  BASE_URL,
  version,
  storage,
  EXTERNAL_APP_URL,
  WIDGETS_BASE_URL,
  AI_SCAN_URL,
};
