import React from "react";
import ContentLoader from "react-content-loader";

const ProductCardShimmer = (props) => (
  <ContentLoader
    speed={2}
    width='100%'
    
    viewBox="0 0 385 458"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="356px" cy="424px" r="20" />
    <rect x="0" y="9" rx="10" ry="10" width="379" height="359" />
    <rect x="2" y="381" rx="6" ry="6" width="281" height="22" />
    <rect x="3" y="420" rx="7" ry="7" width="81" height="16" />
  </ContentLoader>
);

export default ProductCardShimmer;
