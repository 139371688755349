import AdminLayout from "../../../Layout/AdminLayout";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from "react-router";
import { getBrands } from "../../../../../../services/WebsiteRequeries";
import BrandCard from "../../../../Website/Components/BrandCard";
import BrandCardShimmer from "../../../../Website/Components/Shimmers/BrandCardShimmer";
import StoryChapters from "../../../../../common/Stories/StoryChapters/StoryChapters";
import mapServices from "@app/services/mapServices";
import { IoSearchOutline, IoCloseSharp } from "react-icons/io5";
const CircleExplore = () => {
  const [params, setParams] = useState({
    size: 12,
    startSeq: -1,
    startTitle: "0",
  });

  const [brands, setBrands] = useState([]);
  const [category, setCategory] = useState("all");
  const [brandTypes, setBrandTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  const [searchParams, setSearchParams] = useState("");
  const [count, setCount] = useState(24);

  const changeParams = (newParams) => {
    setParams({ ...params, ...newParams });
  };

  const getBrandsHandler = (newParam, clear = false) => {
    setLoading(true);
    getBrands(newParam)
      .then((res) => {
        setCount(res.count);
        setLoading(false);
        if (clear) {
          setBrands(res.brandList);
        } else {
          setBrands([...brands, ...res.brandList]);
        }

        setParams({
          size: 6,
          startSeq: res.brandList?.[res.brandList.length - 1].fields.createDate,
          startTitle:
            res.brandList?.[res.brandList.length - 1].fields.searchTitle,
        });

        //get latest product createdDate for  fetch next products
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    setBrands([]);

    if (searchParams != "" && searchParams != null) {
      changeParams({
        query: searchParams ?? "",
        startTitle: searchParams ?? "",
      });
      getBrandsHandler(
        {
          ...params,
          query: searchParams ?? "",
          startTitle: searchParams ?? "",
        },
        true
      );
      setCategory("all");
    }
  }, [searchParams]);

  useEffect(() => {
    setBrands([]);

    if (category != null) {
      setSearchParams("");
      changeParams({
        size: 12,
        startSeq: -1,
        startTitle: "0",
        circle: true,
        brandType: {
          [category]: true,
        },
      });
      getBrandsHandler(
        {
          size: 12,
          startSeq: -1,
          startTitle: "",
          brandType: {
            [category]: true,
          },
        },
        true
      );
    }
  }, [category]);
  useEffect(() => {
    mapServices.getBrandTypes().then((res) => {
      const items = Object.keys(res).map((key) => ({
        title: key,
        id: res[key],
      }));

      setBrandTypes([{ id: "all", title: "All" }, ...items]);
    });
  }, []);
  return (
    <AdminLayout pageTitle={"Rootip Circle"} isPrimary={true}>
      <div className="w-full h-auto container">
        <div className="w-full p-5 h-40 ">
          <div className="relative w-full flex gap-3 snap-x snap-mandatory overflow-x-auto  pb-4 ">
            {brandTypes.map((item) => (
              <button
                key={item.title}
                type=""
                onClick={() => setCategory(item.id)}
                className={`
                border snap-left shrink-0 
                rounded-full
              transition-all
                  px-7 py-2 text-2xl 
                   
                  ${
                    category == item.id
                      ? " border-primaryLight  bg-primaryLight text-white"
                      : "text-caption hover:text-black bg-white"
                  }
                `}
              >
                {item.title}
              </button>
            ))}
          </div>
          <div className="w-full h-fit flex items-center sm:mb-10">
            <input
              type="text"
              id="txtQuery"
              onKeyUp={(e) => {
                if (e.key === "Enter" || e.keyCode === 13) {
                  setSearchParams(search);
                }
              }}
              className="w-full sm:border-b outline-none h-18 sm:text-4xl text-black sm:mt-5 text-bold px-3"
              placeholder="Search Brands..."
              onChange={(e) => setSearchParams(e.target.value)}
              value={searchParams}
            />
            <div className="flex justify-between sm:flex-col items-center pl-3">
              <IoSearchOutline size={30} />

              <button
                type="button"
                className="hover:text-primary mx-2 block sm:hidden text-primary"
              >
                <IoSearchOutline size={20} color="var(--color-primary)" />
              </button>
            </div>
          </div>
        </div>
        {!loading ? <div className="p-5">{count} Supplier(s) found.</div> : null}

        <InfiniteScroll
          dataLength={brands.length}
          next={() =>
            loading
              ? null
              : getBrandsHandler({ ...params, query: searchParams })
          }
          //To put endMessage and loader to the top.

          hasMore={count >= brands.length}
        >
          <div className="my-10 flex  justify-between flex-wrap  ">
            {brands.length > 0 ? (
              brands.map((item, index) => (
                <div
                  className={`w-full sm:w-4/12 p-4 ${
                    index == brands.length - 1 ? "mr-auto" : ""
                  }`}
                  key={item.id}
                >
                  <BrandCard
                    key={"brand_" + item.id}
                    item={item}
                    href={"/admin/circle/brand/" + item.id}
                  />
                </div>
              ))
            ) : loading ? null : (
              <div className="w-full mt-10 text-center text-[18px] text-caption">
                No match found
              </div>
            )}

            {loading ? (
              <>
                <div className="w-full sm:w-4/12 p-4 ">
                  <BrandCardShimmer />
                </div>
                <div className="w-full sm:w-4/12 p-4 ">
                  <BrandCardShimmer />
                </div>
                <div className="w-full sm:w-4/12 p-4 ">
                  <BrandCardShimmer />
                </div>
              </>
            ) : null}
          </div>
        </InfiniteScroll>
      </div>
    </AdminLayout>
  );
};
export default CircleExplore;
