/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import { Link } from "react-router-dom";
import { getBrandDetails } from "../../../../../services/WebsiteRequeries";
import LazyLoadImageComponent from "../../Components/LazyLoadImageComponent";
import { IoLocationSharp } from "react-icons/io5";
import BrandDetailsShimmer from "../../Components/Shimmers/BrandDetailsShimmer";
import Stories from "../../../../common/Stories/Stories";
import BrandDetailsResponsiveShimmer from "../../Components/Shimmers/BrandDetailsResponsiveShimmer";
import BrandCollections from "./BrandCollections";
import ProductContainer from "../Explore/ProductContainer";
import { QrOptionsContext } from "../../Provider/QrProvider";
const Brand = (props) => {
  const [brandData, setBrandData] = useState({});
  const [loading, setLoading] = useState(true);
  const { qrOptions, setOption } = useContext(QrOptionsContext);
  const qrOwnerId = new URLSearchParams(location.search).get("qrOwnerId");
  useEffect(() => {
    setLoading(true);
    getBrandDetails({
      id: props.match.params.id,
      ...(qrOwnerId ? { qrOwnerId: qrOwnerId } : {}),
    })
      .then((res) => {
        setLoading(false);
        setBrandData(res);
        setOption(res?.fields?.customizeLandingPageOptions);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);
  let qrStatus = !qrOptions?.qrOptionStatus;
  return (
    <Layout subMenu={[]}>
      <div className="w-full h-full sm:pl-0 pl-2">
        {qrOptions?.showHeaderFooter || qrStatus ? (
          <div className="w-full p-2 bg-secondaryLight mt-10">
            <div className="container">
              <ul className="  flex text-lg">
                <li className="mr-2">
                  <Link className="text-primary" to="/explore">
                    Explore
                  </Link>
                </li>
                /
                <li className="mx-2">
                  <Link className="text-primary" to="/explore/brands">
                    Brands
                  </Link>
                </li>
                /
                <li className="mx-2">
                  <span className="productTitle">
                    {brandData?.fields?.businessName}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        ) : null}

        {loading ? (
          <div className="w-full  flex justify-center items-center container">
            <div className="w-full sm:hidden block">
              <BrandDetailsResponsiveShimmer />
            </div>
            <div className="w-full sm:block hidden">
              <BrandDetailsShimmer />
            </div>
          </div>
        ) : qrOptions?.showBrandDetails || qrStatus ? (
          <div className="w-full container p-4 sm:p-0">
            <div className="relative w-full mt-24 ">
              <div className="w-48 h-48  border border-secondary rounded-full top-[-50px] left-20 overflow-hidden absolute">
                <LazyLoadImageComponent
                  src={brandData?.fields?.logo}
                  className="w-full h-full rounded-full border border-secondary"
                  alt=""
                />
              </div>
              <LazyLoadImageComponent
                src={brandData?.fields?.coverImage}
                alt=""
                className="w-full min-h-[200px] sm:min-h-[400px]"
              />
            </div>
            <div className="mt-5">
              {qrOptions?.showBrandTitle || qrStatus ? (
                <Link to={"/brand/" + brandData?.id}>
                  <h3 className="text-bold text-[30px]">
                    {brandData?.fields?.businessName}
                  </h3>
                </Link>
              ) : null}
              <div className="w-full  flex mt-[-11px] items-center">
                <span className="pr-2">
                  <IoLocationSharp size={14} />
                </span>
                <span className="text-[14px] ">
                  {brandData?.fields?.location?.city +
                    " , " +
                    brandData?.fields?.location?.country}
                </span>
              </div>
              <div className="w-full my-4">
                <p
                  className="w-full h-auto whitespace-pre-wrap "
                  style={{
                    color: "black !important",
                    fontSize: "16px !important",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: brandData?.fields?.description,
                  }}
                ></p>
              </div>
              {brandData?.fields?.tags ? (
                <div className="w-full mt-5 flex flex-wrap">
                  {Object?.keys(brandData?.fields?.tags)?.map((item) => (
                    <span
                      className="py-[3px] px-[10px] rounded-lg border text-[14px] my-[5px] mr-[10px] "
                      key={item}
                    >
                      {item}
                    </span>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        ) : !qrStatus && !qrOptions?.showBrandDetails ? (
          <div className="container">
            {qrOptions?.showBrandTitle ? (
              <Link to={"/brand/" + brandData?.id}>
                <h3 className="text-bold text-[30px]">
                  {brandData?.fields?.businessName}
                </h3>
              </Link>
            ) : null}
          </div>
        ) : null}

        <div
          className={`w-full container sm:px-0 px-5 font-bold ${
            qrStatus && !qrOptions?.showBrandDetails ? "mt-32" : ""
          }   ml-auto`}
        >
          <rootip-component
            key={!qrOptions?.showHeaderFooter && !qrStatus}
            id={props.match.params.id}
            name="stories"
            type="brand"
            preview={true}
            poweredby={!qrOptions?.showHeaderFooter && !qrStatus}
          />
        </div>
        {qrOptions?.showProductsCollectionsList || qrStatus ? (
          <>
            <div className="w-full container mt-32 ">
              <BrandCollections {...props} />
            </div>

            <div className="w-full container mt-10">
              <ProductContainer brandId={props.match.params.id} />
            </div>
          </>
        ) : null}

        <div className="py-10"></div>
      </div>
      <style>
        {`
      .na-full-width{
        position: static !important;
        padding:5px
      }
      `}
      </style>
    </Layout>
  );
};

export default Brand;
