import React from "react";

import Ranking from "./Transparency/Ranking/Ranking";
import Thresholds from "./Transparency/Thresholds/Thresholds";
import TransparencySetting from "./Transparency/TransparencySetting";
import Triggers from "./Transparency/Triggers";

function TransparencyTab() {
  return (
    <>
      <div className="flex flex:row mb-2">
        <Ranking groupName="all" />
        <Thresholds groupName="all" />
      </div>
      <div className="flex flex:row mb-2">
        <Ranking groupName="brands" />
        <Thresholds groupName="brands" />
      </div>
      <div className="flex flex:row mb-2">
        <Ranking groupName="suppliers" />
        <Thresholds groupName="suppliers" />
      </div>
      <TransparencySetting />
      <Triggers />
    </>
  );
}

export default TransparencyTab;
