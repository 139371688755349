import React, { useContext, useEffect } from "react";
import { useMachine } from "@xstate/react";

import service from "@app/services/rootipAdminServices";

import fetchMachine from "@app/machines/fetchMachine";

import Context from "@app/context/RootipAdminContext";

import Metric from "./Metric/Metric";

function Metrics() {
  var {
    kpiTarget,
    setKpiTarget,
    brandsOnboarded,
    setBrandsOnboarded,
    suppliersSignedUp,
    setSuppliersSignedUp,
    b2cRegistrations,
    setB2cRegistrations,
  } = useContext(Context);

  var [targetsState, targetsSend] = useMachine(fetchMachine, {
    actions: {
      goFetch: () => {
        service
          .kpiTarget()
          .then((data) => {
            setKpiTarget(data);
            targetsSend("RESOLVE");
          })
          .catch((error) => {
            setKpiTarget(error);
            targetsSend("REJECT");
          });
      },
    },
  });

  useEffect(() => {
    targetsSend("FETCH");
  }, []);
  return (
    <>
      <h3>Brand Metrics</h3>
      <div className="metrics-containter">
        <Metric
          data={brandsOnboarded}
          setData={setBrandsOnboarded}
          service={service.brandsOnboarded}
          label="Brands Onboarded"
          target={
            targetsState.matches("pending") ? "..." : kpiTarget?.brandsOnboarded
          }
        />
        <Metric
          data={suppliersSignedUp}
          setData={setSuppliersSignedUp}
          service={service.suppliersSignedUp}
          label="Suppliers Signed Up"
          target={
            targetsState.matches("pending") ? "..." : kpiTarget?.suppliersSignup
          }
        />
        <Metric
          data={b2cRegistrations}
          setData={setB2cRegistrations}
          service={service.b2cRegistrations}
          label="B2C Registrations"
          target={
            targetsState.matches("pending") ? "..." : kpiTarget?.b2cRegistration
          }
        />
      </div>
      <style jsx>{`
        .metrics-containter {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 2rem;
          margin-top: 1rem;
          margin-bottom: 2rem;
        }
      `}</style>
    </>
  );
}

export default Metrics;
