import React from "react";
import ContentLoader from "react-content-loader";

function ChaptersShimmer() {
  return (
    <>
      <div className="no-wrap min-w-136">
        <ContentLoader viewBox="0 0 100 30">
          <rect x="0" y="0" rx="15" width="90" height="30" />
        </ContentLoader>
      </div>
    </>
  );
}

export default ChaptersShimmer;
