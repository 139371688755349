import React, { useContext, useEffect, useState } from "react";
import Context from "@app/context/StoriesContext";
import Button from "@common/Button/Button";
import HrMenu from "@common/HrMenu/HrMenu";
import ChapterPills from "./ChapterPills/ChapterPills";
import ChaptersShimmer from "./ChaptersShimmer/ChaptersShimmer";

function StoryChapters() {
  var {
    chapters,
    activeChapter,
    setActiveChapter,
    chaptersState,
    customStyles,
    initialDataState,
    engagementSend,
    preview,
    textColor,
    buttonColor,
  } = useContext(Context);

  var [activeCond, setActiveCond] = useState(null);

  return (
    <>
      <div>
        {chaptersState.matches("idle") ||
        chaptersState.matches("pending") ||
        initialDataState.matches("idle") ||
        initialDataState.matches("pending") ? (
          <div className="py-2 flex overflow-hidden">
            <ChaptersShimmer />
            <ChaptersShimmer />
            <ChaptersShimmer />
            <ChaptersShimmer />
            <ChaptersShimmer />
            <ChaptersShimmer />
            <ChaptersShimmer />
            <ChaptersShimmer />
          </div>
        ) : (
          <>
            {"ontouchstart" in document.documentElement ? (
              <ChapterPills customStyles={customStyles} />
            ) : (
              <HrMenu
                setActiveCond={setActiveCond}
                engagementSend={engagementSend}
              >
                {chaptersState.matches("resolved") &&
                  chapters.map((item, index) => (
                    <li key={index} className={`w-max list-space no-wrap`}>
                      <Button
                        type={
                          item.chapterName == activeChapter.chapterName
                            ? "chapter active"
                            : "chapter"
                        }
                        action={() => {
                          setActiveChapter(item);
                          engagementSend("SELECT_CHAPTERS");
                        }}
                        styles={
                          item.chapterName == activeChapter.chapterName
                            ? {
                                backgroundColor: buttonColor,
                                borderColor: buttonColor,
                              }
                            : {}
                        }
                      >
                        <img
                          src={item.icon}
                          alt={item.chapterName + "_icon"}
                          className="mr-1 "
                          style={
                            item.chapterName == activeChapter.chapterName
                              ? {
                                  filter: "brightness(0) invert(1)",
                                  marginRight: "4px",
                                }
                              : { marginRight: "4px" }
                          }
                        ></img>
                        <span className="no-wrap mx-2">
                          {item.chapterName +
                            String.fromCharCode(160) +
                            String.fromCharCode(40) +
                            item.count +
                            String.fromCharCode(41)}
                        </span>
                      </Button>
                    </li>
                  ))}
              </HrMenu>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default StoryChapters;
