import React, { useState, useEffect, useContext, useRef } from "react";
import services from "@app/services/adminServices";
import fetchMachine from "@app/machines/fetchMachine";
import { useMachine } from "@xstate/react";
import { ROOT_ID } from "@app/constants/constants";
import Swal from "sweetalert2";
import ProgressBar from "@ramonak/react-progress-bar";
import FileUpload from "./common/FileUpload";
import importProductsUtil from "../../../utils/importProductsUtil";
import AppContext from "@app/context/AppContext";

function ImportProducts({ brandId }) {
  var { idToken } = useContext(AppContext);

  var [isFinished, setIsFinished] = useState();
  var [isFinished_col, setIsFinished_col] = useState(false);

  var [response, setResponse] = useState();
  var [response_col, setResponse_col] = useState();

  var [list, setList] = useState([]);
  var [list_col, setList_col] = useState([]);

  var [jlist, setJList] = useState([]);
  var [jlist_col, setJList_col] = useState([]);

  var [platform, setPlatform] = useState("");

  var [sumSends, setSumSends] = useState();
  var [sumSends_col, setSumSends_col] = useState(0);

  var [percent, setPercent] = useState(0);
  var [percent_col, setPercent_col] = useState(0);
  var [percent_sh, setPercent_sh] = useState(0);
  var [percent_ss, setPercent_ss] = useState(0);

  var [disabled, setDisabled] = useState(true);
  var [disabled_sh, setDisabled_sh] = useState(true);
  var [disabled_ss, setDisabled_ss] = useState(true);

  const fileInput = useRef();
  var [filename, setFilename] = useState("Please select CSV file");
  var [filename_sh, setFilename_sh] = useState("Please select CSV file");
  var [filename_ss, setFilename_ss] = useState("Please select CSV file");

  var [total, setTotal] = useState(0);
  var [total_col, setTotal_col] = useState(0);

  var [bulk_colState, BulkSend_col] = useMachine(fetchMachine, {
    actions: {
      goFetch: () => {
        services
          .importCollections(list_col, brandId, idToken)
          .then((e) => {
            BulkSend("RESOLVE");

            setSumSends_col((sumSends_col) => sumSends_col + list_col.length);
            setResponse_col(e);
          })
          .catch((error) => {
            console.log(error);
            BulkSend("REJECT");
          });
      },
    },
  });
  var [bulkState, BulkSend] = useMachine(fetchMachine, {
    actions: {
      goFetch: () => {
        services
          .bulkImportProducts(list, brandId, true, idToken)
          .then((e) => {
            BulkSend("RESOLVE");

            setSumSends((sumSends) => sumSends + list.length);

            setResponse(e);
          })
          .catch((error) => {
            console.log(error);
            BulkSend("REJECT");
          });
      },
    },
  });

  //both
  useEffect(() => {
    if (
      isFinished === true &&
      isFinished_col === false &&
      jlist_col.length > 0 &&
      false
    ) {
      UploadFile_Collection();
    }
  }, [percent, isFinished, isFinished_col]);

  var [page_size, setPage_size] = useState(10);
  var [pages, setPages] = useState();
  var [offset, setOffset] = useState();
  var [first, setFirst] = useState(0);
  var [sumRequest, setSumRequest] = useState(0);

  var [pagesCol, setPagesCol] = useState();
  var [offsetCol, setOffsetCol] = useState();
  var [firstCol, setFirstCol] = useState(0);
  var [sumRequestCol, setSumRequestCol] = useState(0);

  var [allowDraft, setAllowDraft] = useState(false);
  useEffect(() => {
    if (total > 0) {
      setPages(parseInt(total / page_size));
      setOffset(total - parseInt(total / page_size) * page_size);
    }

    if (total_col > 0) {
      setPagesCol(parseInt(total_col / page_size));
      setOffsetCol(total_col - parseInt(total_col / page_size) * page_size);
    }
  }, [total, total_col]);

  useEffect(() => {
    if (pages === 0 && offset === 0) {
      setIsFinished(true);
    }
    if (pagesCol === 0 && offsetCol === 0) {
      setIsFinished_col(true);
    }
  }, [pages, offset, pagesCol, offsetCol]);

  //product
  useEffect(() => {
    if (list.length > 0) {
      BulkSend("FETCH");
    }
  }, [list]);
  useEffect(() => {
    if (list_col.length > 0 && isFinished === true) {
      BulkSend_col("FETCH");
    }
  }, [list_col]);
  //product
  useEffect(() => {
    if (sumSends > sumRequest) {
      setSumRequest(sumSends);
    } else if (
      sumRequest <= sumSends + list.length &&
      sumRequest <= total &&
      sumRequest >= 0
    ) {
      setTimeout(() => {
        var c = Math.ceil((sumRequest * 100) / total);

        if (platform === "wordpress") {
          setPercent(c);
        } else if (platform === "shopify") {
          setPercent_sh(c);
        } else if (platform === "squarespace") {
          setPercent_ss(c);
        }
        setSumRequest((sumRequest) => sumRequest + 1);
      }, 1500);
    }
    console.log(sumRequest);
  }, [sumRequest, sumSends]);

  //Collection
  useEffect(() => {
    if (sumSends_col > sumRequestCol) {
      setSumRequestCol(sumSends_col);
    } else if (
      sumRequestCol <= sumSends_col + list_col.length &&
      sumRequestCol >= 0 &&
      sumRequestCol <= total_col
    ) {
      setTimeout(() => {
        var c = Math.ceil((sumRequestCol * 100) / total_col);

        if (platform === "wordpress") {
          setPercent_col(c);
        }
        setSumRequestCol((sumRequestCol) => sumRequestCol + 1);
      }, 2000);
    }
  }, [sumRequestCol, sumSends_col]);

  useEffect(() => {
    if (sumSends === total && isFinished === true) {
      // if (jlist_col.length > 0) {
      //   return;
      // } else
      if (response.type === "success") {
        Swal.fire({
          icon: "success",
          type: "success",
          title: "success!",
          text: response.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          type: "error",
          title: "oops !",
          text: response.message,
        });
      }
    } else if (sumSends > 0) {
      upload_p();
    }
  }, [sumSends]);

  //collection
  useEffect(() => {
    if (sumSends_col === total_col && isFinished_col === true) {
      if (response_col.type === "success") {
        Swal.fire({
          icon: "success",
          type: "success",
          title: "success!",
          text: response_col.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          type: "error",
          title: "oops !",
          text: response_col.message,
        });
      }
    } else if (sumSends_col > 0) {
      upload_c();
    }
  }, [sumSends_col]);

  const UploadFile_Collection = async () => {
    setDisabled_sh(true);
    setDisabled_ss(true);
    setDisabled(true);
    setSumSends_col(0);
    setPercent_col(0);
    upload_c();
  };

  const upload_c = async () => {
    ///
    if (pagesCol > 0) {
      setPagesCol(pagesCol - 1);
      setList_col(jlist_col.slice(firstCol, firstCol + page_size));
      setFirstCol(firstCol + page_size);
    } else if (offsetCol > 0) {
      var f = total_col - offsetCol;
      setList_col(jlist_col.slice(f, total_col));
      setOffsetCol(0);
    }
  };
  const SelectWordpressFile = (evt) => {
    setJList([]);
    setDisabled(true);
    setIsFinished(false);
    setPlatform("wordpress");
    setPercent(0);
    importProductsUtil.readWordpressExcell(
      evt,
      (filename, json, collectionJson) => {
        setFilename(filename);
        setJList(json);
        setTotal(json.length);
        setDisabled(false);
        console.log(json);
        setJList_col(collectionJson);
        setTotal_col(collectionJson.length);
        console.log(collectionJson);
      }
    );
  };

  const UploadFile_product = async () => {
    setDisabled_sh(true);
    setDisabled(true);
    setDisabled_ss(true);
    setSumSends(0);
    setPercent(0);
    setPercent_sh(0);
    setPercent_ss(0);
    setPercent_col(0);
    upload_p();
  };
  const upload_p = async () => {
    ///
    if (pages > 0) {
      setPages(pages - 1);
      setList(jlist.slice(first, first + page_size));
      setFirst(first + page_size);
    } else if (offset > 0) {
      var f = total - offset;
      setList(jlist.slice(f, total));
      setOffset(0);
    }
  };
  const SelectShopifyFile = async (evt) => {
    setJList([]);
    setDisabled_sh(true);
    setIsFinished(false);
    setPlatform("shopify");
    setPercent(0);

    setFilename_sh("Please select CSV file");
    if (evt)
      importProductsUtil.readShopifyExcell(
        evt,
        allowDraft,
        (filename, json) => {
          setFilename_sh(filename);
          setDisabled_sh(false);
          console.log(json);
          setJList(json);
          if (json.length < 10) {
            setPage_size(1);
          }
          setTotal(json.length);
        }
      );
  };

  useEffect(() => {
    setJList([]);
    setDisabled_sh(true);
    setIsFinished(false);
    setPlatform("shopify");
    setPercent(0);
    if (fileInput?.current) {
      fileInput.current.value = null;
    }
    setFilename_sh("Please select CSV file");
  }, [allowDraft]);

  const SelectSquareSpaceFile = async (evt) => {
    setJList([]);
    setDisabled_ss(true);
    setIsFinished(false);
    setPlatform("squarespace");
    setPercent_ss(0);

    importProductsUtil.readSquareSpaceExcell(evt, (filename, json) => {
      setFilename_ss(filename);
      debugger;
      setDisabled_ss(false);
      console.log(json);
      setJList(json);
      setTotal(json.length);
    });
  };
  return (
    <>
      <form className="block items-center w-100 text-14px mb-4">
        <div className="shrink-0 mb-2">
          <img
            width="150"
            src={ROOT_ID + "/sharedlayout/img/how-to/shopify.jpg"}
            alt="WordPress"
          />
        </div>

        <div className="flex items-center mt-2 mb-2">
          <label className="pt-1 mr-3">Import draft products too</label>
          <label className="switch ">
            <input
              type="checkbox"
              id="allowDraft"
              onChange={(e) => {
                setAllowDraft(e.target.checked);
              }}
            />
            <span className="slider round"></span>
          </label>
        </div>

        <FileUpload
          refer={fileInput}
          source="shopify"
          id="shopifyFile"
          onChanged={SelectShopifyFile}
          filename={filename_sh}
          disabled={disabled_sh}
          UploadFile={UploadFile_product}
        ></FileUpload>
        <br />
        {percent_sh > 0 && (
          <div className="w-100 text-14px pt-4 ">
            Products:{sumSends} of {total}
            <ProgressBar
              bgColor="#00a79e"
              baseBgColor="#e0e0de"
              completed={percent_sh}
            />
          </div>
        )}
      </form>

      <form className="block items-center w-100 text-14px pt-7  mb-4">
        <div className="shrink-0  mb-2">
          <img
            width="150"
            src={ROOT_ID + "/sharedlayout/img/how-to/wp.jpg"}
            alt="WordPress"
          />
        </div>
        <FileUpload
          id="worpPressFile"
          source="wordpress"
          onChanged={SelectWordpressFile}
          filename={filename}
          disabled={disabled}
          UploadFile={UploadFile_product}
        ></FileUpload>
        <br />
        {percent > 0 && (
          <div className="w-100 text-14px pt-4 mb-4">
            Products:{sumSends} of {total}
            <ProgressBar
              bgColor="#00a79e"
              baseBgColor="#e0e0de"
              completed={percent}
            />
          </div>
        )}
        {percent_col > 0 && (
          <div className="w-100 text-14px pt-4 mb-4">
            Collection:{sumSends_col} of {total_col}
            <ProgressBar
              bgColor="#00a79e"
              baseBgColor="#e0e0de"
              completed={percent_col}
            />
          </div>
        )}
      </form>

      <form className="block items-center w-100 text-14px pt-7 mb-4">
        <div className="shrink-0 mb-2">
          <img
            width="150"
            src={ROOT_ID + "/sharedlayout/img/how-to/squareSpace.jpg"}
            alt="squareSpace"
          />
        </div>
        <FileUpload
          id="squareSpaceFile"
          source="squareSpace"
          onChanged={SelectSquareSpaceFile}
          filename={filename_ss}
          disabled={disabled_ss}
          UploadFile={UploadFile_product}
        ></FileUpload>
        <br />
        {percent_ss > 0 && (
          <div className="w-100 text-14px pt-4 ">
            Products:{sumSends} of {total}
            <ProgressBar
              bgColor="#00a79e"
              baseBgColor="#e0e0de"
              completed={percent_ss}
            />
          </div>
        )}
      </form>
    </>
  );
}

export default ImportProducts;
