import React, { useState, useContext, useEffect, useRef } from "react";
import Modal from "@common/Modal/Modal";
import "leaflet/dist/leaflet.css";
import Button from "@common/Button/Button";
import { FaTimes } from "@react-icons";
import LoaderIcon from "@common/LoaderIcon/LoaderIcon";
import MapContext from "../../../../../../context/MapContext";
import FormInput from "./FormInput/FormInput";
import Swal from "sweetalert2";
import usePrevious from "../../../../../../hooks/usePrevious";

function AddNode({ visible, outerCb, linkType, node, edit }) {
  var {
    brandId,
    addNodeSend,
    addNodeState,
    getNodeState,
    nodeDetails,
    updateNodeSend,
    updateNodeState,
    widgetName,
    brandTypes,
    brandTypesState,
    linkRequestSend,
    linkRequestState,
  } = useContext(MapContext);

  useEffect(() => {
    setBusinessName("");
    setLogo("");
    setCover("");
    setLocation({
      city: "London",
      country: "United Kingdom",
      lat: "51.505",
      lng: "-0.09",
    });
    setDescription("");
    setBrandType({});
    setWebsite("");
  }, [visible]);

  var [suggestedNode, setSuggestedNode] = useState(null);
  var [businessName, setBusinessName] = useState("");
  var [logo, setLogo] = useState("");
  var [cover, setCover] = useState("");
  var [location, setLocation] = useState({
    city: "London",
    country: "United Kingdom",
    lat: "51.505",
    lng: "-0.09",
  });
  var [description, setDescription] = useState("");
  var [brandType, setBrandType] = useState({});
  var [website, setWebsite] = useState("");
  var [suggestionQuery, setSuggestionQuery] = useState("");

  useEffect(() => {
    if (edit) {
      setBusinessName(nodeDetails.businessName);
      setLocation(nodeDetails.location);
      setLogo(nodeDetails.logo);
      setCover(nodeDetails.coverImage);
      setDescription(nodeDetails.description);
      setBrandType(nodeDetails.brandType);
      setWebsite(nodeDetails.website);
    }
  }, [nodeDetails]);

  useEffect(() => {
    if (suggestedNode) {
      setBusinessName(suggestedNode.fields.businessName);
      setLocation(suggestedNode.fields.location);
      setLogo(suggestedNode.fields.logo);
      setCover(suggestedNode.fields.coverImage);
      setDescription(suggestedNode.fields.description);
      setBrandType(suggestedNode.fields.brandType);
      setWebsite(suggestedNode.fields.website);
    }
  }, [suggestedNode]);

  function handleSubmit(e) {
    e.preventDefault();
    var formData = new FormData(e.target);
    var data = Object.fromEntries(formData.entries());
    if (suggestedNode) {
      linkRequestSend({
        type: "FETCH",
        payload: {
          clientId:
            linkType == "req_SupplierLink"
              ? node.id
              : linkType == "req_ClientLink"
              ? suggestedNode.id
              : null,
          supplierId:
            linkType == "req_SupplierLink"
              ? suggestedNode.id
              : linkType == "req_ClientLink"
              ? node.id
              : null,
          action: "add",
          brandId: brandId,
          host: "AddNode",
        },
      });
      return;
    }
    if (edit || !suggestedNode) {
      if (
        !data.businessName ||
        !data.logo ||
        !data.city ||
        !data.country ||
        !data.lat ||
        !data.lng ||
        !data.cover ||
        !data.description ||
        JSON.stringify(data.brandType) === '"{}"'
      ) {
        Swal.fire({
          title: "Please fill out all required fields!",
          icon: "warning",
          confirmButtonText: `OK`,
          confirmButtonColor: "#00a79e",
        });
        return;
      }
      var payload = {
        businessname: data.businessName,
        logo: data.logo,
        location: {
          city: data.city,
          country: data.country,
          lat: data.lat,
          lng: data.lng,
        },
        description: data.description,
        coverImage: data.cover,
        website: data.website,
        brandType: JSON.parse(data.brandType),
        brandId: brandId,
        linkType: linkType,
        nodeId: node.id,
        logoLowRes: data.logo,
      };
    }

    if (edit) {
      updateNodeSend({
        type: "FETCH",
        payload: payload,
      });
    }
    if (!edit && !suggestedNode) {
      addNodeSend({
        type: "FETCH",
        payload: payload,
      });
    }
  }

  var prevSuggestedNode = usePrevious(suggestedNode);

  useEffect(() => {
    if (prevSuggestedNode && !suggestedNode) {
      setBusinessName(prevSuggestedNode.fields.businessName);
      setLogo("");
      setCover("");
      setLocation({
        city: "London",
        country: "United Kingdom",
        lat: "51.505",
        lng: "-0.09",
      });
      setDescription("");
      setBrandType({});
      setWebsite("");
    }
  }, [suggestedNode]);

  if (
    (edit &&
      (getNodeState.matches("pending") || getNodeState.matches("idle"))) ||
    addNodeState.matches("pending") ||
    updateNodeState.matches("pending") ||
    linkRequestState.matches("pending")
  ) {
    return (
      <Modal
        visible={visible}
        outerCb={() => {
          outerCb();
          setBusinessName("");
          setLogo("");
          setCover("");
          setLocation({
            city: "London",
            country: "United Kingdom",
            lat: "51.505",
            lng: "-0.09",
          });
          setDescription("");
          setBrandType({});
          setWebsite("");
          setSuggestedNode(null);
        }}
        width="w-600p"
        shadowDOM={widgetName}
        className="p-4 my-100p"
      >
        <form
          onSubmit={(e) => e.preventDefault()}
          className="flex flex-col items-start"
        >
          <div className="w-100 flex flex-col items-start">
            {/* <h2 className="mb-4">{edit && "Edit Details"}</h2> */}
            <LoaderIcon />
          </div>
        </form>
      </Modal>
    );
  }

  if ((edit && getNodeState.matches("resolved")) || !edit) {
    return (
      <Modal
        visible={visible}
        outerCb={() => {
          outerCb();
          setBusinessName("");
          setLogo("");
          setCover("");
          setLocation({
            city: "London",
            country: "United Kingdom",
            lat: "51.505",
            lng: "-0.09",
          });
          setDescription("");
          setBrandType({});
          setWebsite("");
          setSuggestedNode(null);
        }}
        width="w-600p"
        shadowDOM={widgetName}
        className="p-4 my-100p"
      >
        <form className="flex flex-col items-start" onSubmit={handleSubmit}>
          <div className="w-100 flex items-start">
            <h2 className="mb-4">
              {!edit && linkType == "req_SupplierLink" && "Add Supplier"}
              {!edit && linkType == "req_ClientLink" && "Add Client"}
              {edit && "Edit Details"}
            </h2>
            <Button
              type="link"
              action={() => {
                outerCb();
              }}
              styles={{
                marginLeft: "auto",
              }}
            >
              <FaTimes size="32" />
            </Button>
          </div>
          {edit ? (
            <FormInput
              type="text"
              label="Business Name"
              name="businessName"
              init={businessName}
              required
              visible
              edit={edit}
            />
          ) : (
            <FormInput
              type="combo"
              label="Business Name"
              name="businessName"
              init={businessName}
              required
              visible
              edit={edit}
              setSuggestedNode={setSuggestedNode}
              suggestedNode={suggestedNode ? true : false}
              setSuggestionQuery={setSuggestionQuery}
              brandId={brandId}
            />
          )}
          <FormInput
            type="file"
            label="Business Logo"
            name="logo"
            init={logo}
            required
            visible
            edit={edit}
            readOnly={suggestedNode ? true : false}
          />
          <FormInput
            type="file"
            label="Business Cover"
            name="cover"
            init={cover}
            required
            visible
            edit={edit}
            readOnly={suggestedNode ? true : false}
          />
          <FormInput
            type="map"
            label="Location"
            name="map"
            init={location}
            required
            visible
            edit={edit}
            readOnly={suggestedNode ? true : false}
          />
          <FormInput
            type="textarea"
            label="Business Description"
            name="description"
            init={description}
            required
            visible
            edit={edit}
            readOnly={suggestedNode ? true : false}
          />
          <FormInput
            type="checkbox"
            label="Business Type"
            name="brandType"
            init={brandType}
            required
            visible
            edit={edit}
            keys={brandTypes}
            keysState={brandTypesState}
            readOnly={suggestedNode ? true : false}
          />
          <FormInput
            type="text"
            label="Website"
            name="website"
            init={website}
            visible
            edit={edit}
            readOnly={suggestedNode ? true : false}
          />
          <div className="flex justify-end w-100">
            <button
              type="submit"
              className="bg-primary rounded-1 px-1 text-light border border-primary"
            >
              {edit ? "Apply" : "Add"}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

export default AddNode;
