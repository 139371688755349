import apiClient from "./apiClient";

function getPublishkey() {
  return apiClient("base").post("/getPublishkey");
}

function payTip(credentials) {
  return apiClient("base").post("/payTip", credentials);
}

function getAllStripeCountries() {
  return apiClient("base").post("/getAllStripeCountries");
}

export default {
  getPublishkey,
  payTip,
  getAllStripeCountries,
};
