import { number } from "prop-types";
import React, { useMemo, useState, useEffect, useRef } from "react";
import StoryChaptersChild from "./StoryChaptersChild";
import PeopleChild from "./PeopleChild";
function StoryChaptersTransparency({ storyChaptersPt, setStoryChaptersPt }) {
  return storyChaptersPt ? (
    storyChaptersPt.map((item, index1) => {
      if (item.chapterName != "People") {
        return (
          <StoryChaptersChild
            key={index1}
            item_index={index1}
            setStoryChaptersPt={setStoryChaptersPt}
            storyChaptersPt={storyChaptersPt}
            storyChaptersItem={item}
          ></StoryChaptersChild>
        );
      } else {
        return (
          <>
            <PeopleChild
              key={index1}
              item_index={index1}
              setStoryChaptersPt={setStoryChaptersPt}
              storyChaptersPt={storyChaptersPt}
              storyChaptersItem={item}
            ></PeopleChild>
          </>
        );
      }
    })
  ) : (
    <div></div>
  );
}
export default StoryChaptersTransparency;
