/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-key */
import React, { createContext, useContext, useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { ROOT_ID } from "../../../../constants/constants";
import logoPng from "@app/assets/images/logo.png";
import { useLocation } from "react-router";
import { QrOptionsContext } from "../Provider/QrProvider";

const Layout = ({ children, subMenu = [] }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);
  const [navbarLinks, setNavbarLinks] = useState([]);
  useEffect(() => {
    let links = [
      {
        title: "For Businesses",
        href: "/index.html",
        active: false,
      },
      {
        title: "For Customers",
        href: "/for-customer.html",
        active: false,
      },
      {
        title: "Explore",
        href: "/app/explore",
        active: false,
      },
      {
        title: "Shared Vision",
        href: "/share-vision.html",
        active: false,
      },
      {
        title: "Blog",
        href: "/rootip-blog.html",
        active: false,
      },
    ];
    let path = location.pathname.split("/")[1];
    let newLinks = links.map((link) => {
      let isActive = link.href.split("/").includes(path);
      return { ...link, active: isActive };
    });
    setNavbarLinks(newLinks);
  }, [location]);
  const { qrOptions } = useContext(QrOptionsContext ?? {});

  return (
    <>
      {qrOptions?.showHeaderFooter || !qrOptions?.qrOptionStatus ? (
        <>
          <Header />
          <div
            className=" sm:block hidden sticky top-0  bg-[#ffffffef] "
            style={{ zIndex: 9999 }}
            id={"menu_header"}
            sticky-change={(e) => console.log(e)}
          >
            {/* lg menu */}
            <div className="   container">
              <div className="flex justify-between items-center relative">
                <div className=" w-fit relative   left-0">
                  {/* logo */}
                  <a className="tt-logo tt-logo-alignment mt-4" href="/">
                    <img
                      src={ROOT_ID + logoPng}
                      alt=""
                      className="w-[84px] h-[20px]"
                      data-was-processed="true"
                    />
                  </a>
                  {/* /logo */}
                </div>
                <nav className="w-full flex-col items-center flex justify-center px-20  text-[16px] font-bold">
                  <ul className=" flex   justify-center h-26 border-b  w-full">
                    {navbarLinks.map((item) => (
                      <li
                        key={item.href}
                        className={`p-5   px-7  transition-all ${
                          item.active
                            ? "border-primary text-primary border-primary border-b"
                            : "hover:text-primaryLight"
                        }`}
                      >
                        <a href={item.href}>{item.title}</a>
                      </li>
                    ))}
                  </ul>
                  <ul className=" flex justify-center mt-2   w-full  transition-all">
                    {subMenu.map((item) => (
                      <li
                        className={`p-5  px-7  `}
                        onClick={item.onClick}
                        key={item.id ?? item.href}
                      >
                        <span
                          className={`nav-link cursor-pointer   ${
                            item.active ? "text-primary" : "text-black "
                          }`}
                          id="productExplore"
                        >
                          {item.title}
                        </span>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            </div>
            {/* /lg-menu  */}
          </div>
        </>
      ) : null}

      <div className="w-full h-full">{children}</div>

      {qrOptions?.showHeaderFooter || !qrOptions?.qrOptionStatus ? (
        <Footer />
      ) : null}
    </>
  );
};

export default Layout;
