import { Machine } from "xstate";

const videoMachime = Machine({
  id: "video",
  initial: "idle",
  states: {
    idle: {
      on: {
        OPEN: {
          target: "loading",
        },
      },
    },
    loading: {
      on: {
        RESOLVE: {
          target: "resolved",
        },
        REJECT: {
          target: "rejected",
        },
      },
    },
    resolved: {
      on: {
        CLOSE: {
          target: "idle",
        },
      },
    },
    rejected: {
      on: {
        CLOSE: {
          target: "idle",
        },
      },
    },
  },
});

export default videoMachime;
