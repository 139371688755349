import React, { useMemo, useState, useEffect, useRef } from "react";

function PeopleChild({
  storyChaptersItem,
  item_index,
  setStoryChaptersPt,
  storyChaptersPt,
}) {
  const [inputFields, setInputFields] = useState([
    {
      custom_point: storyChaptersItem.custom_point,
      employee_point: storyChaptersItem.employee_point,
      group_point: storyChaptersItem.group_point,
      maxScore: storyChaptersItem.maxScore,
    },
  ]);

  const handleFormChange = (index, event) => {
    event.preventDefault();
    let data = [...inputFields];
    data[index][event.target.name] = Number(event.target.value);
    setInputFields(data);
    storyChaptersPt[item_index][event.target.name] = Number(event.target.value);
    setStoryChaptersPt(storyChaptersPt);
  };

  return storyChaptersItem ? (
    <>
      <div className=" info-card ">
        {inputFields.map((input, index) => {
          return (
            <>
              <label className="ml-2  font-weight-bold">
                {storyChaptersItem.chapterName}
              </label>
              <div key={index} className="flex md:flex-row md:items-center">
                <div className="flex md:flex-col  w-25  pr-1">
                  <label className="text-12px">Custom Story Point</label>

                  <input
                    className="w-100 p-2 border"
                    name="custom_point"
                    type="number"
                    placeholder="Custom point"
                    value={input.custom_point}
                    onChange={(event) => handleFormChange(index, event)}
                  />
                </div>
                <div className="flex md:flex-col  w-25 pr-1">
                  <label className="text-12px">Employee Story Point </label>

                  <input
                    className="w-100 p-2 border"
                    name="employee_point"
                    type="number"
                    placeholder="employee point"
                    value={input.employee_point}
                    onChange={(event) => handleFormChange(index, event)}
                  />
                </div>
                <div className="flex md:flex-col  w-25 pr-1">
                  <label className="text-12px">Group Story Point</label>

                  <input
                    className="w-100 p-2 border"
                    name="group_point"
                    type="number"
                    placeholder="group point"
                    value={input.group_point}
                    onChange={(event) => handleFormChange(index, event)}
                  />
                </div>
                <div className="flex md:flex-col  w-25 pr-1">
                  <label className="text-12px">Max Score</label>

                  <input
                    className="w-100 p-2 border"
                    name="maxScore"
                    type="number"
                    placeholder="maxScore"
                    value={input.maxScore}
                    onChange={(event) => handleFormChange(index, event)}
                  />
                </div>
              </div>
            </>
          );
        })}
      </div>
      <style jsx>{`
      .info-card {
        margin: 5px;
        padding-top: 4px;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
          rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        border-radius: var(--border-radius-general);
        background-color: var(--color-light);

      `}</style>
    </>
  ) : (
    <div>hello2</div>
  );
}
export default PeopleChild;
