/* eslint-disable no-useless-escape */
import { useState } from "react";
const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const useValidation = () => {
  const [errors, setErrors] = useState([]);
  const ErrorsHandler = ({ key, message, status }) => {
    let others = errors.filter((item) => item.key != key);
    if (status) {
      setErrors([...others, { status, message, key }]);
    } else if (!status) {
      setErrors(others);
    }
  };

  const validator = ({ rule, value, message }) => {
    switch (rule) {
      case "email":
        if (String(value).toLowerCase().match(emailRegex)) {
          return {
            status: false,
            value: value,
            message: null,
          };
        } else {
          return {
            status: true,
            value: value,
            message: message,
          };
        }

      case "password":
        if (String(value).trim().length >= 6) {
          return {
            status: false,
            value: value,
            message: null,
          };
        } else {
          return {
            status: true,
            value: value,
            message: message,
          };
        }
    }
  };

  const ValidationHandler = async ({ rule, message, value, key }) => {
    let validate =   validator({
      rule: rule,
      value: value,
      message: message,
    });
      ErrorsHandler({
      key: key,
      value: validate.value,
      status: validate.status,
      message: validate.message,
    });
    return { ...validate, key };
  };

  return {
    validator,
    errors,
    resetErrors: setErrors,
    setErrors: ErrorsHandler,
    ValidationHandler,
    getError:(key)=> errors.find(item=>item.key==key)
  };
};

export default useValidation;
