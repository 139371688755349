import React, { useRef, useState, useEffect } from "react";
import Stories from "../Stories/Stories";
import { ROOT_ID } from "@app/constants/constants";
import { useMachine } from "@xstate/react";
import fetchMachine from "../../../machines/fetchMachine";
import service from "@app/services/storiesServices";
import Swal from "sweetalert2";
import LoaderIcon from "@common/LoaderIcon/LoaderIcon";
function StoriesPreview({
  widgetId,
  type,
  preview,
  widgetName,
  ownerId,
  isRetailer,
  widgetCustomizationsApllyToAll,
}) {
  const copyCode = useRef();

  var [previewCustomStyles, setPreviewCustomStyles] = useState(null);
  var [previewBrandId, setPreviewBrandId] = useState(null);
  var [previewWidgetId, setPreviewWidgetId] = useState(null);
  var [textColor, setTextColor] = useState(null);
  var [buttonColor, setButtonColor] = useState(null);
  var [fontWeight, setFontWeight] = useState(null);
  var [isShowStories, setIsShowStories] = useState(null);
  var [isShowMap, setIsShowMap] = useState(null);
  var [isShowTracker, setIsShowTracker] = useState(null);
  var [defaultStyles, setDefaultStyles] = useState(false);

  var [isApplyToAll, setIsApplyToAll] = useState(
    widgetCustomizationsApllyToAll
  );

  var [updateState, updateSend] = useMachine(fetchMachine, {
    actions: {
      goFetch: () => {
        service
          .updateWebWidgetCustomize(
            ownerId,
            previewBrandId,
            textColor,
            buttonColor,
            buttonColor,
            fontWeight,
            isShowStories,
            isShowTracker,
            isShowMap,
            isApplyToAll
          )
          .then((res) => {
            updateSend("RESOLVE");
          });
      },
    },
  });

  useEffect(() => {
    if (defaultStyles) {
      updateSend("FETCH");
    }
  }, [defaultStyles]);

  useEffect(() => {
    updateState.matches("resolved") &&
      Swal.fire({
        icon: "success",
        type: "success",
        title: "Success!",
        text: "Widget customisation has been saved and applied successfully.",
        confirmButtonClass: "btn btn-confirm",
      });
  }, [updateState]);

  const handleCopyCode = () => {
    let codeDiv = copyCode.current;
    if (document.selection) {
      // IE
      var range = document.body.createTextRange();
      range.moveToElementText(codeDiv);
      range.select();
    } else if (window.getSelection) {
      // other browsers
      var range = document.createRange();
      range.selectNode(codeDiv);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
    }
    document.execCommand("copy");
  };

  var [customFlag, setCustomFlag] = useState(false);
  useEffect(() => {
    if (
      (previewCustomStyles?.isShowStories ||
        previewCustomStyles?.isShowTracker ||
        previewCustomStyles?.isShowMap) &&
      !customFlag
    ) {
      setTextColor(previewCustomStyles.textColour);
      setButtonColor(previewCustomStyles.buttonColour);
      setFontWeight(previewCustomStyles.fontWeight);
      setIsShowStories(previewCustomStyles.isShowStories);
      setIsShowMap(previewCustomStyles.isShowMap);
      setIsShowTracker(previewCustomStyles.isShowTracker);
      setCustomFlag(true);
    }
  }, [previewCustomStyles]);

  var [customStyleDisabled, setcustomStyleDisabled] = useState(false);
  useEffect(() => {
    if (ownerId) {
      setCustomFlag(false);
      if (previewBrandId && ownerId !== previewBrandId) {
        setcustomStyleDisabled(true);
      } else {
        setcustomStyleDisabled(false);
      }
    }
  }, [ownerId]);

  useEffect(() => {
    setPreviewCustomStyles({
      textColour: textColor,
      buttonColour: buttonColor,
      fontWeight,
      isShowStories,
      isShowMap,
      isShowTracker,
    });
  }, [
    textColor,
    buttonColor,
    fontWeight,
    isShowStories,
    isShowMap,
    isShowTracker,
  ]);

  return (
    <div>
      {updateState.matches("pending") && (
        <div>
          <div className="overlay-bg"></div>
          <div className="loader-cont">
            <LoaderIcon width="100%" />
          </div>
        </div>
      )}
      <h4 className="font-weight-bold mb-3 text-18px text-dark">
        Preview (Widget 2.0)
      </h4>
      <div className="border">
        {
          <Stories
            ownerId={ownerId}
            widgetId={widgetId}
            type={type}
            preview={preview}
            widgetName={widgetName}
            setPreviewCustomStyles={setPreviewCustomStyles}
            setPreviewBrandId={setPreviewBrandId}
            setPreviewWidgetId={setPreviewWidgetId}
            previewCustomStyles={previewCustomStyles}
            defaultStyles={defaultStyles}
          />
        }
      </div>
      <h4 className="my-4 text-18px">
        {type && type[0].toUpperCase() + type.slice(1)} Stories Script (Widget
        2.0)
      </h4>
      <textarea
        ref={copyCode}
        readOnly
        rows={3}
        value={`<rootip-component name="stories" id="${previewWidgetId}" type="${type}" ownerId="${ownerId}"></rootip-component>
<script src="${ROOT_ID}/app/widget_stories.bundle.js"></script>
<script src="${ROOT_ID}/app/stripe_elements.bundle.js"></script>`}
        className="w-100 rounded-1 border text-3 text-muted px-12px py-6px mb-3"
      ></textarea>
      <button
        type="button"
        className="primary rounded-1 text-14px px-12px py-6px mb-3"
        onClick={handleCopyCode}
      >
        Copy Code
      </button>
      <h4 className="text-18px">How To Embed Story Widget In Your Website:</h4>
      <div className="flex items-center mb-3">
        <a
          className="mr-3"
          rel="noreferrer"
          target="_blank"
          href="https://community.shopify.com/c/Shopify-Design/Iframe-Embed-Portfolio-into-site/td-p/387503"
        >
          <img
            width="150"
            src={ROOT_ID + "/sharedlayout/img/how-to/shopify.png"}
            alt="Shopify"
          />
        </a>
        <a
          className="mr-3"
          rel="noreferrer"
          target="_blank"
          href="https://support.squarespace.com/hc/en-us/articles/206543167"
        >
          <img
            width="150"
            src={ROOT_ID + "/sharedlayout/img/how-to/ss.png"}
            alt="SquareSpace"
          />
        </a>
        <a
          className="mr-3"
          rel="noreferrer"
          target="_blank"
          href="https://smartwp.com/wordpress-iframe"
        >
          <img
            width="150"
            src={ROOT_ID + "/sharedlayout/img/how-to/wp.png"}
            alt="WordPress"
          />
        </a>
      </div>
      <fieldset className="border-0 " disabled={customStyleDisabled}>
        <h4 className="text-18px mb-3">Widget Customisation</h4>
        <div className="flex mb-3">
          <div className="flex items-center mr-3">
            <label htmlFor="textColor" className="mr-1">
              Header and Footer
            </label>
            <div className="flex border">
              <input
                className="bg-transparent color-picker"
                type="color"
                id="textColor"
                value={textColor ? textColor : "#333333"}
                onChange={(e) => {
                  setTextColor(e.target.value);
                }}
              ></input>
              <input
                className="border-0"
                type="text"
                value={textColor ? textColor : "#333333"}
                onChange={(e) => {
                  setTextColor(e.target.value);
                }}
              ></input>
            </div>
          </div>
          <div className="flex items-center mr-3">
            <label htmlFor="buttonColor" className="mr-1">
              Button Colour
            </label>
            <div className="flex border">
              <input
                className="bg-transparent color-picker"
                type="color"
                id="buttonColor"
                value={buttonColor ? buttonColor : "#8DC53E"}
                onChange={(e) => {
                  setButtonColor(e.target.value);
                }}
              ></input>
              <input
                className="border-0"
                type="text"
                value={buttonColor ? buttonColor : "#8DC53E"}
                onChange={(e) => {
                  setButtonColor(e.target.value);
                }}
              ></input>
            </div>
          </div>
          <div className="flex items-center mr-3">
            <label htmlFor="buttonColor" className="mr-1">
              Font Weight
            </label>
            <div className="flex">
              <select
                id="fontWeight"
                className="p-1 border"
                aria-label="Font Weight Select"
                defaultValue={fontWeight}
              >
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="300">300</option>
                <option value="400">400</option>
                <option value="500">500</option>
                <option value="600">600</option>
                <option value="700">700</option>
                <option value="600">800</option>
                <option value="700">900</option>
              </select>
            </div>
          </div>
        </div>
        <div className="flex mb-3">
          <div className="flex items-center mr-3">
            <label htmlFor="isShowMap" className="mr-1">
              Show Stories
            </label>
            <label className="switch">
              <input
                type="checkbox"
                id="isShowStories"
                checked={isShowStories}
                onChange={(e) => {
                  if (!isShowMap && !isShowTracker) {
                    Swal.fire({
                      icon: "warning",
                      type: "warning",
                      title: "oops!",
                      text:
                        "At least one of the sections (stories, tracker, map) must be enabled for widget.",
                    });
                    return;
                  }
                  setIsShowStories(e.target.checked);
                }}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="flex items-center mr-3">
            <label htmlFor="isShowMap" className="mr-1">
              Show Map
            </label>
            <label className="switch">
              <input
                type="checkbox"
                id="isShowMap"
                checked={isShowMap}
                onChange={(e) => {
                  if (!isShowStories && !isShowTracker) {
                    Swal.fire({
                      icon: "warning",
                      type: "warning",
                      title: "oops!",
                      text:
                        "At least one of the sections (stories, tracker, map) must be enabled for widget.",
                    });
                    return;
                  }
                  setIsShowMap(e.target.checked);
                }}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="flex items-center mr-3">
            <label htmlFor="isShowTracker" className="mr-1">
              Show Tracker
            </label>
            <label className="switch">
              <input
                type="checkbox"
                id="isShowTracker"
                checked={isShowTracker}
                onChange={(e) => {
                  if (!isShowStories && !isShowMap) {
                    Swal.fire({
                      icon: "warning",
                      type: "warning",
                      title: "oops!",
                      text:
                        "At least one of the sections (stories, tracker) must be enabled for widget.",
                    });
                    return;
                  }
                  setIsShowTracker(e.target.checked);
                }}
              />
              <span className="slider round"></span>
            </label>
          </div>

          <div className={isRetailer ? "flex items-center " : "hidden"}>
            <label htmlFor="isApplyToAll" className="mr-1">
              Apply To All Brands
            </label>
            <label className="switch">
              <input
                type="checkbox"
                id="isApplyToAll"
                checked={isApplyToAll}
                onChange={(e) => {
                  setIsApplyToAll(e.target.checked);
                }}
              />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        <button
          type="button"
          className="primary text-14px rounded-1 px-12px py-6px mr-3"
          onClick={() => {
            updateSend("FETCH");
          }}
        >
          Save
        </button>
        <button
          type="button"
          className="primary text-14px rounded-1 px-12px py-6px"
          onClick={() => {
            setTextColor("");
            setButtonColor("");
            setFontWeight("400");
            setIsShowStories(true);
            setIsShowMap(false);
            setIsShowTracker(false);
            setDefaultStyles(true);
          }}
        >
          Restore Defaults
        </button>
      </fieldset>
    </div>
  );
}

export default StoriesPreview;
