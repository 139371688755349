import React, { useContext, useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import Context from "@app/context/StoriesContext";
import StoryCard from "./StoryCard/StoryCard";
import StoriesShimmer from "../StoriesShimmer/StoriesShimmer";
import Button from "@common/Button/Button";
import { FaChevronRight, FaChevronLeft } from "@react-icons";

function StoryCards() {
  var {
    stories,
    storiesState,
    chaptersState,
    containerWidth,
    initialDataState,
    engagementSend,
    chapters,
    type,
    host,
  } = useContext(Context);

  var [swipe, setSwipe] = useState(true);
  var [slickSettings, setSlickSettings] = useState(null);
  var storyCardsEl = useRef(null);
  var shimmersSlickSettings = {
    ...slickSettings,
    arrows: false,
  };

  useEffect(() => {
    if (containerWidth > 0) {
      if ("ontouchstart" in document.documentElement && containerWidth <= 600) {
        setSlickSettings((values) => ({
          ...values,
          swipe: swipe,
          infinite: false,
          speed: 500,
          slidesToScroll: 1,
          centerMode: true,
          arrows: false,
          afterChange: (index) => {
            engagementSend("SLIDE_STORIES");
          },
        }));
      } else {
        setSlickSettings((values) => ({
          ...values,
          swipe: swipe,
          infinite: false,
          speed: 500,
          slidesToScroll: 1,
          nextArrow: (
            <Button>
              <FaChevronRight size="32" color="var(--color-dark)" />
            </Button>
          ),
          prevArrow: (
            <Button>
              <FaChevronLeft size="32" color="var(--color-dark)" />
            </Button>
          ),
          centerMode: false,
          arrows: true,
          afterChange: (index) => {
            engagementSend("SLIDE_STORIES");
          },
        }));
      }
      if (containerWidth <= 300) {
        setSlickSettings((values) => ({
          ...values,
          slidesToShow: 1,
          centerPadding: "15px",
        }));
      } else if (containerWidth <= 400) {
        setSlickSettings((values) => ({
          ...values,
          slidesToShow: 1,
          centerPadding: "20px",
        }));
      } else if (containerWidth <= 450) {
        setSlickSettings((values) => ({
          ...values,
          slidesToShow: 1,
          centerPadding: "35px",
        }));
      } else if (containerWidth <= 600) {
        setSlickSettings((values) => ({
          ...values,
          slidesToShow: 1,
          centerPadding: "75px",
        }));
      } else if (containerWidth <= 1024) {
        setSlickSettings((values) => ({
          ...values,
          slidesToShow: 2,
        }));
      } else if (containerWidth > 1024) {
        setSlickSettings((values) => ({
          ...values,
          slidesToShow: 3,
        }));
      }
    }
  }, [containerWidth]);

  useEffect(() => {
    if (
      "ontouchstart" in document.documentElement &&
      storiesState.matches("resolved") &&
      storyCardsEl.current
    )
      if (containerWidth <= 600) {
        storyCardsEl.current
          .querySelector(".slick-track")
          .classList.add("mx-auto");
      } else {
        storyCardsEl.current
          .querySelector(".slick-track")
          .classList.remove("mx-auto");
      }
  }, [storiesState, containerWidth, storyCardsEl]);

  if (chaptersState.matches("resolved") && chapters.length == 0) {
    return (
      <>
        <p className="mb-3 text-3">
          There are no stories attached to this {type} to preview.
        </p>
      </>
    );
  }

  return (
    <>
      <div
        className={
          "my-4 h-min" +
          " " +
          `${
            "ontouchstart" in document.documentElement && containerWidth <= 600
              ? "mx-0"
              : containerWidth > 1200
              ? "mx-6"
              : "mx-4"
          }`
        }
        ref={storyCardsEl}
      >
        {chaptersState.matches("idle") ||
        storiesState.matches("pending") ||
        chaptersState.matches("pending") ||
        initialDataState.matches("pending") ? (
          <StoriesShimmer shimmersSlickSettings={shimmersSlickSettings} />
        ) : (
          <Slider {...slickSettings}>
            {storiesState.matches("resolved") &&
              stories.map((story) => (
                <StoryCard story={story} key={story.id} setSwipe={setSwipe} />
              ))}
          </Slider>
        )}
      </div>
      <style>
      {`
      .slick-list> div {
        margin-left:0;
        }
      
      `}
      </style>
    </>
  );
}

export default StoryCards;
