import apiClient from "./apiClient";

// Product Stories
function getStoryChapters(productId) {
  return apiClient("widgets").post("/getStoryChapters", { productId });
}

function getProductStories(chapterName, productId, brandId) {
  return apiClient("widgets").post("/getProductStories", {
    chapterName,
    productId,
    brandId,
  });
}

function getProductV3(id) {
  return apiClient("widgets").post("/getProductV3", { id, source: "web" });
}

// Brand Stories
function getBrandStoryChapters(brandId) {
  return apiClient("widgets").post("/getBrandStoryChapters", { brandId });
}

function getBrandStories(chapterName, brandId) {
  return apiClient("widgets").post("/getBrandStories", {
    chapterName,
    brandId,
  });
}

// Collection Stories
function getCollectionStoryChapters(collectionId) {
  return apiClient("widgets").post("/getCollectionStoryChapters", {
    collectionId,
  });
}

function getCollectionStories(brandId, collectionId, chapterName) {
  return apiClient("widgets").post("/getCollectionStories", {
    brandId,
    collectionId,
    chapterName,
  });
}

function getCollection(collectionId) {
  return apiClient("widgets").post("/getCollection_w", { id: collectionId });
}

function collectionWidgetEngagement(
  brandId,
  collectionId,
  isFirstEngagement,
  eventType
) {
  return apiClient("widgets").post("/widgetEngagement", {
    brandId,
    collectionId,
    isFirstEngagement,
    eventType,
  });
}

// Common
function getChapters(id, brandId, type) {
  return apiClient("widgets").post("/getChapters", { id, brandId, type });
}

function getStories(id, brandId, type, chapterName) {
  return apiClient("widgets").post("/getStories", {
    id,
    brandId,
    type,
    chapterName,
  });
}

function updateWebWidgetCustomize(
  ownerId,
  brandId,
  textColour,
  backgroundColour,
  buttonColour,
  fontWeight,
  isShowStories,
  isShowTracker,
  isShowMap,
  isApplyToAll
) {
  return apiClient("base").post("/updateWebWidgetCustomize", {
    ownerId,
    brandId,
    textColour,
    backgroundColour,
    buttonColour,
    fontWeight,
    isShowStories,
    isShowTracker,
    isShowMap,
    isApplyToAll,
  });
}

function getWebWidgetCustomize(brandId, ownerId) {
  return apiClient("widgets").post("/getWebWidgetCustomize", {
    brandId,
    ownerId,
  });
}

function widgetEngagement(
  ownerId,
  brandId,
  productId,
  collectionId,
  isFirstEngagement,
  eventType,
  source
) {
  return apiClient("widgets").post("/widgetEngagement", {
    ownerId,
    brandId,
    productId,
    collectionId,
    isFirstEngagement,
    eventType,
    source,
  });
}

function createEmbeddedStoryViewHistory(
  brandId,
  ownerId,
  type,
  id,
  userId,
  url,
  source
) {
  return apiClient("widgets").post("/createEmbeddedStoryViewHistory", {
    brandId,
    ownerId,
    type,
    id,
    userId,
    url,
    source,
  });
}

function getBrandCurrency(brandId) {
  return apiClient("widgets").post("/getBrandCurrency", { brandId });
}

function getWidgetInitialData({
  type,
  entityId,
  host,
  ownerId,
  source,
  shopId,
}) {
  return apiClient("widgets").post("/getWidgetinitialData", {
    type,
    entityId,
    host,
    ownerId,
    source,
    shopId,
  });
}

// Tracker
function rootipStoryTelling(brandId) {
  return apiClient("widgets").post("/rootipStoryTelling", {
    brandId,
  });
}

function rootipScore(brandId) {
  return apiClient("widgets").post("/getTransparencyScore", {
    brandId,
  });
}

// Maps
function mapView(brandId, type, entityId) {
  return apiClient("widgets").post("/showMapOnEntityV2", {
    brandId,
    entityId,
    type,
  });
}

export default {
  //getStoryChapters,
  // getProductStories,
  //getProductV3,
  //getWebWidgetCustomize,
  updateWebWidgetCustomize,
  //getBrandStoryChapters,
  //getBrandStories,
  widgetEngagement,
  //getBrandCurrency,
  //getCollectionStoryChapters,
  //getCollectionStories,
  //getCollection,
  collectionWidgetEngagement,
  rootipStoryTelling,
  rootipScore,
  mapView,
  getWidgetInitialData,
  createEmbeddedStoryViewHistory,
  getChapters,
  getStories,
};
