import React, { useContext, useState, useEffect, useRef } from "react";
import Button from "@common/Button/Button";
import { useMachine } from "@xstate/react";
import fetchMachine from "../../../machines/fetchMachine";
import service from "@app/services/stripeServices";
import SVGGift from "@icons/gift.svg";
import LoaderIcon from "@common/LoaderIcon/LoaderIcon";
import { FaTimes } from "@react-icons";
import StoriesContext from "@app/context/StoriesContext";
import logoPng from "@app/assets/images/logo.png";
import { ROOT_ID } from "@app/constants/constants";
import StoriesWidgetContext from "../../../context/StoriesWidgetContext";
import AppContext from "../../../context/AppContext";

function StripeForm({ tipModalSend, tip, complement, product, story }) {
  var { currentUser } = useContext(
    widgetName == "storiesPreview" ? AppContext : StoriesWidgetContext
  );
  var {
    engagementSend,
    host,
    brandCurrency,
    brandId,
    widgetName,
    stripePubKey,
    stripePromise,
  } = useContext(StoriesContext);

  var user = currentUser?.user;
  console.log(user);
  var [stripeId, setStripeId] = useState(null);
  var stripeRef = useRef(null);
  var submitRef = useRef(null);
  var submitBtnRef = useRef(null);
  var payReqRef = useRef(null);
  var [clientSecret, setClientSecret] = useState(null);
  var [stripeCountries, setStripeCountries] = useState([]);

  var [stripeCountriesState, stripeCountriesSend] = useMachine(fetchMachine, {
    actions: {
      goFetch: () => {
        service.getAllStripeCountries().then((data) => {
          setStripeCountries(data.country);
          if (currentUser?.user?.location?.country) {
            var cn = data.country.find(
              (i) => i.title === currentUser?.user?.location?.country
            );
            if (cn) {
              setCountry(cn.title);
              setCountryCode(cn.countryCode);
            } else {
              setCountry("Australia");
              setCountryCode("AT");
            }
          } else {
            setCountry("Australia");
            setCountryCode("AT");
          }
          stripeCountriesSend("RESOLVE");
        });
      },
    },
  });

  useEffect(() => {
    stripeCountriesSend("FETCH");
  }, []);

  var [payState, paySend] = useMachine(fetchMachine, {
    actions: {
      goFetch: () => {
        service
          .payTip({
            amount: tip * 100,
            currency: brandCurrency.currency,
            storyId: story.id,
            productId: product ? product.product.id : "unknown",
            brandId: brandId,
            complement: complement,
            paytype: "card",
            info: {
              email: email,
              location: {
                city: city,
                country: country,
                countryCode: countryCode,
              },
              firstName: firstName,
              lastName: lastName,
              addressline1: addressline1,
              addressline2: addressline2,
              phoneNumber: phoneNumber,
              postalCode: postalCode,
              stripeId: stripeId,
            },
            source: "web",
          })
          .then((res) => {
            setClientSecret(res.client_secret);
          });
      },
    },
  });

  var [paymentMethod, setPaymentMethod] = useState(null);

  var [confirmState, confirmSend] = useMachine(fetchMachine, {
    actions: {
      goFetch: () => {
        stripePromise.then((res) => {
          res
            .confirmCardPayment(clientSecret, {
              payment_method: paymentMethod,
              setup_future_usage: "off_session",
            })
            .then((res) => {
              if (res.paymentIntent.status == "succeeded") {
                confirmSend("RESOLVE");
              }
            });
        });
      },
    },
  });

  var [email, setEmail] = useState("");
  var [firstName, setFirstName] = useState("");
  var [lastName, setLastName] = useState("");
  var [country, setCountry] = useState("Australia");
  var [countryCode, setCountryCode] = useState("AT");
  var [city, setCity] = useState("");
  var [addressline1, setAddressline1] = useState("");
  var [addressline2, setAddressline2] = useState("");
  var [postalCode, setPostalCode] = useState("");
  var [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setFirstName(user.firstName);
      setLastName(user.lastName);
      //setCountry(user.location.country);
      //setCountryCode(user.location.countryCode);
      setCity(user.location.city);
      setAddressline1(user.addressline1);
      setAddressline2(user.addressline2);
      setPostalCode(user.postalCode);
      setPhoneNumber(user.phoneNumber);
    }
  }, [user]);

  useEffect(() => {
    stripeRef.current.addEventListener("change", ({ target }) => {
      !target.validate() ||
      !email ||
      !firstName ||
      !lastName ||
      !country ||
      !countryCode ||
      !city ||
      !addressline1 ||
      !addressline2 ||
      !postalCode ||
      !phoneNumber
        ? submitRef.current.children[0].setAttribute("disabled", "")
        : submitRef.current.children[0].removeAttribute("disabled");
    });
  }, [
    stripeRef,
    submitBtnRef,
    email,
    firstName,
    lastName,
    country,
    countryCode,
    city,
    addressline1,
    addressline2,
    postalCode,
    phoneNumber,
  ]);

  useEffect(() => {
    if (
      !email ||
      !firstName ||
      !lastName ||
      !country ||
      !countryCode ||
      !city ||
      !addressline1 ||
      !addressline2 ||
      !postalCode ||
      !phoneNumber ||
      !stripeRef.current?.validate()
    ) {
      submitRef.current.children[0].setAttribute("disabled", "");
    } else {
      submitRef.current.children[0].removeAttribute("disabled");
    }
  }, [
    email,
    firstName,
    lastName,
    country,
    countryCode,
    city,
    addressline1,
    addressline2,
    postalCode,
    phoneNumber,
  ]);

  var [paymentMethodState, setPaymentMethodState] = useState("idle");

  useEffect(() => {
    submitRef.current.addEventListener("click", (e) => {
      setPaymentMethodState("pending");
      stripeRef.current.createPaymentMethod();
      stripeRef.current.addEventListener("payment-method", (res) => {
        var { detail } = res;
        setPaymentMethodState("resolved");
        setPaymentMethod(detail.id);
        setStripeId(detail.id);
      });
    });
  }, [submitRef]);

  useEffect(() => {
    stripeId && paySend("FETCH");
  }, [stripeId]);

  useEffect(() => {
    clientSecret && confirmSend("FETCH");
  }, [clientSecret]);

  useEffect(() => {
    stripeCountries.map((element) => {
      if (element.title == country) {
        setCountryCode(element.stripeCode);
      }
    });
  }, [country]);

  return (
    <>
      {!confirmState.matches("resolved") && (
        <>
          <form className="flex flex-col">
            <Button
              type="link"
              action={() => {
                tipModalSend("CLOSE");
              }}
              styles={{
                marginLeft: "auto",
              }}
            >
              <FaTimes size="32" />
            </Button>
            <h3 className="text-primary text-3 font-weight-normal mb-2">
              Personal Details
            </h3>
            <input
              required
              className="required border w-100 rounded-1 mb-4 p-2 text-muted text-3"
              type="email"
              name="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            ></input>
            <input
              required
              className="required border w-100 rounded-1 mb-4 p-2 text-muted text-3"
              type="text"
              name="firsName"
              placeholder="Enter first name"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            ></input>
            <input
              required
              className="required border w-100 rounded-1 mb-4 p-2 text-muted text-3"
              type="text"
              name="lastName"
              placeholder="Enter last name"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            ></input>
            {/* <input
              className="required border w-100 rounded-1 mb-4 p-2 text-muted text-3"
              type="text"
              name="country"
              placeholder="Enter country"
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
            ></input> */}
            <select
              required
              value={country.title}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
              className="required border w-100 rounded-1 mb-4 p-2 text-muted text-3"
            >
              {stripeCountries.map((country) => (
                <option key={country.title} value={country.title}>
                  {country.title}
                </option>
              ))}
            </select>
            <input
              required
              className="required border w-100 rounded-1 mb-4 p-2 text-muted text-3"
              type="text"
              name="city"
              placeholder="Enter city"
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
            ></input>
            <input
              required
              className="required border w-100 rounded-1 mb-4 p-2 text-muted text-3"
              type="text"
              name="addressLine1"
              placeholder="Enter address line1"
              value={addressline1}
              onChange={(e) => {
                setAddressline1(e.target.value);
              }}
            ></input>
            <input
              required
              className="required border w-100 rounded-1 mb-4 p-2 text-muted text-3"
              type="text"
              name="addressLine2"
              placeholder="Enter address line2"
              value={addressline2}
              onChange={(e) => {
                setAddressline2(e.target.value);
              }}
            ></input>
            <input
              required
              className="required border w-100 rounded-1 mb-4 p-2 text-muted text-3"
              type="text"
              name="postalCode"
              placeholder="Enter postal code"
              value={postalCode}
              onChange={(e) => {
                setPostalCode(e.target.value);
              }}
            ></input>
            <input
              required
              className="required border w-100 rounded-1 mb-4 p-2 text-muted text-3"
              type="text"
              name="phoneNumber"
              placeholder="Enter phone number"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
            ></input>

            <stripe-elements
              generate="token"
              ref={stripeRef}
              publishable-key={stripePubKey}
            >
              {" "}
            </stripe-elements>
            {payState.matches("pending") ||
            confirmState.matches("pending") ||
            paymentMethodState == "pending" ? (
              <div className="mb-4">
                <LoaderIcon width={38} height={38} />
              </div>
            ) : (
              <>
                <mwc-button ref={submitRef}>
                  <Button
                    disabled
                    type="primary"
                    size="lg"
                    stretch
                    action={() => {}}
                    className="mb-4"
                  >
                    Submit
                  </Button>
                </mwc-button>
              </>
            )}

            <Button
              type="primary"
              size="lg"
              stretch
              action={() => {
                tipModalSend("BACK");
              }}
            >
              Back
            </Button>
            {(host == "customer" || host == "dashboard") && (
              <div className="flex items-center mt-4">
                <span className="text-3">Powered by&nbsp;</span>
                <a
                  onClick={() => engagementSend("CLICK_POWERED_BY")}
                  href="https://rootip.io"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    height="16px"
                    src={ROOT_ID + logoPng}
                    alt="Rootip Logo"
                  ></img>
                </a>
              </div>
            )}
          </form>
        </>
      )}
      {confirmState.matches("resolved") && (
        <>
          <div>
            <SVGGift />
            <p className="py-4">
              Your tip is on its way, thank you for sharing the love!
            </p>
            <Button
              type="primary"
              size="lg"
              stretch
              action={() => {
                tipModalSend("CLOSE");
              }}
            >
              Continue
            </Button>
          </div>
        </>
      )}
    </>
  );
}

export default StripeForm;
