import { useMachine } from "@xstate/react";
import React, { useEffect, useMemo, useState } from "react";
import StoriesWidgetContext from "../../context/StoriesWidgetContext";
import fetchMachine from "../../machines/fetchMachine";
import Stories from "../common/Stories/Stories";
import { firebase } from "../../constants/constants";
import stripeServices from "../../services/stripeServices";
import authServices from "../../services/authServices";

import { loadStripe } from "@stripe/stripe-js";
authServices;
function StoriesWidget({
  ownerId,
  widgetId,
  widgetType,
  widgetPreview,
  widgetName = null,
  isWebComponent,
  shopId,
  source,
  poweredby=false
}) {
  var [user, setUser] = useState(null);
  var [currentUser, setCurrentUser] = useState(null);
  var [urlParams, setUrlParams] = useState(null);

  var [authState, authSend] = useMachine(fetchMachine, {
    actions: {
      goFetch: () => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            setUser(user);
            authSend("RESOLVE");
            idTokenSend("FETCH");
          } else {
            setUser(null);
            authSend("REJECT");
            idTokenSend("DENY");
            userSend("DENY");
          }
        });
      },
    },
  });

  var [idToken, setIdtoken] = useState(null);
  var [idTokenState, idTokenSend] = useMachine(fetchMachine, {
    actions: {
      goFetch: () => {
        firebase
          .auth()
          .currentUser?.getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            setIdtoken(idToken);
            idTokenSend("RESOLVE");
            userSend("FETCH");
          });
      },
    },
  });

  var [userState, userSend] = useMachine(fetchMachine, {
    actions: {
      goFetch: () => {
        authServices
          .getCurrentUser(idToken)
          .then((currentUser) => {
            setCurrentUser(currentUser);
            userSend("RESOLVE");
          })
          .catch((error) => {
            console.log(error);
            userSend("REJECT");
          });
      },
    },
  });

  useEffect(() => {
    setUrlParams(new URLSearchParams(window.location.search));
  }, []);

  useEffect(() => {
    authSend("FETCH");
  }, []);

  var context = useMemo(
    () => ({
      firebase,
      user,
      setUser,
      currentUser,
      urlParams,
      widgetName,
      userState,
      idToken,
 
    }),
    [
      firebase,
      user,
      setUser,
      currentUser,
      urlParams,
      widgetName,
      userState,
      idToken,
 
    ]
  );
  return (
    <StoriesWidgetContext.Provider value={context}>
      <Stories
        widgetId={widgetId}
        type={widgetType}
        preview={widgetPreview}
        widgetName={widgetName}
        ownerId={ownerId}
        isWebComponent={isWebComponent}
        shopId={shopId}
        source={source}
        poweredby={poweredby}
      ></Stories>
    </StoriesWidgetContext.Provider>
  );
}
export default StoriesWidget;
