/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { ROOT_ID } from "../../../../constants/constants";
import logoPng from "@app/assets/images/logo.png";
import visa from "@app/assets/images/visa.png";
import stripe from "@app/assets/images/stripe.png";
import master_card from "@app/assets/images/master-card.png";
const Footer = () => {
  return (
    <footer className="nomargin sm:bg-white sm:border-t bg-light mt-32">
      <div className="container py-16">
        <div className="w-full flex-col flex items-center">
          <div className="text-center w-full  ">
            <div className=" items-center flex justify-center">
              {/* logo */}
              <a className="tt-logo tt-logo-alignment" href="index.html">
                <img src={ROOT_ID + logoPng} className="w-32 h-8 mt-6" alt="" />
              </a>
              {/* /logo */}
            </div>
          </div>
          <div className="w-32  flex items-center justify-center mt-10 ">
            <div className="w-1/2 flex justify-center">
              <a href="" data-tip="facebook">
                <FaFacebookF color="#666666" />
              </a>
            </div>

            <div className="w-1/2 flex justify-center font-bold">
              <a href="" data-tip="instagram ">
                <FaInstagram color="#666666" />
              </a>
            </div>
          </div>

          <div className="col-12 text-center mt-7">
            <ul className="footer-links w-full flex flex-wrap justify-around">
              <li className="w-fit p-4">
                <a href="aboutus.html">About us</a>
              </li>
              <li className="w-fit p-4">
                <a href="values.html">Values</a>
              </li>
              <li className="w-fit p-4">
                <a href="technology.html">Technology</a>
              </li>
              {/*<li><a href="faqs.html">FAQs</a></li>
     <li><a href="pricing.html">Pricing</a></li> */}
              <li className="w-fit p-4">
                <a href="privacy-policy.html">Privacy policy</a>
              </li>
              <li className="w-fit p-4">
                <a href="termsconditions.html">Terms &amp; conditions</a>
              </li>
              <li className="w-fit p-4">
                <a href="contactus.html">Contact us</a>
              </li>
            </ul>
          </div>

          <div className="w-full flex justify-center flex-row-reverse">
            <div className="  ">
              <img
                src={ROOT_ID + master_card}
                className="w-full h-full "
                alt=""
              />
            </div>
            <div className="   ">
              <img src={ROOT_ID + visa} className="w-full h-full " alt="" />
            </div>
            <div className="   ">
              <img src={ROOT_ID + stripe} className="w-full h-full " alt="" />
            </div>
          </div>
        </div>
        {/* copyright */}
        <p className="text-center copyright text-lg">
          2022 © Rootip <span className="hide-phone">- rootip.io</span>
        </p>
        {/* /copyright */}
      </div>
    </footer>
  );
};

export default Footer;
