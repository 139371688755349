import React, { cloneElement, useState } from "react";

function Tab({
  title,
  label,
  id,
  changeTab,
  active,
  itemClass,
  itemStyle = {},
  activeClass,
  activeStyle,
}) {
  return (
    <li
      className={itemClass ? itemClass : ""}
      style={active == id ? activeStyle : itemStyle}
    >
      <button
        style={active == id ? activeStyle : itemStyle}
        onClick={() => changeTab(id)}
        className={
          "w-100 bg-light p-2 px-3" +
          " " +
          (active == id ? "active" : "text-inherit") +
          " " +
          (active == id ? activeClass : "")
        }
      >
        {label ? label : title}
      </button>
    </li>
  );
}

function Tabs(props) {
  var {
    selector,
    items,
    listClass,
    itemClass,
    itemStyle,
    activeClass,
    contentClass,
    activeStyle,
    listStyle,
  } = props;
  var [active, setActive] = useState("0");
  function changeTab(key) {
    setActive(key);
  }
  return (
    <div className={selector ? selector : ""}>
      <nav className={selector ? selector + "_tabs" : ""}>
        <ul className={listClass ? listClass : ""} style={listStyle}>
          {items.map((item) => (
            <Tab
              title={item.props.title}
              label={item.props.label}
              key={item.key}
              id={item.key}
              changeTab={() => changeTab(item.key)}
              active={active}
              itemClass={itemClass}
              itemStyle={itemStyle}
              activeClass={activeClass}
              activeStyle={activeStyle}
            />
          ))}
        </ul>
      </nav>
      <div
        className={
          selector
            ? selector + "_view"
            : "" + " " + contentClass
            ? contentClass
            : ""
        }
      >
        {items.map((item) => {
          return active == item.key ? cloneElement(item) : null;
        })}
      </div>
    </div>
  );
}

export default Tabs;
