import React, { useState, useEffect } from "react";
import { useMachine } from "@xstate/react";
import { format, subDays, differenceInDays } from "date-fns";

import { DateRange } from "react-date-range";

import LoaderIcon from "@common/LoaderIcon/LoaderIcon";
import Icon from "@common/Icon/Icon";
import Button from "@common/Button/Button";
import LineChart from "@common/LineChart/LineChart";
import fetchMachine from "@app/machines/fetchMachine";

function Metric({
  label = "NA",
  target = "NA" || 0,
  data = [],
  setData,
  service,
}) {
  var points = {
    "30 Days": [
      format(new Date(), "yyyyMMdd"),
      format(subDays(new Date(), 15), "yyyyMMdd"),
      format(subDays(new Date(), 30), "yyyyMMdd"),
    ],
    "60 Days": [
      format(new Date(), "yyyyMMdd"),
      format(subDays(new Date(), 30), "yyyyMMdd"),
      format(subDays(new Date(), 60), "yyyyMMdd"),
    ],
    "90 Days": [
      format(new Date(), "yyyyMMdd"),
      format(subDays(new Date(), 45), "yyyyMMdd"),
      format(subDays(new Date(), 90), "yyyyMMdd"),
    ],
    "1 Year": [
      format(new Date(), "yyyyMMdd"),
      format(subDays(new Date(), 30), "yyyyMMdd"),
      format(subDays(new Date(), 60), "yyyyMMdd"),
      format(subDays(new Date(), 90), "yyyyMMdd"),
      format(subDays(new Date(), 120), "yyyyMMdd"),
      format(subDays(new Date(), 150), "yyyyMMdd"),
      format(subDays(new Date(), 180), "yyyyMMdd"),
      format(subDays(new Date(), 210), "yyyyMMdd"),
      format(subDays(new Date(), 240), "yyyyMMdd"),
      format(subDays(new Date(), 270), "yyyyMMdd"),
      format(subDays(new Date(), 300), "yyyyMMdd"),
      format(subDays(new Date(), 330), "yyyyMMdd"),
      format(subDays(new Date(), 365), "yyyyMMdd"),
    ],
  };
  var [customPoint, setCustomPoint] = useState(null);
  var [option, setOption] = useState("30 Days");
  var [state, send] = useMachine(fetchMachine, {
    actions: {
      goFetch: () => {
        service(customPoint ? customPoint : points[option])
          .then((data) => {
            setData(
              data.map((d) => {
                return {
                  key: new Date(d.key),
                  value: d.value,
                };
              })
            );
            send("RESOLVE");
          })
          .catch((error) => {
            setData(error);
            send("REJECT");
          });
      },
    },
  });

  useEffect(() => {
    send("FETCH");
  }, [option, customPoint]);

  var [showDatePicker, setShowDatePicker] = useState(false);
  var [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  function handleSelect(event) {
    if (event.target.value == "custom") {
      setShowDatePicker(true);
      return;
    }
    setOption(event.target.value);
  }

  return (
    <>
      <div className="metric">
        <div className="header">
          <h3>{label}</h3>
        </div>
        <div className="middle">
          <p>
            Target - <span>{target}</span>
          </p>
          <div className="calendar-container">
            <Icon name="calendar-event" size={24} />
            {showDatePicker ? (
              <div className="date-picker">
                <DateRange
                  editableDateInputs={true}
                  onChange={(item) => setRange([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={range}
                />
                <div className="date-picker_bottom-row">
                  <Button
                    action={() => setShowDatePicker(false)}
                    title="Cancel"
                    type="cancel"
                  />
                  <Button
                    action={() => {
                      setCustomPoint([
                        format(range[0].startDate, "yyyyMMdd"),
                        format(
                          subDays(
                            range[0].startDate,
                            differenceInDays(
                              range[0].startDate,
                              range[0].endDate
                            ) / 2
                          ),
                          "yyyyMMdd"
                        ),
                        format(range[0].endDate, "yyyyMMdd"),
                      ]);
                      setOption("custom");
                      setShowDatePicker(false);
                    }}
                    title="Apply"
                    type="primary"
                  />
                </div>
              </div>
            ) : null}
            <select
              value={option}
              onChange={handleSelect}
              onBlur={handleSelect}
            >
              <option value="30 Days">30 Days</option>
              <option value="60 Days">60 Days</option>
              <option value="90 Days">90 Days</option>
              <option value="1 Year">1 Year</option>
              <option value="custom">Custom</option>
            </select>
          </div>
        </div>
        <div className="chart">
          {state.matches("resolved") && <LineChart data={data} target={35} />}
          {state.matches("pending") && <LoaderIcon />}
        </div>
      </div>
      <style jsx global>{`
        .chart .line {
          fill: none;
          stroke: var(--color-primary);
          stroke-width: 1;
        }

        .chart .overlay {
          fill: none;
          pointer-events: all;
        }

        /* Style the dots by assigning a fill and stroke */
        .chart .dot {
          fill: var(--color-light);
          stroke: var(--color-primary);
        }
        .chart .dot:hover {
          fill: var(--color-primary);
          stroke: var(--color-light);
        }
        .chart .axis {
          font-size: 6px;
        }
        .chart .axis .tick line {
          stroke: none;
        }

        .chart .domain {
          display: none;
        }
        .chart .grid line {
          stroke: var(--color-grey-light);
        }
      `}</style>
      <style jsx>{`
        .metric {
          box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
            rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
          padding: 1rem;
          background-color: var(--color-light);
          border-radius: var(--border-radius-general);
        }
        .header {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
        .header > h3 {
          margin-right: auto;
        }
        .middle {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1rem 0;
        }
        .calendar-container {
          display: flex;
          position: relative;
        }
        .date-picker {
          position: absolute;
          min-width: fit-content;
          display: flex;
          flex-direction: column;
        }
        select {
          border: none;
          background-color: transparent;
        }
      `}</style>
    </>
  );
}

export default Metric;
