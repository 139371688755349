import React, { useContext, useEffect, useState } from "react";

import service from "@app/services/rootipAdminServices";
import useFetch from "../../../../../hooks/useFetch";
import SpiderChart from "./SpiderChart";

function UsageCharts() {
  var [suggestedMax, setSuggestedMax] = useState(0);
  var [allUsage, setAllUsage] = useState();
  var [customUsage, setCustomUsage] = useState();

  useEffect(() => {
    var tempSuggestedMax = 0;
    if (allUsage) {
      allUsage.map((item) => {
        if (tempSuggestedMax < item.score) {
          tempSuggestedMax = item.score;
        }
      });
    }
    setSuggestedMax(tempSuggestedMax);
  }, [allUsage]);

  var [storyUsageReport, , storyUsageReportSend] = useFetch(
    service.storyUsageStateReport,
    [],
    (data) => {
      setAllUsage(data.allUsage);
      setCustomUsage(data.customUsage);
      storyUsageReportSend("RESOLVE");
    }
  );

  useEffect(() => {
    storyUsageReportSend("FETCH");
  }, []);

  return allUsage && customUsage && suggestedMax > 0 ? (
    <>
      <div className="flex flex-row">
        <div className=" flex flex-col info-card w-50">
          <p className="ml-2 mb-1 info-label ">Usage Of All Story Chapters</p>
          <SpiderChart
            key={7}
            spiderData={allUsage}
            suggestedMax={suggestedMax}
          />
        </div>
        <div className=" flex flex-col info-card w-50">
          <p className="ml-2 mb-1 info-label ">
            Usage Of Custom Story Chapters
          </p>
          <SpiderChart
            key={8}
            spiderData={customUsage}
            suggestedMax={suggestedMax}
          />
        </div>
      </div>
      <style jsx>{`
        .info-card {
          display: flex;
          padding-right: 4px;
          padding-top: 4px;
          margin-left: 5px;
          box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
            rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
          border-radius: var(--border-radius-general);
          background-color: var(--color-light);
        }

        .kpis-container {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 2rem;
          margin-bottom: 2rem;
        }
      `}</style>
    </>
  ) : (
    <> </>
  );
}
export default UsageCharts;
