import React, { useMemo, useState, useEffect, useRef } from "react";

function StoryChaptersChild({
  storyChaptersItem,
  item_index,
  setStoryChaptersPt,
  storyChaptersPt,
}) {
  const [inputFields, setInputFields] = useState([
    {
      custom_point: storyChaptersItem.custom_point,
      predefined_point: storyChaptersItem.predefined_point,
      maxScore: storyChaptersItem.maxScore,
    },
  ]);

  const handleFormChange = (index, event) => {
    event.preventDefault();
    let data = [...inputFields];
    data[index][event.target.name] = Number(event.target.value);
    setInputFields(data);
    storyChaptersPt[item_index][event.target.name] = Number(event.target.value);
    setStoryChaptersPt(storyChaptersPt);
  };

  return storyChaptersItem ? (
    <>
      <div className=" info-card ">
        {inputFields.map((input, index) => {
          return (
            <>
              <label className="ml-2  font-weight-bold">
                {storyChaptersItem.chapterName}
              </label>
              <div key={index} className="grid grid-cols-3 gap-1">
                <div className="flex md:flex-col p-1">
                  <label className="text-14px">Custom Story Point</label>

                  <input
                    className="w-60 p-2 border"
                    name="custom_point"
                    type="number"
                    placeholder="Custom point"
                    value={input.custom_point}
                    onChange={(event) => handleFormChange(index, event)}
                  />
                </div>
                <div className="flex md:flex-col p-1">
                  <label className="text-14px">Predefined Story Point</label>

                  <input
                    className="w-60 p-2 border"
                    type="number"
                    name="predefined_point"
                    placeholder="predefined point"
                    value={input.predefined_point}
                    onChange={(event) => handleFormChange(index, event)}
                  />
                </div>

                <div className="flex md:flex-col  p-1">
                  <label className="text-14px">Max Score</label>

                  <input
                    className="w-60 p-2 border"
                    name="maxScore"
                    type="number"
                    placeholder="maxScore"
                    value={input.maxScore}
                    onChange={(event) => handleFormChange(index, event)}
                  />
                </div>
              </div>
            </>
          );
        })}
      </div>
      <style jsx>{`
      .info-card {
        margin: 5px;
        padding-top: 4px;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
          rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        border-radius: var(--border-radius-general);
        background-color: var(--color-light);

      `}</style>
    </>
  ) : (
    <div>hello2</div>
  );
}
export default StoryChaptersChild;
