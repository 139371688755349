import React from "react";
import ContetnLoader from "react-content-loader";
import Slider from "react-slick";

function StoriesShimmer({ shimmersSlickSettings }) {
  return (
    <>
      <Slider {...shimmersSlickSettings}>
        <div>
          <div className="border rounded-2 overflow-hidden flex flex-col m-auto bg-light text-dark na-story-card">
            <ContetnLoader viewBox="0 0 400 460">
              <circle cx="40" cy="40" r="30" />
              <rect x="110" y="15" rx="3" ry="3" width="70" height="10" />
              <rect x="110" y="35" rx="3" ry="3" width="88" height="10" />
              <rect x="110" y="55" rx="3" ry="3" width="100" height="10" />
              <rect x="0" y="85" width="400" height="200" />
              <rect x="10" y="320" rx="3" ry="3" width="330" height="10" />
              <rect x="10" y="340" rx="3" ry="3" width="330" height="10" />
              <rect x="10" y="360" rx="3" ry="3" width="100" height="10" />
            </ContetnLoader>
          </div>
        </div>
        <div>
          <div className="border rounded-2 overflow-hidden flex flex-col m-auto bg-light text-dark na-story-card">
            <ContetnLoader viewBox="0 0 400 460">
              <circle cx="40" cy="40" r="30" />
              <rect x="110" y="15" rx="3" ry="3" width="70" height="10" />
              <rect x="110" y="35" rx="3" ry="3" width="88" height="10" />
              <rect x="110" y="55" rx="3" ry="3" width="100" height="10" />
              <rect x="0" y="85" width="400" height="200" />
              <rect x="10" y="320" rx="3" ry="3" width="330" height="10" />
              <rect x="10" y="340" rx="3" ry="3" width="330" height="10" />
              <rect x="10" y="360" rx="3" ry="3" width="100" height="10" />
            </ContetnLoader>
          </div>
        </div>
        <div>
          <div className="border rounded-2 overflow-hidden flex flex-col m-auto bg-light text-dark na-story-card">
            <ContetnLoader viewBox="0 0 400 460">
              <circle cx="40" cy="40" r="30" />
              <rect x="110" y="15" rx="3" ry="3" width="70" height="10" />
              <rect x="110" y="35" rx="3" ry="3" width="88" height="10" />
              <rect x="110" y="55" rx="3" ry="3" width="100" height="10" />
              <rect x="0" y="85" width="400" height="200" />
              <rect x="10" y="320" rx="3" ry="3" width="330" height="10" />
              <rect x="10" y="340" rx="3" ry="3" width="330" height="10" />
              <rect x="10" y="360" rx="3" ry="3" width="100" height="10" />
            </ContetnLoader>
          </div>
        </div>
      </Slider>
    </>
  );
}

export default StoriesShimmer;
